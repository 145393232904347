import {
  PURGE_USER,
  SIGN_IN_SUCCESS,
  SIGN_IN_FAIL,
  SIGN_OUT,
  RESTORE_USER,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAIL,
  UPDATE_USER_PROFILE,
} from "./actionTypes";

const initialState = {
  signedIn: false,
  subscribeImageChange: 0,
  state: "NOT_SIGNED_IN",
  updated: false,
  AccessToken: false,
};

const user = (state = initialState, action) => {
  switch (action.type) {
    case PURGE_USER:
      return initialState;

    case SIGN_IN_SUCCESS:
    case RESTORE_USER:
    case UPDATE_USER_SUCCESS:
      return { ...state, ...action.profile };

    case SIGN_OUT:
      return { ...initialState };

    case UPDATE_USER_PROFILE:
      return { ...state, subscribeImageChange: state.subscribeImageChange + 1 };

    case SIGN_IN_FAIL:
    case UPDATE_USER_FAIL:
      // console.error("[User Reducer]", action.payload);
      return state;
    default:
      break;
  }
  return state;
};

export default user;
