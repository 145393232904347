import { makeFreshAllIds } from "../helpers";
import {
  PURGE_TIMETABLE,
  GET_TIMETABLE_SUCCESS,
  GET_TIMETABLE_FAIL,
  GET_TIMETABLES_BY_GROUP_SUCCESS,
  GET_TIMETABLES_BY_GROUP_FAIL,
  CREATE_TIMETABLE_SUCCESS,
  CREATE_TIMETABLE_FAIL,
  UPDATE_TIMETABLE_SUCCESS,
  UPDATE_TIMETABLE_FAIL,
  DELETE_TIMETABLE_SUCCESS,
  DELETE_TIMETABLE_FAIL,
} from "./actionTypes";

const initialState = {
  byId: {},
  allIds: [],
};

const timetable = (state = initialState, action) => {
  switch (action.type) {
    case PURGE_TIMETABLE:
      return initialState;

    case GET_TIMETABLE_SUCCESS:
    case CREATE_TIMETABLE_SUCCESS:
    case UPDATE_TIMETABLE_SUCCESS:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.timetable.timetableId]: action.timetable,
        },
        allIds: makeFreshAllIds(state.allIds, [action.timetable.timetableId]),
      };

    case GET_TIMETABLES_BY_GROUP_SUCCESS:
      return {
        ...state,
        byId: { ...state.byId, ...action.byId },
        allIds: makeFreshAllIds(state.allIds, action.allIds),
      };

    case DELETE_TIMETABLE_SUCCESS:
    // const newAllIds = [
    //   ...state.allIds.filter((id) => id !== action.timetable.timetableId),
    // ];
    // const newById = { ...state.byId };

    // if (action.timetable.timetableId in newById) {
    //   delete newById[action.timetable.timetableId];
    // }
    // return {
    //   ...state,
    //   allIds: newAllIds,
    //   byId: newById,
    // };

    case GET_TIMETABLE_FAIL:
    case GET_TIMETABLES_BY_GROUP_FAIL:
    case CREATE_TIMETABLE_FAIL:
    case UPDATE_TIMETABLE_FAIL:
    case DELETE_TIMETABLE_FAIL:
      // console.error("[Timetable Reducer]", action.payload);
      return state;
    default:
      break;
  }
  return state;
};

export default timetable;
