import React, { useRef, useState } from "react";
import ProfileImage from "../ProfileImage";
import styles from "./ModalUpdateProfileImage.module.scss";
import UploadFileRoundedIcon from "@mui/icons-material/UploadFileRounded";
import { getProfileImageKey, uploadImage } from "@api";
import { useDispatch, useSelector } from "react-redux";
import { setReportLog, updateUserProfile } from "@app/store/actions";
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  VStack,
} from "@chakra-ui/react";
import { ButtonConstants } from "@app/Constants/ButtonConstants";
import { t } from "i18next";
import { useTranslation } from "react-i18next";

const ModalUpdateProfileImage = ({ isOpen, onClose, currentProfileImage }) => {
  const { t } = useTranslation();
  const [file, setFile] = useState();
  const [preview, setPreview] = useState();
  const [img, setImg] = useState();
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleImage = (e) => {
    const file = e.target.files[0];
    const url = URL.createObjectURL(file);
    setFile(file);
    setPreview(url);

    setImg({
      uri: url,
      type: file.type,
      name: file.name,
    });
  };

  const save = async () => {
    try {
      uploadImage(file, getProfileImageKey(user.clientId)).then(() => {
        dispatch(updateUserProfile());
        // alert("프로필 사진이 변경되었습니다.");
        alert(t("sidemenu.setting.alert.changeprofile.success.text"));
        onClose();
      });
    } catch (e) {
      // alert(e);
      console.error(e);
      onClose();
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {/* 프로필 사진 변경 */}
          {t("sidemenu.setting.button.changeprofile")}
        </ModalHeader>
        <ModalBody>
          <VStack spacing={4}>
            <ProfileImage
              size={120}
              url={preview ? preview : currentProfileImage}
              subscribeImageChange={user.subscribeImageChange}
            />
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: "none" }}
              onChange={handleImage}
            />
            <Button
              leftIcon={<UploadFileRoundedIcon />}
              component="span"
              onClick={handleButtonClick}
            >
              {/* 이미지 불러오기 */}
              {t("sidemenu.setting.modal.button.changeimage")}
            </Button>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={() => {
              onClose();
              dispatch(
                setReportLog(ButtonConstants.CONFIG.CANCEL_PROFILE_IMAGE_CHANGE)
              );
            }}
            mr={3}
          >
            {/* 취소 */}
            {t("sidemenu.setting.modal.button.cancel")}
          </Button>
          <Button
            onClick={() => {
              save();
              dispatch(
                setReportLog(ButtonConstants.CONFIG.CHANGE_PROFILE_IMAGE)
              );
            }}
          >
            {/* 변경 */}
            {t("sidemenu.setting.modal.button.change")}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalUpdateProfileImage;
