import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Modal,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";

const FullScreenDialog = styled(Dialog)`
  & .MuiDialog-paper {
    height: 100%;
    width: 100%;
    max-height: none;
    max-width: none;
    // 중앙정렬
    align-items: center;
    justify-content: center;
  }
`;

const FullScreenDialogTitleContent = styled(DialogTitle)`
  &.MuiDialogTitle-root {
    margin-top: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
  }
`;

const FullScreenDialogContent = styled(DialogContent)`
  margin-bottom: 20%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

const FullScreenDialogActions = styled(DialogActions)`
  position: absolute;
  bottom: 10%;
  left: 0%;
  right: 10%;

  & .my-button {
    color: #ffffff;
    border-radius: 16px;
    background-color: #0894a0;
    &:hover {
      background-color: #056b75;
    }

    @media (min-width: 3840px) {
      font-size: 88px !important;
      padding: 20px 50px;
    }
    @media (min-width: 2560px) and (max-width: 3839px) {
      font-size: 48px !important;
      padding: 15px 40px;
    }

    @media (min-width: 1980px) and (max-width: 2559px) {
      font-size: 38px !important;
      padding: 10px 30px;
    }

    @media (min-width: 1440px) and (max-width: 1979px) {
      border-radius: 8px;
      font-size: 28px !important;
      padding: 8px 24px;
    }

    @media (max-width: 1439px) {
      border-radius: 4px;
      font-size: 24px !important;
      padding: 6px 20px;
    }
  }
`;

function FullScreenInviteCodeModal({ children, open, onClose }) {
  const { t } = useTranslation();
  const [showContent, setShowContent] = useState(open);

  useEffect(() => {
    setShowContent(open);
  }, [open]);

  const handleClose = () => {
    setShowContent(false);
    onClose();
  };

  return (
    <FullScreenDialog fullScreen open={showContent} onClose={handleClose}>
      <Typography
        variant="h1"
        sx={{
          fontSize: {
            xs: "88px",
            sm: "48px",
            md: "38px",
            lg: "28px",
            xl: "24px",
          },
          fontWeight: "bold",
          textAlign: "center",
          color: "var(--secondary)",
        }}
      >
        {/* 학생들이 수업에 참여할 수 있도록 아래 초대코드를 알려주세요. */}
        {t("timetable.nav.link.invitationcode.fullscreenmodal.text")}
      </Typography>
      {children}
      <FullScreenDialogActions>
        <Button variant="contained" size="large" onClick={handleClose}>
          {/* 닫기 */}
          {t("timetable.dashboard.addchart.button.close")}
        </Button>
      </FullScreenDialogActions>
    </FullScreenDialog>
  );
}

export default FullScreenInviteCodeModal;
