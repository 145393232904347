import {
  Divider,
  HStack,
  Icon,
  SimpleGrid,
  Stack,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { PiPlugsConnectedBold } from "react-icons/pi";
import { PiPlugsBold } from "react-icons/pi";
import StudentThumnail from "./StudentThumnail";
import { selectActiveClassroom } from "@app/store/selectors";
import { useSelector } from "react-redux";
import { useParticipationByClassroom } from "../QuizPang/hooks/useParticipation";
import ModalStudentFullScreen from "@app/components/Modal/ModalStudentFullScreen";
import { useTranslation } from "react-i18next";

const StudentThumnailList = ({
  colums,
  studentList,
  sortingOption,
  setSelectedStudent,
  selectedStudent,
  setSelectedImage,
  selectedImage,
  isOpen,
  onClose,
}) => {
  const { t } = useTranslation();

  const { images } = useSelector((state) => state.control);

  const activeClassroom = useSelector((state) => selectActiveClassroom(state));

  const participationData = useParticipationByClassroom(
    activeClassroom?.classroomId
  );

  if (participationData.isLoading) {
    return <div>로딩중</div>;
  }

  const participationStudents = participationData.participations;

  // participationStudents의 배열의 학생 리스트를 비교해서 state가 ATTEND인 학생만 뽑아낸 후 studentList에서 같은 clientId를 가진 학생을 분류해주는 함수
  const getParticipationStudents = (studentList) => {
    const result = [];
    studentList.forEach((student) => {
      participationStudents?.forEach((participationStudent) => {
        if (
          student.clientId === participationStudent.clientId &&
          participationStudent.state === "ATTEND"
        ) {
          result.push(student);
        }
      });
    });
    return result;
  };

  const participationStudentList = getParticipationStudents(studentList);

  // participationStudentList 중에서 clientId가 같은 학생의 이미지를 가져오는 함수
  const getImage = (participationStudentList) => {
    const result = [];
    participationStudentList.forEach((student) => {
      images !== undefined &&
        images !== null &&
        Object.keys(images).find((key) => key === student.clientId) &&
        result.push(images[student.clientId]);
    });
    return result;
  };

  const imageList = getImage(participationStudentList);

  // Attend가 아닌 학생들을 뽑아내는 함수
  const getOfflineStudents = (studentList) => {
    const result = [];
    studentList.forEach((student) => {
      participationStudents?.forEach((participationStudent) => {
        if (
          student.clientId === participationStudent.clientId &&
          participationStudent.state !== "ATTEND"
        ) {
          result.push(student);
        }
      });
    });
    return result;
  };

  const offlineStudents = getOfflineStudents(studentList);

  return (
    <Stack direction="column" w="full" spacing={4}>
      <ModalStudentFullScreen
        isOpen={isOpen}
        onClose={onClose}
        participationStudentList={participationStudentList}
        offlineStudents={offlineStudents}
      />
      <Stack direction="column">
        <HStack spacing={2}>
          <Icon as={PiPlugsConnectedBold} />
          <Text>
            {/* 온라인 */}
            {t("timetable.focustime.studentlist.online")}
          </Text>
          <Text fontSize="lg" fontWeight="bold">
            {participationStudentList?.length}
            {t("focustime.text.people")}
          </Text>
        </HStack>

        <SimpleGrid columns={colums} spacing={4}>
          {participationStudentList?.map((student, index) => (
            <StudentThumnail
              key={student.clientId}
              student={student}
              image={imageList[index]}
              w="full"
              h="full"
              colums={colums}
              state="online"
              setSelectedStudent={setSelectedStudent}
              selectedStudent={selectedStudent}
              setSelectedImage={setSelectedImage}
              selectedImage={selectedImage}
            />
          ))}
        </SimpleGrid>
        <Divider w="full" color="gray.300" />
      </Stack>
      <Stack direction="column">
        <HStack spacing={2}>
          <Icon as={PiPlugsBold} />
          <Text>
            {/* 오프라인 */}
            {t("timetable.focustime.studentlist.offline")}
          </Text>
          <Text fontSize="lg" fontWeight="bold">
            {offlineStudents?.length}
            {t("focustime.text.people")}
          </Text>
        </HStack>
        <SimpleGrid columns={colums} spacing={4}>
          {offlineStudents?.map((student) => (
            <StudentThumnail
              key={student.clientId}
              student={student}
              w="full"
              h="full"
              colums={colums}
              state="offline"
            />
          ))}
        </SimpleGrid>
      </Stack>
    </Stack>
  );
};

export default StudentThumnailList;
