import {
  Box,
  Button,
  Card,
  FormControl,
  Stack,
  CardFooter,
} from "@chakra-ui/react";
import SurveyTypeSelect from "./SurveyEditor/SurveyTypeSelect";
import { useChoices, useMultipleType } from "@app/hooks/form/useSurveyForm";
import TitleEditor from "./SurveyEditor/TitleEditor";
import DescripitonEditor from "./SurveyEditor/DescripitonEditor";
import SurveyCategory from "./SurveyCategory/SurveyCategory";
import { useCreateQuiz, usePatchQuizset } from "@app/api/quiz/hooks/useQuizset";
import { CHOICE } from "@app/Constants/surveyType";
import { useState } from "react";
import SurveyTimeLimit from "./SurveyEditor/SurveyTimeLimit";
import SurveyTypeEditor from "./SurveyEditor/SurveyTypeEditor";
import { QuizVideoModal } from "../Quiz/QuizVideoModal";

const SurveyForm = ({
  checked,
  quizsetId,
  quizsetData,
  surveyFormData,
  handleSurveyTitle,
  handleSurveryFormChange,
  handleSurveyDescription,
  minutesHandler,
  secondsHandler,
}) => {
  const {
    header,
    keyword,
    quizSubject,
    description,
    grade,
    quizLevel,
    quizType,
    surveyDescription,
  } = surveyFormData;
  const [saveQuizId, setSaveQuizId] = useState([{ quizId: "" }]);
  const { clientId, schoolId } = quizsetData;
  const { mutateAsync: createQuiz } = useCreateQuiz();
  const { mutateAsync: patchQuizset } = usePatchQuizset();
  const { multipleType, handleMultipleTypeChange } = useMultipleType();
  const {
    choices,
    handleChoicesAddClick,
    handleChoicesDeleteClick,
    handlChoiceDescriptionChange,
    handleEmptyChoices,
    radioSetChoices,
    tureFalseChoices,
  } = useChoices(CHOICE);
console.log(surveyFormData)
  const handleTemporarySubmit = async () => {
    const isChoicesType = quizType === "MULTIPLE" || quizType === "OX";
    const { surveyDescription, ...filterSurveyFormData } = surveyFormData;
    const defaultSaveData = {
      schoolId: schoolId,
      clientId: clientId,
      ...filterSurveyFormData,
      grade: parseInt(grade, 10),
      quizLevel: parseInt(quizLevel, 10),
    };
    if (isChoicesType) {
      const saveData = {
        ...defaultSaveData,
        choices: JSON.stringify(choices),
      };
      const quizData = await createQuiz(saveData);
      getQuizId(quizData);
    } else {
      const quizData = await createQuiz(defaultSaveData);
      getQuizId(quizData);
    }
  };
  const getQuizId = (quizData) => {
    const { quizId } = quizData.data;
    console.log(quizId);
    setSaveQuizId((prevQuizId) => {
      const newQuizId = [...prevQuizId];
      const newQuizIdIndex = newQuizId.length - 1;
      newQuizId[newQuizIdIndex] = {
        quizId: quizId,
      };
      return newQuizId;
    });
  };

  const handleSubmit = () => {
    console.log("dnld", saveQuizId);
    const {
      schoolName,
      clientId: quizsetDataClient,
      schoolId: quizsetDataSchoolId,
      clientName,
      createdAt,
      updatedAt,
      totalTimeLimit,
      quizCount,
      ...filterQuizsetData
    } = quizsetData;
    const saveQuizsetData = {
      ...filterQuizsetData,
      title: header,
      subject: quizSubject,
      keyword,
      description: surveyDescription,
      quizzes: JSON.stringify(saveQuizId),
    };
    patchQuizset(saveQuizsetData);
  };
  return (
    <FormControl>
      <Box w={"full"} h={"full"} overflow={"auto"} mt="20px">
        <Stack direction="column" spacing={4} w={"full"}>
          <Card border="1px" borderColor="gray.200" w={"full"}>
            <Box
              w={"full"}
              h="600"
              overflow={"auto"}
              p={8}
              borderRadius="lg"
              position="relative"
            >
              <Stack direction="column" spacing={8} w={"full"}>
                <TitleEditor
                  headingText="1. 설문지 질문"
                  value={surveyFormData.title}
                  handleSurveyTitle={handleSurveyTitle}
                  handleSurveryFormChange={handleSurveryFormChange}    
                  inputValue={surveyFormData.link}
                />
                <SurveyTypeSelect
                  handleSurveryFormChange={handleSurveryFormChange}
                  surveyType={quizType}
                  handleEmptyChoices={handleEmptyChoices}
                  tureFalseChoices={tureFalseChoices}
                />
                <SurveyTypeEditor
                  surveyType={quizType}
                  multipleType={multipleType.multipleType}
                  handleMultipleTypeChange={handleMultipleTypeChange}
                  choices={choices}
                  handleChoicesAddClick={handleChoicesAddClick}
                  handleChoicesDeleteClick={handleChoicesDeleteClick}
                  handlChoiceDescriptionChange={handlChoiceDescriptionChange}
                  handleEmptyChoices={handleEmptyChoices}
                  radioSetChoices={radioSetChoices}
                />
                <SurveyTimeLimit
                  value={surveyFormData.timelimit}
                  checked={checked}
                  minutesHandler={minutesHandler}
                  secondsHandler={secondsHandler}
                  handleSurveryFormChange={handleSurveryFormChange}
                />
                <DescripitonEditor
                  headingText="5. 설문지 해설"
                  value={description}
                  handleSurveyDescription={handleSurveyDescription}
                />
                <SurveyCategory
                  surveyFormData={surveyFormData}
                  handleSurveryFormChange={handleSurveryFormChange}
                />
              </Stack>
            </Box>
            <CardFooter
              display={"flex"}
              justifyContent={"flex-end"}
              alignItems={"center"}
              p={4}
            >
              <Button onClick={handleTemporarySubmit}>임시 저장하기</Button>
              <Button onClick={handleSubmit}>저장하기</Button>
            </CardFooter>
            <QuizVideoModal/>
          </Card>
        </Stack>
      </Box>
    </FormControl>
  );
};

export default SurveyForm;
