export const PURGE_QUIZSET_SESSION = 'PURGE_QUIZSET_SESSION';
export const GET_QUIZSET_SESSION_HISTORY_SUCCESS =
  'GET_QUIZSET_SESSION_HISTORY_SUCCESS';
export const GET_QUIZSET_SESSION_HISTORY_FAIL =
  'GET_QUIZSET_SESSION_HISTORY_FAIL';
export const CREATE_QUIZSET_SESSION_SUCCESS = 'CREATE_QUIZSET_SESSION_SUCCESS';
export const CREATE_QUIZSET_SESSION_FAIL = 'CREATE_QUIZSET_SESSION_FAIL';
export const CREATE_QUIZSET_SESSION_BY_QUIZ_SUCCESS =
  'CREATE_QUIZSET_SESSION_BY_QUIZ_SUCCESS';
export const CREATE_QUIZSET_SESSION_BY_QUIZ_FAIL =
  'CREATE_QUIZSET_SESSION_BY_QUIZ_FAIL';
export const GET_QUIZSET_SESSION_RESULT_SUCCESS =
  'GET_QUIZSET_SESSION_RESULT_SUCCESS';
export const GET_QUIZSET_SESSION_RESULT_FAIL =
  'GET_QUIZSET_SESSION_RESULT_FAIL';

export const SET_QUIZSET_STUDENT_STATUS = 'SET_QUIZSET_STUDENT_STATUS';
export const RESET_QUIZSET_STUDENT_STATUS = 'RESET_QUIZSET_STUDENT_STATUS';

export const GET_READY_STUDENT_LIST_SUCCESS = 'GET_READY_STUDENT_LIST_SUCCESS';
export const GET_READY_STUDENT_LIST_FAIL = 'GET_READY_STUDENT_LIST_FAIL';
export const PURGE_READY_STUDENT_LIST = 'PURGE_READY_STUDENT_LIST';

export const GET_ANSWERED_STUDENT_LIST_SUCCESS =
  'GET_ANSWERED_STUDENT_LIST_SUCCESS';
export const GET_ANSWERED_STUDENT_LIST_FAIL = 'GET_ANSWERED_STUDENT_LIST_FAIL';
export const PURGE_ANSWERED_STUDENT_LIST = 'PURGE_ANSWERED_STUDENT_LIST';
