import React from "react";
import styled from "styled-components";
import LiteYouTubeEmbed from "react-lite-youtube-embed";
import getYoutubeId from "get-youtube-id";

function QuizVideoPreview({ linkType, link }) {
  if (!link || !linkType) {
    return null;
  }

  return (
    <ImageContainer>
      {linkType === "YOUTUBE" && (
        <LiteYouTubeEmbed id={getYoutubeId(link)} title="video" />
      )}
      {linkType === "IMG" && <Image src={link} />}
    </ImageContainer>
  );
}

export const ImageContainer = styled.div`
  background: #f5f5f5;
  border-radius: 8px;
  max-width: 300px;
  max-height: 300px;
  width: 100%;
  position: relative;
  overflow: hidden;
  margin: 0 auto;
`;

export const Image = styled.img`
  max-width: 100%;
  max-height: 300px;
  height: auto;
  object-fit: contain;
  display: block;
  margin: 0 auto;
`;

export default QuizVideoPreview;
