import { call, put, takeLeading, takeEvery } from "redux-saga/effects";
import {
  getGroupPromise,
  getGroupSuccess,
  getGroupFail,
  createInviteCodePromise,
  createInviteCodeSuccess,
  createInviteCodeFail,
  createGroupPromise,
  createGroupSuccess,
  createGroupFail,
  updateGroupPromise,
  updateGroupSuccess,
  updateGroupFail,
  deleteGroupPromise,
  deleteGroupSuccess,
  deleteGroupFail
} from "@store/actions";
import * as api from "@api";
import { implementPromiseAction } from "@adobe/redux-saga-promise";

function* getGroupPromiseHandler(action) {
  yield call(implementPromiseAction, action, function*() {
    try {
      const response = yield call(api.getGroup, action.payload);
      yield put(getGroupSuccess(response.data));
      return yield response.data;
    } catch (error) {
      yield put(getGroupFail(error));
    }
  });
}

function* createInviteCodePromiseHandler(action) {
  yield call(implementPromiseAction, action, function*() {
    try {
      const response = yield call(api.createInviteCode, action.payload);
      yield put(createInviteCodeSuccess(response.data));
      return yield response.data;
    } catch (error) {
      yield put(createInviteCodeFail(error));
    }
  });
}

function* createGroupPromiseHandler(action) {
  yield call(implementPromiseAction, action, function*() {
    try {
      const response = yield call(api.createGroup, action.payload);
      yield put(createGroupSuccess(response.data));
      return yield response.data;
    } catch (error) {
      yield put(createGroupFail(error));
    }
  });
}

function* updateGroupPromiseHandler(action) {
  yield call(implementPromiseAction, action, function*() {
    try {
      const response = yield call(api.updateGroup, action.payload);
      yield put(updateGroupSuccess(response.data));
      return yield response.data;
    } catch (error) {
      yield put(updateGroupFail(error));
    }
  });
}

function* deleteGroupPromiseHandler(action) {
  yield call(implementPromiseAction, action, function*() {
    try {
      const response = yield call(api.deleteGroup, action.payload);
      yield put(deleteGroupSuccess(response.data));
      return yield response.data;
    } catch (error) {
      yield put(deleteGroupFail(error));
    }
  });
}

function* groupSaga() {
  yield takeLeading(getGroupPromise, getGroupPromiseHandler);
  yield takeLeading(createInviteCodePromise, createInviteCodePromiseHandler);
  yield takeEvery(createGroupPromise, createGroupPromiseHandler);
  yield takeLeading(updateGroupPromise, updateGroupPromiseHandler);
  yield takeLeading(deleteGroupPromise, deleteGroupPromiseHandler);
}

export default groupSaga;
