import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSocketData } from "@store/actions";
import moment from "moment";
import {
  Box,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CloseButton,
  HStack,
  Image,
  Spinner,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { useDeleteFilelink } from "@app/api/classroom/hooks/useFilelink";
import {
  socketKind,
  socketMethod,
  socketUrl,
} from "@app/Constants/socketConstants";

const getFaviconImageUrl = (link) => {
  return `https://www.google.com/s2/favicons?sz=64&domain_url=${link}`;
};

const CardFileLink = ({ groupId, filelink }) => {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [imageLoaded, setImageLoaded] = useState(false);

  const { mutate: deleteFilelink } = useDeleteFilelink();

  const sendDeleteFilelink = (filelinkId) => {
    dispatch(
      setSocketData({
        method: socketMethod.POST,
        uri: socketUrl.classroomSendImage,
        groupId: groupId,
        clientId: user?.clientId,
        type: socketKind.classroom.DELETE_FILELINK,
        data: filelinkId,
      })
    );
  };

  const onClick = () => {
    window.open(filelink.link);
  };

  const isImageLink =
    filelink.link.substr(filelink.link.length - 3) === "png" ||
    filelink.link.substr(filelink.link.length - 3) === "jpg" ||
    filelink.link.substr(filelink.link.length - 4) === "jpeg" ||
    filelink.link.substr(filelink.link.length - 3) === "gif";

  useEffect(() => {
    if (isImageLink) {
      const timer = setTimeout(() => {
        setImageLoaded(true);
      }, 1200); // 1.2초 뒤에 이미지 로드

      return () => clearTimeout(timer);
    }
  }, [filelink.link]);

  const onClickDeleteFilelink = async (filelinkId) => {
    await deleteFilelink(filelinkId);
    sendDeleteFilelink(filelink.filelinkId);
  };

  return (
    <Card
      size="sm"
      cursor="pointer"
      _hover={{
        bg: "gray.200",
      }}
      border="1px"
      borderColor="gray.200"
    >
      <CardHeader>
        <HStack spacing={2} justifyContent="space-between" alignItems="center">
          <Text size="sm" fontWeight="bold">
            {filelink.title ?? "제목없음"}
          </Text>
          <CloseButton
            onClick={() => {
              onClickDeleteFilelink(filelink.filelinkId);
            }}
          />
        </HStack>
      </CardHeader>
      <CardBody onClick={onClick}>
        <HStack spacing={2}>
          <Box>
            {isImageLink ? (
              imageLoaded ? (
                <Image src={filelink.link} alt="icon" boxSize="32px" />
              ) : (
                <Spinner />
              )
            ) : (
              <Image
                src={getFaviconImageUrl(filelink.link)}
                alt="icon"
                boxSize="32px"
              />
            )}
          </Box>
          <Tooltip label={filelink.link} placement="bottom">
            <Text
              size="sm"
              color="gray.500"
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
            >
              {filelink.link}
            </Text>
          </Tooltip>
        </HStack>
      </CardBody>
      <CardFooter>
        <Text size="sm" color="gray.500">
          {moment(filelink.updatedAt).format("YYYY.MM.DD")}
        </Text>
      </CardFooter>
    </Card>
  );
};

export default CardFileLink;
