import styled from 'styled-components';
import { Column, Row } from '@app/pages/QuizPang/common';

export const ImageContainer = styled(Column)`
  background: #f5f5f5;
  border-radius: 8px;
  width: 480px;
  position: relative;
  overflow: hidden;
`;

export const ImageContainerRow = styled(Row)`
  background: #f5f5f5;
  border-radius: 8px;
  width: 480px;
  position: relative;
  overflow: hidden;
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;
