import React from "react";
import ClassroomWrapper from "@app/components/Layout/Common/ClassroomWrapper";
import SurveyList from "./SurveyList";

const SurveyHome = () => {
  return (
    <ClassroomWrapper currentPageName="설문지">
      <SurveyList />
    </ClassroomWrapper>
  );
};

export default SurveyHome;
