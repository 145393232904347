import { call, put, takeLeading } from "redux-saga/effects";
import {
  getClassroomPromise,
  getClassroomSuccess,
  getClassroomFail,
  getActiveClassroomPromise,
  getActiveClassroomSuccess,
  getActiveClassroomFail,
  setActiveClassroomExist,
  setActiveClassroomNotExist,
  createClassroomPromise,
  createClassroomSuccess,
  createClassroomFail,
  deleteClassroomPromise,
  deleteClassroomSuccess,
  deleteClassroomFail,
  updateClassroomPromise,
  updateClassroomSuccess,
  updateClassroomFail,
} from "@store/actions";
import * as api from "@api";
import { implementPromiseAction } from "@adobe/redux-saga-promise";
import { updateClassroomLoading } from "./actions";
import { useSelector } from "react-redux";

function* createClassroomPromiseHandler(action) {
  yield put(updateClassroomLoading(true));
  yield call(implementPromiseAction, action, function*() {
    try {
      const response = yield call(api.createClassroom, action.payload);
      yield put(createClassroomSuccess(response.data));
      return yield response.data;
    } catch (error) {
      yield put(createClassroomFail(error));
    } finally {
      yield put(updateClassroomLoading(false));
    }
  });
}

function* getClassroomPromiseHandler(action) {
  yield call(implementPromiseAction, action, function*() {
    try {
      const response = yield call(api.getClassroom, action.payload);
      yield put(getClassroomSuccess(response.data));
      return yield response.data;
    } catch (error) {
      yield put(getClassroomFail(error));
    }
  });
}

function* getActiveClassroomPromiseHandler(action) {
  yield call(implementPromiseAction, action, function*() {
    try {
      const response = yield call(api.getActiveClassroom, action.payload);
      if (response.data.classroom.state === "IN_CLASS") {
        yield put(getActiveClassroomSuccess(response.data));
      }
      yield put(setActiveClassroomExist(response.data));
      return yield response.data;
    } catch (error) {
      yield put(getActiveClassroomFail(error));
      yield put(setActiveClassroomNotExist());
      return null;
    }
  });
}

function* deleteClassroomPromiseHandler(action) {
  yield put(updateClassroomLoading(true));
  yield call(implementPromiseAction, action, function*() {
    try {
      const response = yield call(api.deleteClassroom, action.payload);
      yield put(deleteClassroomSuccess(response.data));
      return yield response.data;
    } catch (error) {
      yield put(deleteClassroomFail(error));
    } finally {
      yield put(updateClassroomLoading(false));
    }
  });
}

function* updateClassroomPromiseHandler(action) {
  yield call(implementPromiseAction, action, function*() {
    try {
      const response = yield call(api.updateClassroom, action.payload);
      yield put(updateClassroomSuccess(response.data));
      return yield response.data;
    } catch (error) {
      yield put(updateClassroomFail(error));
      throw error;
    }
  });
}

function* classroomSaga() {
  yield takeLeading(getClassroomPromise, getClassroomPromiseHandler);
  yield takeLeading(
    getActiveClassroomPromise,
    getActiveClassroomPromiseHandler
  );
  yield takeLeading(createClassroomPromise, createClassroomPromiseHandler);
  yield takeLeading(deleteClassroomPromise, deleteClassroomPromiseHandler);
  yield takeLeading(updateClassroomPromise, updateClassroomPromiseHandler);
}

export default classroomSaga;
