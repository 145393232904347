import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Heading,
  HStack,
  IconButton,
  Button,
  Card,
  CardFooter,
} from "@chakra-ui/react";
import { DeleteIcon, CopyIcon, CloseIcon } from "@chakra-ui/icons";
import QuizEditorWrapper from "@app/components/Quiz/Common/QuizEditorWrapper";
import QuizTitle from "@app/components/Quiz/QuizTitle";
import QuizType from "@app/components/Quiz/QuizType";
import QuizTypeEditor from "@app/components/Quiz/QuizTypeEditor";
import QuizTimelimit from "@app/components/Quiz/QuizTimelimit";
import QuizExplanation from "@app/components/Quiz/QuizExplanation";
import QuizCategory from "@app/components/Quiz/QuizCategory";
import { QuizVideoModal } from "@app/components/Quiz/QuizVideoModal";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import {
  useQuizByQuizId,
  usePatchQuizset,
  useQuizsetByQuizsetId,
  usePatchQuizForTest,
  useCreateQuiz,
  usePatchQuiz,
  useDeleteQuizByQuizId,
  usePatchQuizsetForTest,
} from "@app/api/quiz/hooks/useQuizset";
import { useDispatch, useSelector } from "react-redux";
import { getQuizImageKey, resolveImgPath, uploadImage } from "@api";
import { setReportLog } from "@app/store/actions";
import { ButtonConstants } from "@app/Constants/ButtonConstants";

const QuizEditor = ({
  quizsetType,
  setEditMode,
  quizId,
  setQuizId,
  header,
  testCrewType,
}) => {
  const { quizsetId } = useParams();
  const { clientId, schoolId } = useSelector((state) => state.user);
  const { mutateAsync: patchQuiz } = usePatchQuizForTest(); //abtest
  const { mutateAsync: createQuiz } = useCreateQuiz();
  const { mutateAsync: patchQuizset } = usePatchQuizset();
  const { mutateAsync: patchQuizsetForTest } = usePatchQuizsetForTest();
  const { mutateAsync: patchQuizLegacy } = usePatchQuiz();
  const { mutateAsync: deleteQuiz } = useDeleteQuizByQuizId();
  const { data, isLoading, error, isSuccess } = useQuizByQuizId(quizId);
  const quiz = useQuizsetByQuizsetId(quizsetId);
  const [quizTitle, setQuizTitle] = useState("");
  const [quizType, setQuizType] = useState("MULTIPLE");
  const [timelimit, setTimelimit] = useState(10);
  const [quizExplanation, setQuizExplanation] = useState("");
  const [quizLevel, setQuizLevel] = useState(1);
  const [grade, setGrade] = useState(0);
  const [subject, setSubject] = useState("");
  const [selectPublisher, setSelectPublisher] = useState("");
  const [keyword, setKeyword] = useState("");
  const [choices, setChoices] = useState([{ description: "", point: 1 }]); //초기화시 타입: [{ description: 'O', point: 0 },{ description: 'X', point: 0 }] or [{ description: '', point: 0 }] 선다형 기준으로 초기화함
  const [sampleAnswerType, setSampleAnswerType] = useState(""); // 'IMG' 'TXT'
  const [sampleAnswer, setSampleAnswer] = useState(""); // 답안 유형이 IMG일 경우 스토리지 링크가 제공됨
  const [sampleAnswerImageFile, setSampleAnswerImageFile] = useState(null);
  const [link, setLink] = useState(""); //받아온링크
  const [linkType, setLinkType] = useState(""); // 'VIDEO', 'YOUTUBE', 'IMG'
  //아래는 요청에 보내지않는 변수임
  const [videoLink, setVideoLink] = useState(null); //올릴링크
  const [imageFile, setImageFile] = useState(null); //올릴 이미지의 링크
  const [uploadedImgPath, setUploadedImgPath] = useState(null); //받아온 이미지의 링크
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);
  const dispatch = useDispatch();
  const onClickClose = () => {
    if (
      window.confirm("새로 작성한 내용은 사라집니다. 작성을 취소하시겠습니까?")
    )
      setEditMode(false);
  };
  const onClickDuplicateQuiz = async () => {
    await handleSave(true);
  };
  const onClickDeleteQuiz = async (quizIdForDelete) => {
    try {
      if (!quizId) {
        //아무것도 없는데 삭제누름-->닫기로
        setEditMode(false);
        return;
      }
      if (window.confirm("퀴즈를 삭제하시겠습니까?")) {
        const parsedQuizzesArr = JSON.parse(quiz.data.quizzes);
        const quizzesIdArr = parsedQuizzesArr.map((quiz) => ({
          quizId: quiz.quizId,
        }));
        const filteredQuizIdArr = quizzesIdArr.filter(
          (quiz) => quiz.quizId !== quizIdForDelete
        );
        const stringifiedQuizIdArr = JSON.stringify(filteredQuizIdArr);
        let newQuizsetData = { ...quiz.data, quizzes: stringifiedQuizIdArr };
        newQuizsetData = deleteUnnecessaryQuizsetFiledsForRequest(
          newQuizsetData
        );
        setQuizId(null);
        await deleteQuiz(quizIdForDelete);
        await patchQuizset(newQuizsetData);
        setEditMode(false);
      }
    } catch (e) {
      console.error(e);
    }
  };
  const deleteUnnecessaryQuizsetFiledsForRequest = (quizset) => {
    let result = { ...quizset };
    delete result.schoolId;
    delete result.schoolName;
    delete result.clientId;
    delete result.clientName;
    delete result.createdAt;
    delete result.quizCount;
    delete result.totalTimeLimit;
    delete result.updatedAt;
    return result;
  };
  const setFetchedQuizData = useCallback(
    (quizData) => {
      if (!data) {
        return;
      }
      setChoices(JSON.parse(quizData.choices));
      setQuizExplanation(quizData.description);
      setGrade(quizData.grade);
      setKeyword(quizData.keyword);
      setLinkType(quizData.linkType);
      if (quizData.linkType === "IMG") setUploadedImgPath(quizData.link);
      if (quizData.linkType === "YOUTUBE") setVideoLink(quizData.link);
      setSelectPublisher(quizData.quizCategory);
      setQuizLevel(quizData.quizLevel);
      setSubject(quizData.quizSubject);
      setQuizType(quizData.quizType);
      setSampleAnswer(quizData.sampleAnswer);
      setSampleAnswerType(quizData.sampleAnswerType);
      setTimelimit(quizData.timelimit);
      setQuizTitle(quizData.title);
    },
    [data]
  );
  const processQuiz = async (patchedQuiz) => {
    let result;
    const parsedQuizData = JSON.parse(quiz.data.quizzes);
    parsedQuizData.push(patchedQuiz.data);
    result = JSON.stringify(parsedQuizData);
    return result;
  };
  const processQuizset = async (stringifiedQuizzes) => {
    let newQuizset = { ...quiz.data };
    newQuizset.quizzes = stringifiedQuizzes;
    newQuizset.subject = "테스트";
    newQuizset.keyword = "테스트키워드";
    newQuizset.description = "테스트설명";
    newQuizset = deleteUnnecessaryQuizsetFiledsForRequest(newQuizset);
    const quizzesArray = JSON.parse(newQuizset.quizzes);
    const quizzesIdArray = quizzesArray.map((quiz) => ({
      quizId: quiz.quizId,
    }));
    newQuizset.quizzes = JSON.stringify(quizzesIdArray);
    return newQuizset;
  };
  const patchQuizFunc = async (quizData, isDuplicate) => {
    let result;
    try {
      let quizDataForPatch = {
        ...quizData,
        quizId: quizId,
        testCrewType: testCrewType,
      };
      let quizDataForPatchNormal = {
        ...quizData,
        quizId: quizId,
      };
      const quizDataForCreate = {
        ...quizData,
        clientId: clientId,
        schoolId: schoolId,
      };
      const createQuizAndReturnQuizId = async (data) => {
        const response = await createQuiz(data);
        const newQuizId = response.data.quizId;
        return newQuizId;
      };
      if (quizId && quizsetType === "ABTEST") {
        //id는 있으면서 ABTEST
        if (isDuplicate) {
          //복제라면
          quizDataForPatch.quizId = await createQuizAndReturnQuizId(
            quizDataForCreate
          );
        }
        result = await patchQuiz(quizDataForPatch);
      } else if (quizId && quizsetType === "QUIZ") {
        //id는 있으면서 QUIZ
        if (isDuplicate) {
          //복제라면
          quizDataForPatchNormal.quizId = await createQuizAndReturnQuizId(
            quizDataForCreate
          );
        }
        result = await patchQuizLegacy(quizDataForPatchNormal);
      } else if (!quizId && quizsetType === "ABTEST") {
        //id없으면서 ABTEST
        quizDataForPatch.quizId = await createQuizAndReturnQuizId(
          quizDataForCreate
        );
        result = await patchQuiz(quizDataForPatch);
      } else if (!quizId && quizsetType === "QUIZ") {
        //id없으면서 QUIZ
        quizDataForPatchNormal.quizId = await createQuizAndReturnQuizId(
          quizDataForCreate
        );
        result = { data: quizDataForPatchNormal };
      }
    } catch (e) {
      console.log(e);
    }
    console.log("result");
    return result;
  };
  const handleSampleImage = async (quizData) => {
    if (sampleAnswerImageFile) {
      const imageKey = await getQuizImageKey(clientId);
      await uploadImage(sampleAnswerImageFile, imageKey);
      const sampleAnswerImgPath = await resolveImgPath(imageKey);
      quizData.sampleAnswer = sampleAnswerImgPath;
    }
  };
  const handleImageFile = async (quizData) => {
    // upload image if exists
    if (imageFile) {
      const imageKey = getQuizImageKey(clientId);
      await uploadImage(imageFile, imageKey);
      quizData.link = resolveImgPath(imageKey);
    }
  };
  const handleSave = async (isDuplicate) => {
    const formattedChoices = choices.map((choice, index) => ({
      ...choice,
      index: index + 1,
      isAnswer: choice.point > 0 ? true : false,
    }));

    let quizData = {
      title: quizTitle,
      quizType: quizType,
      timelimit: timelimit,
      description: quizExplanation,
      quizCategory: selectPublisher ? selectPublisher : "해당 없음",
      grade: parseInt(grade, 10),
      quizSubject: subject ? subject : "과목 없음",
      keyword: keyword ? keyword : "키워드 없음",
      quizLevel: parseInt(quizLevel, 10),
      choices: JSON.stringify(formattedChoices),
      header: header,
      link: linkType === "YOUTUBE" ? videoLink : uploadedImgPath,
      linkType: linkType,
      sampleAnswerType: sampleAnswerType,
      sampleAnswer: sampleAnswer,
      // 아래는 mock...
      noInfringementAccepted: true,
      sharingAccepted: true,
      copyrightMandateAccepted: true,
    };
    await handleSampleImage(quizData); //필기문항 이미지처리
    await handleImageFile(quizData); //1번문항 이미지처리
    const patchedQuiz = await patchQuizFunc(quizData, isDuplicate); //퀴즈패치
    const stringifiedArr = await processQuiz(patchedQuiz); //퀴즈 프로세싱
    const newQuizset = await processQuizset(stringifiedArr); //퀴즈셋프로세싱
    try {
      if (!quizId || quizId !== patchedQuiz.data.quizId) {
        //퀴즈아이디가 없거나, patch된 퀴즈의 id가 기존퀴즈id와 다를때(퀴즈아이디 새로받아서 복제)때만 quizsetPatch한다.
        //편집할때는 quiz만 patch하면됨
        if (quizsetType === "ABTEST") {
          await patchQuizsetForTest(newQuizset);
        } else {
          await patchQuizset(newQuizset);
        }
      }
    } catch (e) {
      console.log(e);
    }
    setEditMode(false);
  };

  useEffect(() => {
    if (quizId) {
      setFetchedQuizData(data);
    }
  }, [quizId, data, setFetchedQuizData]);

  return (
    <Card border="1px" borderColor="gray.200" w={"full"}>
      <Box
        w={"full"}
        h="600"
        overflow={"auto"}
        p={8}
        borderRadius="lg"
        position="relative"
      >
        <QuizEditorWrapper>
          <QuizTitle
            setLinkType={setLinkType}
            imageFile={imageFile}
            setImageFile={setImageFile}
            uploadedImgPath={uploadedImgPath}
            setUploadedImgPath={setUploadedImgPath}
            videoLink={videoLink ? videoLink : link}
            setVideoLink={setVideoLink}
            setIsVideoModalOpen={setIsVideoModalOpen}
            quizTitleValue={quizTitle}
            onChangeQuizTitle={setQuizTitle}
          />
          <QuizType
            setChoices={setChoices}
            quizTypeValue={quizType}
            onChangeSelect={setQuizType}
            setSampleAnswerType={setSampleAnswerType}
            setSampleAnswer={setSampleAnswer}
            setSampleAnswerImageFile={setSampleAnswerImageFile}
          />
          <QuizTypeEditor
            setSampleAnswerType={setSampleAnswerType}
            sampleAnswerType={sampleAnswerType}
            setSampleAnswer={setSampleAnswer}
            sampleAnswer={sampleAnswer}
            selectedQuizType={quizType}
            sampleAnswerImageFile={sampleAnswerImageFile}
            setSampleAnswerImageFile={setSampleAnswerImageFile}
            choices={choices}
            setChoices={setChoices}
          />
          <QuizTimelimit onChange={setTimelimit} timelimitValue={timelimit} />
          <QuizExplanation
            quizExplanationValue={quizExplanation}
            onChangeQuizExplanation={setQuizExplanation}
          />
          <QuizCategory
            quizLevel={quizLevel}
            setQuizLevel={setQuizLevel}
            grade={grade}
            setGrade={setGrade}
            subject={subject}
            setSubject={setSubject}
            selectPublisher={selectPublisher}
            setSelectPublisher={setSelectPublisher}
            keyword={keyword}
            setKeyword={setKeyword}
          />

          <HStack justify="end" spacing={4}>
            <IconButton
              onClick={() => {
                onClickDuplicateQuiz();
                dispatch(
                  setReportLog(ButtonConstants.MANAGING_QUIZPANG.DUPLICATE_QUIZ)
                );
              }}
              aria-label="복제하기"
              icon={<CopyIcon />}
              isRound
            />
            <IconButton
              onClick={() => {
                onClickDeleteQuiz(quizId);
                dispatch(
                  setReportLog(ButtonConstants.MANAGING_QUIZPANG.DELETE_QUIZ)
                );
              }}
              aria-label="삭제하기"
              icon={<DeleteIcon />}
              isRound
            />
            <IconButton
              onClick={() => {
                onClickClose();
                dispatch(
                  setReportLog(
                    ButtonConstants.MANAGING_QUIZPANG.CANCEL_QUIZ_EDIT
                  )
                );
              }}
              aria-label="취소하기"
              icon={<CloseIcon />}
              isRound
            />
          </HStack>
        </QuizEditorWrapper>
      </Box>
      <CardFooter
        display={"flex"}
        justifyContent={"flex-end"}
        alignItems={"center"}
        p={4}
      >
        <Button onClick={() => handleSave(false)}>저장하기</Button>
      </CardFooter>
      <QuizVideoModal
        removeImage={() => {
          setImageFile(null);
          setUploadedImgPath(null);
        }}
        setLinkType={setLinkType}
        videoLink={videoLink}
        setVideoLink={setVideoLink}
        open={isVideoModalOpen}
        onClose={() => setIsVideoModalOpen(false)}
      />
    </Card>
  );
  //}
};

export default QuizEditor;
