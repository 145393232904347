export const PURGE_GROUP = "PURGE_GROUP";

export const GET_GROUP_SUCCESS = "GET_GROUP_SUCCESS";
export const GET_GROUP_FAIL = "GET_GROUP_FAIL";

export const GET_GROUPS_SUCCESS = "GET_GROUPS_SUCCESS";
export const GET_GROUPS_FAIL = "GET_GROUPS_FAIL";

export const GET_GROUP_BY_INVITE_SUCCESS = "GET_GROUP_BY_INVITE_SUCCESS";
export const GET_GROUP_BY_INVITE_FAIL = "GET_GROUP_BY_INVITE_FAIL";

export const CREATE_GROUP_SUCCESS = "CREATE_GROUP_SUCCESS";
export const CREATE_GROUP_FAIL = "CREATE_GROUP_FAIL";

export const UPDATE_GROUP_SUCCESS = "UPDATE_GROUP_SUCCESS";
export const UPDATE_GROUP_FAIL = "UPDATE_GROUP_FAIL";

export const DELETE_GROUP_SUCCESS = "DELETE_GROUP_SUCCESS";
export const DELETE_GROUP_FAIL = "DELETE_GROUP_FAIL";

export const CREATE_INVITE_CODE_SUCCESS = "CREATE_INVITE_CODE_SUCCESS";
export const CREATE_INVITE_CODE_FAIL = "CREATE_INVITE_CODE_FAIL";
