import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import {
  createQuiz,
  patchQuiz,
  createQuizsetForTest,
  getQuizsetByQuizsetId,
  getQuizsetListByClient,
  patchQuizForTest,
  patchQuizset,
  deleteQuiz,
  deleteQuizset,
  patchQuizsetForTest,
  getimpulsivityStudentListByGroupId,
} from "../quizApi";
import { getQuiz } from "@app/api/request";

export const useQuizsetByClientId = (clientId, type, orderType, orderBy) => {
  console.log("useQuizsetByClientId", clientId, type, orderType, orderBy);
  return useInfiniteQuery(
    ["quizsetList", clientId, type, orderType, orderBy],
    async ({ pageParam = 0 }) => {
      const offset = pageParam; // 페이지 번호에 따른 offset 계산
      const response = await getQuizsetListByClient({
        clientId,
        type,
        offset,
        limit: 10,
        orderType,
        orderBy,
      });
      return response;
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        const currentPage = lastPage.data.currentPage;
        const totalPages = lastPage.data.totalPages;
        console.log(currentPage, totalPages);
        if (currentPage <= totalPages - 1) {
          return currentPage + 1;
        } else {
          return undefined; // 마지막 페이지인 경우 undefined 반환
        }
      },
    }
  );
};

export const useQuizsetByQuizsetId = (quizsetId) => {
  return useQuery({
    queryKey: ["quizset"],
    queryFn: () => {
      return getQuizsetByQuizsetId(quizsetId);
    },
    enabled: !!quizsetId,
    select: ({ data }) => ({
      ...data,
    }),
  });
};

export const useCreateQuizsetForTest = (data) => {
  const queryClient = useQueryClient();

  return useMutation((data) => createQuizsetForTest(data), {
    onSuccess: (response) => {
      console.log("response", response);
      queryClient.refetchQueries("quizsetList");
    },
  });
};

export const usePatchQuizForTest = () => {
  const queryClient = useQueryClient();

  return useMutation((data) => patchQuizForTest(data), {
    onSuccess: (response) => {
      console.log("response", response);
      queryClient.refetchQueries("quizsetList");
    },
  });
};
export const useDeleteQuizsetByQuizsetId = () => {
  const queryClient = useQueryClient();

  return useMutation((quizsetId) => deleteQuizset(quizsetId), {
    onSuccess: (response) => {
      console.log("response", response);
      queryClient.refetchQueries("quizsetList");
    },
  });
};
// 퀴즈를 생성하는 훅
export const useCreateQuiz = () => {
  const queryClient = useQueryClient();

  return useMutation((data) => createQuiz(data), {
    onSuccess: (response) => {
      console.log("response", response);
      queryClient.refetchQueries("quizsetList");
    },
  });
};
export const usePatchQuiz = () => {
  const queryClient = useQueryClient();

  return useMutation((data) => patchQuiz(data), {
    onSuccess: (response) => {
      console.log("response", response);
      queryClient.refetchQueries("quizsetList");
    },
  });
};
// 퀴즈셋을 patch하는 훅
export const usePatchQuizsetForTest = () => {
  const queryClient = useQueryClient();

  return useMutation((data) => patchQuizsetForTest(data), {
    onSuccess: (response) => {
      console.log("response", response);
      queryClient.refetchQueries("ab-quizset");
    },
  });
};
export const usePatchQuizset = () => {
  const queryClient = useQueryClient();

  return useMutation((data) => patchQuizset(data), {
    onSuccess: (response) => {
      console.log("response", response);
      queryClient.refetchQueries("quizset");
    },
  });
};

// 퀴즈를 조회하는 훅
export const useQuizByQuizId = (quizId) => {
  return useQuery({
    queryKey: ["quiz", quizId],
    queryFn: () => getQuiz(quizId),
    enabled: !!quizId,
    select: ({ data }) => ({
      ...data,
    }),
  });
};

//퀴즈를 삭제하는 훅
export const useDeleteQuizByQuizId = () => {
  const queryClient = useQueryClient();

  return useMutation((quizId) => deleteQuiz(quizId), {
    onSuccess: (response) => {
      console.log("삭제response", response);
      queryClient.removeQueries({ queryKey: "quizset" }); //삭제된 퀴즈셋의 quizzes가 캐시에 남아 quiz를 fetch하기때문에 해당캐시를 삭제함
    },
  });
};

// impulsivity에 따른 학생 명단을 조회하는 훅
export const useImpulsivityStudentListByGroupId = (groupId) => {
  return useQuery({
    queryKey: ["impulsivityStudentList", groupId],
    queryFn: () => getimpulsivityStudentListByGroupId(groupId),
    enabled: !!groupId,
    select: ({ data }) => ({
      ...data,
    }),
  });
};
