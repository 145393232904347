import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import styled from "styled-components";
import {
  Body2,
  Column,
  HorizontalSpace8,
  InputGray,
  Row,
} from "@app/pages/QuizPang/common";
import { Button } from "@mui/material";
import { createQuizCategory } from "@api";
import { useDispatch, useSelector } from "react-redux";
import { getQuizCategoryAllPromise } from "@app/store/actions";

export const CategoryModal = ({ open, onCancel, onAdd, refreshPublishers }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const [category, setCategory] = useState("");

  const onCreate = async (category) => {
    const category_ = category.trim();
    if (
      category_?.length === 0 ||
      category_ == null ||
      category_ == undefined
    ) {
      alert("교과서 출판사를 입력해 주세요.");
      setCategory("");
      return;
    }
    const body = {
      schoolId: user.schoolId,
      clientId: user.clientId,
      quizCategory: category_,
    };
    const response = createQuizCategory(body).then((res) => {
      dispatch(getQuizCategoryAllPromise())
        .then((res) => console.log("[CategoryModal QuizCategoryAll response]"))
        .catch((error) =>
          console.log("[CategoryModal QuizCategoryAll error]", error)
        );
    });
    setCategory("");
    await refreshPublishers();
  };

  return (
    <Modal open={open}>
      <Container>
        <Body2
          style={{
            marginTop: 8,
            marginBottom: 8,
            color: "rgba(0, 0, 0, 0.87)",
          }}
        >
          교과서 출판사 추가
        </Body2>
        <InputGray
          placeholder="교과서 출판사 입력"
          value={category}
          onChange={(e) => setCategory(e.target.value)}
        />
        <Column style={{ flex: 1, justifyContent: "flex-end" }}>
          <Row style={{ alignSelf: "flex-end" }}>
            <Button
              onClick={() => {
                onCancel();
                setCategory("");
              }}
            >
              취소
            </Button>
            <HorizontalSpace8 />
            <Button
              onClick={() => {
                onAdd(category);
                setCategory(category);
                onCreate(category);
              }}
            >
              추가
            </Button>
          </Row>
        </Column>
      </Container>
    </Modal>
  );
};

const Container = styled(Column)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.04), 0 36px 41px rgba(0, 0, 0, 0.04),
    0 4.50776px 5.13384px rgba(0, 0, 0, 0.03);
  border-radius: 8px;
  width: 360px;
  height: 200px;
  padding: 16px;
`;
