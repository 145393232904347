export const SET_COMMENTS_LAYER_PATH = "SET_COMMENTS_LAYER_PATH";
export const SET_COMMENTS_STUDENT = "SET_COMMENTS_STUDENT";
export const SET_COMMENTS_SEND_CLIENT = "SET_COMMENTS_SEND_CLIENT";
export const SET_COMMENTS_SHARED_SCREEN = "SET_COMMENTS_SHARED_SCREEN";
export const SET_COMMENTS_STROKE_COLOR = "SET_COMMENTS_STROKE_COLOR";
export const GET_COMMENTS_LAYER_SUCCESS = "GET_COMMENTS_LAYER_SUCCESS";
export const GET_COMMENTS_LAYER_FAIL = "GET_COMMENTS_LAYER_FAIL";
export const CREATE_COMMENTS_LAYER_SUCCESS = "CREATE_COMMENTS_LAYER_SUCCESS";
export const CREATE_COMMENTS_LAYER_FAIL = "CREATE_COMMENTS_LAYER_FAIL";
export const UPDATE_COMMENTS_LAYER_SUCCESS = "UPDATE_COMMENTS_LAYER_SUCCESS";
export const UPDATE_COMMENTS_LAYER_FAIL = "UPDATE_COMMENTS_LAYER_FAIL";
export const CREATE_COMMENTARY_SUCCESS = "CREATE_COMMENTARY_SUCCESS";
export const CREATE_COMMENTARY_FAIL = "CREATE_COMMENTARY_FAIL";
export const UPDATE_COMMENTARY_SUCCESS = "UPDATE_COMMENTARY_SUCCESS";
export const UPDATE_COMMENTARY_FAIL = "UPDATE_COMMENTARY_FAIL";
