import { CloseButton, HStack, IconButton } from "@chakra-ui/react";
import PanoramaFishEyeIcon from "@mui/icons-material/PanoramaFishEye";
import React from "react";

const TrueFalse = ({ choices }) => {
  const TFchoiseArr = [
    { icon: <PanoramaFishEyeIcon /> },
    { icon: <CloseButton /> },
  ];
  return (
    <>
      {choices.map((choice, choiceIndex) => (
        <HStack spacing={4}>
          <IconButton
            aria-label={choice.description}
            icon={TFchoiseArr[choiceIndex].icon}
            variant="outline"
          />
        </HStack>
      ))}
    </>
  );
};

export default TrueFalse;
