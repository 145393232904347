import {
  CREATE_REPORT_LOG_FAIL,
  CREATE_REPORT_LOG_SUCCESS,
  PURGE_REPORT_LOG,
  SET_REPORT_LOG,
  SET_REPORT_LOCATION_LOG,
  SET_REPORT_CLIENT_ID,
  SET_REPORT_ERROR_LOG,
} from "./actionTypes";
import { createPromiseAction } from "@adobe/redux-saga-promise";

export const setReportErrorLog = (reportLog) => ({
  type: SET_REPORT_ERROR_LOG,
  reports: reportLog,
});

export const setReportLocationLog = (reportLog) => ({
  type: SET_REPORT_LOCATION_LOG,
  reports: reportLog,
});

export const purgeReportLog = () => ({
  type: PURGE_REPORT_LOG,
});

export const setReportLog = (reportLog) => ({
  type: SET_REPORT_LOG,
  reports: reportLog,
});
export const setReportClientId = (clientId) => {
  return {
    type: SET_REPORT_CLIENT_ID,
    clientId: clientId,
  };
};
export const createReportLogPromise = createPromiseAction("CREATE_REPORT_LOG");

export const createReportLogSuccess = (report) => ({
  type: CREATE_REPORT_LOG_SUCCESS,
  report: report,
});

export const createReportLogFail = () => {
  return {
    type: CREATE_REPORT_LOG_FAIL,
  };
};
