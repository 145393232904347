import React from "react";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Container,
  Divider,
  HStack,
  Icon,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { PiPlugsConnectedBold } from "react-icons/pi";
import { PiPlugsBold } from "react-icons/pi";
import { useSelector } from "react-redux";
import StudentThumnail from "@app/pages/NewClassroom/StudentThumnail";
import { useTranslation } from "react-i18next";

const ModalStudentFullScreen = ({
  onClose,
  isOpen,
  participationStudentList,
  offlineStudents,
}) => {
  const { t } = useTranslation();
  const { images } = useSelector((state) => state.control);

  // participationStudentList 중에서 clientId가 같은 학생의 이미지를 가져오는 함수
  const getImage = (participationStudentList) => {
    const result = [];
    participationStudentList.forEach((student) => {
      images !== undefined &&
        images !== null &&
        Object.keys(images).find((key) => key === student.clientId) &&
        result.push(images[student.clientId]);
    });
    return result;
  };

  const imageList = getImage(participationStudentList);

  return (
    <Modal
      size={"full"}
      onClose={onClose}
      isOpen={isOpen}
      scrollBehavior={"inside"}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalBody>
          <Container maxW="container.xl" p="8">
            <VStack spacing={4} w={"full"}>
              <HStack spacing={2} w={"full"} justifyContent={"space-between"}>
                <Button onClick={onClose}>
                  {/* 닫기 */}
                  {t(
                    "timetable.focustime.studentlist.allstudent.view.close.button"
                  )}
                </Button>
                <Breadcrumb>
                  <BreadcrumbItem>
                    <BreadcrumbLink>
                      <Text fontSize={"2xl"} fontWeight={"bold"}>
                        {/* 모든 학생 화면 보기 */}
                        {t(
                          "timetable.focustime.studentlist.allstudent.view.title"
                        )}
                      </Text>
                    </BreadcrumbLink>
                  </BreadcrumbItem>
                </Breadcrumb>
              </HStack>
              <Divider w={"full"} color="gray.200" />
              <Stack direction="column" w="full" spacing={4}>
                <Stack direction="column">
                  <HStack spacing={2}>
                    <Icon as={PiPlugsConnectedBold} />
                    <Text>
                      {/* 온라인 */}
                      {t("timetable.focustime.studentlist.online")}
                    </Text>
                    <Text fontSize="lg" fontWeight="bold">
                      {participationStudentList?.length}
                      {t("focustime.text.people")}
                    </Text>
                  </HStack>
                  <SimpleGrid columns={4} spacing={4}>
                    {participationStudentList?.map((student, index) => (
                      <StudentThumnail
                        key={student.clientId}
                        student={student}
                        image={imageList[index]}
                        w="full"
                        h="full"
                        colums={4}
                        state="online"
                      />
                    ))}
                  </SimpleGrid>
                  <Divider w="full" color="gray.300" />
                  <Stack direction="column">
                    <HStack spacing={2}>
                      <Icon as={PiPlugsBold} />
                      <Text>
                        {/* 오프라인 */}
                        {t("timetable.focustime.studentlist.offline")}
                      </Text>
                      <Text fontSize="lg" fontWeight="bold">
                        {offlineStudents?.length}
                        {t("focustime.text.people")}
                      </Text>
                    </HStack>
                    <SimpleGrid columns={4} spacing={4}>
                      {offlineStudents?.map((student) => (
                        <StudentThumnail
                          key={student.clientId}
                          student={student}
                          w="full"
                          h="full"
                          colums={4}
                          state="offline"
                        />
                      ))}
                    </SimpleGrid>
                  </Stack>
                </Stack>
              </Stack>
            </VStack>
          </Container>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ModalStudentFullScreen;
