export const PURGE_MEMBERSHIP = "PURGE_MEMBERSHIP";

export const GET_MEMBERSHIP_SUCCESS = "GET_MEMBERSHIP_SUCCESS";
export const GET_MEMBERSHIP_FAIL = "GET_MEMBERSHIP_FAIL";

export const GET_MEMBERSHIPS_BY_CLIENT_SUCCESS =
  "GET_MEMBERSHIPS_BY_CLIENT_SUCCESS";
export const GET_MEMBERSHIPS_BY_CLIENT_FAIL = "GET_MEMBERSHIPS_BY_CLIENT_FAIL";

export const GET_MEMBERSHIPS_BY_GROUP_SUCCESS =
  "GET_MEMBERSHIPS_BY_GROUP_SUCCESS";
export const GET_MEMBERSHIPS_BY_GROUP_FAIL = "GET_MEMBERSHIPS_BY_GROUP_FAIL";

export const CREATE_MEMBERSHIP_SUCCESS = "CREATE_MEMBERSHIP_SUCCESS";
export const CREATE_MEMBERSHIP_FAIL = "CREATE_MEMBERSHIP_FAIL";

export const DELETE_MEMBERSHIP_SUCCESS = "DELETE_MEMBERSHIP_SUCCESS";
export const DELETE_MEMBERSHIP_FAIL = "DELETE_MEMBERSHIP_FAIL";
