import React from 'react';

const useUserAgent = () => {
  const userAgent = navigator.userAgent;
  const platform =
    window.navigator?.userAgentData?.platform ?? window.navigator.platform;

  // 메모리 사용량 계산
  // const usedMemory = window.performance;
  // const usedMemoryMb = (usedMemory.memory.usedJSHeapSize / 1024 / 1024).toFixed(
  //   2
  // );

  // 운영 체제 식별
  const os = identifyOS(platform, userAgent);

  // 브라우저 식별
  const userBrowser = identifyBrowser(userAgent);
  const browserVersion = getBrowserVersion(userAgent, userBrowser);

  return {
    os,
    browser: userBrowser,
    browserVersion,
    // usedMemoryMb,
  };
};

// 운영 체제 식별을 위한 함수
function identifyOS(platform, userAgent) {
  const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPC', 'Mac68K', 'macOS'];
  const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
  const iosPlatforms = ['iPhone', 'iPad', 'iPod'];

  if (macosPlatforms.includes(platform)) {
    return 'macOS';
  } else if (iosPlatforms.includes(platform)) {
    return 'iOS';
  } else if (windowsPlatforms.includes(platform)) {
    return 'Windows';
  } else if (/Android/.test(userAgent)) {
    return 'Android';
  } else if (/Linux/.test(platform)) {
    return 'Linux';
  }

  return null;
}

// 브라우저 식별을 위한 함수
function identifyBrowser(userAgent) {
  const browser = {
    chrome: /chrome/i.test(userAgent) && !/edg/i.test(userAgent),
    safari: /safari/i.test(userAgent) && !/chrome/i.test(userAgent),
    firefox: /firefox/i.test(userAgent),
    ie: /internet explorer/i.test(userAgent) || /msie/i.test(userAgent),
    edge: /edg/i.test(userAgent),
  };

  return Object.keys(browser).find((key) => browser[key]) || null;
}

// 브라우저 버전 식별을 위한 함수
function getBrowserVersion(userAgent, browserName) {
  // 정규 표현식을 사용하여 버전 번호 추출
  let match = userAgent.match(new RegExp(`${browserName}[\/ ]+([\\d.]+)`, 'i'));
  return match && match[1] ? match[1] : null;
}

export default useUserAgent;
