import { Input } from "@chakra-ui/react";
import React from "react";

const TextInput = ({ placeholder, ...props }) => {
  return (
    <Input type="text" placeholder={placeholder} {...props} />
  );
};

export default TextInput;
