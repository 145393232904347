import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Tooltip from "@material-ui/core/Tooltip";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { classifyTimetable } from "@app/utils/timetable/timetableUtils";
import { useLocation } from "react-router-dom";
import { Box, HStack, Stack, Text, VStack } from "@chakra-ui/react";
import { getTimetablesByGroupId } from "@app/api/timetable/timetableApi";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useTranslation } from "react-i18next";

const InClassInformation = ({ group, data }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const { groupId } = useParams();
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      width="100%"
      border="1px"
      borderColor="gray.200"
      p={4}
      borderRadius="lg"
      bg="#f2f2f2"
    >
      <Tooltip title={group?.subjectName} arrow>
        <VStack
          w={"full"}
          h={"full"}
          spacing={0}
          alignItems="center"
          justifyContent="center"
        >
          <HStack
            spacing={2}
            justifyContent="center"
            alignItems="center"
            mb={2}
          >
            <Tooltip title={data?.period} arrow>
              <Text
                fontWeight="bold"
                fontSize={"sm"}
                overflow={"hidden"}
                textOverflow={"ellipsis"}
                whiteSpace={"nowrap"}
              >
                {data?.period}
                {t("timetable.text.period.nd")}
              </Text>
            </Tooltip>
            <Text fontWeight="bold" fontSize={"sm"}>
              {data?.startTime}&nbsp;~
            </Text>
            <Text fontWeight="bold" fontSize={"sm"}>
              &nbsp;{data?.endTime}
            </Text>
          </HStack>
          <HStack>
            <Text fontWeight="bold" fontSize={"sm"}>
              {group?.grade} - {group?.groupName}
            </Text>
            <Text fontWeight="bold" fontSize={"sm"}>
              &nbsp;{"/"}
            </Text>
            <Text
              fontSize={"sm"}
              textOverflow="ellipsis"
              overflow="hidden"
              whiteSpace="nowrap"
              fontWeight="bold"
            >
              &nbsp;{group?.subjectName}
            </Text>
          </HStack>
        </VStack>
      </Tooltip>
    </Box>
  );
};

export default InClassInformation;
