import { Button, HStack, Stack } from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
import Multiple from "./Multiple";
import Chose from "./Chose/Chose";
import RadioInputGroup from "./Radio/RadioInputGroup";

const MultipleType = ({
  value,
  choices,
  handleChoicesAddClick,
  handleChoicesDeleteClick,
  handlChoiceDescriptionChange,
}) => {
  const isDelectType = value === "MULTIPLE" || value === "CHOSE";
  const renderMulitpleType = (value, index, description) => {
    switch (value) {
      case "MULTIPLE":
        return (
          <Multiple
            index={index}
            value={description}
            handlChoiceDescriptionChange={handlChoiceDescriptionChange}
            handleChoicesDeleteClick={handleChoicesDeleteClick}
          />
        );
      case "CHOSE":
        return (
          <Chose
            index={index}
            value={description}
            handlChoiceDescriptionChange={handlChoiceDescriptionChange}
            handleChoicesDeleteClick={handleChoicesDeleteClick}
          />
        );
      default:
        return null;
    }
  };
  return (
    <Stack spacing={isDelectType && 4} w="full" overflow="hidden">
      {choices.map((choice, index) => (
        <Stack direction="column" style={{ flex: "flexStart" }} key={index}>
          <HStack spacing={1}>
            {renderMulitpleType(value, index, choice.description)}
          </HStack>
        </Stack>
      ))}
      {isDelectType && (
        <Button leftIcon={<AddIcon />} onClick={handleChoicesAddClick}>
          보기 추가
        </Button>
      )}
      {!isDelectType && (
        <Stack direction="column" style={{ flex: "flexStart" }}>
          <RadioInputGroup choices={choices} />
        </Stack>
      )}
    </Stack>
  );
};

export default MultipleType;
