import { Psychology } from "@mui/icons-material";
import styled from "styled-components";
import { ReactComponent as ABImg } from "@assets/images/icons/a-b.svg";
import { ReactComponent as AttendanceImg } from "@assets/images/icons/attendance.svg";

export const HomeButtonSec = styled.div`
  padding: 12px 16px;
  border-radius: 8px;
  display: flex;
  align-items: center;

  //간격 20px
  margin-bottom: 20px;

  &:hover {
    color: white;
    cursor: pointer;
    svg {
      fill: white;
    }
    background-color: var(--secondary);
  }
  @media all and (max-width: 1200px) {
    width: 80px;
    flex-direction: column;
  }
  //버튼 반응형
`;

export const ImgSetSec = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 24px;
  object-fit: contain;
  @media all and (max-width: 1200px) {
    margin-right: 0;
  }
  //버튼 이미지 반응형
`;

export const SpanSidebar = styled.div`
  font-family: "Noto Sans KR", sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;

  cursor: pointer;
  text-align: left;
  letter-spacing: normal;

  @media all and (max-width: 1200px) {
    font-size: 10px;
    width: 66px;
    text-align: center;
  }
  //홈버튼 리스트 반응형
`;

function HomeButtonList({ location, history, onPressAfter }) {
  //퀴즈팡 관리 스타일
  let style;
  if (window.innerWidth > 1200) {
    style = {
      width: "24px",
      height: "24px",
      marginRight: "24px",
      objectFit: "contain",
      color: location.pathname.includes("/managing") ? "#ffffff" : "#999999",
    };
  } else {
    style = {
      width: "24px",
      height: "24px",
      objectFit: "contain",
      color: location.pathname.includes("/managing") ? "#ffffff" : "#999999",
    };
  }
  return (
    <>
      <HomeButtonSec
        style={
          location.pathname.includes("/managing")
            ? { backgroundColor: "var(--secondary)" }
            : {}
        }
        onClick={onPressAfter}
      >
        <Psychology style={style} />
        <SpanSidebar
          style={
            location.pathname === "/managing" ? { color: "var(--white)" } : {}
          }
        >
          수업 시간 외 앱/웹 사용 관리
        </SpanSidebar>
      </HomeButtonSec>

      <HomeButtonSec
        style={
          location.pathname.includes("/quiz-pang")
            ? { backgroundColor: "var(--secondary)" }
            : {}
        }
        onClick={() => history.replace("/quiz-pang")}
      >
        <ABImg
          style={style}
          fill={
            location.pathname.includes("/quiz-pang") ? "#ffffff" : "#999999"
          }
        />
        <SpanSidebar
          style={
            location.pathname.includes("/quiz-pang")
              ? { color: "var(--white)" }
              : {}
          }
        >
          퀴즈팡 관리
        </SpanSidebar>
      </HomeButtonSec>

      <HomeButtonSec
        style={
          location.pathname.includes("/quiz-management")
            ? { backgroundColor: "var(--secondary)" }
            : {}
        }
        onClick={() => history.replace("/quiz-management")}
      >
        <ABImg
          style={{
            width: "24px",
            height: "24px",
            marginRight: "24px",
            objectFit: "contain",
          }}
          fill={
            location.pathname.includes("/quiz-management")
              ? "var(--grey-lighten-1)"
              : "var(--grey-lighten-1)"
          }
        />
        <SpanSidebar
          style={
            location.pathname.includes("/quiz-pang")
              ? { color: "var(--grey-lighten-1)" }
              : { color: "var(--grey-lighten-1)" }
          }
        >
          새로운 퀴즈팡 관리
        </SpanSidebar>
      </HomeButtonSec>

      {/*<HomeButtonSec
        style={
          location.pathname.includes('/attendance')
            ? { backgroundColor: '#05869225' }
            : {}
        }
        onClick={() => history.replace('/attendance')}
      >
        <AttendanceImg
          style={{
            width: '24px',
            height: '24px',
            marginRight: '24px',
            objectFit: 'contain',
          }}
          fill={
            location.pathname.includes('/attendance') ? '#35B5C2' : '#ffffff'
          }
        />
        <SpanSidebar
          style={
            location.pathname.includes('/attendance')
              ? { color: '#35B5C2' }
              : { color: '#ffffff' }
          }
        >
          출석부
        </SpanSidebar>
      </HomeButtonSec>*/}
    </>
  );
}

export default HomeButtonList;
