import {
  GET_GROUP_ACTIVITY_SUCCESS,
  GET_GROUP_ACTIVITY_FAIL,
  CREATE_GROUP_ACTIVITY_SUCCESS,
  CREATE_GROUP_ACTIVITY_FAIL,
  DELETE_GROUP_ACTIVITY_SUCCESS,
  DELETE_GROUP_ACTIVITY_FAIL,
  GET_SUBGROUP_ACTIVITY_SUCCESS,
  GET_SUBGROUP_ACTIVITY_FAIL,
  CREATE_SUBGROUP_ACTIVITY_SUCCESS,
  CREATE_SUBGROUP_ACTIVITY_FAIL,
  UPDATE_SUBGROUP_ACTIVITY_SUCCESS,
  UPDATE_SUBGROUP_ACTIVITY_FAIL,
  UPDATE_SUBGROUP_ACTIVITY_STUDENT,
  DELETE_SUBGROUP_ACTIVITY_SUCCESS,
  DELETE_SUBGROUP_ACTIVITY_FAIL,
  GET_SUBGROUP_ACTIVITY_STUDENT_SUCCESS,
  GET_SUBGROUP_ACTIVITY_STUDENT_FAIL,
  GROUP_ACTIVITY_LOADING
} from "./actionTypes";
import { createPromiseAction } from "@adobe/redux-saga-promise";

export const updateGroupActivityLoading = isLoading => ({
  type: GROUP_ACTIVITY_LOADING,
  payload: isLoading
});
export const getGroupActivityPromise = createPromiseAction(
  "GET_GROUP_ACTIVITY_PROMISE"
);
export const getGroupActivitySuccess = ({ subgroupActivitys }) => ({
  type: GET_GROUP_ACTIVITY_SUCCESS,
  payload: {
    activityList: subgroupActivitys
  }
});
export const getGroupActivityFail = error => ({
  type: GET_GROUP_ACTIVITY_FAIL,
  payload: error
});

export const createGroupActivityPromise = createPromiseAction(
  "CREATE_GROUP_ACTIVITY_PROMISE"
);
export const createGroupActivitySuccess = () => ({
  type: CREATE_GROUP_ACTIVITY_SUCCESS
});
export const createGroupActivityFail = error => ({
  type: CREATE_GROUP_ACTIVITY_FAIL,
  payload: error
});

export const deleteGroupActivityPromise = createPromiseAction(
  "DELETE_GROUP_ACTIVITY_PROMISE"
);
export const deleteGroupActivitySuccess = subgroupActivityId => ({
  type: DELETE_GROUP_ACTIVITY_SUCCESS,
  payload: {
    subgroupActivityId
  }
});
export const deleteGroupActivityFail = error => ({
  type: DELETE_GROUP_ACTIVITY_FAIL,
  payload: error
});

export const getSubgroupActivityPromise = createPromiseAction(
  "GET_SUBGROUP_ACTIVITY_PROMISE"
);
export const getSubgroupActivitySuccess = ({ subgroups }) => ({
  type: GET_SUBGROUP_ACTIVITY_SUCCESS,
  payload: {
    subgroupList: subgroups
  }
});
export const getSubgroupActivityFail = error => ({
  type: GET_SUBGROUP_ACTIVITY_FAIL,
  payload: error
});

export const createSubgroupActivityPromise = createPromiseAction(
  "CREATE_SUBGROUP_ACTIVITY_PROMISE"
);
export const createSubgroupActivitySuccess = subgroup => ({
  type: CREATE_SUBGROUP_ACTIVITY_SUCCESS,
  payload: {
    subgroup
  }
});
export const createSubgroupActivityFail = error => ({
  type: CREATE_SUBGROUP_ACTIVITY_FAIL,
  payload: error
});

export const updateSubgroupActivityPromise = createPromiseAction(
  "UPDATE_SUBGROUP_ACTIVITY_PROMISE"
);
export const updateSubgroupActivitySuccess = () => ({
  type: UPDATE_SUBGROUP_ACTIVITY_SUCCESS
});
export const updateSubgroupActivityFail = error => ({
  type: UPDATE_SUBGROUP_ACTIVITY_FAIL,
  payload: error
});

export const deleteSubgroupActivityPromise = createPromiseAction(
  "DELETE_SUBGROUP_ACTIVITY_PROMISE"
);
export const deleteSubgroupActivitySuccess = subgroupId => ({
  type: DELETE_SUBGROUP_ACTIVITY_SUCCESS,
  payload: {
    subgroupId
  }
});
export const deleteSubgroupActivityFail = error => ({
  type: DELETE_SUBGROUP_ACTIVITY_FAIL,
  payload: error
});

export const getSubgroupActivityStudentPromise = createPromiseAction(
  "GET_SUBGROUP_ACTIVITY_STUDENT_PROMISE"
);
export const getSubgroupActivityStudentSuccess = ({ subgroupActivitys }) => ({
  type: GET_SUBGROUP_ACTIVITY_STUDENT_SUCCESS,
  payload: {
    activityList: subgroupActivitys
  }
});
export const getSubgroupActivityStudentFail = error => ({
  type: GET_SUBGROUP_ACTIVITY_STUDENT_FAIL,
  payload: error
});

export const updateSubgroupActivityStudent = subgroupList => ({
  type: UPDATE_SUBGROUP_ACTIVITY_STUDENT,
  payload: { subgroupList }
});
