import { call, put, takeLatest, takeEvery } from "redux-saga/effects";
import {
  getGroupActivityPromise,
  getGroupActivitySuccess,
  getGroupActivityFail,
  createGroupActivityPromise,
  createGroupActivitySuccess,
  createGroupActivityFail,
  deleteGroupActivityPromise,
  deleteGroupActivitySuccess,
  deleteGroupActivityFail,
  getSubgroupActivityPromise,
  getSubgroupActivitySuccess,
  getSubgroupActivityFail,
  createSubgroupActivityPromise,
  createSubgroupActivityFail,
  deleteSubgroupActivityPromise,
  deleteSubgroupActivitySuccess,
  deleteSubgroupActivityFail,
  getSubgroupActivityStudentPromise,
  getSubgroupActivityStudentSuccess,
  getSubgroupActivityStudentFail
} from "@store/actions";
import * as api from "@api";
import { implementPromiseAction } from "@adobe/redux-saga-promise";
import {
  updateGroupActivityLoading,
  updateSubgroupActivityFail,
  updateSubgroupActivityPromise
} from "./actions";

/**
 * 모둠활동 리스트
 * @param { groupId } action
 */
function* getGroupActivityPromiseHandler(action) {
  yield put(updateGroupActivityLoading(true));
  yield call(implementPromiseAction, action, function*() {
    try {
      const response = yield call(api.getGroupActivity, action.payload);
      yield put(getGroupActivitySuccess(response.data));
      return yield response.data;
    } catch (error) {
      yield put(getGroupActivityFail(error));
    } finally {
      yield put(updateGroupActivityLoading(false));
    }
  });
}

/**
 * 모둠활동 생성
 * @param { groupId, name } action
 */
function* createGroupActivityPromiseHandler(action) {
  yield call(implementPromiseAction, action, function*() {
    try {
      const response = yield call(api.createGroupActivity, action.payload);
      yield put(createGroupActivitySuccess(response.data));
      yield put(getGroupActivityPromise(action.payload.groupId));
      return yield response.data;
    } catch (error) {
      yield put(createGroupActivityFail(error));
    }
  });
}

/**
 * 모둠활동 삭제
 * @param { subgroupActivityId } action
 */
function* deleteGroupActivityPromiseHandler(action) {
  yield call(implementPromiseAction, action, function*() {
    try {
      const response = yield call(api.deleteGroupActivity, action.payload);
      yield put(deleteGroupActivitySuccess(action.payload));
      return yield response.data;
    } catch (error) {
      yield put(deleteGroupActivityFail(error));
    }
  });
}

/**
 * 모둠 리스트
 * @param { subgroupActivityId } action
 */
function* getSubgroupActivityPromiseHandler(action) {
  yield put(updateGroupActivityLoading(true));
  yield call(implementPromiseAction, action, function*() {
    try {
      const response = yield call(api.getSubgroupActivity, action.payload);
      yield put(getSubgroupActivitySuccess(response.data));
      return yield response.data;
    } catch (error) {
      yield put(getSubgroupActivityFail(error));
    } finally {
      yield put(updateGroupActivityLoading(false));
    }
  });
}

/**
 * 모둠 생성
 * @param { subgroupActivityId, name, students } action
 */
function* createSubgroupActivityPromiseHandler(action) {
  yield call(implementPromiseAction, action, function*() {
    try {
      const response = yield call(api.createSubgroupActivity, action.payload);
      yield put(getSubgroupActivityPromise(action.payload.subgroupActivityId));
      return yield response.data;
    } catch (error) {
      yield put(createSubgroupActivityFail(error));
    }
  });
}

/**
 * 모둠 수정
 * @param { subgroupId, subgroupActivityId, name, students } action
 */
function* updateSubgroupActivityPromiseHandler(action) {
  yield call(implementPromiseAction, action, function*() {
    try {
      const {
        payload: { subgroupActivityId, ...payload }
      } = action;
      const response = yield call(api.updateSubgroupActivity, payload);
      yield put(getSubgroupActivityPromise(subgroupActivityId));
      return yield response.data;
    } catch (error) {
      yield put(updateSubgroupActivityFail(error));
    }
  });
}

/**
 * 모둠 삭제
 * @param { subgroupId } action
 */
function* deleteSubgroupActivityPromiseHandler(action) {
  yield call(implementPromiseAction, action, function*() {
    try {
      const response = yield call(api.deleteSubgroupActivity, action.payload);
      yield put(deleteSubgroupActivitySuccess(action.payload));
      return yield response.data;
    } catch (error) {
      yield put(deleteSubgroupActivityFail(error));
    }
  });
}

/**
 * 모둠 학생
 * @param { subgroupId } action
 */
function* getSubgroupActivityStudentPromiseHandler(action) {
  yield put(updateGroupActivityLoading(true));
  yield call(implementPromiseAction, action, function*() {
    try {
      const response = yield call(
        api.getSubgroupActivityStudent,
        action.payload
      );
      yield put(getSubgroupActivityStudentSuccess(response.data));
      return yield response.data;
    } catch (error) {
      yield put(getSubgroupActivityStudentFail(error));
    } finally {
      yield put(updateGroupActivityLoading(false));
    }
  });
}

function* groupActivitySaga() {
  yield takeLatest(getGroupActivityPromise, getGroupActivityPromiseHandler);
  yield takeEvery(
    createGroupActivityPromise,
    createGroupActivityPromiseHandler
  );
  yield takeLatest(
    deleteGroupActivityPromise,
    deleteGroupActivityPromiseHandler
  );
  yield takeLatest(
    getSubgroupActivityPromise,
    getSubgroupActivityPromiseHandler
  );
  yield takeEvery(
    createSubgroupActivityPromise,
    createSubgroupActivityPromiseHandler
  );
  yield takeEvery(
    updateSubgroupActivityPromise,
    updateSubgroupActivityPromiseHandler
  );
  yield takeLatest(
    deleteSubgroupActivityPromise,
    deleteSubgroupActivityPromiseHandler
  );
  yield takeLatest(
    getSubgroupActivityStudentPromise,
    getSubgroupActivityStudentPromiseHandler
  );
}

export default groupActivitySaga;
