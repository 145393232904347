export const gradeList = [
  [1, "timetable.addtimetable.select.1stgrade"],
  [2, "timetable.addtimetable.select.2ndgrade"],
  [3, "timetable.addtimetable.select.3rdgrade"],
  [4, "timetable.addtimetable.select.4thgrade"],
  [5, "timetable.addtimetable.select.5thgrade"],
  [6, "timetable.addtimetable.select.6thgrade"],
];
export const classGrouplist = [
  [1, "timetable.addtimetable.select.Class1"],
  [2, "timetable.addtimetable.select.Class2"],
  [3, "timetable.addtimetable.select.Class3"],
  [4, "timetable.addtimetable.select.Class4"],
  [5, "timetable.addtimetable.select.Class5"],
  [6, "timetable.addtimetable.select.Class6"],
  [7, "timetable.addtimetable.select.Class7"],
  [8, "timetable.addtimetable.select.Class8"],
  [9, "timetable.addtimetable.select.Class9"],
  [10, "timetable.addtimetable.select.Class10"],
  [11, "timetable.addtimetable.select.Class11"],
  [12, "timetable.addtimetable.select.Class12"],
  [13, "timetable.addtimetable.select.Class13"],
  [14, "timetable.addtimetable.select.Class14"],
  [15, "timetable.addtimetable.select.Class15"],
  [16, "timetable.addtimetable.select.Class16"],
  [17, "timetable.addtimetable.select.Class17"],
  [18, "timetable.addtimetable.select.Class18"],
  [19, "timetable.addtimetable.select.Class19"],
  [20, "timetable.addtimetable.select.Class20"],
  [21, "timetable.addtimetable.select.Class21"],
  [22, "timetable.addtimetable.select.Class22"],
  [23, "timetable.addtimetable.select.Class23"],
  [24, "timetable.addtimetable.select.Class24"],
  [25, "timetable.addtimetable.select.Class25"],
  [26, "timetable.addtimetable.select.Class26"],
  [27, "timetable.addtimetable.select.Class27"],
  [28, "timetable.addtimetable.select.Class28"],
  [29, "timetable.addtimetable.select.Class29"],
  [30, "timetable.addtimetable.select.Class30"],
  [31, "timetable.addtimetable.select.Class31"],
  [32, "timetable.addtimetable.select.Class32"],
  [33, "timetable.addtimetable.select.Class33"],
  [34, "timetable.addtimetable.select.Class34"],
  [35, "timetable.addtimetable.select.Class35"],
  [36, "timetable.addtimetable.select.Class36"],
  [37, "timetable.addtimetable.select.Class37"],
  [38, "timetable.addtimetable.select.Class38"],
  [39, "timetable.addtimetable.select.Class39"],
  [40, "timetable.addtimetable.select.Class40"],
  [41, "timetable.addtimetable.select.Class41"],
  [42, "timetable.addtimetable.select.Class42"],
  [43, "timetable.addtimetable.select.Class43"],
  [44, "timetable.addtimetable.select.Class44"],
  [45, "timetable.addtimetable.select.Class45"],
  [46, "timetable.addtimetable.select.Class46"],
  [47, "timetable.addtimetable.select.Class47"],
  [48, "timetable.addtimetable.select.Class48"],
  [49, "timetable.addtimetable.select.Class49"],
  [50, "timetable.addtimetable.select.Class50"],
  [0, "기타"],
];
export const classtimes = [
  [1, "timetable.addtimetable.select.1stclass"],
  [2, "timetable.addtimetable.select.2ndclass"],
  [3, "timetable.addtimetable.select.3rdclass"],
  [4, "timetable.addtimetable.select.4thclass"],
  [5, "timetable.addtimetable.select.5thclass"],
  [6, "timetable.addtimetable.select.6thclass"],
  [7, "timetable.addtimetable.select.7thclass"],
  [8, "timetable.addtimetable.select.8thclass"],
];
export const weekdays = [
  [1, "timetable.addtimetable.select.monday"],
  [2, "timetable.addtimetable.select.tuesday"],
  [3, "timetable.addtimetable.select.wednesday"],
  [4, "timetable.addtimetable.select.thursday"],
  [5, "timetable.addtimetable.select.friday"],
];

export const timeList = [
  ["rRule", "반복 안함"],
  ["FREQ=DAILY", "매일"],
  ["FREQ=WEEKLY", "매주"],
  // ['FREQ=DAILY;BYDAY=MO,TU,WE,TH,FR', '주중 매일(월-금)'],
];
