import React, { memo, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { purgeAll, setSidebarState, updateUserPromise } from "@store/actions";
import { useHistory } from "react-router-dom";
import { profileImgPath } from "@api";
import ModalUpdateProfileImage from "@components/Modal/ModalUpdateProfileImage";
import ProfileImage from "@components/ProfileImage";
import { setReportLog, signInPromise } from "@app/store/actions";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import ForumIcon from "@mui/icons-material/Forum";
import Wrapper from "@app/components/Layout/Common/Wrapper";
import {
  Button,
  Grid,
  GridItem,
  HStack,
  Heading,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightElement,
  Stack,
  Text,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import { ButtonConstants } from "@app/Constants/ButtonConstants";
import { useTranslation } from "react-i18next";

const Settings = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [pw, setPw] = useState("");
  const [confirmPw, setConfirmedPw] = useState("");
  const control = useSelector((state) => state.control);
  const user = useSelector((state) => state.user);
  const [PWdisabled, setPWDisabled] = useState(false);
  const [PWcurrent, setPWCurrent] = useState(false);
  const [ChangeNext, setChangeNext] = useState(false);
  const [show, setShow] = React.useState(false);

  const handleClick = () => setShow(!show);

  const onCurrentPWCheck = () => {
    dispatch(
      signInPromise({
        email: user?.email,
        password: PWcurrent,
        role: "TEACHER",
      })
    ).then((res) => {
      if (res) {
        // console.log('선생님으로 로그인성공', res);
        setChangeNext(true);
      } else {
        dispatch(
          signInPromise({
            email: user?.email,
            password: PWcurrent,
            role: "ADMIN",
          })
        ).then((res) => {
          if (res) {
            // console.log('어드민으로 로그인성공', res);
            setChangeNext(true);
          } else {
            // console.log('로그인실패 > 비번틀림');
            // alert("비밀번호가 일치하지 않습니다. 다시 입력해 주세요.");
            alert(t("sidemenu.setting.password.alert.nomatch.text"));
            setChangeNext(false);
          }
        });
      }
    });
  };

  const onChangePWCheck = useCallback((e) => {
    const pwRegex = /^(?=.*\d)(?=.*[a-zA-Z])(?=.*[*!@#$%^&+=]).{8,16}$/;
    if (!pwRegex.test(e.target.value)) {
      setPWDisabled(true);
    } else {
      setPWDisabled(false);
    }
  }, []);

  useEffect(() => {
    if (user.state === "REGISTERED_APPROVED") {
      if (control.sidebarState === false) dispatch(setSidebarState());
      setUserName(user.userName);
      setEmail(user.email);
    } else {
      //dispatch(setSidebarState(false));
    }
  }, [user, control.sidebarState, dispatch]);

  const sendChagnedInfo = () => {
    if (!userName) {
      // alert("이름을 입력해주세요.");
      alert(t("sidemenu.setting.changeinfo.alert.username.text"));
      return;
    }
    if (userName.length > 35) {
      // alert("이름은 35자 이내로 입력해주세요.");
      alert(t("sidemenu.setting.changeinfo.alert.usernamelength.text"));
      return;
    }
    dispatch(
      updateUserPromise({
        accountId: user.accountId,
        userName: userName,
      })
    )
      .then((response) => {
        console.log(response);
        // alert(`정보가 성공적으로 변경되었습니다.`);
        alert(t("sidemenu.setting.changeInfo.alert.success.text"));
      })
      .catch((error) => {
        console.log(error);
        alert(`정보 변경에 실패했습니다. 입력하신 정보를 확인해주세요.`);
      });
  };
  const sendChangedPassword = () => {
    if (!pw) {
      // alert("비밀번호를 입력해주세요.");
      alert(t("sidemenu.setting.password.alert.confirm.text"));
      return;
    }
    if (PWdisabled) {
      // alert("비밀번호는 8~16자 문자, 숫자, 특수문자를 사용하세요.");
      alert(t("sidemenu.setting.password.alert.validrange.text"));
      return;
    }
    dispatch(
      updateUserPromise({
        accountId: user.accountId,
        password: pw,
      })
    )
      .then((response) => {
        console.log(response);
        // alert(`비밀번호가 성공적으로 변경되었습니다!`);
        alert(t("sidemenu.setting.password.alert.success.text"));
        setChangeNext(false);
      })
      .catch((error) => {
        console.log(error);
        // alert(`정보 변경에 실패했습니다. 입력하신 정보를 확인해주세요.`);
        alert(t("sidemenu.setting.password.alert.nomatch.text"));
      });
  };

  return (
    <Wrapper
      // currentPageName={"설정"}
      currentPageName={t("sidemenu.setting.text.title")}
    >
      <Stack w="full">
        <Grid templateColumns={"400px 1fr"} gap={6}>
          <GridItem borderRight="1px" borderRightColor="gray.200">
            <Heading fontSize="xl">
              {/* 포커스팡 회원 정보 */}
              {t("sidemenu.setting.text.information.user")}
            </Heading>
            <Stack spacing={4} direction="column" mt={4} p={4}>
              <VStack spacing={2}>
                <ProfileImage
                  size={84}
                  url={profileImgPath(user.clientId)}
                  subscribeImageChange={user.subscribeImageChange}
                />
                <Text fontSize="lg" fontWeight="bold">
                  {user?.userName}
                </Text>
                <Text fontSize="sm" color="gray.500">
                  {user?.email}
                </Text>
              </VStack>
            </Stack>
          </GridItem>

          <GridItem>
            <Heading fontSize="xl">
              {/* 회원 정보 수정 */}
              {t("sidemenu.setting.text.information.edit")}
            </Heading>
            <Stack spacing={4} direction="column" mt={4} p={4}>
              <HStack spacing={4}>
                <ProfileImage
                  size={84}
                  url={profileImgPath(user.clientId)}
                  subscribeImageChange={user.subscribeImageChange}
                />
                <Button
                  onClick={() => {
                    onOpen();
                    dispatch(
                      setReportLog(
                        ButtonConstants.CONFIG.OPEN_CHANGE_IMAGE_MODAL
                      )
                    );
                  }}
                >
                  {/* 프로필 사진 변경 */}
                  {t("sidemenu.setting.button.changeprofile")}
                </Button>
              </HStack>
              <InputGroup>
                <InputLeftAddon
                  // children="이름"
                  children={t("sidemenu.setting.placeholder.name")}
                  w="80px"
                />
                <Input
                  variant="outline"
                  name="name"
                  value={userName}
                  onChange={(e) => setUserName(e.target.value)}
                />
              </InputGroup>
              <InputGroup>
                <InputLeftAddon
                  // children="이메일"
                  children={t("sidemenu.setting.placeholder.email")}
                  w="80px"
                />
                <Input value={user.email} isDisabled />
              </InputGroup>
              <Button
                isDisabled={userName === "" || email === ""}
                onClick={() => {
                  sendChagnedInfo();
                  dispatch(setReportLog(ButtonConstants.CONFIG.CHANGE_INFO));
                }}
              >
                {/* 정보 변경 */}
                {t("sidemenu.setting.button.changeinfo")}
              </Button>
            </Stack>

            <Heading fontSize="xl">
              {/* 비밀번호 변경 */}
              {t("sidemenu.setting.text.passinfo")}
            </Heading>
            <Stack spacing={4} direction="column" p={4}>
              <Text fontSize="sm" color="gray.500">
                {/* 1. 먼저 현재 비밀번호를 입력해주세요 */}
                {t("sidemenu.setting.text.passdescription")}
              </Text>
              <InputGroup>
                <Input
                  // placeholder="현재 비밀번호 입력"
                  placeholder={t("sidemenu.setting.placeholder.password")}
                  onChange={(e) => {
                    setPWCurrent(e.target.value);
                  }}
                  type={show ? "text" : "password"}
                />
                <InputRightElement width="4.5rem">
                  <Button h="1.75rem" size="sm" onClick={handleClick}>
                    {/* {show ? "숨기기" : "보기"} */}
                    {show
                      ? t("sidemenu.setting.placeholder.password.hide")
                      : t("sidemenu.setting.placeholder.password.show")}
                  </Button>
                </InputRightElement>
              </InputGroup>
              <VStack alignItems="flex-end">
                <Button
                  colorScheme="blue"
                  onClick={() => {
                    onCurrentPWCheck();
                    dispatch(
                      setReportLog(
                        ButtonConstants.CONFIG.CHECK_CURRENT_PASSWORD
                      )
                    );
                  }}
                  isDisabled={
                    // 비번을 입력하지 않았을 때
                    PWcurrent === "" ||
                    PWcurrent === null ||
                    PWcurrent === 0 ||
                    PWcurrent === undefined ||
                    PWcurrent === false
                  }
                >
                  {/* 다음 */}
                  {t("sidemenu.setting.button.next")}
                </Button>
              </VStack>
              {ChangeNext ? (
                <>
                  <InputGroup>
                    <Input
                      // placeholder="새 비밀번호 입력"
                      placeholder={t(
                        "sidemenu.setting.placeholder.newpassword"
                      )}
                      onChange={(e) => {
                        setPw(e.target.value);
                        onChangePWCheck(e);
                      }}
                      type={show ? "text" : "password"}
                    />
                    <InputRightElement width="4.5rem">
                      <Button h="1.75rem" size="sm" onClick={handleClick}>
                        {/* {show ? "숨기기" : "보기"} */}
                        {show
                          ? t("sidemenu.setting.placeholder.password.hide")
                          : t("sidemenu.setting.placeholder.password.show")}
                      </Button>
                    </InputRightElement>
                  </InputGroup>
                  <div style={{ fontSize: 13, color: "red", marginBottom: 8 }}>
                    {/* {PWdisabled
                      ? "비밀번호는 8~16자 문자, 숫자, 특수문자를 사용하세요."
                      : null} */}
                    {PWdisabled
                      ? t(
                          "sidemenu.setting.text.password.validrange.description"
                        )
                      : null}
                  </div>
                  <InputGroup>
                    <Input
                      // placeholder="비밀번호 입력 확인"
                      placeholder={t(
                        "sidemenu.setting.placeholder.newpassword.check"
                      )}
                      onChange={(e) => setConfirmedPw(e.target.value)}
                      type={show ? "text" : "password"}
                    />
                    <InputRightElement width="4.5rem">
                      <Button h="1.75rem" size="sm" onClick={handleClick}>
                        {/* {show ? "숨기기" : "보기"} */}
                        {show
                          ? t("sidemenu.setting.placeholder.password.hide")
                          : t("sidemenu.setting.placeholder.password.show")}
                      </Button>
                    </InputRightElement>
                  </InputGroup>
                  <VStack alignItems="flex-end">
                    {pw === confirmPw && pw !== "" && !PWdisabled ? (
                      <Button
                        colorScheme="blue"
                        onClick={() => {
                          sendChangedPassword();
                          dispatch(
                            setReportLog(ButtonConstants.CONFIG.CHANGE_PASSWORD)
                          );
                        }}
                      >
                        {/* 비밀번호 변경 */}
                        {t("sidemenu.setting.button.newpassword")}
                      </Button>
                    ) : pw === "" ? (
                      <Text fontSize="md" color="gray.500" fontWeight="bold">
                        {/* 비밀번호를 입력해주세요. */}
                        {t("sidemenu.setting.password.alert.confirm.text")}
                      </Text>
                    ) : (
                      <Text fontSize="md" color="gray.500" fontWeight="bold">
                        {/* 비밀번호가 일치하지 않습니다. */}
                        {t("sidemenu.setting.password.alert.nomatch.text")}
                      </Text>
                    )}
                  </VStack>
                </>
              ) : null}
            </Stack>
            <Text fontSize="sm" color="gray.500" textAlign="center">
              ver.3.1.6
            </Text>
          </GridItem>
        </Grid>
      </Stack>
      <ModalUpdateProfileImage
        isOpen={isOpen}
        onClose={onClose}
        onOpen={onOpen}
        currentProfileImage={profileImgPath(user.clientId)}
      />
    </Wrapper>
  );
};

export default memo(Settings);
