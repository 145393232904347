import {
  getBackend,
  postBackend,
  patchBackend,
  deleteBackend,
  postBackendFormData,
} from "./Backend";

export const signIn = async (userData) => {
  return await postBackend("signIn/email?role=TEACHER", userData, true);
};

export const updateUser = async (userData) => {
  return await patchBackend("account", userData, true);
};

export const getAccountToken = async (accountId) => {
  return await getBackend(`account/auth?accountId=${accountId}`);
};

///// User specific end

// update Client or Account Info
export const getClient = async (accountId) => {
  return await getBackend(`account?accountId=${accountId}`);
};

export const getClientsByGroup = async (groupId) => {
  return await getBackend(`membership?groupId=${groupId}`);
};

export const updateClient = async (clientData) => {
  return await patchBackend("client", clientData);
};

//// participant
export const createParticipation = async (participationData) => {
  return await postBackend("participation", participationData);
};

export const getParticipation = async (participationData) => {
  console.log("participationData", participationData);
  return await getBackend(
    `participation?classroomId=${participationData.classroomId}&clientId=${participationData.clientId}`
  );
};

export const getParticipationsByClassroom = async (classroomId) => {
  return await getBackend(`participation?classroomId=${classroomId}`);
};

export const getParticipationsByClient = async (clientId) => {
  return await getBackend("participation", clientId);
};

export const updateParticipation = async (participationData) => {
  return await patchBackend("participation", participationData);
};

export const deleteParticipation = async (deleteParticipationData) => {
  return await deleteBackend(
    `participation/${deleteParticipationData.classroomId}/${deleteParticipationData.clientId}`
  );
};

//// managed
export const createManaged = async (managedData) => {
  return await postBackend("managed", managedData);
};

export const getManaged = async (managedData) => {
  return await getBackend(
    `managed?managingId=${managedData.managingId}&clientId=${managedData.clientId}`
  );
};

export const getManagedsByManaging = async (managingId) => {
  return await getBackend(`managed?managingId=${managingId}`);
};

export const getManagedsByGroup = async (groupId) => {
  return await getBackend(`managed?groupId=${groupId}`);
};

export const getManagedsByClient = async (clientId) => {
  return await getBackend(`managed?clientId=${clientId}`);
};

export const updateManaged = async (managedData) => {
  return await patchBackend("managed", managedData);
};

export const deleteManaged = async (managingId, clientId) => {
  return await deleteBackend(`managed/${managingId}/${clientId}`);
};

///// classroom
export const getClassroom = async (classroomId) => {
  return await getBackend(`classroom?classroomId=${classroomId}`);
};

export const getActiveClassroom = async (clientId) => {
  return await getBackend(`classroom?clientId=${clientId}&active=true`);
};

export const createClassroom = async (createdClassroom) => {
  return await postBackend("classroom", createdClassroom);
};

export const updateClassroom = async (updatedClassroom) => {
  return await patchBackend("classroom", updatedClassroom);
};

export const deleteClassroom = async (classroomId) => {
  return await deleteBackend(`classroom/${classroomId}`);
};

///// managing
export const getManaging = async (managingId) => {
  return await getBackend(`managing?managingId=${managingId}`);
};

export const getManagingsByGroup = async (managingData) => {
  return await getBackend(`managing?groupId=${managingData}`);
};

export const getActiveManaging = async (clientId) => {
  return await getBackend(`managing?clientId=${clientId}&active=true`);
};

export const getActiveManagingByGroup = async (groupId) => {
  return await getBackend(`managing?groupId=${groupId}&active=true`);
};

export const createManaging = async (createdManaging) => {
  return await postBackend("managing", createdManaging);
};

export const updateManaging = async (updatedManaging) => {
  return await patchBackend("managing", updatedManaging);
};

export const deleteManaging = async (managingId) => {
  return await deleteBackend(`managing/${managingId}`);
};

//// membership
export const getMembership = async ({ clientId, groupId }) => {
  return await getBackend(
    `membership?clientId=${clientId}&groupId=${groupId}`,
    true
  );
};

export const getMembershipByClient = async (clientId) => {
  return await getBackend(`membership?clientId=${clientId}`);
};

export const getMembershipsByGroup = async (groupId) => {
  return await getBackend(`membership?groupId=${groupId}`);
};

export const createMembership = async (membership) => {
  return await postBackend("membership", membership);
};

export const deleteMembership = async (groupId, clientId) => {
  return await deleteBackend(`membership/${groupId}/${clientId}`);
};

//// group
export const getGroup = async (groupId) => {
  return await getBackend(`group?groupId=${groupId}`);
};

export const createInviteCode = async (groupId) => {
  return await getBackend(`group?generateInviteCode=true&groupId=${groupId}`);
};

export const getGroupByInviteCode = async (inviteCode) => {
  return await getBackend(`group?inviteCode=${inviteCode}`);
};

export const createGroup = async (group) => {
  return await postBackend("group", group);
};

export const updateGroup = async (updatedGroup) => {
  return await patchBackend("group", updatedGroup);
};

export const deleteGroup = async (groupId) => {
  return await deleteBackend(`group/${groupId}`);
};

//// Notification
export const getNotification = async (notificationId) => {
  return await getBackend(
    `notification?notificationId=${notificationId}`,
    true
  );
};

export const getNotifications = async (clientId) => {
  return await getBackend(`notification?clientId=${clientId}`);
};

export const createNotification = async (notification) => {
  return await postBackend("notification", notification);
};

export const updateNotification = async (updatedNotification) => {
  return await patchBackend("notification", updatedNotification);
};

export const deleteNotification = async (notificationId) => {
  return await deleteBackend(`notification/${notificationId}`);
};

///// program
export const getProgram = async (programId) => {
  return await getBackend(`program?programId=${programId}`);
};

export const getPrograms = async () => {
  return await getBackend("program/all");
};

///// programconfig
export const getProgramconfig = async (programconfigId) => {
  return await getBackend(
    `programconfig?programconfigId=${programconfigId}`,
    true
  );
};

export const getGroupProgramconfigs = async (groupId) => {
  return await getBackend(`programconfig?groupId=${groupId}`);
};

export const createProgramconfig = async (programconfigData) => {
  return await postBackend("programconfig", programconfigData);
};

export const updateProgramconfig = async (programconfigData) => {
  return await patchBackend("programconfig", programconfigData);
};

export const deleteProgramconfig = async (programconfigId) => {
  return await deleteBackend(`programconfig/${programconfigId}`);
};

///// school
export const getSchool = async (schoolId) => {
  return await getBackend(`school?schoolId=${schoolId}`);
};

export const getAllSchools = async (index) => {
  return await getBackend(`school/all?index=${index}`);
};

export const getAllSchoolsFilteredDevision = async (dvision, index) => {
  return await getBackend(`school/all?dvision=${dvision}&index=${index}`);
};

export const getAllSchoolsFilteredDvisionAndCity = async (
  dvision,
  schoolLevel,
  index
) => {
  return await getBackend(
    `school/all?devision=${dvision}&schoolLevel=${schoolLevel}&index=${index}`
  );
};

///// device
export const registerDevice = async (deviceData) => {
  return await postBackend("device/register", deviceData);
};

//// timetable
export const getTimetable = async (timetableId) => {
  return await getBackend(`timetable?timetableId=${timetableId}`);
};

export const getTimetablesByGroup = async (groupId) => {
  return await getBackend(`timetable?groupId=${groupId}`);
};

export const createTimetable = async (timetableData) => {
  return await postBackend("timetable", timetableData);
};

export const updateTimetable = async (timetableData) => {
  return await patchBackend("timetable", timetableData);
};

export const deleteTimetable = async (timetableId) => {
  return await deleteBackend(`timetable/${timetableId}`);
};

//// Filelink
export const createFilelink = async (filelinkData) => {
  return await postBackend("filelink", filelinkData);
};

export const getFilelink = async (filelinkId) => {
  return await getBackend(`filelink?filelinkId=${filelinkId}`);
};

export const getGroupFilelink = async (groupId) => {
  return await getBackend(`filelink?groupId=${groupId}`);
};

export const deleteFilelink = async (filelinkId) => {
  return await deleteBackend(`filelink/${filelinkId}`);
};

//// kakaocert
export const requestCert = async (certData) => {
  return await postBackend("cert/request/", certData);
};

export const checkResponse = async (receiptId) => {
  return await postBackend("cert/check/", receiptId);
};

export const updateProfileImage = async (image) => {
  return await postBackendFormData(`client/upload/`, { profile: image });
};

// quiz
export const getQuiz = async (quizId) => {
  return await getBackend(`quiz?quizId=${quizId}`);
};
export const getQuizListAll = async (index) => {
  return await getBackend(`quiz/all?index=${index}`);
};

export const getQuizListByClient = async (clientId) => {
  return await getBackend(`quiz?clientId=${clientId}`);
};

export const getQuizListBySchool = async (schoolId) => {
  return await getBackend(`quiz?schoolId=${schoolId}`);
};

export const createQuiz = async (quizData) => {
  return await postBackend("quiz", quizData);
};

export const updateQuiz = async (quizData) => {
  return await patchBackend("quiz", quizData);
};

export const deleteQuiz = async (quizId) => {
  return await deleteBackend(`quiz/${quizId}`);
};

export const createQuizCategory = async (quizCategory) => {
  return await postBackend("quizCategory", quizCategory);
};

export const getQuizCategory = async () => {
  return await getBackend("quizCategory/all");
};

// quizset
export const getQuizsetListAll = async (index) => {
  return await getBackend(`quizset/all?index=${index}`);
};
// export const getQuizsetListAll = async (data) => {
//   let queryString = 'quizset/all?index=' + data.index;

//   if (data.grade) {
//     queryString += '&grade=' + data.grade;
//   }

//   if (data.subject) {
//     queryString += '&subject=' + data.subject;
//   }

//   return await getBackend(queryString);
// }
export const getQuizsetByQuizsetId = async (quizsetId) => {
  return await getBackend(`quizset?quizsetId=${quizsetId}`);
};

export const getQuizsetListByClient = async (clientId) => {
  return await getBackend(`quizset?clientId=${clientId}`);
};

export const getQuizsetListBySchool = async (schoolId) => {
  return await getBackend(`quizset?schoolId=${schoolId}`);
};

export const createQuizset = async (quizsetData) => {
  return await postBackend("quizset", quizsetData);
};

export const updateQuizset = async (quizsetData) => {
  return await patchBackend("quizset", quizsetData);
};

export const deleteQuizset = async (quizsetId) => {
  return await deleteBackend(`quizset/${quizsetId}`);
};

// abTest TestCrewType
export const getTestCrewType = async (
  quizsetSessionId,
  testCrewType,
  clientId
) => {
  return await getBackend(
    `ab-labs/test-crews?quizsetSessionId=${quizsetSessionId}&testCrewType=${testCrewType}&clientId=${clientId}`
  );
};

export const getCrewQuizset = async (quizsetId, testCrewType) => {
  return await getBackend(
    `ab-labs/quizset?quizsetId=${quizsetId}&testCrewType=${testCrewType}`
  );
};

/**
 * Quizset Session
 */
export const getQuizsetSessionHistories = async (groupId, index) => {
  return await getBackend(
    `quizsetSession/history?groupId=${groupId}&index=${index}`
  );
};
export const getQuizsetSessionActive = async (groupId) => {
  return await getBackend(`quizsetSession?groupId=${groupId}&active=true`);
};
export const getQuizsetSessionActiveAll = async (clientId) => {
  return await getBackend(`quizsetSession?clientId=${clientId}&active=true`);
};

export const createQuizsetSession = async (params) => {
  return await postBackend("quizsetSession", params);
};
export const patchQuizsetSession = async (params) => {
  console.log("patchQuizsetSession");
  return await patchBackend("quizsetSession", params);
};
export const createQuizsetSessionByQuiz = async ({
  quizId,
  groupId,
  clientId,
  startAt,
}) => {
  return await postBackend("quizsetSession/one", {
    quizId,
    groupId,
    clientId,
    startAt,
  });
};

export const getQuizsetSessionResult = async (quizsetSessionId, quizOrder) => {
  return await getBackend(
    `quizsetSession/result?quizsetSessionId=${quizsetSessionId}&quizOrder=${quizOrder}`
  );
};

/**
 * Quizset Session Joint
 */
export const getQuizsetSessionQuizJoint = async (quizsetSessionId) => {
  return await getBackend(
    `quizsetSessionQuizJoint?quizsetSessionId=${quizsetSessionId}`
  );
};
export const createQuizsetSessionQuizJoint = async (params) => {
  return await postBackend("quizsetSessionQuizJoint", params);
};

// 퀴즈 채점
export const getScoringNeededQuizResponses = async (quizsetSessionId) => {
  return await getBackend(`quizScoring?quizsetSessionId=${quizsetSessionId}`);
};

export const scoreQuizResponse = async (
  quizsetSessionId,
  clientId,
  choiceId,
  score
) => {
  return await patchBackend("quizScoring", {
    quizsetSessionId,
    clientId,
    choiceId,
    score,
    isScoringFinished: true,
  });
};

// 모둠활동
export const getGroupActivity = async (groupId) => {
  return await getBackend(`subgroupActivity?groupId=${groupId}`);
};
export const createGroupActivity = async (params) => {
  return await postBackend("subgroupActivity", params);
};
export const deleteGroupActivity = async (subgroupActivityId) => {
  return await deleteBackend(`subgroupActivity/${subgroupActivityId}`);
};
export const getSubgroupActivity = async (subgroupActivityId) => {
  return await getBackend(`subgroup?subgroupActivityId=${subgroupActivityId}`);
};
export const createSubgroupActivity = async (params) => {
  return await postBackend("subgroup", params);
};
export const deleteSubgroupActivity = async (subgroupActivityId) => {
  return await deleteBackend(`subgroup/${subgroupActivityId}`);
};
export const updateSubgroupActivity = async (params) => {
  return await patchBackend(`subgroup`, params);
};
export const getSubgroupActivityStudent = async (subgroupId) => {
  return await getBackend(`subgroupStudent?subgroupId=${subgroupId}`);
};

//ReportLog
export const createReport = async (createdReport) => {
  return await postBackend("report?list=true", createdReport);
};

//reportList
export const getReport = async (reportId) => {
  return await getBackend(`report?reportId=${reportId}`);
};

export const getReportsListByClient = async (data) => {
  return await getBackend(
    `report?clientId=${data.clientId}&offset=${data.offset}&amount=${data.amount}`
  );
};

export const getReportListByGroup = async (data) => {
  return await getBackend(
    `report?groupId=${data.groupId}&offset=${data.offset}&amount=${data.amount}`
  );
};

export const deleteReport = async (reportId) => {
  return await deleteBackend(`report/${reportId}`);
};

//reportEvents
export const createReportEvents = async (createdEvents) => {
  return await postBackend("log/screenShare/history", createdEvents);
};

// CommentsLayer
export const getCommentsLayer = async (classroomId) => {
  return await getBackend(`/commentsLayer?classroomId=${classroomId}`, true);
};
export const createCommentsLayer = async (data) => {
  return await postBackend(`/commentsLayer`, data, true);
};
export const updateCommentsLayer = async (data) => {
  return await patchBackend(`/commentsLayer`, data, true);
};
// Commentary
export const getCommentary = async (classroomId) => {
  return await getBackend(`/commentary?classroomId=${classroomId}`, true);
};
export const createCommentary = async (data) => {
  return await postBackend(`/commentary`, data, true);
};
export const updateCommentary = async (data) => {
  return await patchBackend(`/commentary`, data, true);
};

// ButtonHistory
export const createButtonHistory = async (data) => {
  return await postBackend(`/buttonEvent/history`, data, true);
};

//DAshboard Component

export const getDashboardComponent = async () => {
  const response = await getBackend("/component/all");
  return response.data.dashboardComponents;
};

// Dashboard Layout
/**
 * @param {Object} data
 * @param {number} data.componentId
 * @param {number} data.groupId
 * @param {number} data.clientId
 * @param {number} data.rowNum
 * @param {number} data.colNum
 * @param {boolean} data.isAllowed
 * @returns {Promise<*>}
 * @example
 * const data = 
 *  {
        "groupId": "XXXX-XXXX-XXXX-XXXX", // UUID
        "clientId": "XXXX-XXXX-XXXX-XXXX", // UUID
        "componentIds": [ // componentId(Int) 배열, 컴포넌트아이디를 추가한 순서대로 입력
            22, 5, 31, 10, 3, 32
        ]
 *  }
 * */
export const postDashboardLayout = async (data) => {
  const response = await postBackend(`/layout?list=true`, data);
  return response.data;
};

export const getDashboardLayoutByGroupId = async (groupId) => {
  const response = await getBackend(`/layout?groupId=${groupId}`);
  return response.data;
};

//  {
//   "layoutId": x, // Int(required)
//   "rowNum": x, // Int(optional)
//   "colNum": x, // Int(optional)
//   "isAllowed": true | false // Boolean(optional)
// }
/**
 * @param {Object} data
 * @param {number} data.layoutId
 * @param {number} data.rowNum
 * @param {number} data.colNum
 * @param {boolean} data.isAllowed
 * @returns {Promise<*>}
 * @example
 * const data = {
 * layoutId: 1,
 * rowNum: 1,
 * colNum: 1,
 * isAllowed: true
 * }
 * */
export const patchDashboardLayout = async (data) => {
  const response = await patchBackend(`/layout`, data);
  return response.data;
};

/**
 * @param {string} groupId
 * @param {number} componentId
 * */

export const deleteDashboardLayout = async (data) => {
  const response = await deleteBackend(
    `/layout?groupId=${data.groupId}&componentId=${data.componentId}`
  );
  return response.data;
};

// Dashboard Data
export const getSummaryTotalData = async (schoolId, classYear, classNumber) => {
  const response = await getBackend(
    `/summary/total?schoolId=${schoolId}&classYear=${classYear}&classNumber=${classNumber}`
  );
  return response.data;
};

// participationStudent
export const participationStudent = async (groupId) => {
  const response = await getBackend(`/participationStudent?groupId=${groupId}`);
  return response.data;
};

// 학업성취
// quizpangSub
export const getQuizpangSub = async (quizsetSessionId) => {
  const response = await getBackend(`/quizScoreSub`);
  return response.data;
};

export const getQuizScoreGroup = async (quizsetSessionId, groupId) => {
  const response = await getBackend(
    `/quizScoreGroup?quizsetSessionId=${quizsetSessionId}&groupId=${groupId}`
  );
  return response.data;
};

//ExamScoreSummary

export const getExamScoreSummaryOfGroupId = async (groupId, subjectName) => {
  const response = await getBackend(
    `/examStatistics?groupId=${groupId}&subjectName=${subjectName}&type=studentTrend`
  );
  console.log("response", response.data);
  return response.data;
};

export const getExamScoreSummaryOfSchoolId = async (schoolId) => {
  const response = await getBackend(`/examScoreSummary?schoolId=${schoolId}`);
  return response.data;
};

// ExamStatistics
// 바 차트용
export const getExamStatisticsTypeBar = async (groupId, subjectName) => {
  const response = await getBackend(
    `/examStatistics?type=scoreComparison&groupId=${groupId}&subjectName=${subjectName}`
  );
  return response.data;
};

//examStatistics?type=subjectComparison
export const getExamStatisticsSubjectComparison = async (
  groupId,
  subjectName
) => {
  const response = await getBackend(
    `/examStatistics?type=subjectComparison&groupId=${groupId}&subjectName=${subjectName}`
  );
  return response.data;
};

//examStatistics?type=scoreComparison
export const getExamStatisticsScoreComparison = async (
  groupId,
  subjectName
) => {
  const response = await getBackend(
    `/examStatistics?type=scoreComparison&groupId=${groupId}&subjectName=${subjectName}`
  );
  return response.data;
};

//examStatistics?type=schoolWrongRate
export const getExamStatisticsSchoolWrongRate = async (
  schoolId,
  subjectName
) => {
  const response = await getBackend(
    `/examStatistics?type=schoolWrongRate&schoolId=${schoolId}&subjectName=${subjectName}`
  );
  return response.data;
};

//examStatistics?type=studentTrend
export const getExamStatisticsStudentTrendOfClass = async (groupId) => {
  const response = await getBackend(
    `/examStatistics?type=studentTrend&groupId=${groupId}`
  );
  return response.data;
};

//examStatistics?type=studentTrend`
export const getExamStatisticsStudentTrendOfSubjectName = async (
  groupId,
  subjectName
) => {
  const response = await getBackend(
    `/examStatistics?type=studentTrend&groupId=${groupId}&subjectName=${subjectName}`
  );
  return response.data;
};

// antV chart_boxplot
//GET /v3/chart/performance?style=BOX`
export const getChartPerformanceBoxOfSubjectName = async (
  style,
  type,
  groupId,
  year,
  month,
  subejctName
) => {
  const response = await getBackend(
    `/chart/performance?style=${style}&type=${type}&groupId=${groupId}&year=${year}&month=${month}&subjectName=${subejctName}`
  );
  return response.data;
};

// antV chart_BoxChart
//GET /v3/chart/performance?style=BOX`

/**
 * @param {number} style
 * @param {number} type
 * @param {number} groupId
 * @param {number} year
 * @param {number} month
 * @param {boolean} subjectName
 * @returns {Promise<*>}
 * */
export const getChartPerformanceColumnOfSubjectName = async (
  style,
  type,
  groupId,
  year,
  month,
  subejctName
) => {
  const response = await getBackend(
    `/chart/performance?style=${style}&type=${type}&groupId=${groupId}&year=${year}&month=${month}&subjectName=${subejctName}`
  );
  return response.data;
};

/**
 * @param {number} style
 * @param {number} type
 * @param {number} groupId
 * @param {number} year
 * @param {number} month
 * @returns {Promise<*>}
 * */
export const getChartPerformanceColumn = async (
  style,
  type,
  groupId,
  year,
  month
) => {
  const response = await getBackend(
    `/chart/performance?style=${style}&type=${type}&groupId=${groupId}&year=${year}&month=${month}`
  );
  return response.data;
};

export const getChartPerformanceBox = async (
  style,
  type,
  groupId,
  year,
  month
) => {
  const response = await getBackend(
    `/chart/performance?style=${style}&type=${type}&groupId=${groupId}&year=${year}&month=${month}`
  );
  return response.data;
};

// System Notification
/**
 * @param {
 *   count: Int(optional)
 *   hour: Int(optional)
 *   state: String(optional)
 *   limit: Int
 *   offset: Int
 * }
 */
export const getSystemNotification = async (params) => {
  return await getBackend(
    `/systemNotification?${new URLSearchParams(params).toString()}`
  );
};
/**
 * @param {
 * "state": "UNREAD", // String,
 * "notificationType": "COMMAND", // String
 * "notificationKind": "CLASS_START", //String
 * "fromClientId": "XXXX-XXXX-XXXX-XXXX", // UUID
 * "toClientId": "XXXX-XXXX-XXXX-XXXX", // UUID
 * "title": "xxxx", // String(optional)
 * "body": "xxxx", // String(optional)
 * "payload": "xxxx", //String(optional)
 * }
 */
export const createSystemNotification = async (data) => {
  return await postBackend(`/systemNotification`, data);
};
export const createSystemNotificationByGroup = async (data) => {
  return await postBackend(`/systemNotification?group=true`, data);
};
/**
 * @param {
 *  "systemNotificationId": "x", // Int
 *  "state": "READ" // String
 * }
 */
export const updateSystemNotification = async (data) => {
  return await patchBackend(`/systemNotification`, data);
};
export const deleteSystemNotification = async (systemNotificationId) => {
  return await deleteBackend(`systemNotification/${systemNotificationId}`);
};
export const postSystemNotificationError = async (data) => {
  return await postBackend("/systemNotification/error", data);
};

export const getTempChart = async (groupId) => {
  const response = await getBackend(`/tempChart?groupId=${groupId}`);
  return response.data;
};

// groupNotice
export const createGroupNotice = async (data) => {
  return await postBackend(`/groupnotice`, data);
};

export const patchGroupNotice = async (data) => {
  return await patchBackend(`/groupnotice`, data);
};

export const deleteGroupNotice = async (groupNoticeId) => {
  return await deleteBackend(`groupnotice/${groupNoticeId}`);
};

export const getGroupNotice = async (groupId) => {
  return await getBackend(`/groupnotice?groupId=${groupId}`);
};

export const getSubmissionGroupNotice = async (groupNoticeId) => {
  return await getBackend(`/submission?groupnoticeId=${groupNoticeId}`);
};

/**
 * chart/achievement
 */
export const getChartAchievement = async (groupId, type) => {
  return await getBackend(`/chart/achievement?groupId=${groupId}&type=${type}`);
};
/**
 * chart/submission
 */
export const getChartSubmission = async (groupId) => {
  return await getBackend(`/chart/submission?groupId=${groupId}`);
};
/**
 * chart/attendance
 */
export const getChartAttendance = async (groupId) => {
  return await getBackend(`/chart/participation?groupId=${groupId}`);
};
/**
 * chart/impulsiveness
 */
export const getChartMental = async (groupId, mentalType) => {
  return await getBackend(
    `/chart/mental/groups?groupId=${groupId}&mentalAnalysisType=${mentalType}`
  );
};

export const getQuizsetSessionSurvey = async (groupId, stdClientId) => {
  return await getBackend(
    `/quizsetSession/survey?groupId=${groupId}${
      stdClientId ? `&clientId=${stdClientId}` : ""
    }`
  );
};
/**
 * 심리묶음시작 시 학생들 설문 진행 현황 리스트
 * */

export const getQuizsetSessionSurveyBundleList = async (groupId) => {
  return await getBackend(`/quizsetSession/survey?groupId=${groupId}`);
};
export const getQuizsetSessionSurveyHistory = async (groupId) => {
  return await getBackend(`/quizsetSession/survey/history?groupId=${groupId}`);
};

export const getLinesChart = async (componentId, groupId, clientId) => {
  return await getBackend(
    `/charts/lines?componentId=${componentId}&groupId=${groupId}&clientId=${clientId}`
  );
};

export const getColumnsChart = async (componentId, groupId, clientId) => {
  return await getBackend(
    `/charts/columns?componentId=${componentId}&groupId=${groupId}&clientId=${clientId}`
  );
};

export const getGaugeChart = async (componentId, groupId, clientId) => {
  return await getBackend(
    `/charts/gauges?componentId=${componentId}&groupId=${groupId}&clientId=${clientId}`
  );
};

export const getHistogramChart = async (componentId, groupId, clientId) => {
  return await getBackend(
    `/charts/histograms?componentId=${componentId}&groupId=${groupId}&clientId=${clientId}`
  );
};

export const getBoxChart = async (componentId, groupId, clientId) => {
  return await getBackend(
    `/charts/boxes?componentId=${componentId}&groupId=${groupId}&clientId=${clientId}`
  );
};

export const getInterestingChart = async (componentId, groupId) => {
  return await getBackend(
    `/interesting-students?groupId=${groupId}&componentId=${componentId} `
  );
};
