import { profileImgPath } from "@api";
import { setSocketData } from "@app/store/actions";
import { selectActiveClassroom } from "@app/store/selectors";
import {
  Avatar,
  Box,
  Card,
  CardBody,
  CardFooter,
  Flex,
  Heading,
  Image,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import StopScreenShareIcon from "@mui/icons-material/StopScreenShare";
import ConnectedNoInternetIcon from "@mui/icons-material/SignalCellularConnectedNoInternet0Bar";

const StudentThumnail = ({
  image,
  student,
  colums,
  state,
  selectedStudent,
  setSelectedStudent,
  setSelectedImage,
}) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const activeClassroom = useSelector((state) => selectActiveClassroom(state));

  const studentFocusStatusList = useSelector(
    (state) => state.control.studentFocusStatusList
  );
  const studentGoodFocusStatusList = useSelector(
    (state) => state.control.studentGoodFocusStatusList
  );

  const [networkStable, setNetworkStable] = useState(true);

  const myProfileImage = useMemo(() => {
    if (!student) {
      return undefined;
    }
    return profileImgPath(student?.clientId);
  }, [student]);

  useEffect(() => {
    if (selectedStudent === student?.clientId) {
      dispatch(
        setSocketData({
          method: "POST",
          uri: "/classroom/sendImage",
          type: "SELECT_STUDENT",
          clientId: user?.clientId,
          groupId: activeClassroom?.groupId,
          data: student?.clientId,
        })
      );
      setSelectedImage(image);
    }
  }, [selectedStudent, student, image, setSelectedImage]);

  useEffect(() => {
    setNetworkStable(true);
    const timer = setTimeout(() => {
      setNetworkStable(false);
    }, 30000);
    return () => clearTimeout(timer);
  }, [image]);

  return (
    <Card
      variant={
        {
          offline: "filled",
        }[state] || "outline"
      }
      boxShadow={
        studentGoodFocusStatusList?.includes(student?.clientId)
          ? "0px 5px 5px 5px rgba(0, 255, 0, 0.5)" // 초록
          : studentFocusStatusList?.includes(student?.clientId)
          ? "0px 5px 5px 5px rgba(255, 165, 0, 0.5)" //주황
          : "0px 1px 3px 1px rgba(0, 0, 0, 0.15)"
      }
      transition={"box-shadow 0.3s ease-in-out"}
      onClick={() => {
        if (selectedStudent === student?.clientId) {
          dispatch(
            setSocketData({
              method: "POST",
              uri: "/classroom/sendImage",
              type: "SELECT_STUDENT",
              clientId: user?.clientId,
              groupId: activeClassroom?.groupId,
              data: user?.clientId,
            })
          );
          setSelectedStudent(null);
        } else {
          setSelectedStudent(student?.clientId);
        }
      }}
      cursor={"pointer"}
    >
      {/* {state !== "offline" && (
        <CardBody>
          {image ===
          "UklGRugCAABXRUJQVlA4WAoAAAAwAAAAjwEA2wAASUNDUMgBAAAAAAHIAAAAAAQwAABtbnRyUkdCIFhZWiAH4AABAAEAAAAAAABhY3NwAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAQAA9tYAAQAAAADTLQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAlkZXNjAAAA8AAAACRyWFlaAAABFAAAABRnWFlaAAABKAAAABRiWFlaAAABPAAAABR3dHB0AAABUAAAABRyVFJDAAABZAAAAChnVFJDAAABZAAAAChiVFJDAAABZAAAAChjcHJ0AAABjAAAADxtbHVjAAAAAAAAAAEAAAAMZW5VUwAAAAgAAAAcAHMAUgBHAEJYWVogAAAAAAAAb6IAADj1AAADkFhZWiAAAAAAAABimQAAt4UAABjaWFlaIAAAAAAAACSgAAAPhAAAts9YWVogAAAAAAAA9tYAAQAAAADTLXBhcmEAAAAAAAQAAAACZmYAAPKnAAANWQAAE9AAAApbAAAAAAAAAABtbHVjAAAAAAAAAAEAAAAMZW5VUwAAACAAAAAcAEcAbwBvAGcAbABlACAASQBuAGMALgAgADIAMAAxADZBTFBIJwAAAAEHEBERJAFJisqIiAZAkf7/p4j+J1Ag/v6jiEgARf7/JxGR+u8/vgBWUDggygAAABAWAJ0BKpAB3AA+0WixUygmJKKgKAEAGglpbuF3YRtACewD32ych77ZOQ99snIe+2TkPfbJyHvtk5D32ych77ZOQ99snIe+2TkPfbJyHvtk5D32ych77ZOQ99snIe+2TkPfbJyHvtk5D32ych77ZOQ99snIe+2TkPfbJyHvtk5D32ych77ZOQ99snIe+2TkPfbJyHvtk5D32ych77ZOQ99snIe+2TkPfbJyHvtk5D32ych77ZOQ96QAAP7/xgYAAAAAAAAAAAAAAAA=" ? (
            <Text align="center">
              <StopScreenShareIcon sx={{ margin: "0 0.5rem" }} />
              아직 화면 공유를 하지 않았어요
            </Text>
          ) : image === undefined && networkStable ? (
            <div></div>
          ) : image === undefined && !networkStable ? (
            <Text align="center">
              <ConnectedNoInternetIcon sx={{ margin: "0 0.5rem" }} />
              학생의 네트워크가 불안정합니다
            </Text>
          ) : networkStable ? (
            <Image
              borderRadius="lg"
              src={`data:image/webp;base64,${image}`}
              style={{
                objectFit: "cover",
                width: "100%",
                height: "100%",
                objectPosition: "center center",
              }}
              alt="학생 이미지"
            />
          ) : (
            <Text align="center">
              <ConnectedNoInternetIcon sx={{ margin: "0 0.5rem" }} />
              학생의 네트워크가 불안정합니다
            </Text>
          )}
        </CardBody>
      )} */}
      <CardBody>
        {image !== undefined ? (
          <Image
            borderRadius="lg"
            src={`data:image/webp;base64,${image}`}
            style={{
              objectFit: "cover",
              width: "100%",
              height: "100%",
              objectPosition: "center center",
            }}
            alt="학생 이미지"
          />
        ) : (
          <Text align="center"></Text>
        )}
      </CardBody>

      <CardFooter>
        <Flex flex="1" gap="4" alignItems="center" flexWrap="wrap">
          {colums === 1 && <Avatar src={myProfileImage} />}
          <Box>
            <Heading size="sm">{student?.userName}</Heading>
            <Text>{student?.studentNumber}</Text>
          </Box>
        </Flex>
      </CardFooter>
    </Card>
  );
};

export default StudentThumnail;
