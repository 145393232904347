import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import LogoutIcon from "@mui/icons-material/Logout";
import { purgeAll } from "@app/store/actions";
import { Button } from "@chakra-ui/react";

function LogoutButton() {
  const dispatch = useDispatch();
  const history = useHistory();

  const onPurgeClick = () => {
    if (window.confirm("로그아웃 하시겠어요?")) {
      dispatch(purgeAll());
      history.replace("/auth");
    }
  };

  return (
    <Button
      variant={"outline"}
      leftIcon={<LogoutIcon />}
      onClick={onPurgeClick}
    >
      로그아웃
    </Button>
  );
}

export default LogoutButton;
