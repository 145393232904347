import { useQuizByQuizId } from "@app/api/quiz/hooks/useQuizset";
import quizset from "@app/store/quizset/reducer";
import {
  Heading,
  Input,
  InputGroup,
  InputLeftAddon,
  Spinner,
  Stack,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import ReactQuill from "react-quill";
import QuizVideoPreview from "./QuizVideoPreview";
import { textTrimmer } from "./util";
const QuizTypeMultiplePreview = ({ quizId }) => {
  const quiz = useQuizByQuizId(quizId);

  if (quiz.isLoading) {
    return (
      <VStack>
        <Spinner />
        <Heading>퀴즈를 불러오고 있습니다.</Heading>
      </VStack>
    );
  }

  const quizData = quiz?.data;

  const choicesArray = quizData.choices ? JSON.parse(quizData.choices) : [];

  const isArray = Array.isArray(choicesArray);

  return (
    <Stack
      direction="column"
      spacing={4}
      w={"full"}
      style={{
        whiteSpace: "no-wrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      }}
    >
      <ReactQuill
        value={quizData.title}
        readOnly
        theme="bubble"
        style={{
          whiteSpace: "no-wrap",
          overflow: "hidden",
          width: "100%",
          maxHeight: "200px",
        }}
      />
      <QuizVideoPreview link={quizData.link} linkType={quizData.linkType} />
      {isArray
        ? choicesArray.map((choice, index) => (
            <Stack key={choice.choiceId}>
              <InputGroup>
                <InputLeftAddon>{index + 1}번</InputLeftAddon>
                <Input
                  placeholder={`${index + 1}번 보기가 비어있습니다.`}
                  value={textTrimmer(choice.description, 30)}
                  readOnly
                />
              </InputGroup>
            </Stack>
          ))
        : null}
    </Stack>
  );
};

export default QuizTypeMultiplePreview;
