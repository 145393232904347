import { Stack } from "@chakra-ui/react";
import { Hidden } from "@mui/material";
import React from "react";

const QuizTypeWrapper = ({ children }) => {
  return (
    <Stack spacing={4} w="full" overflow="hidden">
      {children}
    </Stack>
  );
};

export default QuizTypeWrapper;
