export const ButtonConstants = {
  SIDEBAR: {
    HOME: "SIDEBAR_HOME",
    SELF_MANAGING: "SIDEBAR_SELF_MANAGING",
    SETTING: "SIDEBAR_SETTING",
    NOTICE: "SIDEBAR_NOTICE",
    CLASSROOM: "SIDEBAR_CLASSROOM",
    BAND: "SIDEBAR_BAND",
    QUIZPANG: "SIDEBAR_QUIZPANG",
    ASSIGNMENT: "SIDEBAR_ASSIGNMENT",
    SIDEBAR: "SIDEBAR",
    QUIZPANG_MANAGING: "QUIZPANG_MANAGING",
  },
  HOME: {
    ADD_CLASS: "ADD_CLASS",
    ADD_CLASS_CANCEL: "ADD_CLASS_CANCEL",
    ADD_CLASS_CONFIRM: "ADD_CLASS_CONFIRM",

    CANCEL: "CANCEL",
    CONFIRM: "CONFIRM",

    TO_CLASS: "TO_CLASS",
    TO_SETTING: "TO_SETTING",

    OPEN_ADD_CLASS_MODAL: "OPEN_ADD_CLASS_MODAL",
    OPEN_EDIT_CLASS_MODAL: "OPEN_EDIT_CLASS_MODAL",

    ADD_CLASS_TIME: "ADD_CLASS_TIME",
    DELETE_CLASS: "DELETE_CLASS",
    CHANGE_CLASS_INFO: "CHANGE_CLASS_INFO",
    CHANGE_CLASS_INFO_CANCEL: "CHANGE_CLASS_INFO_CANCEL",

    TRY_LOGOUT: "TRY_LOGOUT",
  },
  SELF_MANAGING: {
    //todo: 초대 된 상태 확인 필요
    SAVE: "SAVE",
    SUBMIT: "SUBMIT",
    CANCEL: "CANCEL",
    START_MANAGING_GROUP: "START_SELF_MANAGING_GROUP",
    OPEN_ADD_MANAGING_GROUP_MODAL: "OPEN_ADD_MANAGING_GROUP_MODAL",
    OPEN_EDIT_MANAGING_GROUP_MODAL: "OPEN_EDIT_MANAGING_GROUP_MODAL",
    ADD_MANAGING_GROUP: "ADD_MANAGING_GROUP",
    EDIT_MANAGING_GROUP: "EDIT_MANAGING_GROUP",
    REMOVE_MANAGING_GROUP: "REMOVE_MANAGING_GROUP",
    OPEN_ADD_MANAGING_PLAN_MODAL: "OPEN_ADD_MANAGING_PLAN_MODAL",
    OPEN_SELECT_APP_MODAL: "OPEN_SELECT_APP_MODAL",
    SAVE_SELF_MANAGING_INFO: "SAVE_SELF_MANAGING_INFO",
    CANCEL_SELF_MANAGING_GROUP: "CANCEL_SELF_MANAGING_GROUP",
  },
  CONFIG: {
    CHANGE_PROFILE_IMAGE: "CHANGE_PROFILE_IMAGE",
    LOAD_IMAGE: "LOAD_IMAGE",
    CANCLE: "CANCEL",
    CONFIRM_IMAGE_CHANGE: "CONFIRM_IMAGE_CHANGE",
    LOGOUT: "LOGOUT",
    CHANGE_INFO: "CHANGE_INFO",
    CHANGE_PASSWORD: "CHANGE_PASSWORD",
    LINK_TO_FOCUSPANG_ASK: "LINK_TO_FOCUSPANG_ASK",
    LINK_TO_FOCUSPANG_GUIDE: "LINK_TO_FOCUSPANG_GUIDE",

    OPEN_CHANGE_IMAGE_MODAL: "OPEN_CHANGE_IMAGE_MODAL",
    CHECK_CURRENT_PASSWORD: "CHECK_CURRENT_PASSWORD",
    CANCEL_PROFILE_IMAGE_CHANGE: "CANCEL_PROFILE_IMAGE_CHANGE",
  },
  BASEMODAL: {
    CANCEL: "MODAL_CANCEL",
    COMPLETE: "MODAL_COMPLETE",
  },
  CLASSROOM: {
    FILELINK: "FILELINK",
    WEBLINK: "WEBLINK",
    FULLSCREEN: "FULLSCREEN",
    END_FULLSCREEN: "END_FULLSCREEN",
  },
  COMMENTS_LAYER: {
    PEN: "COMMENTS_LAYER_PEN",
    ERASER: "COMMENTS_LAYER_ERASER",
    CLEAR: "COMMENTS_LAYER_CLEAR",
    SCREENSHARE: "COMMENTS_LAYER_SCREENSHARE",
    COLOR: "COMMENTS_LAYER_COLOR",
    SEE_STUDENT_PEN: "SEE_STUDENT_PEN",
  },
  BAND: { FULLSCREEN: "FULLSCREEN", CHANGE_SIZE: "CHANGE_SIZE" },
  QUIZPANG: {
    BACK_TO_QUIZPANG_HOME: "BACK_TO_QUIZPANG_HOME",
    LOAD_MORE: "LOAD_MORE",
    QUIZ_BATTLE_LOG_TAP: "QUIZ_BATTLE_LOG_TAP",
    QUIZ_EXIT: "QUIZ_EXIT",
    SURVEY_TAP: "SURVEY_TAP",
    START_QUIZ_GRADING: "START_QUIZ_GRADING",
    SUBMIT_GRADE: "SUBMIT_GRADE",
    CONTINUE_GRADING: "CONTINUE_GRADING",
    STOP_GRADING: "STOP_GRADING",
    NEXT_ANSWER: "NEXT_ANSWER",
    NEXT_QUIZ: "NEXT_QUIZ",
    PREV_ANSWER: "PREV_ANSWER",
    PREV_QUIZ: "PREV_QUIZ",
    START_QUIZPANG: "START_QUIZPANG",
    STOP_QUIZPANG: "STOP_QUIZPANG",
    NEXT_QUIZPANG: "NEXT_QUIZPANG",
    REFETCH_QUIZ_RESULT: "REFETCH_QUIZ_RESULT",
    TO_QUIZPANG_MANAGING: "TO_QUIZPANG_MANAGING",
    TO_GRADING_PAGE: "TO_GRADING_PAGE",
    TO_QUIZ_TAP: "TO_QUIZ_TAP",
    TO_PARTICIPITATION_TAP: "TO_PARTICIPITATION_TAP",
    TOGGLE_SURVEY_LIST: "TOGGLE_SURVEY_LIST",
    TOGGLE_QUIZ_LIST: "TOGGLE_QUIZ_LIST",
    TO_FIRST_PAGE: "TO_FIRST_PAGE",
    TO_NEXT_PAGE: "TO_NEXT_PAGE",
    TO_PREV_PAGE: "TO_PREV_PAGE",
    TO_QUIZPANG_RESULT: "TO_QUIZPANG_RESULT",
  },

  COLLAPSE_CARD: {
    TOGGLE: "TOGGLE",
  },
  SURVEY: {
    JOIN_CURRENT_SURVEY: "JOIN_CURRENT_SURVEY",
    START_SURVEY: "START_SURVEY",
    BACK_TO_SURVEY_HOME: "BACK_TO_SURVEY_HOME",
    RELOAD_SURVEY: "RELOAD_SURVEY",
    NEXT_QUESTION: "NEXT_QUESTION",
    END_SURVEY: "END_SURVEY",
  },
  FILELINK: {
    FILELINK_SUBMIT: "FILELINK_SUBMIT",
    SEND_FILE: "FILELINK_SEND_FILE",
    SEND_LINK: "FILELINK_SEND_LINK",
    SELECT_FILE: "FILELINK_SELECT_FILE",
    CONFIRM_SEND_LINK: "CONFIRM_SEND_LINK",
    REMOVE_FILELINK: "REMOVE_FILELINK",
  },
  NOTICE: {
    OPEN_NOTICE_MODAL: "OPEN_NOTICE_MODAL",
    OPEN_ASSIGNMENT_NOTICE_MODAL: "OPEN__ASSIGNMENT_NOTICE_MODAL",
    EDIT_NOTICE: "EDIT_NOTICE",
    COMPLETE_EDIT: "COMPLETE_EDIT_NOTICE",
    CANCEL_EDIT: "CANCEL_EDIT_NOTICE",
    UPLOAD_FILE: "UPLOAD_FILE",
    ADD_NOTICE_GROUP: "ADD_NOTICE_GROUP",
    CANCEL: "CANCEL",
    CANCEL_ADD_NOTICE: "CANCEL_ADD_NOTICE",
    REGIST_NOTICE: "REGIST_NOTICE",
    REGIST_ASSIGNMENT_NOTICE: "REGIST_ASSIGNMENT_NOTICE",
    DELETE_NOTICE: "DELETE_NOTICE",
    OPEN_DELETE_NOTICE_MODAL: "OPEN_DELETE_NOTICE_MODAL",
    CANCEL_DELETE_NOTICE: "CANCEL_DELETE_NOTICE",
    SAVE_EDITED_NOTICE: "SAVE_EDITED_NOTICE",
    SAVE_EDITED_ASSIGNMENT_NOTICE: "SAVE_EDITED_ASSIGNMENT_NOTICE",
    CANCEL_EDIT_NOTICE: "CANCEL_EDIT_NOTICE",
    CANCEL_EDIT_ASSIGNMENT_NOTICE: "CANCEL_EDIT_ASSIGNMENT_NOTICE",
  },
  TEXT_EDITOR: {
    FILE_INPUT: "TEXT_EDITOR_FILE_INPUT",
  },
  CARD: {
    REMOVE_ITEM: "REMOVE_ITEM",
  },
  DASHBOARD: {
    ADD_CHART: "ADD_CHART",
    ORDER_NEW_CHART: "ORDER_NEW_CHART",
    SELECT_CHART: "SELECT_CHART",
    REMOVE_CHART: "REMOVE_CHART",
  },
  STUDENT_LIST: {
    OPEN_KICK_STUDENT_MODAL: "OPEN_KICK_STUDENT_MODAL",
    KICK_STUDENT: "KICK_STUDENT",
    CANCEL_KICK_STUDENT: "CANCEL_KICK_STUDENT",
    OPEN_EDIT_STUDENT_MODAL: "OPEN_EDIT_STUDENT_MODAL",
    EDIT_STUDENT: "EDIT_STUDENT",
    CANCEL_EDIT_STUDENT: "CANCEL_EDIT_STUDENT",
    CANCEL: "CANCEL",
    KICK: "KICK",
    EDIT: "EDIT",
    REFRESH_STUDENT_LIST: "REFRESH_STUDENT_LIST",
  },
  FOCUSTIME: {
    START_FOCUSTIME: "START_FOCUSTIME",
    APP_MANAGING_TOGGLE: "APP_MANAGING_TOGGLE",
    OPEN_APP_MANAGING_MODAL: "OPEN_APP_MANAGING_MODAL",
    SAVE_MANAGING_APP: "SAVE_MANAGING_APP",
    CANCEL: "CANCEL",
    END_FOCUSTIME: "END_FOCUSTIME",
    FULLSCREEN: "FULLSCREEN",
    LOCK_SCREEN: "LOCK_SCREEN",
    SHARE_SCREEN: "SHARE_SCREEN",
    SELECT_TEACHER_SCREEN: "SELECT_TEACHER_SCREEN",
    INIT_SCREEN: "INIT_SCREEN",
    SORT_STUDENT: "SORT_STUDENT",
    SEE_STUDENT_THUMBNAIL: "SEE_STUDENT_THUMBNAIL",
    SEE_TEACHER_AND_STUDENT_THUMBNAIL: "SEE_TEACHER_AND_STUDENT_THUMBNAIL",
    ADD_TIME: "ADD_TIME",
    SUB_TIME: "SUB_TIME",
  },
  ASSIGNMENT: {
    OPEN_ASSIGNMENT_NOTICE_MODAL: "OPEN__ASSIGNMENT_NOTICE_MODAL",
    OPEN_DELETE_ASSIGNMENT_NOTICE_MODAL: "OPEN_DELETE_ASSIGNMENT_NOTICE_MODAL",
    VIEW_ASSIGNMENT_SUBMIT_LIST: "VIEW_ASSIGNMENT_SUBMIT_LIST",
    ADD_ASSIGNMENT: "ADD_ASSIGNMENT",
    EDIT_ASSIGNMENT: "EDIT_ASSIGNMENT",
    REMOVE_ASSIGNMENT: "REMOVE_ASSIGNMENT",
    COMPLETE_EDIT_ASSIGNMENT: "COMPLETE_EDIT_ASSIGNMENT",
    CANCEL: "CANCEL",
    CANCEL_DELETE_ASSIGNMENT: "CANCEL_DELETE_ASSIGNMENT",
  },
  MANAGING_QUIZPANG: {
    OPEN_ADD_QUIZSET_MODAL: "OPEN_ADD_QUIZSET_MODAL",
    ADD_QUIZSET: "ADD_QUIZSET",
    ADD_SECTION: "ADD_SECTION",
    ADD_QUIZ: "ADD_QUIZ",
    IMPORT_QUIZ: "IMPORT_QUIZ",
    CREATE_QUIZ: "CREATE_QUIZ",
    GO_BACK_TO_MANAGING_QUIZPANG: "GO_BACK_TO_MANAGING_QUIZPANG",
    ADD_VIDEO_LINK: "ADD_VIDEO_LINK",
    ADD_IMAGE: "ADD_IMAGE",
    OPEN_ADD_VIDEO_LINK_MODAL: "OPEN_ADD_VIDEO_LINK_MODAL",
    REMOVE_IMAGE: "REMOVE_IMAGE",
    REMOVE_VIDEO_LINK: "REMOVE_VIDEO_LINK",
    ADD_CHOICE_MULTIPLE: "ADD_CHOICE_MULTIPLE",
    ADD_CHOICE_SHORT: "ADD_CHOICE_SHORT",
    ADD_EXAMPLE_ANSWER_IMAGE: "ADD_EXAMPLE_ANSWER_IMAGE",
    INCREASE_SCORE: "INCREASE_SCORE",
    DECREASE_SCORE: "DECREASE_SCORE",
    OPEN_ADD_PUBLISHER_MODAL: "OPEN_ADD_PUBLISHER_MODAL",
    CANCEL_ADD_PUBLISHER: "CANCEL_ADD_PUBLISHER",
    ADD_PUBLISHER: "ADD_PULBISHER",
    OPEN_ADD_SUBJECT_MODAL: "OPEN_ADD_SUBJECT_MODAL",
    CANCEL_ADD_SUBJECT: "CANCEL_ADD_SUBJECT",
    ADD_SUBJECT: "ADD_SUBJECT",

    DUPLICATE_QUIZ: "DUPLICATE_QUIZ",
    DELETE_QUIZ: "DELETE_QUIZ",
    CANCEL_QUIZ_EDIT: "CANCEL_QUIZ_EDIT",
    SAVE_QUIZ: "SAVE_QUIZ",
    EDIT_QUIZ: "EDIT_QUIZ",
    TO_QUIZ: "TO_QUIZ",
    TO_QUIZ_EDITOR: "TO_QUIZ_EDITOR",
  },
  GROUP_ACTIVITY: {
    SUBMIT_NEW_GROUP_TOPIC: "SUBMIT_NEW_GROUP_TOPIC",
    DELETE_GROUP_TOPIC: "DELETE_GROUP_TOPIC",
    OPEN_DELETE_GROUP_TOPIC_MODAL: "OPEN_DELETE_GROUP_TOPIC_MODAL",
    CANCEL_DELETE_GROUP_TOPIC: "CANCEL_DELETE_GROUP_TOPIC",
    DELETE_GROUP: "DELETE_GROUP",
    OPEN_DELETE_GROUP_MODAL: "OPEN_DELETE_GROUP_MODAL",
    OPEN_CREATE_GROUP_MODAL: "OPEN_CREATE_GROUP_MODAL",
    OPEN_EDIT_GROUP_MODAL: "OPEN_EDIT_GROUP_MODAL",
    CREATE_GROUP: "CREATE_GROUP",

    CANCEL: "CANCEL_GROUP_ACTIVITY_MODAL",
    CONFIRM: "CONFIRM_GROUP_ACTIVITY_MODAL",
    CANCEL_EDIT_GROUP_INFO: "CANCEL_EDIT_GROUP_INFO",
  },
  // LONG: 'LONG',
  // SHORT: 'SHORT',
  // RADIO: 'RADIO',
  // OX: 'OX',
  // HEADLINE: 'HEADLINE',
  // TIME: 'TIME',
  // STYLUS: 'STYLUS',
  // MULTIPLE: 'MULTIPLE',
  // DAY: 'DAY',
};
