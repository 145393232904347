import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  CloseButton,
  Container,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useUserAgent from "@app/utils/deviceInfo/useUserAgent";
import useNetworkSpeed from "@app/utils/network/useNetworkSpeed";
import { useInterval } from "@api";
import { createNetworkLatency } from "@app/api/network/networkApi";

const AlertManager = () => {
  const userDeviceInfo = useUserAgent();

  const user = useSelector((state) => state.user);
  const { speed, error, startMeasurement } = useNetworkSpeed();
  const [showAlert, setShowAlert] = useState(false);
  const dispatch = useDispatch();

  useInterval(() => {
    console.log("[AlertManager]:useInterval!!!");
    sendNetworkSpeed();
  }, 600000);

  const sendNetworkSpeed = async (classroomId) => {
    try {
      const measuredSpeed = await startMeasurement();
      if (measuredSpeed.avgSpeed < 3) {
        setShowAlert(true);
      }
      createNetworkLatency({
        schoolId: user.schoolId,
        clientId: user.clientId,
        maxMbps: measuredSpeed.maxSpeed.toString(),
        avgMbps: measuredSpeed.avgSpeed.toString(),
        minMbps: measuredSpeed.minSpeed.toString(),
        ipAddr: measuredSpeed.ip.toString(),
      });
    } catch (err) {
      console.error(
        "[AlertManager]: 네트워크 속도 측정중 오류가 발생하였습니다.:",
        err
      );
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowAlert(false);
    }, 15000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Container
      position="absolute"
      top="10"
      left="0"
      right="0"
      zIndex="1000"
      maxW="30%"
      maxH="100%"
      pointerEvents="none"
    >
      {showAlert && (
        <Alert
          status="warning"
          variant="subtle"
          flexDirection="column"
          justifyContent="center"
          textAlign="center"
          height="300px"
          pointerEvents="auto"
          borderRadius="lg"
        >
          <CloseButton
            mt={-4}
            alignSelf={"flex-start"}
            onClick={() => setShowAlert(false)}
          />
          <AlertIcon boxSize="40px" mr={0} />
          <AlertTitle mt={4} mb={1} fontSize="lg">
            네트워크 속도가 느립니다.
          </AlertTitle>
        </Alert>
      )}
    </Container>
  );
};

export default AlertManager;
