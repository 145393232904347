import { getMembershipsByGroup } from '@api';
import { useQuery } from '@tanstack/react-query';

export const useStudentList = (groupId = '') => {
  const { data: students, isFetching, isLoading, isError, refetch } = useQuery({
    queryKey: ['studentList', groupId],
    queryFn: () => getMembershipsByGroup(groupId),
    enabled: !!groupId,
    select: ({ data }) => {
      const clients = data?.clientMemberships.map((clientMembershipData) => {
        return clientMembershipData.client;
      });

      const filteredClients = clients.filter((client) => {
        return client.role === 'STUDENT';
      });

      const sortedStdList = filteredClients.sort((a, b) => {
        if (a.studentNumber > b.studentNumber) {
          return 1;
        } else if (a.studentNumber < b.studentNumber) {
          return -1;
        } else {
          return 0;
        }
      });

      return sortedStdList;
    },
  });

  return {
    students,
    isFetching,
    isLoading,
    isError,
    refetch,
  };
};

// 학생의 클라이언트id만 배열로 반환
export const useStudentIdList = (groupId = '') => {
  const {
    data: students,
    isFetching,
    isLoading,
    isError,
    isSuccess,
    refetch,
  } = useQuery({
    queryKey: ['studentIdList', groupId],
    queryFn: () => getMembershipsByGroup(groupId),
    enabled: !!groupId,
    select: ({ data }) => {
      const clients = data?.clientMemberships.map((clientMembershipData) => {
        return clientMembershipData.client;
      });

      const filteredClients = clients.filter((client) => {
        return client.role === 'STUDENT';
      });

      const sortedStdList = filteredClients.sort((a, b) => {
        if (a.studentNumber > b.studentNumber) {
          return 1;
        } else if (a.studentNumber < b.studentNumber) {
          return -1;
        } else {
          return 0;
        }
      });

      const studentIdList = sortedStdList.map((student) => {
        return student.clientId;
      });

      return studentIdList;
    },
  });

  return {
    students,
    isFetching,
    isLoading,
    isError,
    isSuccess,
    refetch,
  };
};

// 학생을 수업에서 퇴장 시키는 훅
export const useStudentExit = () => {};
