import { Stack, Select, Input } from "@chakra-ui/react";
import React from "react";
import HeadingText from "./HeadingText";
import { TIME_LIMIT } from "@app/Constants/surveyType";

const SurveyTimeLimit = ({
  value,
  checked,
  minutesHandler,
  secondsHandler,
  handleSurveryFormChange,
}) => {
  return (
    <Stack direction={"column"} spacing={4} w={"full"}>
      <HeadingText headingText="3. 시간" />
      <Stack direction={"row"}>
        <Select
          width={158}
          name="timelimit"
          value={value}
          onChange={handleSurveryFormChange}
        >
          {TIME_LIMIT.map((time) => (
            <option key={time} value={time}>
              {time === "직접 입력"
                ? time
                : time >= 60
                ? time / 60 + "분"
                : time + "초"}
            </option>
          ))}
        </Select>

        <Stack direction={["column", "row"]} spacing="17px">
          <div style={{ position: "relative" }}>
            <Input
              variant="standard"
              name="timelimit"
              style={{ width: 58 }}
              disabled={!checked}
              value={Math.floor(value / 60)}
              onChange={minutesHandler}
            />
            <span style={{ position: "absolute", right: 0, bottom: 4 }}>
              분
            </span>
          </div>
          <div style={{ position: "relative" }}>
            <Input
              variant="standard"
              style={{ width: 58 }}
              name="timelimit"
              disabled={!checked}
              value={Math.floor(value % 60)}
              onChange={secondsHandler}
            />
            <span style={{ position: "absolute", right: 0, bottom: 4 }}>
              초
            </span>
          </div>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default SurveyTimeLimit;
