import { getParticipationsByClassroom } from '@api';
import { setStudentListUpdate } from '@app/store/actions';
import { useQuery } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';

export const useParticipationByClassroom = (classroomId) => {
  const {
    data: participations,
    isFetching,
    isLoading,
    isError,
    refetch,
    isSuccess,
    error,
  } = useQuery({
    queryKey: ['participation', classroomId],
    queryFn: () => getParticipationsByClassroom(classroomId),
    enabled: !!classroomId,
    select: ({ data }) => {
      return data?.clientParticipations.map((participation) => {
        console.log();
        return {
          ...participation.client,
          ...participation.participation,
        };
      });
    },
    onError: (error) => {
      if (error?.response?.status === 404) {
        return;
      }
    },
  });

  return {
    participations,
    isFetching,
    isLoading,
    isError,
    isSuccess,
    refetch,
  };
};
