import React from "react";
import styled from "@emotion/styled";
import { HStack, Text, useMediaQuery } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { Tooltip } from "@mui/material";

const ClassTimeline = ({ period, startTimeString, endTimeString }) => {
  const { t } = useTranslation();
  const [isLargerThan1155] = useMediaQuery("(min-width: 1156px)");
  return (
    <HStack spacing={4}>
      <Text
        fontSize="xs"
        fontWeight={{
          lg: "normal",
          xl: "normal",
          lg: "normal",
          xl: "normal",
        }}
        width={{
          sm: "29.5px",
          md: "29.5px",
          lg: "full",
          xl: "full",
        }}
        overflow="hidden"
        textOverflow="ellipsis"
        whiteSpace="nowrap"
      >
        <Tooltip
          title={`${period}${t(
            "timetable.text.period.st"
          )}\n${startTimeString.slice(0, 5)} ~ ${endTimeString.slice(0, 5)}}`}
          arrow
        >
          {period < 10 ? `${period}${t("timetable.text.period.st")}` : period}
        </Tooltip>
      </Text>
      <Text fontSize="xs" display={isLargerThan1155 ? "" : "none"}>
        {startTimeString.slice(0, 5)}
      </Text>
    </HStack>
  );
};

export default ClassTimeline;
