import { Heading, Select } from "@chakra-ui/react";
import React from "react";
import { quizTypeToText, quizTypes } from "./QuizTypeData";
import QuizTypeWrapper from "./Common/QuizTypeWrapper";

const QuizType = ({
  setSampleAnswerType,
  setSampleAnswer,
  setSampleAnswerImageFile,
  setChoices,
  quizTypeValue,
  onChangeSelect,
}) => {
  const selectChoiceType = (type) => {
    if (type === "MULTIPLE" || type === "SHORT") {
      setChoices([{ description: "", point: 1 }]);
    }
    if (type === "OX") {
      setChoices([
        { description: "O", point: 0 },
        { description: "X", point: 0 },
      ]);
    }
    if (type === "STYLUS") {
      setSampleAnswerType("IMG");
    } else if (type === "LONG") {
      setSampleAnswerType("TXT");
    } else {
      setSampleAnswerType(null);
    }
    setSampleAnswerImageFile(null);
    setSampleAnswer("");
  };
  return (
    <QuizTypeWrapper>
      <Heading fontSize={"lg"}>2. 문제 유형</Heading>

      <Select
        placeholder="퀴즈 타입을 선택하세요."
        width={219}
        value={quizTypeValue ?? "MULTIPLE"}
        onChange={(e) => {
          const value = e.target.value === "" ? "MULTIPLE" : e.target.value;
          onChangeSelect(value);
          selectChoiceType(value);
        }}
      >
        {quizTypes?.map((x) => {
          return (
            <option key={x} value={x}>
              {quizTypeToText[x]}
            </option>
          );
        })}
      </Select>
    </QuizTypeWrapper>
  );
};

export default QuizType;
