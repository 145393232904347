import {
  PURGE_MEMBERSHIP,
  GET_MEMBERSHIP_SUCCESS,
  GET_MEMBERSHIP_FAIL,
  GET_MEMBERSHIPS_BY_CLIENT_SUCCESS,
  GET_MEMBERSHIPS_BY_CLIENT_FAIL,
  GET_MEMBERSHIPS_BY_GROUP_SUCCESS,
  GET_MEMBERSHIPS_BY_GROUP_FAIL,
  CREATE_MEMBERSHIP_SUCCESS,
  CREATE_MEMBERSHIP_FAIL,
  DELETE_MEMBERSHIP_SUCCESS,
  DELETE_MEMBERSHIP_FAIL
} from "./actionTypes";
import { createPromiseAction } from "@adobe/redux-saga-promise";

export const purgeMembership = () => ({
  type: PURGE_MEMBERSHIP
});

// GET_MEMBERSHIP
export const getMembershipPromise = createPromiseAction(
  "GET_MEMBERSHIP_PROMISE"
);

export const getMembershipSuccess = membership => ({
  type: GET_MEMBERSHIP_SUCCESS,
  membership: membership
});

export const getMembershipFail = error => ({
  type: GET_MEMBERSHIP_FAIL,
  payload: error
});

///// GET_MEMBERSHIP BY CLIENT

export const getMembershipByClientPromise = createPromiseAction(
  "GET_MEMBERSHIP_BY_CLIENT_PROMISE"
);

export const getMembershipByClientSuccess = membershipData => ({
  type: GET_MEMBERSHIPS_BY_CLIENT_SUCCESS,
  membershipData: membershipData
});

export const getMembershipByClientFail = error => ({
  type: GET_MEMBERSHIPS_BY_CLIENT_FAIL,
  payload: error
});

///// GET_MEMBERSHIP BY GROUP

export const getMembershipsByGroupPromise = createPromiseAction(
  "GET_MEMBERSHIPS_BY_GROUP_PROMISE"
);

export const getMembershipsByGroupSuccess = membershipsData => {
  let byId = {};
  membershipsData.forEach(clientMembershipData => {
    byId[
      `${clientMembershipData.membership.clientId},${clientMembershipData.membership.groupId}`
    ] = clientMembershipData.membership;
  });

  const allIds = membershipsData.map(clientMembershipData => {
    return `${clientMembershipData.membership.clientId},${clientMembershipData.membership.groupId}`;
  });

  return {
    type: GET_MEMBERSHIPS_BY_GROUP_SUCCESS,
    byId: byId,
    allIds: allIds,
    membershipsData: membershipsData
  };
};

export const getMembershipsByGroupFail = error => ({
  type: GET_MEMBERSHIPS_BY_GROUP_FAIL,
  payload: error
});

export const createMembershipPromise = createPromiseAction(
  "CREATE_MEMBERSHIP_PROMISE"
);

export const createMembershipSuccess = membershipData => ({
  type: CREATE_MEMBERSHIP_SUCCESS,
  membershipData: membershipData
});

export const createMembershipFail = error => ({
  type: CREATE_MEMBERSHIP_FAIL,
  payload: error
});

export const deleteMembershipPromise = createPromiseAction(
  "DELETE_MEMBERSHIP_PROMISE"
);

export const deleteMembershipSuccess = membershipData => ({
  type: DELETE_MEMBERSHIP_SUCCESS,
  membershipData: membershipData
});

export const deleteMembershipFail = error => ({
  type: DELETE_MEMBERSHIP_FAIL,
  payload: error
});
