import { call, put, takeLeading } from "redux-saga/effects";
import { PURGE_ALL, SIGN_OUT, RESTORE_USER } from "./actionTypes";
import {
  signInPromise,
  signInSuccess,
  signInFail,
  updateUserPromise,
  updateUserSuccess,
  updateUserFail,
  signOut,
  purgeAccount,
  purgeClassroom,
  purgeClient,
  purgeControl,
  purgeDevice,
  purgeFilelink,
  purgeGroup,
  purgeKakaocert,
  purgeMembership,
  purgeNotification,
  purgeParticipation,
  purgeProgramconfig,
  purgeProgram,
  purgeSchool,
  purgeTimetable,
  purgeUser,
  purgeQuiz,
  purgeQuizset,
  purgeQuizsetSession
} from "@store/actions";
import * as api from "@api";
import { implementPromiseAction } from "@adobe/redux-saga-promise";

function* purgeAllHandler() {
  try {
    console.log("Purging all redux...");
    yield put(signOut());
    yield put(purgeUser());
    yield put(purgeAccount());
    yield put(purgeClassroom());
    yield put(purgeClient());
    yield put(purgeControl());
    yield put(purgeDevice());
    yield put(purgeFilelink());
    yield put(purgeGroup());
    yield put(purgeKakaocert());
    yield put(purgeMembership());
    yield put(purgeNotification());
    yield put(purgeParticipation());
    yield put(purgeProgramconfig());
    yield put(purgeProgram());
    yield put(purgeSchool());
    yield put(purgeTimetable());
    yield put(purgeQuiz());
    yield put(purgeQuizset());
    yield put(purgeQuizsetSession());
  } catch (error) {
    console.error("[User Saga]", error);
  }
}

function* signInPromiseHandler(action) {
  yield call(implementPromiseAction, action, function*() {
    try {
      const signInData = action.payload;
      const response = yield call(api.signIn, signInData);
      if (response.data.client.state != "REGISTERED_APPROVED") {
        alert(
          signInData.email + "은 정지된 계정입니다. 문의해 주시기 바랍니다."
        );
        yield put(signInFail(response));
        return;
      }
      const userData = api.unpackUser(
        response.data.account,
        response.data.client
      );
      yield call(api.setToken, response.data.jwt.token);
      yield call(api.storeStorageData, "@user", userData);
      yield put(signInSuccess(userData));
      return yield userData;
    } catch (error) {
      yield put(signInFail(error));
    }
  });
}

function* signOutHandler() {
  try {
    yield call(api.resetToken);
    yield call(api.storeStorageData, "@user", null);
  } catch (error) {
    console.error(error);
  }
}

function* restoreUserHandler({ profile: profileData }) {
  try {
    console.log("Restoring from session...");
    // todo: this packs then immediately unpacks profileData.
    // todo: should fix this
    yield call(api.setToken, sessionStorage.getItem("token"));
  } catch (error) {
    console.error(error);
  }
}

function* updateUserPromiseHandler(action) {
  yield call(implementPromiseAction, action, function*() {
    try {
      const updatedUser = action.payload;
      const response = yield call(api.updateUser, updatedUser);
      const userData = api.unpackUser(
        response.data.account,
        response.data.client
      );
      yield call(api.storeStorageData, "@user", userData);
      yield put(updateUserSuccess(userData));
      return yield userData;
    } catch (error) {
      yield put(updateUserFail(error));
    }
  });
}

function* userSaga() {
  yield takeLeading(PURGE_ALL, purgeAllHandler);
  yield takeLeading(SIGN_OUT, signOutHandler);
  yield takeLeading(RESTORE_USER, restoreUserHandler);
  yield takeLeading(signInPromise, signInPromiseHandler);
  yield takeLeading(updateUserPromise, updateUserPromiseHandler);
}

export default userSaga;
