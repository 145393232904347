import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import imgNotiIcon from "@images/home/img_notice.png";
import ICONS from "@images/appIcons/index.js";
import Switch from "@mui/material/Switch";
import { setSocketData } from "@store/actions";
import { Chip } from "@mui/material";
import {
  Card,
  CardBody,
  HStack,
  Image,
  Stack,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

const CardClassManagementsSmall = ({
  id,
  enabled,
  name,
  alias,
  groupId,
  updateProgramconfigAsync,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const user = useSelector((state) => state.user);
  const [enable, setEnable] = useState(enabled);
  const [enableState, setEnableState] = useState("차단중");
  const handleToggle = () => {
    const newEnable = !enable;
    setEnable(newEnable);
    updateProgramconfigAsync({
      programconfigId: id,
      isAllowed: newEnable,
    });
    dispatch(
      setSocketData({
        method: "POST",
        uri: "/classroom/sendImage",
        groupId: groupId,
        clientId: user?.clientId,
        type: "UPDATE_PROGRAMCONFIG",
        data: "",
      })
    );
  };
  useEffect(() => {
    if (!enable) {
      // setEnableState("차단중");
      setEnableState(t("timetable.focustime.accordion.app.state.blocked"));
    }
    if (enable) {
      // setEnableState("허용중");
      setEnableState(t("timetable.focustime.accordion.app.state.allowed"));
    }
  }, [enable]);

  return (
    <Card border="1px" borderColor="gray.200" h="130px">
      <Tooltip label={name} placement="top">
        <CardBody>
          <Stack direction="column" spacing={2} w="full">
            <HStack spacing={2} justifyContent="space-between">
              <HStack>
                {enable ? (
                  <Image
                    boxSize="16px"
                    src={ICONS[alias] || imgNotiIcon}
                    alt="icon"
                  />
                ) : (
                  <Image
                    filter="grayscale(100%)"
                    boxSize="16px"
                    src={ICONS[alias] || imgNotiIcon}
                    alt="icon"
                  />
                )}
                <Text
                  size="sm"
                  w="20"
                  overflow="hidden"
                  textOverflow="ellipsis"
                  whiteSpace="nowrap"
                >
                  {name}
                </Text>
              </HStack>
              <Switch
                checked={enable}
                onChange={handleToggle}
                value={enabled}
              />
            </HStack>
            <Chip
              label={enableState}
              variant="filled"
              color={enable ? "primary" : "default"}
            />
          </Stack>
        </CardBody>
      </Tooltip>
    </Card>
  );
};

export default CardClassManagementsSmall;
