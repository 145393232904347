import React from "react";
import {
  Card,
  Icon,
  Text,
  Tooltip,
  VStack,
  useMediaQuery,
} from "@chakra-ui/react";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useTranslation } from "react-i18next";

const CardLectureInfoEmpty = ({
  timetable,
  group,
  modal_func,
  prop_tables,
  prop_weekday,
}) => {
  const { t } = useTranslation();

  const [isLargerThan1155] = useMediaQuery("(min-width: 1156px)");
  const [isLargerThan767] = useMediaQuery("(min-width: 768px)");

  return (
    <Tooltip
      // label="비어있는 시간을 클릭하여 수업을 추가할 수 있습니다."
      label={t("timetable.hover.text.description")}
      hasArrow
    >
      <Card
        w="full"
        minW="96.45px"
        h={{
          sm: "80px",
          md: "80px",
          lg: "80px",
          xl: "80px",
        }}
        minH={{
          sm: "80px",
          md: "80px",
          lg: "80px",
          xl: "80px",
        }}
        border="1px dashed"
        bg="gray.50"
        borderColor="gray.200"
        p={4}
        onClick={() => {
          modal_func({
            timetables: prop_tables,
            weekDay: prop_weekday,
            period: timetable.period,
          });
        }}
      >
        <VStack
          w="full"
          h="full"
          spacing={0}
          alignItems="center"
          justifyContent="center"
        >
          <Icon
            as={AddCircleOutlineIcon}
            w={isLargerThan767 ? "24px" : "20px"}
            h={isLargerThan767 ? "24px" : "20px"}
            color="gray.300"
          />
        </VStack>
      </Card>
    </Tooltip>
  );
};

export default CardLectureInfoEmpty;
