import config from "@app/config";

export const serviceErrorSlackAlarm = async (
  error,
  schoolName,
  schoolId,
  clientId,
  errorType,
  errorFile,
  errorLine
) => {
  const slackWebhookUrl = config.systemErrorSlackAlarm;
  const errorMessages = `🪳에러메세지: ${error.message}🪳
  \n학교: ${schoolName} - ${schoolId}
  \n사용자: ${clientId}
  \n에러원인: ${errorType}을 요청하던 도중 문제가 발생하였습니다.
  \n에러위치: ${errorFile}의 ${errorLine}라인에서 문제가 발생하였습니다.`;

  try {
    await fetch(slackWebhookUrl, {
      method: "POST",
      body: JSON.stringify({ text: errorMessages }),
    });
  } catch (err) {
    console.error("Slack으로 에러 알림을 보낼 수 없습니다.", err);
  }
};

export const serviceSocketDisconnectSlackAlarm = async (
  schoolId,
  userName,
  clientId
) => {
  const slackWebhookUrl = config.socketErrorSlackAlarm;
  const errorMessages = `학교 정보: ${JSON.stringify(schoolId)}
  \n유저 정보: ${JSON.stringify(userName)}
  \n클라이언트 정보: ${JSON.stringify(clientId)}
  \n사용자의 소켓 연결이 끊겼습니다.`;

  try {
    await fetch(slackWebhookUrl, {
      method: "POST",
      body: JSON.stringify({ text: errorMessages }),
    });
  } catch (err) {
    console.error("Slack으로 에러 알림을 보낼 수 없습니다.", err);
  }
};

export const serviceSocketReconnectSlackAlarm = async (
  schoolId,
  userName,
  clientId
) => {
  const slackWebhookUrl = config.socketErrorSlackAlarm;
  const errorMessages = `학교 정보: ${JSON.stringify(schoolId)}
  \n유저 정보: ${JSON.stringify(userName)}
  \n클라이언트 정보: ${JSON.stringify(clientId)}
  \n사용자가 웹소켓 연결을 다시 시도하였습니다..`;
  try {
    await fetch(slackWebhookUrl, {
      method: "POST",
      body: JSON.stringify({ text: errorMessages }),
    });
  } catch (err) {
    console.error("Slack으로 에러 알림을 보낼 수 없습니다.", err);
  }
};

export const serviceSocketReconnectErrorSlackAlarm = async (
  error,
  schoolId,
  userName,
  clientId
) => {
  const slackWebhookUrl = config.socketErrorSlackAlarm;
  const errorMessages = `오류 메세지: ${error.message}
  \n학교 정보: ${JSON.stringify(schoolId)}
  \n유저 정보: ${JSON.stringify(userName)}
  \n클라이언트 정보: ${JSON.stringify(clientId)}
  \n사용자의 소켓 재연결에 실패했습니다. 서버를 확인해주세요.`;

  try {
    await fetch(slackWebhookUrl, {
      method: "POST",
      body: JSON.stringify({ text: errorMessages }),
    });
  } catch (err) {
    console.error("Slack으로 에러 알림을 보낼 수 없습니다.", err);
  }
};

export const userServiceLoginSlackAlarm = async (clientData) => {
  const slackWebhookUrl = config.loginSlackAlarm;
  const loginMessages = `🎉선생님이 로그인 했습니다.
  \n유저 정보: ${JSON.stringify(
    clientData.userName
  )} \n학교 정보: ${JSON.stringify(
    clientData.schoolId
  )} \n클라이언트 정보: ${JSON.stringify(
    clientData.clientId
  )}\n 이메일 정보: ${JSON.stringify(clientData.email)}
  `;

  try {
    await fetch(slackWebhookUrl, {
      method: "POST",
      body: JSON.stringify({ text: loginMessages }),
    });
  } catch (err) {
    console.error("Slack으로 에러 알림을 보낼 수 없습니다.", err);
  }
};
