import { AddIcon, MinusIcon } from "@chakra-ui/icons";
import { HStack, IconButton, Text } from "@chakra-ui/react";
import React from "react";

const QuizScoreEditor = ({
  score,
  index,
  increase,
  decrease,
  decreaseDisabled = false,
}) => {
  return (
    <HStack spacing={4}>
      <IconButton
        disabled={decreaseDisabled}
        aria-label="점수 하락"
        icon={<MinusIcon />}
        onClick={() => decrease(index)}
      />
      <Text
        w={{
          base: "40px",
          md: "60px",
        }}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        {score} 점
      </Text>
      <IconButton
        aria-label="점수 상승"
        icon={<AddIcon />}
        onClick={() => increase(index)}
      />
    </HStack>
  );
};

export default QuizScoreEditor;
