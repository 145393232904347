import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { clearReportData, purgeAll } from "@app/store/actions";
import { refreshToken } from "@api";

const AuthManager = ({ children }) => {
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const client = useSelector(state => state.clients.byId);
  const history = useHistory();
  const location = useLocation();
  const updated = useSelector(state => state.user.updated);
  const [userValidated, setUserValidated] = useState(false);
  const [prevUserState, setPrevUserState] = useState(null);
  const myInfo = Object.values(client).filter(
    info => info.clientId === user.clientId
  );

  useEffect(() => {
    if (
      myInfo[0]?.state === "REGISTERED_CERT" ||
      myInfo[0]?.state === "ACCOUNT_SUSPENSION"
    ) {
      dispatch(purgeAll());
      history.replace("/auth");
      return;
    }
  }, [client]);

  useEffect(() => {
    if (user.signedIn) {
      if (!updated) {
        dispatch(purgeAll());
      }
      setUserValidated(true);
    } else {
      history.replace("/renewal");
    }
  }, [history, user.signedIn]);

  useEffect(() => {
    if (!updated) {
      dispatch(purgeAll());
    }
  }, [updated]);

  useEffect(() => {
    if (user?.state === null) {
      console.log("[AuthManager]", "user.state null");
      return;
    }

    if (prevUserState === user.state) {
      return;
    }
    console.log("[AuthManager]", prevUserState, "->", user.state);
    setPrevUserState(user.state);
    refreshToken();

    switch (user.state) {
      case "NOT_SIGNED_IN":
        console.log(
          "[AuthManager]",
          "navigating to AuthPage:",
          user.state,
          userValidated
        );
        history.replace("/renewal");
        return;

      case "REGISTERED_APPROVED":
        setUserValidated(true);
        return;

      default:
        console.log("[AuthManager]", "invalid user state:", user.state);

        setUserValidated(true);
        return;
    }
  }, [user.state]);

  useEffect(() => {
    // todo: this is temporal solution. remove this dispatch when the problem solved.
    dispatch(clearReportData());
    if (!userValidated || user.state === "NOT_SIGNED_IN") {
      return;
    }

    console.log(
      "[AuthManager]",
      " navigating to ClassStack",
      "current:",
      location.pathname.includes("auth")
    );
    if (location.pathname.includes("auth")) {
      history.replace("/home");
    }
  }, [userValidated, user.state, location.pathname, history]);

  return <div>{userValidated && children}</div>;
};

export default AuthManager;
