export const PURGE_CONTROL = 'PURGE_CONTROL';
export const SET_DIRTY = 'SET_DIRTY';

export const SET_SELECTED_PAGE = 'SET_SELECTED_PAGE';
export const SET_SELECTED_CLASS = 'SET_SELECTED_CLASS';

export const SET_APPROVAL_COMPLETED = 'SET_APPROVAL_COMPLETED';

export const SET_SIDEBAR_STATE = 'SET_SIDEBAR_STATE';

export const SET_DEV_MODE = 'SET_DEV_MODE';

export const SET_CLASSROOM_STATE = 'SET_CLASSROOM_STATE';

export const SET_FILELINK_STATE = 'SET_FILELINK_STATE';

export const SET_SOCKET_DATA = 'SET_SOCKET_DATA';

export const SET_REPORT_DATA = 'SET_REPORT_DATA';
export const CLEAR_REPORT_DATA = 'CLEAR_REPORT_DATA';

export const SET_SCREEN_DATA = 'SET_SCREEN_DATA';

export const SET_STUDENT_IMAGES = 'SET_STUDENT_IMAGES';

export const SET_SELECTED_STUDENT = 'SET_SELECTED_STUDENT';

export const SET_STUDENTLIST_UPDATE_REQUEST = 'SET_STUDENTLIST_UPDATE_REQUEST';

export const CLEAR_SCREEN_DATA = 'CLEAR_SCREEN_DATA';

export const CLEAR_STUDENT_IMAGES = 'CLEAR_STUDENT_IMAGES';

export const SET_BIG_URI = 'SET_BIG_URI';

export const CLEAR_BIG_URI = 'CLEAR_BIG_URI';

export const SET_REFRESH_STATE = 'SET_REFRESH_STATE';

export const SET_SELECTED_GROUP = 'SET_SELECTED_GROUP';

export const SET_NETWORK_STATE = 'SET_NETWORK_STATE';

export const CLEAR_NETWORK_STATE = 'CLEAR_NETWORK_STATE';

export const SET_QUIZSETSESSION_ID = 'SET_QUIZSETSESSION_ID';

export const PURGE_QUIZSETSESSION_ID = 'PURGE_QUIZSETSESSION_ID';

export const ADD_STUDENT_GOOD_FOCUS_STATUS_LIST =
  'ADD_STUDENT_GOOD_FOCUS_STATUS_LIST';

export const ADD_STUDENT_FOCUS_STATUS_LIST = 'ADD_STUDENT_FOCUS_STATUS_LIST';

export const REMOVE_STUDENT_FOCUS_STATUS_LIST =
  'REMOVE_STUDENT_FOCUS_STATUS_LIST';

export const PURGE_STUDENT_FOCUS_STATUS_LIST =
  'PURGE_STUDENT_FOCUS_STATUS_LIST';

export const SET_QUIZ_RESULT_UPDATE_REQUEST = 'SET_QUIZ_RESULT_UPDATE_REQUEST';

export const SET_QUIZ_RESULT_UPDATE_COMPLETED =
  'SET_QUIZ_RESULT_UPDATE_COMPLETED';
