export const SURVEY_TYPE = {
  MULTIPLE: "선다형",
  SHORT: "단답형",
  OX: "O/X",
  TIME:"시간 선택", 
  STYLUS: "필기",
  LONG: "서술형",
};

export const surveyTypes = Object.keys(SURVEY_TYPE);

export const MULTIPLE_TYPE = {
  MULTIPLE: "선다형",
  CHOSE: "선택형",
  RADIO:"라디오 선택지"
}

export const multipleTypes = Object.keys(MULTIPLE_TYPE)

export const CHOICE = [{ index: 1, description: "", point: 0, isAnswer: true }];
export const SURVEY_FORM_DATA = {
  header: "",
  title: "",
  grade: 0,
  quizCategory: "",
  quizSubject: "",
  quizType: "MULTIPLE",
  quizLevel: 1,
  keyword: "",
  description: "",
  timelimit: 10,
  linkType: "",
  link: "",
  surveyDescription: "",
  noInfringementAccepted: true,
  sharingAccepted: true,
  copyrightMandateAccepted: true,
};

export const TIME_LIMIT = [10, 30, 60, 180, 300, 600, 900, "직접 입력"]