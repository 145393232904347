import {
  QueryClient,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import {
  createFilelink,
  deleteFilelink,
  getFilelinkByGroupId,
} from "../filelinkApi";

export const useFilelinkByGroupId = (groupId) => {
  const { data: filelink, isLoading, isError } = useQuery({
    queryKey: ["filelink", groupId],
    queryFn: () => getFilelinkByGroupId(groupId),
    enabled: !!groupId,
    refetchOnWindowFocus: false,
    retry: false,
  });

  return { filelink, isLoading, isError };
};

//createFilelink
export const useCreateFilelink = (flielinkData) => {
  const queryClient = useQueryClient();

  return useMutation((data) => createFilelink(data), {
    onSuccess: (response) => {
      console.log("response", response);
      queryClient.refetchQueries("filelink");
    },
  });
};

//deleteFilelink
export const useDeleteFilelink = () => {
  const queryClient = useQueryClient();

  return useMutation((filelinkId) => deleteFilelink(filelinkId), {
    onSuccess: (response) => {
      console.log("response", response);
      queryClient.refetchQueries("filelink");
    },
  });
};
