import {
  PURGE_QUIZ,
  PURGE_OTHER_SCHOOL_QUIZ,
  GET_QUIZ_LIST_ALL_SUCCESS,
  GET_QUIZ_LIST_ALL_FAIL,
  GET_QUIZ_LIST_BY_CLIENT_SUCCESS,
  GET_QUIZ_LIST_BY_CLIENT_FAIL,
  GET_QUIZ_LIST_BY_SCHOOL_SUCCESS,
  GET_QUIZ_LIST_BY_SCHOOL_FAIL,
  GET_QUIZ_CATEGORY_ALL_SUCCESS,
  GET_QUIZ_CATEGORY_ALL_FAIL,
  CREATE_QUIZ_SUCCESS,
  CREATE_QUIZ_FAIL,
  UPDATE_QUIZ_SUCCESS,
  UPDATE_QUIZ_FAIL,
  DELETE_QUIZ_SUCCESS,
  DELETE_QUIZ_FAIL
} from "./actionTypes";
import { createPromiseAction } from "@adobe/redux-saga-promise";

export const purgeQuiz = () => ({
  type: PURGE_QUIZ
});
export const purgeOtherSchoolQuiz = () => ({
  type: PURGE_OTHER_SCHOOL_QUIZ
});

// quiz list all
export const getQuizListAllPromise = createPromiseAction(
  "GET_QUIZ_LIST_ALL_PROMISE"
);
export const getQuizListAllSuccess = quizListData => {
  let byId = {};
  quizListData.forEach(quizData => {
    byId[quizData.quizId] = quizData;
  });
  const allIds = quizListData.map(quizData => quizData.quizId);
  return {
    type: GET_QUIZ_LIST_ALL_SUCCESS,
    otherSchoolById: byId,
    otherSchoolAllIds: allIds
  };
};
export const getQuizListAllFail = error => ({
  type: GET_QUIZ_LIST_ALL_FAIL,
  payload: error
});

// quiz list by client
export const getQuizListByClientPromise = createPromiseAction(
  "GET_QUIZ_LIST_BY_CLIENT_PROMISE"
);
export const getQuizListByClientSuccess = quizListData => {
  let byId = {};
  quizListData.forEach(quizData => {
    byId[quizData.quizId] = quizData;
  });
  const allIds = quizListData.map(quizData => quizData.quizId);
  return {
    type: GET_QUIZ_LIST_BY_CLIENT_SUCCESS,
    byId: byId,
    allIds: allIds
  };
};
export const getQuizListByClientFail = error => ({
  type: GET_QUIZ_LIST_BY_CLIENT_FAIL,
  payload: error
});

// quiz list by school
export const getQuizListBySchoolPromise = createPromiseAction(
  "GET_QUIZ_LIST_BY_SCHOOL_PROMISE"
);
export const getQuizListBySchoolSuccess = quizListData => {
  let byId = {};
  quizListData.forEach(quizData => {
    byId[quizData.quizId] = quizData;
  });
  const allIds = quizListData.map(quizData => quizData.quizId);
  return {
    type: GET_QUIZ_LIST_BY_SCHOOL_SUCCESS,
    otherSchoolById: byId,
    otherSchoolAllIds: allIds
  };
};
export const getQuizListBySchoolFail = error => ({
  type: GET_QUIZ_LIST_BY_SCHOOL_FAIL,
  payload: error
});

// get quiz category all
export const getQuizCategoryAllPromise = createPromiseAction(
  "GET_QUIZ_CATEGORY_ALL_PROMISE"
);
export const getQuizCategoryAllSuccess = quizCategories => {
  let byId = {};
  quizCategories.forEach(
    quizCategory => (byId[quizCategory.quizCategoryId] = quizCategory)
  );
  const allIds = quizCategories.map(
    quizCategory => quizCategory.quizCategoryId
  );
  const quizCategoryTitle = quizCategories.map(
    quizCategory => quizCategory.quizCategory
  );
  return {
    type: GET_QUIZ_CATEGORY_ALL_SUCCESS,
    // byId: byId,
    // allIds: allIds,
    quizCategoryAll: quizCategoryTitle
  };
};
export const getQuizCategoryAllFail = error => ({
  type: GET_QUIZ_CATEGORY_ALL_FAIL,
  payload: error
});

// create quiz
export const createQuizPromise = createPromiseAction("CREATE_QUIZ_PROMISE");
export const createQuizSuccess = quizData => ({
  type: CREATE_QUIZ_SUCCESS,
  quiz: quizData
});
export const createQuizFail = error => ({
  type: CREATE_QUIZ_FAIL,
  payload: error
});

// update quiz
export const updateQuizPromise = createPromiseAction("UPDATE_QUIZ_PROMISE");
export const updateQuizSuccess = quizData => ({
  type: UPDATE_QUIZ_SUCCESS,
  quiz: quizData
});
export const updateQuizFail = error => ({
  type: UPDATE_QUIZ_FAIL,
  payload: error
});

// delete quiz
export const deleteQuizPromise = createPromiseAction("DELETE_QUIZ_PROMISE");
export const deleteQuizSuccess = quizId => ({
  type: DELETE_QUIZ_SUCCESS,
  quizId
});
export const deleteQuizFail = error => ({
  type: DELETE_QUIZ_FAIL,
  payload: error
});
