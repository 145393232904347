export const selectGroupFilelinks = (state, groupId) => {
  if (state.filelinks.allIds.length === 0) {
    return [];
  }
  return state.filelinks.allIds
    .filter(
      filelinkId =>
        state.filelinks.byId[filelinkId].state !== "DELETED" &&
        state.filelinks.byId[filelinkId].groupId === groupId
    )
    .map(filelinkId => state.filelinks.byId[filelinkId]);
};
