import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  createQuizsetSession,
  createQuizsetSessionQuizJoint,
  getQuizsetSessionActive,
  getQuizsetSessionActiveAll,
  patchQuizsetSession,
} from "@api";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  notificationKind,
  useSystemNotification,
} from "@app/react-query/querys/useSystemNotification";
import { useHistory } from "react-router-dom";
import {
  createQuizsetSessionSuccess,
  purgeAnsweredStudentList,
  resetQuizsetStudentStatus,
} from "@app/store/actions";
import { useCallback, useMemo } from "react";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import quizset from "@app/store/quizset/reducer";

export const STUDENT_STATUS = {
  NONE: "미참여",
  READY_INPROGRESS: "준비중",
  READY_SUCCESS: "준비완료",
  QUIZ_INPROGRESS: "작성중",
  QUIZ_SUBMIT: "작성완료",
};
export const useQuizsetSession = (groupId = "") => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const history = useHistory();
  const user = useSelector((state) => state.user);
  const { onSendMessageByGroup } = useSystemNotification(groupId, true);
  const location = useLocation();

  const { data: activeQuizsetSession, isFetching } = useQuery({
    queryKey: ["quizsetSessionActive"],
    queryFn: () => getQuizsetSessionActive(groupId),
    enabled: !!user?.clientId && !!groupId,
    select: ({ data }) => data,
  });

  const { mutate: mutateQuizetSession } = useMutation({
    mutationFn: () => getQuizsetSessionActiveAll(user.clientId),
    enabled: !!user?.clientId,
    retry: (failCount, error) => {
      if (error?.response?.status && error.response.status === 404) {
        return false;
      }
      return failCount < 3;
    },
    retryDelay: 3000,
    onSuccess: ({ data: { quizsetSession } }) => {
      switch (quizsetSession.state) {
        case "READY":
          if (!/quiz-pang\/.*\/quizset-ready/g.test(window.location.pathname)) {
            history.push(
              `/quiz-pang/${quizsetSession.groupId}/quizset-ready/${quizsetSession.quizsetId}`
            );
          }
          break;
        case "STARTED":
          if (!/quiz-pang\/.*\/session/g.test(window.location.pathname)) {
            history.push(
              `/quiz-pang/${quizsetSession.groupId}/session/${quizsetSession.quizsetSessionId}`
            );
          }
          break;
        default:
          break;
      }
    },
  });

  // 퀴즈 준비
  const { mutateAsync: onCreateQuizsetSession } = useMutation({
    mutationFn: ({ quizsetId, groupId }) =>
      createQuizsetSession({
        quizsetId,
        groupId,
        clientId: user?.clientId,
        startAt: moment().toISOString(),
      }),
    onSuccess: ({ data }) => {
      dispatch(createQuizsetSessionSuccess(data));
      queryClient.setQueryData(["quizsetSessionActive"], { data });
      createQuizsetSessionQuizJoint({
        quizsetSessionId: data?.quizsetSession?.quizsetSessionId,
        quizOrder: 0,
      });

      onSendMessageByGroup({
        notificationKind: notificationKind.QUIZSET_READY,
        payload: JSON.stringify({
          groupId,
          quizsetId: data?.quizsetSession?.quizsetId,
        }),
      });
    },
    onError: () => {
      console.error("Error: Create Quizset Session");
    },
  });

  // surveyList(surveyList는 quizsetId의 모음이다.를 전달받아 리스트 수만큼 quizsetSession을 생성하는 함수
  const { mutateAsync: onCreateQuizsetSessionList } = useMutation({
    mutationFn: ({ surveyList, groupId }) =>
      surveyList.map((survey) =>
        createQuizsetSession({
          quizsetId: survey,
          groupId,
          clientId: user?.clientId,
          startAt: moment().toISOString(),
        })
      ),
    onSuccess: ({ data }) => {
      queryClient.setQueryData(["surveysetSession"], { data });
    },
    onError: () => {
      console.error("Error: Create Quizset Session");
    },
  });

  // 퀴즈 시작
  const { mutateAsync: onStartQuizsetSession } = useMutation({
    mutationFn: ({ quizsetSessionId }) =>
      patchQuizsetSession({
        quizsetSessionId,
        state: "STARTED",
      }),
    onSuccess: async (_, { quizsetSessionId }) => {
      dispatch(resetQuizsetStudentStatus());
      // await onNextQuizsetSession({
      //   quizsetSessionId,
      //   quizOrder: 0,
      // });
      onSendMessageByGroup({
        notificationKind: notificationKind.QUIZSET_START,
        payload: JSON.stringify({
          groupId,
          quizsetSessionId,
        }),
      });
      queryClient.refetchQueries(["quizsetSessionActive"]);
      history.push({
        pathname: `/quiz-pang/${groupId}/session/${quizsetSessionId}`,
      });
    },
  });
  // 다음 퀴즈
  const { mutateAsync: onNextQuizsetSession } = useMutation({
    mutationFn: ({ quizsetSessionId, quizOrder }) =>
      createQuizsetSessionQuizJoint({
        quizsetSessionId,
        quizOrder,
      }),
    onSuccess: (_, { quizOrder, quizsetSessionId }) => {
      dispatch(resetQuizsetStudentStatus());
      dispatch(purgeAnsweredStudentList());
      queryClient.setQueryData(["quizsetSessionActive"], (oldData) => ({
        data: {
          ...oldData.data,
          quizsetSessionQuizJoint: {
            ...oldData.data.quizsetSessionQuizJoint,
            quizOrder,
          },
        },
      }));
      onSendMessageByGroup({
        notificationKind: notificationKind.QUIZ_NEXT_START,
        payload: JSON.stringify({
          quizsetSessionId: quizsetSessionId,
          idx: String(quizOrder),
          groupId,
        }),
      });

      history.replace({
        pathname: `/quiz-pang/${groupId}/session/${quizsetSessionId}`,
      });
    },
  });

  // 퀴즈 종료
  const { mutateAsync: onFinishedQuizsetSession } = useMutation({
    mutationFn: ({ quizsetSessionId }) =>
      patchQuizsetSession({
        quizsetSessionId,
        state: "FINISHED",
      }),
    onSuccess: () => {
      dispatch(resetQuizsetStudentStatus());
      onSendMessageByGroup({
        notificationKind: notificationKind.QUIZSET_COMPLETED,
        payload: JSON.stringify({
          groupId,
        }),
      });
    },
  });

  //

  const storageQuizTime = useMemo(() => {
    const storageStr = localStorage.getItem("quizSessionSpinner");
    if (storageStr && activeQuizsetSession) {
      const storage = JSON.parse(storageStr);
      const { quizsetSessionQuizJoint, quizsetSession } = activeQuizsetSession;

      if (
        quizsetSessionQuizJoint &&
        storage?.quizOrder === quizsetSessionQuizJoint.quizOrder &&
        storage?.quizsetSessionId === quizsetSession?.quizsetSessionId
      ) {
        return storage;
      }
    }
    return null;
  }, [activeQuizsetSession]);

  const saveQuizTime = useCallback(
    (second) => {
      if (
        !activeQuizsetSession ||
        !activeQuizsetSession?.quizsetSessionQuizJoint
      ) {
        return;
      }
      localStorage.setItem(
        "quizSessionSpinner",
        JSON.stringify({
          quizsetSessionId:
            activeQuizsetSession.quizsetSession.quizsetSessionId,
          quizOrder: activeQuizsetSession.quizsetSessionQuizJoint.quizOrder,
          second,
        })
      );
    },
    [activeQuizsetSession]
  );
  const clearQuizTime = useCallback(() => {
    localStorage.removeItem("quizSessionSpinner");
  }, []);

  return {
    quizsetSession: activeQuizsetSession?.quizsetSession,
    quizsetSessionQuizJoint: activeQuizsetSession?.quizsetSessionQuizJoint,
    isLoading: isFetching,
    onCreateQuizsetSession,
    onStartQuizsetSession,
    onNextQuizsetSession,
    onFinishedQuizsetSession,
    onCreateQuizsetSessionList,
    mutateQuizetSession,
    storageQuizTime,
    saveQuizTime,
    clearQuizTime,
  };
};

export const useActiveQuizsetSession = (groupId = "") => {
  const { data, isLoading, isError } = useQuery({
    queryKey: ["quizsetSessionActive"],
    queryFn: () => getQuizsetSessionActive(groupId),
    enabled: !!groupId,
    select: ({ data }) => data,
    onSuccess: ({ data }) => {
      console.log("data", data);
    },
  });

  return {
    quizsetSession: data?.quizsetSession,
    isLoading,
    isError,
  };
};

export const useCreateQuizsetSession = (surveyList, groupId) => {
  const queryClient = useQueryClient();
  const user = useSelector((state) => state.user);
  const { mutate, isError, isLoading, isSuccess } = useMutation({
    mutationFn: () =>
      surveyList.map((survey) =>
        createQuizsetSession({
          quizsetId: survey,
          groupId,
          clientId: user?.clientId,
          startAt: moment().toISOString(),
        })
      ),
    onSuccess: ({ data }) => {
      queryClient.setQueryData(["surveysetSession", groupId], { data });
    },
    onError: () => {
      console.error("Error: Create Quizset Session");
    },
    enabled: !!user?.clientId,
    retry: false,
  });

  return {
    mutate,
    isLoading,
    isError,
    isSuccess,
  };
};

export const useFinishQuizsetSession = (quizsetSessionIds, state) => {
  const queryClient = useQueryClient();
  const { mutate, isError, isLoading } = useMutation({
    mutationFn: () =>
      quizsetSessionIds.map((quizsetSessionId) =>
        patchQuizsetSession({
          quizsetSessionId,
          state,
        })
      ),
    onSuccess: () => {
      queryClient.invalidateQueries(["quizsetSessionActive"]);
    },
    onError: () => {
      console.error("Error: Create Quizset Session");
    },
    enabled: !!quizsetSessionIds || !!state,
  });

  return {
    mutate,
    isLoading,
    isError,
  };
};
