import {
  PURGE_GROUP,
  GET_GROUP_SUCCESS,
  GET_GROUP_FAIL,
  GET_GROUP_BY_INVITE_SUCCESS,
  GET_GROUP_BY_INVITE_FAIL,
  GET_GROUPS_SUCCESS,
  GET_GROUPS_FAIL,
  CREATE_GROUP_SUCCESS,
  CREATE_GROUP_FAIL,
  UPDATE_GROUP_SUCCESS,
  UPDATE_GROUP_FAIL,
  DELETE_GROUP_SUCCESS,
  DELETE_GROUP_FAIL,
  CREATE_INVITE_CODE_SUCCESS,
  CREATE_INVITE_CODE_FAIL
} from "./actionTypes";
import { createPromiseAction } from "@adobe/redux-saga-promise";

export const purgeGroup = () => ({
  type: PURGE_GROUP
});

///// GET_GROUP
export const getGroupPromise = createPromiseAction("GET_GROUP_PROMISE");

export const getGroupSuccess = groupData => ({
  type: GET_GROUP_SUCCESS,
  group: groupData
});

export const getGroupFail = error => ({
  type: GET_GROUP_FAIL,
  payload: error
});

//// CREATE_INVITE_CODE
export const createInviteCodePromise = createPromiseAction(
  "CREATE_INVITE_CODE_PROMISE"
);

export const createInviteCodeSuccess = inviteCode => ({
  type: CREATE_INVITE_CODE_SUCCESS,
  inviteCode: inviteCode
});

export const createInviteCodeFail = error => ({
  type: CREATE_INVITE_CODE_FAIL,
  payload: error
});

//// Invite Code
export const getGroupByInviteCodePromise = createPromiseAction(
  "GET_GROUP_BY_INVITE_CODE_PROMISE"
);

export const getGroupByInviteSuccess = groupData => ({
  type: GET_GROUP_BY_INVITE_SUCCESS,
  decipherGroup: groupData
});

export const getGroupByInviteFail = error => ({
  type: GET_GROUP_BY_INVITE_FAIL,
  payload: error
});

//// GET_GROUPS
export const getGroupsPromise = createPromiseAction("GET_GROUPS_PROMISE");

export const getGroupsSuccess = groupsData => {
  let byId = {};
  groupsData.forEach(groupData => {
    byId[groupData.id] = groupData;
  });

  const allIds = groupsData.map(groupData => groupData.id);
  return {
    type: GET_GROUPS_SUCCESS,
    byId: byId,
    allIds: allIds
  };
};

export const getGroupsByMembershipSuccess = groupMembershipsData => {
  let byId = {};
  groupMembershipsData.forEach(groupMembershipData => {
    byId[groupMembershipData.group.groupId] = groupMembershipData.group;
  });

  const allIds = groupMembershipsData.map(
    groupMembershipData => groupMembershipData.group.groupId
  );

  return {
    type: GET_GROUPS_SUCCESS,
    byId: byId,
    allIds: allIds
  };
};

export const getGroupsFail = error => ({
  type: GET_GROUPS_FAIL,
  payload: error
});

///// CREATE_GROUP
export const createGroupPromise = createPromiseAction("CREATE_GROUP_PROMISE");

export const createGroupSuccess = groupData => ({
  type: CREATE_GROUP_SUCCESS,
  group: groupData
});

export const createGroupFail = error => ({
  type: CREATE_GROUP_FAIL,
  payload: error
});

///// UPDATE_GROUP
export const updateGroupPromise = createPromiseAction("UPDATE_GROUP_PROMISE");

export const updateGroupSuccess = groupData => ({
  type: UPDATE_GROUP_SUCCESS,
  group: groupData
});

export const updateGroupFail = error => ({
  type: UPDATE_GROUP_FAIL,
  payload: error
});

///// DELETE_GROUP
export const deleteGroupPromise = createPromiseAction("DELETE_GROUP_PROMISE");

export const deleteGroupSuccess = groupData => ({
  type: DELETE_GROUP_SUCCESS,
  group: groupData
});

export const deleteGroupFail = error => ({
  type: DELETE_GROUP_FAIL,
  payload: error
});
