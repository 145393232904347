import React, { useCallback, useState } from "react";
import QuizTypeWrapper from "./Common/QuizTypeWrapper";
import { HStack, Heading, Input, Button, Stack } from "@chakra-ui/react";
import QuizScoreEditor from "./Common/QuizScoreEditor";
import QuizQusetionDelete from "./Common/QuizQusetionDelete";
import { AddIcon } from "@chakra-ui/icons";
const QuizTypeShortInput = ({ choices, setChoices }) => {
  const [decreaseDisabled, setDecreaseDisabled] = useState(false);
  const addChoice = () => {
    setChoices((prev) => {
      const newChoices = [...prev];
      newChoices.push({ description: "", point: 1 });
      return newChoices;
    });
  };
  const deleteChoice = (index) => {
    console.log(choices);
    console.log("삭제하는 인덱스", index);
    setChoices((prev) => {
      const newCho = prev.filter((x, i) => i !== index);
      return newCho;
    });
  };
  const setChoiceDescription = useCallback(
    (e, index) => {
      setChoices((prev) => {
        let newChoices = [...prev];
        newChoices[index] = {
          description: e.target.value,
          point: newChoices[index].point,
        };
        return newChoices;
      });
    },
    [setChoices]
  );
  const increaseScore = useCallback(
    (index) => {
      setChoices((prev) => {
        let newChoices = [...prev];
        newChoices[index] = {
          description: newChoices[index].description,
          point: ++newChoices[index].point,
        };
        setDecreaseDisabled(false);
        return newChoices;
      });
    },
    [setChoices]
  );
  const decreaseScore = useCallback(
    (index) => {
      setChoices((prev) => {
        let newChoices = [...prev];
        if (newChoices[index].point <= 1) {
          setDecreaseDisabled(true);
          return prev;
        }
        newChoices[index] = {
          description: newChoices[index].description,
          point: --newChoices[index].point,
        };
        return newChoices;
      });
    },
    [setChoices]
  );
  return (
    <QuizTypeWrapper>
      <Heading fontSize={"lg"}>3. 보기입력</Heading>
      {choices.map((choice, index) => {
        return (
          <Stack direction={"column"} style={{ flex: "flexStart" }}>
            <HStack spacing={1}>
              <Input
                placeholder="주관식 문항 입력"
                onChange={(e) => setChoiceDescription(e, index)}
                value={choice.description}
              />
              <QuizQusetionDelete
                deleteHandler={deleteChoice}
                index={index}
                disabled={choices.length <= 1}
              />
            </HStack>

            <QuizScoreEditor
              score={choice.point}
              index={index}
              increase={increaseScore}
              decrease={decreaseScore}
              decreaseDisabled={decreaseDisabled}
            />
          </Stack>
        );
      })}

      <Button leftIcon={<AddIcon />} onClick={() => addChoice()}>
        보기 추가
      </Button>
    </QuizTypeWrapper>
  );
};

export default QuizTypeShortInput;
