import { CloseButton, IconButton } from "@chakra-ui/react";
import React from "react";

const QuizQusetionDelete = ({ deleteHandler, index, disabled }) => {
  if (disabled) return null;

  return (
    <IconButton
      aria-label="문항제거"
      icon={<CloseButton />}
      onClick={() => deleteHandler(index)}
      disabled={disabled}
    />
  );
};

export default QuizQusetionDelete;
