import QuizQusetionDelete from "@app/components/Quiz/Common/QuizQusetionDelete";
import { Input, InputLeftAddon, InputGroup } from "@chakra-ui/react";
import React from "react";

const Multiple = ({
  index,
  value,
  handlChoiceDescriptionChange,
  handleChoicesDeleteClick,
}) => {
  return (
    <>
      <InputGroup>
        <InputLeftAddon>{index + 1}번</InputLeftAddon>
        <Input
          placeholder={`${index + 1}번 보기 입력`}
          value={value}
          onChange={(event) => handlChoiceDescriptionChange(event, index)}
        />
      </InputGroup>
      <QuizQusetionDelete
        deleteHandler={handleChoicesDeleteClick}
        index={index}
      />
    </>
  );
};

export default Multiple;
