import {
  PURGE_CLIENT,
  GET_CLIENT_SUCCESS,
  GET_CLIENT_FAIL,
  GET_CLIENTS_SUCCESS,
  GET_CLIENTS_FAIL,
  UPDATE_CLIENT_SUCCESS,
  UPDATE_CLIENT_FAIL,
  DELETE_CLIENT_SUCCESS
} from "./actionTypes";
import * as api from "@api";
import { createPromiseAction } from "@adobe/redux-saga-promise";
import { DELETE_CLASSROOM_FAIL } from "../classrooms/actionTypes";

export const purgeClient = () => ({
  type: PURGE_CLIENT
});

///// GET_CLIENT
export const getClientPromise = createPromiseAction("GET_CLIENT_PROMISE");
export const getClientSuccess = clientData => ({
  type: GET_CLIENT_SUCCESS,
  client: api.unpackClient(clientData)
});

export const getClientFail = error => ({
  type: GET_CLIENT_FAIL,
  payload: error
});

///// GET_CLIENTS
export const getClientsPromise = createPromiseAction("GET_CLIENTS_PROMISE");
export const getClientsByMembershipSuccess = clientMembershipsData => {
  let byId = {};
  clientMembershipsData.forEach(clientMembershipData => {
    byId[clientMembershipData.client.clientId] = api.unpackClient(
      clientMembershipData
    );
  });

  const allIds = clientMembershipsData.map(
    clientMembershipsData => clientMembershipsData.client.clientId
  );

  return {
    type: GET_CLIENTS_SUCCESS,
    byId: byId,
    allIds: allIds
  };
};

export const getClientsSuccess = clientsData => {
  let byId = {};
  clientsData.forEach(clientData => {
    byId[clientData.clientId] = api.unpackClient(clientData);
  });

  const allIds = clientsData.map(clientData => clientData.clientId);
  return {
    type: GET_CLIENTS_SUCCESS,
    byId: byId,
    allIds: allIds
  };
};

export const getClientsFail = error => ({
  type: GET_CLIENTS_FAIL,
  payload: error
});

export const updateClientsPromise = createPromiseAction(
  "UPDATE_CLIENT_PROMISE"
);

export const updateClientSuccess = clientData => {
  return {
    type: UPDATE_CLIENT_SUCCESS,
    payload: clientData
  };
};

export const updateClientFail = error => ({
  type: UPDATE_CLIENT_FAIL,
  payload: error
});

export const deleteClientsPromise = createPromiseAction(
  "DELETE_CLIENT_PROMISE"
);

export const deleteClientSuccess = clientData => {
  return {
    type: DELETE_CLIENT_SUCCESS,
    payload: clientData
  };
};

export const delteClientFail = error => ({
  type: DELETE_CLASSROOM_FAIL,
  payload: error
});
