import {
  CREATE_GROUP_ACTIVITY_FAIL,
  CREATE_SUBGROUP_ACTIVITY_FAIL,
  DELETE_GROUP_ACTIVITY_FAIL,
  DELETE_GROUP_ACTIVITY_SUCCESS,
  DELETE_SUBGROUP_ACTIVITY_FAIL,
  DELETE_SUBGROUP_ACTIVITY_SUCCESS,
  GET_GROUP_ACTIVITY_FAIL,
  GET_GROUP_ACTIVITY_SUCCESS,
  GET_SUBGROUP_ACTIVITY_FAIL,
  GET_SUBGROUP_ACTIVITY_SUCCESS,
  UPDATE_SUBGROUP_ACTIVITY_FAIL,
  UPDATE_SUBGROUP_ACTIVITY_STUDENT,
  GROUP_ACTIVITY_LOADING,
} from "./actionTypes";

const initialState = {
  activityList: [],
  subgroupList: [],
  isLoading: false,
};

const groupActivity = (state = initialState, action) => {
  switch (action.type) {
    case GROUP_ACTIVITY_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };

    case GET_GROUP_ACTIVITY_SUCCESS:
      return {
        ...state,
        activityList: action.payload.activityList,
      };
    case DELETE_GROUP_ACTIVITY_SUCCESS:
      return {
        ...state,
        activityList: state.activityList.filter(
          (item) =>
            item.subgroupActivityId !== action.payload.subgroupActivityId
        ),
      };
    case GET_SUBGROUP_ACTIVITY_SUCCESS:
      return {
        ...state,
        subgroupList: action.payload.subgroupList.map((subgroup) => ({
          ...subgroup,
          students: JSON.parse(subgroup.students),
        })),
      };
    case GET_SUBGROUP_ACTIVITY_FAIL:
      return {
        ...state,
        subgroupList: [],
      };
    case DELETE_SUBGROUP_ACTIVITY_SUCCESS:
      return {
        ...state,
        subgroupList: state.subgroupList.filter(
          (item) => item.subgroupId !== action.payload.subgroupId
        ),
      };
    case UPDATE_SUBGROUP_ACTIVITY_STUDENT:
      return {
        ...state,
        subgroupList: action.payload.subgroupList,
      };
    case GET_GROUP_ACTIVITY_FAIL:
    case CREATE_GROUP_ACTIVITY_FAIL:
    case CREATE_SUBGROUP_ACTIVITY_FAIL:
    case DELETE_GROUP_ACTIVITY_FAIL:
    case DELETE_SUBGROUP_ACTIVITY_FAIL:
    case UPDATE_SUBGROUP_ACTIVITY_FAIL:
      // console.error("[Group Activity Reducer] ", action.payload);
      return state;

    default:
      break;
  }
  return state;
};

export default groupActivity;
