export const PURGE_QUIZ = "PURGE_QUIZ";
export const PURGE_OTHER_SCHOOL_QUIZ = "PURGE_OTHER_SCHOOL_QUIZ";
export const GET_QUIZ_LIST_ALL_SUCCESS = "GET_QUIZ_LIST_ALL_SUCCESS";
export const GET_QUIZ_LIST_ALL_FAIL = "GET_QUIZ_LIST_ALL_FAIL";
export const GET_QUIZ_LIST_BY_CLIENT_SUCCESS =
  "GET_QUIZ_LIST_BY_CLIENT_SUCCESS";
export const GET_QUIZ_LIST_BY_CLIENT_FAIL = "GET_QUIZ_LIST_BY_CLIENT_FAIL";
export const GET_QUIZ_LIST_BY_SCHOOL_SUCCESS =
  "GET_QUIZ_LIST_BY_SCHOOL_SUCCESS";
export const GET_QUIZ_LIST_BY_SCHOOL_FAIL = "GET_QUIZ_LIST_BY_SCHOOL_FAIL";
export const GET_QUIZ_CATEGORY_ALL_SUCCESS = "GET_QUIZ_CATEGORY_ALL_SUCCESS";
export const GET_QUIZ_CATEGORY_ALL_FAIL = "GET_QUIZ_CATEGORY_ALL_FAIL";
export const CREATE_QUIZ_SUCCESS = "CREATE_QUIZ_SUCCESS";
export const CREATE_QUIZ_FAIL = "CREATE_QUIZ_FAIL";
export const UPDATE_QUIZ_SUCCESS = "UPDATE_QUIZ_SUCCESS";
export const UPDATE_QUIZ_FAIL = "UPDATE_QUIZ_FAIL";
export const DELETE_QUIZ_SUCCESS = "DELETE_QUIZ_SUCCESS";
export const DELETE_QUIZ_FAIL = "DELETE_QUIZ_FAIL";
