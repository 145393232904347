export const socketKind = {
  classroom: {
    CLASS_START: "CLASS_START",
    CLASS_UPDATE: "CLASS_UPDATE",
    CLASS_END: "CLASS_END",
    DOING_CLASS: "DOING_CLASS",
    FINISHED_CLASS: "FINISHED_CLASS",
    FOCUS_START: "FOCUS_START",
    FOCUS_END: "FOCUS_END",
    SELECT_STUDENT: "SELECT_STUDENT",
    CREATE_FILELINK: "CREATE_FILELINK",
    DELETE_FILELINK: "DELETE_FILELINK",
  },

  timetable: {
    CLASS_UPDATE: "CLASS_UPDATE",
    CLASS_DELETE: "CLASS_DELETE",
  },

  quizpang: {
    QUIZSET_START: "QUIZSET_START",
    QUIZSET_DOING: "QUIZSET_DOING",
    QUIZSET_NEXT_START: "QUIZSET_NEXT_START",
    QUIZSET_FINISHED: "QUIZSET_FINISHED",
    QUIZSET_COMPLETED: "QUIZSET_COMPLETED",
    QUIZSET_NEXT_START: "QUIZSET_NEXT_START",
    QUIZSET_SESSION_ACTIVE_CHECK: "QUIZSET_SESSION_ACTIVE_CHECK",
    QUIZSET_SESSION_ACTIVE_CONFIRM: "QUIZSET_SESSION_ACTIVE_CONFIRM",
    QUIZ_STUDENT_COMPLETED: "QUIZ_STUDENT_COMPLETED",
  },
};

export const socketUrl = {
  classroomSendImage: "/classroom/sendImage",
  pulse: "/pulse",
};

export const socketMethod = {
  // method
  POST: "POST",
  GET: "GET",
  PATCH: "PATCH",
  DELETE: "DELETE",
};
