import React from "react";
import { useHistory } from "react-router-dom";
import styles from "./CardLectureInfo.module.scss";
import BaseCard from "./baseCard";
import ClassTimeline from "./classTimeline";
import SettingsIcon from "@mui/icons-material/Settings";
import { Tooltip } from "@material-ui/core";
import { useSelector } from "react-redux";
import { Box, Stack, Typography } from "@mui/material";
import {
  Card,
  HStack,
  IconButton,
  Text,
  VStack,
  useMediaQuery,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { getTimetablesByGroupId } from "@app/api/timetable/timetableApi";

const CardLectureInfo = ({
  timetable,
  group,
  modal_func,
  prop_tables,
  prop_weekday,
}) => {
  const { t } = useTranslation();

  const user = useSelector((state) => state.user);
  const queryClient = useQueryClient();
  const history = useHistory();
  const [isLargerThan1155] = useMediaQuery("(min-width: 1156px)");

  const navigateToClassRoom = async () => {
    if (group.groupType === "AFTER") {
      history.push({
        pathname: `/after-school/${group?.groupId}`,
        state: { group },
      });
    } else {
      const data = await getTimetablesByGroupId(group?.groupId);
      queryClient.setQueryData(["timetable", group?.groupId], data.data);
      history.push({
        pathname: `/notice/${group?.groupId}`,
        state: { group },
      });
    }
  };

  const handleSetting = (e) => {
    e.stopPropagation();
    modal_func({
      timetables: prop_tables,
      weekDay: prop_weekday,
      period: timetable.period,
      startTime: timetable.startTime,
      endTime: timetable.endTime,
      group: group,
      timetableId: timetable.timetableId,
    });
  };

  return (
    <Card
      w="full"
      minW="96.45px"
      h={{
        sm: "80px",
        md: "80px",
        lg: "80px",
        xl: "80px",
      }}
      p={4}
      onClick={() => {
        navigateToClassRoom();
      }}
      border="1px"
      borderColor="gray.200"
    >
      <VStack
        w="full"
        h="full"
        spacing={0}
        alignItems="center"
        justifyContent="center"
      >
        <HStack alignItems="center" justifyContent="center" gap={0} w="full">
          <ClassTimeline
            period={timetable.period}
            startTimeString={`${timetable.startTime}`}
            endTimeString={`${timetable.endTime}`}
          />
          <Tooltip
            // title="해당 수업 정보를 수정 하실 수 있습니다."
            title={t("timetable.hover.text.description")}
            arrow
          >
            <IconButton
              variant="ghost"
              colorScheme="gray"
              color="gray.300"
              icon={<SettingsIcon fontSize="small" />}
              onClick={handleSetting}
              size="sm"
            />
          </Tooltip>
        </HStack>
        <HStack spacing={2} alignItems="center" justifyContent="center">
          <div style={{ display: isLargerThan1155 ? "flex" : "" }}>
            <Text
              fontSize={{
                base: "xs",
                md: "sm",
                lg: "sm",
                xl: "md",
              }}
              fontWeight={"nomal"}
              whiteSpace="nowrap"
              textAlign="center"
              margin="0 0.5rem"
            >
              {group.grade} - {group.groupName}
            </Text>
            <Tooltip title={group.subjectName} arrow>
              <Text
                fontSize={{
                  base: "xs",
                  md: "sm",
                  lg: "sm",
                  xl: "md",
                }}
                fontWeight={"nomal"}
                whiteSpace="nowrap"
                component="p"
                textAlign="center"
              >
                {group.subjectName.length > 5
                  ? group.subjectName.substring(0, 5) + "..."
                  : group.subjectName}
              </Text>
            </Tooltip>
          </div>
        </HStack>
      </VStack>
    </Card>
  );
};

export default CardLectureInfo;
