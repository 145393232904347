import { useSchoolBySchoolId } from "@app/api/school/hooks/useSchool";
import { useQuizsetSession } from "@app/pages/QuizPang/hooks";
import {
  getManagedsByClientPromise,
  getMembershipByClientPromise,
} from "@app/store/actions";
import { getProgramsPromise } from "@store/actions";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

const GroupManager = () => {
  const { mutateQuizetSession } = useQuizsetSession("");
  const user = useSelector((state) => state.user);
  const networkCounter = useSelector((state) => state.control.networkState);
  const dispatch = useDispatch();

  const { school } = useSchoolBySchoolId(user?.schoolId);

  useEffect(() => {
    if (!user || !user.signedIn) {
      return;
    }
    dispatch(getMembershipByClientPromise(user?.clientId));
    dispatch(getManagedsByClientPromise(user?.clientId));
    mutateQuizetSession(user?.clientId);
  }, [dispatch, user, networkCounter]);

  useEffect(() => {
    if (!user || !user.signedIn) {
      return;
    }
    dispatch(getProgramsPromise());
  }, [dispatch, user, networkCounter]);

  return <div />;
};

export default GroupManager;
