// 모둠 활동
export const GET_GROUP_ACTIVITY_SUCCESS = "GET_GROUP_ACTIVITY_SUCCESS";
export const GET_GROUP_ACTIVITY_FAIL = "GET_GROUP_ACTIVITY_FAIL";
export const CREATE_GROUP_ACTIVITY_SUCCESS = "CREATE_GROUP_ACTIVITY_SUCCESS";
export const CREATE_GROUP_ACTIVITY_FAIL = "CREATE_GROUP_ACTIVITY_FAIL";
export const DELETE_GROUP_ACTIVITY_SUCCESS = "DELETE_GROUP_ACTIVITY_SUCCESS";
export const DELETE_GROUP_ACTIVITY_FAIL = "DELETE_GROUP_ACTIVITY_FAIL";

// 모둠
export const GET_SUBGROUP_ACTIVITY_SUCCESS = "GET_SUBGROUP_ACTIVITY_SUCCESS";
export const GET_SUBGROUP_ACTIVITY_FAIL = "GET_SUBGROUP_ACTIVITY_FAIL";
export const CREATE_SUBGROUP_ACTIVITY_SUCCESS =
  "CREATE_SUBGROUP_ACTIVITY_SUCCESS";
export const CREATE_SUBGROUP_ACTIVITY_FAIL = "CREATE_SUBGROUP_ACTIVITY_FAIL";
export const UPDATE_SUBGROUP_ACTIVITY_SUCCESS =
  "UPDATE_SUBGROUP_ACTIVITY_SUCCESS";
export const UPDATE_SUBGROUP_ACTIVITY_FAIL = "UPDATE_SUBGROUP_ACTIVITY_FAIL";
export const DELETE_SUBGROUP_ACTIVITY_SUCCESS =
  "DELETE_SUBGROUP_ACTIVITY_SUCCESS";
export const DELETE_SUBGROUP_ACTIVITY_FAIL = "DELETE_SUBGROUP_ACTIVITY_FAIL";

// 모둠 학생
export const GET_SUBGROUP_ACTIVITY_STUDENT_SUCCESS =
  "GET_SUBGROUP_ACTIVITY_STUDENT_SUCCESS";
export const GET_SUBGROUP_ACTIVITY_STUDENT_FAIL =
  "GET_SUBGROUP_ACTIVITY_STUDENT_FAIL";
export const UPDATE_SUBGROUP_ACTIVITY_STUDENT =
  "UPDATE_SUBGROUP_ACTIVITY_STUDENT";
// 로딩
export const GROUP_ACTIVITY_LOADING = "GROUP_ACTIVITY_LOADING";
