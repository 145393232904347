import { call, put, takeEvery, takeLeading } from "redux-saga/effects";
import {
  getManagingPromise,
  getManagingSuccess,
  getManagingFail,
  getManagingsByGroupPromise,
  getManagingsByGroupSuccess,
  getManagingsByGroupFail,
  getActiveManagingPromise,
  getActiveManagingSuccess,
  getActiveManagingFail,
  setActiveManagingExist,
  setActiveManagingNotExist,
  createManagingPromise,
  createManagingSuccess,
  createManagingFail,
  updateManagingPromise,
  updateManagingSuccess,
  updateManagingFail,
  updateManagingLoading,
  deleteManagingPromise,
  deleteManagingSuccess,
  deleteManagingFail
} from "@store/actions";
import * as api from "@api";
import { implementPromiseAction } from "@adobe/redux-saga-promise";

function* createManagingPromiseHandler(action) {
  yield put(updateManagingLoading(true));
  yield call(implementPromiseAction, action, function*() {
    try {
      const response = yield call(api.createManaging, action.payload);
      yield put(createManagingSuccess(response.data));
      return yield response.data;
    } catch (error) {
      yield put(createManagingFail(error));
    } finally {
      yield put(updateManagingLoading(false));
    }
  });
}

function* getManagingPromiseHandler(action) {
  yield call(implementPromiseAction, action, function*() {
    try {
      const response = yield call(api.getManaging, action.payload);
      console.log("res", response.data);
      yield put(getManagingSuccess(response.data));
      return yield response.data;
    } catch (error) {
      yield put(getManagingFail(error));
    }
  });
}

function* getManagingsByGroupPromiseHandler(action) {
  yield call(implementPromiseAction, action, function*() {
    try {
      const response = yield call(api.getManagingsByGroup, action.payload);
      yield put(getManagingsByGroupSuccess(response.data.managings));
      return yield response.data;
    } catch (error) {
      yield put(getManagingsByGroupFail(error));
    }
  });
}

function* getActiveManagingPromiseHandler(action) {
  yield call(implementPromiseAction, action, function*() {
    try {
      const response = yield call(api.getActiveManaging, action.payload);
      if (response.data.managing.state === "IN_MANAGING") {
        yield put(getActiveManagingSuccess(response.data));
      }
      yield put(setActiveManagingExist(response.data));
      return yield response.data;
    } catch (error) {
      yield put(getActiveManagingFail(error));
      yield put(setActiveManagingNotExist());
      return null;
    }
  });
}

function* deleteManagingPromiseHandler(action) {
  yield put(updateManagingLoading(true));
  yield call(implementPromiseAction, action, function*() {
    try {
      const response = yield call(api.deleteManaging, action.payload);
      yield put(deleteManagingSuccess(response.data));
      return yield response.data;
    } catch (error) {
      yield put(deleteManagingFail(error));
    }
    // finally {
    //   yield put(updateManagingLoading(false));
    // }
  });
}

function* updateManagingPromiseHandler(action) {
  yield call(implementPromiseAction, action, function*() {
    try {
      const response = yield call(api.updateManaging, action.payload);
      yield put(updateManagingSuccess(response.data));
      return yield response.data;
    } catch (error) {
      yield put(updateManagingFail(error));
      throw error;
    }
  });
}

function* managingSaga() {
  yield takeEvery(
    getManagingsByGroupPromise,
    getManagingsByGroupPromiseHandler
  );
  yield takeLeading(getManagingPromise, getManagingPromiseHandler);
  yield takeLeading(getActiveManagingPromise, getActiveManagingPromiseHandler);
  yield takeLeading(createManagingPromise, createManagingPromiseHandler);
  yield takeLeading(deleteManagingPromise, deleteManagingPromiseHandler);
  yield takeLeading(updateManagingPromise, updateManagingPromiseHandler);
}

export default managingSaga;
