const ICONS = {
  facebook: require('./facebook.png'),
  instagram: require('./instagram.png'),
  kakaotalk: require('./kakaotalk.png'),
  naverwebtoon: require('./naverwebtoon.png'),
  netflix: require('./netflix.png'),
  youtube: require('./youtube.png'),
  discord: require('./discord.png'),
  vlive: require('./vlive.png'),
  coupang: require('./coupang.png'),
  elevenst: require('./elevenst.png'),
  blackdesertm: require('./blackdesertm.png'),
  naversports: require('./naversports.png'),
  daangn: require('./daangn.png'),
  lezhincomics: require('./lezhincomics.png'),
  roblox: require('./roblox.png'),
  lookpin: require('./lookpin.png'),
  lineagem: require('./lineagem.png'),
  minecraft: require('./minecraft.png'),
  modoomarble: require('./modoomarble.png'),
  musinsa: require('./musinsa.jpg'),
  muorigin3: require('./muorigin3.png'),
  baemin: require('./baemin.png'),
  pubg: require('./pubg.jpg'),
  bunjang: require('./bunjang.png'),
  brawlstars: require('./brawlstars.png'),
  styleshare: require('./styleshare.png'),
  spotvnow: require('./spotvnow.png'),
  serieson: require('./serieson.png'),
  seezn: require('./seezn.png'),
  afreecatv: require('./afreecatv.png'),
  ably: require('./ably.png'),
  yupdduk: require('./yupdduk.png'),
  becomeaking: require('./becomeaking.jpg'),
  yogiyo: require('./yogiyo.png'),
  wavve: require('./wavve.png'),
  zepeto: require('./zepeto.jpg'),
  zigzag: require('./zigzag.png'),
  kakaobank: require('./kakaobank.png'),
  kartriderrushplus: require('./kartriderrushplus.png'),
  ktoon: require('./ktoon.png'),
  comico: require('./comico.png'),
  cookierunovenbreak: require('./cookierunovenbreak.png'),
  cookierunkingdom: require('./cookierunkingdom.png'),
  coupangplay: require('./coupangplay.png'),
  toss: require('./toss.png'),
  twitchtv: require('./twitchtv.png'),
  facebookmessenger: require('./facebookmessenger.png'),
  fgo: require('./fgo.png'),
  fifaonline4m: require('./fifaonline4m.png'),
  hiver: require('./hiver.jpg'),
  coupangeats: require('./coupangeats.webp'),
  goodchoice: require('./goodchoice.png'),
  grilsfrontline: require('./grilsfrontline.webp'),
  lineage2: require('./lineage2.webp'),
  podbbang: require('./podbbang.jpeg'),
  sevenknights: require('./sevenKnights.png'),
  yanolja: require('./yanolja.jpeg'),
  galaxystore: require('./galaxystore.png'),
  googlePlayStore: require('./googlePlayStore.png'),
  samsungCamera: require('./samsungCamera.png'),
  samsungGallery: require('./samsungGallery.png'),
  samsungInternet: require('./samsungInternet.png'),
  samsungVoiceRecorder: require('./samsungVoiceRecorder.png'),
  telegram: require('./telegram.png'),
  onenote: require('./onenote.png'),
  google: require('./google.png'),
  naver: require('./naver.png'),
  daum: require('./daum.png'),
  nate: require('./nate.png'),
  oliveYoung: require('./oliveYoung.png'),
  tikTok: require('./tikTok.png'),
  papago: require('./papago.png'),
  braveBrowser: require('./braveBrowser.png'),
  tistory: require('./tistory.png'),
};

export default ICONS;
