// 함수 설명
// 1. 요일을 받아서 한글로 변환한다.
export function getDayToKorean(day, t) {
  switch (day) {
    case "Mon":
      return t("timetable.text.monday");
    case "Tue":
      return t("timetable.text.tuesday");
    case "Wed":
      return t("timetable.text.wednesday");
    case "Thu":
      return t("timetable.text.thursday");
    case "Fri":
      return t("timetable.text.friday");
    case "Sat":
      return "토";
    case "Sun":
      return "일";
    default:
      return "";
  }
}

// 함수 설명
// 1. 시간표를 받아서 시간표를 요일별로 분류한다.
// 2. 분류된 시간표를 시간 순서대로 정렬한다.
// 3. 분류된 시간표를 반환한다.
export function classifyTimetable(timetables) {
  const classified = {
    Mon: [],
    Tue: [],
    Wed: [],
    Thu: [],
    Fri: [],
  };
  timetables.forEach((timetable) => {
    timetable.timetables.forEach((timetable) => {
      if (timetable === null || timetable === undefined) {
        return "";
      }
      switch (timetable.weekday) {
        case 1:
          classified.Mon.push(timetable);
          break;
        case 2:
          classified.Tue.push(timetable);
          break;
        case 3:
          classified.Wed.push(timetable);
          break;
        case 4:
          classified.Thu.push(timetable);
          break;
        case 5:
          classified.Fri.push(timetable);
          break;
      }
    });
  });

  classified.Mon.sort((a, b) => a.period - b.period);
  classified.Tue.sort((a, b) => a.period - b.period);
  classified.Wed.sort((a, b) => a.period - b.period);
  classified.Thu.sort((a, b) => a.period - b.period);
  classified.Fri.sort((a, b) => a.period - b.period);
  return classified;
}

// timetableList와 groupId를 받아서 timetableList의 timetables만 반환한다.
export function getTimetableByGroupId(timetableList, groupId) {
  return timetableList.find((timetable) => timetable.groupId === groupId)
    ?.timetables;
}
