import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Sidebar from "@components/Layout/Sidebar";
import ClassList from "@components/Layout/ClassList";
import EventPanel from "@components/Layout/EventPanel";
import { setToken, getStorageData, useInterval } from "@api";
import { purgeAll } from "@app/store/actions";
import { useHistory } from "react-router-dom";
import winterIsComming from "assets/images/winterIsComming.png";
import { NotiPanel } from "@app/components/Layout/NotiPanel";
import { VerticalSpace8 } from "../QuizPang/common";
import Header from "@app/components/Layout/Header";
import { Box } from "@mui/material";
import Body from "@app/components/Layout/Body";
import SidebarWithHeader from "@app/components/Layout/Common/Sidebar";

const Home = () => {
  const user = useSelector((state) => state.user);

  useEffect(() => {
    if (user.token !== undefined && user.token !== null) {
      setToken(user.token).then(() => {});
    }
  }, []);

  return (
    <SidebarWithHeader>
      <ClassList />
    </SidebarWithHeader>
  );
};

export default Home;
