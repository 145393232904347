import React, { useMemo } from 'react';
import ProfileImage from '../ProfileImage';
import { useSelector } from 'react-redux';
import { profileImgPath } from '@api';
import styled from 'styled-components';
import { SettingsIcon } from '@chakra-ui/icons';
import { useHistory } from 'react-router-dom';

export const TopUserSectionWrapper = styled.div`
  width: 100%;
  background-color: #f2f2f2;
  padding: 16px;
  display: flex;
  align-items: center;

  //유저 정보 반응형
  @media all and (max-width: 1200px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const ProfileImageWrapper = styled.div`
  padding: 8px;
  display: flex;
`;

export const UserInfoWrapper = styled.div`
  padding: 8px;
  width: 175px;
  display: flex;
  flex-direction: column;

  @media all and (max-width: 1200px) {
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
`;

export const UserName = styled.div`
  font-size: 28px;
  font-weight: 700;
  font-size: 20px;
  letter-spacing: 0.25px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #212121;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const UserMail = styled.div`
  font-size: 12px;
  line-height: 20px;
  color: #666566;
  letter-spacing: 0.4px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  justify-content: start;

  @media all and (max-width: 1200px) {
    width: 95px;
    font-size: 10px;
  } //이메일 반응형
`;

const TopUserSection = () => {
  const user = useSelector((state) => state.user);
  const history = useHistory();

  const myProfileImage = useMemo(() => {
    if (!user) {
      return undefined;
    }
    return profileImgPath(user.clientId);
  }, [user]);

  const subscribeImageChange = useMemo(() => user?.subscribeImageChange, [
    user,
  ]);

  const onPressSettings = () => {
    history.replace('/settings');
    return;
  };

  return (
    <TopUserSectionWrapper>
      <ProfileImageWrapper>
        <ProfileImage
          size={44}
          url={myProfileImage}
          subscribeImageChange={user?.subscribeImageChange}
        />
      </ProfileImageWrapper>
      <UserInfoWrapper>
        <NameBlock>
          <UserName>{user.userName}</UserName>
          <SettingBlock onClick={() => onPressSettings()}>
            <SettingsIcon
              style={{
                color: '#888888',
                width: 15,
                height: 15,
              }}
            />
          </SettingBlock>
        </NameBlock>
        <UserMail>{user?.email}</UserMail>
      </UserInfoWrapper>
    </TopUserSectionWrapper>
  );
};

export default TopUserSection;

const SettingBlock = styled.div`
  border: 1px solid #eeeeee;
  border-radius: 4px;
  padding: 8px;
  width: 25px;
  height: 25px;
  margin-left: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  cursor: pointer;

  @media all and (max-width: 1200px) {
    display: none;
  }
`;
const NameBlock = styled.div`
  display: flex;
  align-items: center;
`;
