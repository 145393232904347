import ClassroomStateChangeButton from "@app/components/Buttons/ClassroomState/ClassroomStateChangeButton";
import { selectActiveClassroom } from "@app/store/selectors";
import {
  Box,
  Center,
  Image,
  Stack,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import TeacherScreenToolbar from "./TeacherScreenToolbar";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import FileLink from "@app/components/Layout/FileLink";
import ClassManagementPannel from "@app/components/Layout/ClassManagementPannel";
import { useScreenShare } from "@app/utils/classroom/useScreenShare";
import ModalTeacherFullScreen from "@app/components/Modal/ModalTeacherFullScreen";
import { CommentsLayer } from "@app/components/CommentsLayer/CommentsLayer";

const TeacherScreen = ({
  selectedStudent,
  setSelectedStudent,
  selectedImage,
  setSelectedImage,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isCurrentlySharing, setIsCurrentlySharing] = useState(false);
  const user = useSelector((state) => state.user);
  const [loading, setLoading] = useState(true);
  const activeClassroom = useSelector((state) => selectActiveClassroom(state));
  const {
    isSharing,
    startSharing,
    stopSharing,
    capturedImage,
  } = useScreenShare(user, activeClassroom, 0.5);
  const { groupId } = useParams();

  return (
    <Stack direction="column" spacing={0} align="center" w="full">
      <VStack spacing={2} w="full">
        <Stack w="full" spacing={0}>
          <Box
            w="full"
            h={{
              sm: 300,
              md: 400,
              lg: 450,
              xl: 500,
            }}
            bg="gray.200"
            justifyContent="center"
            alignItems="center"
            borderWidth={1}
            borderTopRadius="lg"
          >
            {activeClassroom ? (
              <Box
                h={{
                  sm: 300,
                  md: 400,
                  lg: 450,
                  xl: 500,
                }}
                w="full"
                position="relative"
              >
                {activeClassroom && (
                  <CommentsLayer screenImg={capturedImage} isVisible={true} />
                )}
                {activeClassroom &&
                  isSharing &&
                  capturedImage &&
                  !selectedStudent && (
                    <Image
                      boxSize="full"
                      src={capturedImage}
                      alt="Captured Screen"
                      style={{
                        display: loading ? "none" : "block",
                        objectFit: "contain",
                      }}
                      onLoad={() => setLoading(false)}
                    />
                  )}
                {activeClassroom && selectedStudent && selectedImage && (
                  <Image
                    boxSize="full"
                    src={`data:image/webp;base64,${selectedImage}`}
                    alt="Captured Screen"
                    style={{
                      display: loading ? "none" : "block",
                      objectFit: "contain",
                    }}
                    onLoad={() => setLoading(false)}
                  />
                )}
              </Box>
            ) : (
              <VStack mt="20%">
                <Center>
                  <ClassroomStateChangeButton />
                </Center>
              </VStack>
            )}
          </Box>
          <Box
            w={{
              sm: "100%",
              md: "100%",
              lg: "100%",
              xl: "100%",
            }}
          >
            {activeClassroom && (
              <TeacherScreenToolbar
                setLoading={setLoading}
                isSharing={isSharing}
                startSharing={startSharing}
                stopSharing={stopSharing}
                setIsCurrentlySharing={setIsCurrentlySharing}
                isOpen={isOpen}
                onOpen={onOpen}
                onClose={onClose}
                setSelectedStudent={setSelectedStudent}
                selectedStudent={selectedStudent}
                setSelectedImage={setSelectedImage}
                selectedImage={selectedImage}
              />
            )}
          </Box>
        </Stack>
        <Stack w="full" spacing="2">
          <FileLink groupId={groupId} />
          <ClassManagementPannel />
        </Stack>
      </VStack>
      <ModalTeacherFullScreen
        capturedImage={capturedImage}
        loading={loading}
        setLoading={setLoading}
        onClose={onClose}
        isOpen={isOpen}
        activeClassroom={activeClassroom}
        isSharing={isSharing}
        startSharing={startSharing}
        stopSharing={stopSharing}
        setSelectedStudent={setSelectedStudent}
        selectedStudent={selectedStudent}
        setSelectedImage={setSelectedImage}
        selectedImage={selectedImage}
      />
    </Stack>
  );
};

export default TeacherScreen;
