import { useRef } from "react"
import { useState } from "react"

export const useCreateTimetableValidate = () => {
	const [isDisabled, setIsDisabled] = useState(true)
	const [isError, setIsError] = useState({
    subjectName: false,
    groupName: false,
    grade: false,
    week: false,
    period: false,
    startTime: false,
    endTime: false
	})
	const inputRefs = useRef({})
	
	const addInputRef = (name, ref) => {
    if (ref) {
      inputRefs.current[name] = ref
    }
  }
  const validate = (event) => {
    const {name, value } = event.target
    
      const isFilled = Object.keys(inputRefs.current).every((key) => {
        const el = inputRefs.current[key];
        return el && el.value;
      });

    setIsDisabled(!isFilled);
    
    const isEmpty = value === ''
    setIsError({...isError, [name]: isEmpty})

	}
	return {isDisabled, isError, addInputRef, validate, setIsDisabled}
}