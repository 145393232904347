import SensorsIcon from '@mui/icons-material/Sensors';
import { Tooltip } from '@mui/material';
import styled from 'styled-components';

const GradeClassStatusWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 16px 16px 0 16px;
`;

const SensorsIconStyled = styled(SensorsIcon)`
  color: #40c3d1;
  width: 20px;
  height: 20px;
`;

function GradeClassStatus(props) {
  const { grade, groupName, subjectName } = props;

  return (
    <GradeClassStatusWrapper>
      <SensorsIconStyled />
      {`${grade}학년 ${groupName}반 `}
      <Tooltip title={subjectName} arrow>
        <div
          style={{
            maxWidth: '30%',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
          }}
        >
          {`${subjectName}`}{' '}
        </div>
      </Tooltip>
      수업 중
    </GradeClassStatusWrapper>
  );
}

export default GradeClassStatus;
