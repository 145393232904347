import React from "react";
import { Box, Button, Text, VStack } from "@chakra-ui/react";

const QuizGroupCard = ({ testCrewType, children, onAddQuiz, editMode }) => {
  return (
    <Box
      w={"full"}
      h="800"
      overflow={"auto"}
      p={8}
      border="1px"
      borderColor="gray.200"
      borderRadius="lg"
      position="relative"
    >
      <VStack spacing={4}>
        <Text fontSize="xl">{`${testCrewType} 그룹`}</Text>
        {!editMode && (
          <Button w={"30%"} onClick={onAddQuiz}>
            퀴즈 생성하기
          </Button>
        )}
        {children}
      </VStack>
    </Box>
  );
};

export default QuizGroupCard;
