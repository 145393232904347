import React from "react";
import QuizTypeWrapper from "./Common/QuizTypeWrapper";
import {
  Box,
  Heading,
  Button,
  Image,
  Text,
  HStack,
  Input,
  Stack,
  InputGroup,
  InputLeftAddon,
  Select,
} from "@chakra-ui/react";
import { AddIcon, DeleteIcon } from "@chakra-ui/icons";
import { TextEditorForQuizpang } from "../Input/TextEditorForQuizpang";
import imagePlaceholder from "@images/quiz-pang/image-placeholder.png";
import styled from "styled-components";
export const sampleAnswerTypeToText = {
  IMG: "이미지 첨부",
  TXT: "작성답안 첨부",
};
export const sampleAnswerTypes = Object.keys(sampleAnswerTypeToText);

const QuizTypeStylus = ({
  sampleAnswerType,
  setSampleAnswerType,
  sampleAnswer,
  setSampleAnswer,
  sampleAnswerImageFile,
  setSampleAnswerImageFile,
}) => {
  const sampleAnswerImgPath = () => {
    if (sampleAnswer === "NotSet") return null;
    return (
      sampleAnswer ||
      (sampleAnswerImageFile !== null
        ? URL.createObjectURL(sampleAnswerImageFile)
        : null)
    );
  };
  const removeSampleAnswerImageFile = () => {
    setSampleAnswerImageFile(null);
    setSampleAnswer(null);
  };
  return (
    <QuizTypeWrapper>
      <Heading fontSize={"lg"}>3. 모범답안</Heading>
      <Stack direction={"column"}>
        <Select
          width={150}
          value={sampleAnswerType}
          onChange={(e) => {
            removeSampleAnswerImageFile();
            setSampleAnswerType(e.target.value);
          }}
        >
          {sampleAnswerTypes?.map((x) => (
            <option key={x} value={x}>
              {sampleAnswerTypeToText[x]}
            </option>
          ))}
        </Select>
        {sampleAnswerType === "IMG" && (
          <Stack
            direction={"column"}
            style={{
              border: "1px solid #e0e0e0",
              borderRadius: 4,
              height: 260,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {!!sampleAnswerImgPath() ? ( //이미지가 올라가있을때
              <ImageContainer
                style={{ width: "unset", background: "transparent" }}
              >
                <Image
                  src={sampleAnswerImgPath()}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                  }}
                />
                <ImageOverlayContainer>
                  <Button
                    variant="contained"
                    startIcon={<DeleteIcon sx={{ color: "#FF5252" }} />}
                    style={{ color: "#FF5252", background: "#fff" }}
                    onClick={() => removeSampleAnswerImageFile()}
                  >
                    이미지 삭제
                  </Button>
                </ImageOverlayContainer>
              </ImageContainer>
            ) : (
              //이미지가 아직 안올라갔을때
              <ImagePlaceholder
                htmlFor="quizSampleAnswerImagePicker"
                style={{ width: "100%", height: "100%" }}
              >
                <input
                  id="quizSampleAnswerImagePicker"
                  accept="image/*"
                  type="file"
                  style={{ display: "none" }}
                  onChange={(e) => {
                    setSampleAnswerImageFile(e.target.files[0]);
                  }}
                />
                <Stack direction={"column"} style={{ alignItems: "center" }}>
                  <ButtonBox style={{ border: "none", marginRight: 0 }}>
                    <img
                      src={imagePlaceholder}
                      style={{ width: 20, height: 16 }}
                      alt="image_input"
                    />
                  </ButtonBox>
                  <VerticalSpace8 />
                  <Body2 style={{ color: "rgba(0, 0, 0, 0.37)" }}>
                    답안 이미지를 추가할 수 있습니다.
                  </Body2>
                </Stack>
              </ImagePlaceholder>
            )}
          </Stack>
        )}
        {sampleAnswerType === "TXT" && (
          <TextEditorForQuizpang
            value={sampleAnswer}
            onChange={(sampleAnswer) => setSampleAnswer(sampleAnswer)}
            isFile={false}
          />
        )}
      </Stack>
    </QuizTypeWrapper>
  );
};

//codes from pages/QuizPang/common.js
export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
export const BaseText = styled.span`
  font-family: "Noto Sans KR", "Roboto", sans-serif;
  letter-spacing: 0.25px;
  color: rgba(0, 0, 0, 0.87);
`;

export const Body2 = styled(BaseText)`
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.25px;
  color: rgba(0, 0, 0, 0.6);
`;
export const ImageContainer = styled(Column)`
  background: #f5f5f5;
  border-radius: 8px;
  width: 480px;
  position: relative;
  overflow: hidden;
`;
export const ButtonBox = styled(Column)`
  width: 100px;
  height: 100px;
  background: var(--grey-lighten-4);
  border: 1px dashed var(--grey-darken-1);
  border-radius: 6px;
  align-items: center;
  justify-content: center;
  margin-right: 30px;
  gap: 10px;
  cursor: pointer;
`;
export const ImageOverlayContainer = styled(Row)`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 16px;
  align-items: flex-end;
  justify-content: flex-end;
`;
export const VerticalSpace8 = styled.div`
  height: 8px;
`;
export const ImagePlaceholder = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;

  :hover {
    cursor: pointer;
  }
`;

export default QuizTypeStylus;
