import { authProtectedRoutes } from "@routes";
import React, { Suspense, useEffect, useState } from "react";
import { Provider } from "react-redux";
import { persistStore } from "redux-persist";
import { store } from "@store/index";
import { Switch, useHistory } from "react-router-dom";
import AppRoute from "@routes/route";
import { PersistGate } from "redux-persist/integration/react";
import { THEME_ID, createTheme } from "@mui/material/styles";
import MasterManager from "./managers";
import "./common.scss";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { Spin } from "antd";
import SidebarSkeleton from "./components/Layout/Sidebar/SidebarSkeleton";
import {
  ChakraProvider,
  Container,
  Spinner,
  VStack,
  extendTheme,
} from "@chakra-ui/react";
import * as Sentry from "@sentry/react";

const muiTheme = createTheme({
  palette: {
    primary: {
      main: "#13305a",
    },
    secondary: {
      main: "#0D213F",
      contrastText: "#eeeeee",
    },
    white: {
      main: "#eeeeee",
    },
    _red: {
      main: "#FF5252",
    },
    _green: {
      main: "#4CAF50",
    },
    _orange: {
      main: "#4CAF50",
    },
    _blue: {
      main: "var(--primary)",
    },
  },
  typography: {
    fontFamily: "'Noto Sans KR', sans-serif",
  },
  // 버튼 아웃라인 스타일
  components: {
    MuiButton: {
      styleOverrides: {
        outlined: {
          fontWeight: "normal",
          "&:hover": {},
        },
        root: {
          background: "#fff",
          color: "#000000",
          borderRadius: "12px",
          border: "1px solid rgb(0, 0, 0, 5%)",
          boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.3)",

          "&.Mui-disabled": {
            // backgroundColor: '#6F8099',
            // color: '#eeeeee',
          },
          "&:hover": {
            background: "#eee",
          },
          "&:hover": {
            background: "#eee",
          },
        },
      },
    },
  },
});
const theme = extendTheme();

const App = (props) => {
  const [loading, setLoading] = useState(true);

  const history = useHistory();
  function getOS() {
    var userAgent = window.navigator.userAgent,
      platform =
        window.navigator?.userAgentData?.platform ?? window.navigator.platform,
      macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"],
      windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"],
      iosPlatforms = ["iPhone", "iPad", "iPod"],
      os = null;

    if (macosPlatforms.indexOf(platform) !== -1) {
      os = "Mac OS";
    } else if (iosPlatforms.indexOf(platform) !== -1) {
      os = "iOS";
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
      os = "Windows";
    } else if (/Android/.test(userAgent)) {
      os = "Android";
    } else if (!os && /Linux/.test(platform)) {
      os = "Linux";
    }

    return os;
  }

  const persistor = persistStore(
    store,
    {}
    // refreshToken
  );
  var isIOSChrome = window.navigator.userAgent.match("CriOS");
  var isChromium = window.chrome;
  var vendorName = window.navigator.vendor;
  var isOpera = typeof window.opr !== "undefined";
  var isIEedge = window.navigator.userAgent.indexOf("Edg") > -1;
  useEffect(() => {
    const presentOs = getOS();
    if (isIOSChrome) {
      alert("IOS와 크롬브라우저로 해당서비스를 이용할 수 없습니다.");
      history.replace("/");
    } else if (
      isChromium !== null &&
      typeof isChromium !== "undefined" &&
      vendorName === "Google Inc." &&
      isOpera === false &&
      isIEedge === false
    ) {
      if (presentOs === "Android") {
        alert("안드로이드와 크롬브라우저로 해당서비스를 이용할 수 없습니다.");
        history.replace("/");
      }
    }
  }, []);

  // Sentry.init({
  //   dsn:
  //     "https://1d16047c1249c47c3601ea5e496007c8@o433400.ingest.sentry.io/4506262211067904",
  //   integrations: [
  //     new Sentry.BrowserTracing({
  //       // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  //       tracePropagationTargets: [
  //         "localhost",
  //         /^https:\/\/yourserver\.io\/api/,
  //       ],
  //     }),
  //     new Sentry.Replay(),
  //   ],
  //   // Performance Monitoring
  //   tracesSampleRate: 1.0, // Capture 100% of the transactions
  //   // Session Replay
  //   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  //   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  //   enabled:
  //     process.env.REACT_APP_STATIC_ADDR === "https://ktor.focuspang.com/v3",
  // });

  return (
    <Provider store={store}>
      <ChakraProvider theme={{ ...theme, [THEME_ID]: muiTheme }} resetCSS>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <PersistGate persistor={persistor}>
            <Suspense
              fallback={
                <Container
                  maxW="container.xl"
                  alignItems="center"
                  justifyContent="center"
                  display="flex"
                  height="100vh"
                >
                  <Spinner
                    thickness="4px"
                    speed="0.65s"
                    emptyColor="gray.200"
                    color="blue.500"
                    size="xl"
                  />
                </Container>
              }
            >
              <Switch>
                {authProtectedRoutes.map((route, idx) => {
                  return (
                    <AppRoute
                      path={route.path}
                      component={route.component}
                      key={idx}
                      isAuthProtected={true}
                      exact
                    />
                  );
                })}
              </Switch>
            </Suspense>
            <MasterManager />
          </PersistGate>
        </LocalizationProvider>
      </ChakraProvider>
    </Provider>
  );
};

export default App;
