import {
  deleteBackend,
  getBackend,
  patchBackend,
  postBackend,
} from "../Backend";

// 퀴즈셋 생성 for a-b test
export const createQuizsetForTest = async (quizsetData) => {
  return await postBackend(`/quizset`, quizsetData);
};

// 퀴즈셋 중간 결과 조회 for a-b test
export const getQuizsetSessionForTest = async (quizsetSessionId, quizOrder) => {
  return await getBackend(
    `ab-labs/quizsetSession/result?quizsetSessionId=${quizsetSessionId}&quizOrder=${quizOrder}`
  );
};

export const getQuizsetListByClient = async (data) => {
  const { clientId, offset, type, orderType, orderBy, limit } = data;
  return await getBackend(
    `quizset?clientId=${clientId}&quizsetType=${type}&offset=${offset}&limit=${limit}&orderType=${orderType}&orderBy=${orderBy}`
  );
};

export const deleteQuizset = async (quizsetId) => {
  return await deleteBackend(`quizset/${quizsetId}`);
};
// quizsetId로 퀴즈셋 조회
export const getQuizsetByQuizsetId = async (quizsetId) => {
  return await getBackend(`quizset?quizsetId=${quizsetId}`);
};

// quiz 생성
export const createQuiz = async (quizData) => {
  return await postBackend(`quiz`, quizData);
};
//quiz를 patch
export const patchQuiz = async (quizData) => {
  return await patchBackend(`quiz`, quizData);
};

// 퀴즈셋 patch for a-b test
export const patchQuizForTest = async (quizsetData) => {
  return await patchBackend(`ab-labs/quiz`, quizsetData);
};

// 퀴즈셋 patch
export const patchQuizset = async (quizsetData) => {
  return await patchBackend("quizset", quizsetData);
};

// 퀴즈셋 patch for a-b test
export const patchQuizsetForTest = async (quizsetData) => {
  return await patchBackend("ab-labs/quizset", quizsetData);
};

// 퀴즈를 조회
export const getQuizByQuizId = async (quizId) => {
  return await getBackend(`quiz?quizId=${quizId}`);
};

// 퀴즈셋 세션 학생 그룹 추가
export const addGroupStudents = async (groupData) => {
  return await postBackend(`ab-labs/test-crews`, groupData);
};
//퀴즈삭제
export const deleteQuiz = async (quizId) => {
  return await deleteBackend(`quiz/${quizId}`);
};

// 충동성에 따른 학생 명단 조회
export const getimpulsivityStudentListByGroupId = async (groupId) => {
  return await getBackend(`ab-labs/test-crews/impulsivity?groupId=${groupId}`);
};
