import { call, put, takeLeading } from "redux-saga/effects";
import {
  getManagedPromise,
  getManagedSuccess,
  getManagedFail,
  getManagedsByClientPromise,
  getManagedsByClientSuccess,
  getManagedsByClientFail,
  getManagedsByManagingPromise,
  getManagedsByManagingSuccess,
  getManagedsByManagingFail,
  createManagedPromise,
  createManagedSuccess,
  createManagedFail,
  updateManagedPromise,
  updateManagedSuccess,
  updateManagedFail,
  deleteManagedPromise,
  deleteManagedSuccess,
  deleteManagedFail
} from "@store/actions";
import * as api from "@api";
import { implementPromiseAction } from "@adobe/redux-saga-promise";
import {
  getManagedsByGroupFail,
  getManagedsByGroupPromise,
  getManagedsByGroupSuccess
} from "./actions";

function* getManagedPromiseHandler(action) {
  yield call(implementPromiseAction, action, function*() {
    try {
      const params = action.payload;
      const response = yield call(api.getManaged, params);
      yield put(getManagedSuccess(response.data));
      return yield response.data;
    } catch (error) {
      yield put(getManagedFail(error));
    }
  });
}

function* getManagedsByClientPromiseHandler(action) {
  yield call(implementPromiseAction, action, function*() {
    try {
      const response = yield call(api.getManagedsByClient, action.payload);
      yield put(getManagedsByClientSuccess(response.data.managingManageds));
      return yield response.data;
    } catch (error) {
      yield put(getManagedsByClientFail(error));
    }
  });
}

function* getManagedsByManagingPromiseHandler(action) {
  yield call(implementPromiseAction, action, function*() {
    try {
      const response = yield call(api.getManagedsByManaging, action.payload);
      yield put(getManagedsByManagingSuccess(response.data));
      return yield response.data;
    } catch (error) {
      yield put(getManagedsByManagingFail(error));
    }
  });
}

function* getManagedsByGroupPromiseHandler(action) {
  yield call(implementPromiseAction, action, function*() {
    try {
      const response = yield call(api.getManagedsByGroup, action.payload);
      yield put(getManagedsByGroupSuccess(response.data));
    } catch (error) {
      yield put(getManagedsByGroupFail(error));
    }
  });
}

function* createManagedPromiseHandler(action) {
  yield call(implementPromiseAction, action, function*() {
    try {
      const params = action.payload;
      const response = yield call(api.createManaged, params);
      yield put(createManagedSuccess(response.data));
      return yield response.data;
    } catch (error) {
      yield put(createManagedFail(error));
    }
  });
}

function* updateManagedPromiseHandler(action) {
  yield call(implementPromiseAction, action, function*() {
    try {
      const params = action.payload;
      const response = yield call(api.updateManaged, params);
      yield put(updateManagedSuccess(response.data));
      return yield response.data;
    } catch (error) {
      yield put(updateManagedFail(error));
    }
  });
}

function* deleteManagedPromiseHandler(action) {
  yield call(implementPromiseAction, action, function*() {
    try {
      const params = action.payload;
      const response = yield call(api.deleteManaged, params);
      yield put(deleteManagedSuccess(response.data));
      return yield response.data;
    } catch (error) {
      yield put(deleteManagedFail(error));
    }
  });
}

function* managedSaga() {
  yield takeLeading(getManagedPromise, getManagedPromiseHandler);
  yield takeLeading(
    getManagedsByClientPromise,
    getManagedsByClientPromiseHandler
  );
  yield takeLeading(
    getManagedsByManagingPromise,
    getManagedsByManagingPromiseHandler
  );
  yield takeLeading(
    getManagedsByGroupPromise,
    getManagedsByGroupPromiseHandler
  );
  yield takeLeading(createManagedPromise, createManagedPromiseHandler);
  yield takeLeading(updateManagedPromise, updateManagedPromiseHandler);
  yield takeLeading(deleteManagedPromise, deleteManagedPromiseHandler);
}

export default managedSaga;
