import {
  CREATE_QUIZSET_SESSION_BY_QUIZ_FAIL,
  CREATE_QUIZSET_SESSION_BY_QUIZ_SUCCESS,
  CREATE_QUIZSET_SESSION_FAIL,
  CREATE_QUIZSET_SESSION_SUCCESS,
  GET_QUIZSET_SESSION_HISTORY_FAIL,
  GET_QUIZSET_SESSION_HISTORY_SUCCESS,
  GET_QUIZSET_SESSION_RESULT_FAIL,
  GET_QUIZSET_SESSION_RESULT_SUCCESS,
  GET_READY_STUDENT_LIST_FAIL,
  GET_READY_STUDENT_LIST_SUCCESS,
  GET_ANSWERED_STUDENT_LIST_FAIL,
  GET_ANSWERED_STUDENT_LIST_SUCCESS,
  PURGE_QUIZSET_SESSION,
  SET_QUIZSET_STUDENT_STATUS,
  PURGE_READY_STUDENT_LIST,
  PURGE_ANSWERED_STUDENT_LIST,
  RESET_QUIZSET_STUDENT_STATUS,
} from "./actionTypes";

const initialState = {
  histories: [],
  sessionsById: {},
  resultsById: {},
  studentStatus: {},
  readyStudentList: [],
  answeredStudentList: [],
};

const quizsetSession = (state = initialState, action) => {
  switch (action.type) {
    case PURGE_QUIZSET_SESSION:
      return initialState;
    case PURGE_READY_STUDENT_LIST:
      return {
        ...state,
        readyStudentList: [],
      };
    case PURGE_ANSWERED_STUDENT_LIST:
      return {
        ...state,
        answeredStudentList: [],
      };
    case GET_QUIZSET_SESSION_HISTORY_SUCCESS:
      return {
        ...state,
        histories: action.data,
      };

    case CREATE_QUIZSET_SESSION_SUCCESS:
    case CREATE_QUIZSET_SESSION_BY_QUIZ_SUCCESS:
      return {
        ...state,
        sessionsById: {
          ...state.sessionsById,
          [action?.data?.quizsetSession?.quizsetSessionId]: action?.data,
        },
      };

    case GET_QUIZSET_SESSION_RESULT_SUCCESS:
      return {
        ...state,
        resultsById: {
          ...state.resultsById,
          [action.data.quizsetSessionId]: action.data.result,
        },
      };

    case SET_QUIZSET_STUDENT_STATUS:
      return {
        ...state,
        studentStatus: {
          ...state.studentStatus,
          [action.clientId]: action.payload,
        },
      };
    case RESET_QUIZSET_STUDENT_STATUS:
      return {
        ...state,
        studentStatus: {},
      };
    case GET_READY_STUDENT_LIST_SUCCESS:
      return {
        ...state,
        readyStudentList: [
          ...new Set([...state.readyStudentList, action.clientId]),
        ],
      };
    case GET_ANSWERED_STUDENT_LIST_SUCCESS:
      return {
        ...state,
        answeredStudentList: [
          ...new Set([...state.answeredStudentList, action.clientId]),
        ],
      };
    case GET_QUIZSET_SESSION_HISTORY_FAIL:
    case CREATE_QUIZSET_SESSION_FAIL:
    case CREATE_QUIZSET_SESSION_BY_QUIZ_FAIL:
    case GET_QUIZSET_SESSION_RESULT_FAIL:
    case GET_READY_STUDENT_LIST_FAIL:
    case GET_ANSWERED_STUDENT_LIST_FAIL:
      // console.error('[QuizsetSession Reducer] ', action.payload);
      return state;

    default:
      break;
  }
  return state;
};

export default quizsetSession;
