export const RECEIVE_SHARING_SCREEN = "RECEIVE_SHARING_SCREEN";

export const SHARING_SCREEN = "SAHRING_SCREEN";

export const RESPONSE_SCREEN_SHARE_ON = "RESPONSE_SCREEN_SHARE_ON";
export const RESPONSE_SCREEN_SHARE_OFF = "RESPONSE_SCREEN_SHARE_OFF";

export const CLICK_SCREEN_SHARE_ON = "CLICK_SCREEN_SHARE_ON";
export const CLICK_SCREEN_SHARE_OFF = "CLICK_SCREEN_SHARE_OFF";

export const ERROR_SCREEN_SHARE_ON = "ERROR_SCREEN_SHARE_ON";
export const ERROR_SCREEN_SHARE_OFF = "ERROR_SCREEN_SHARE_OFF";
