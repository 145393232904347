import {
  PURGE_ALL,
  PURGE_USER,
  RESTORE_USER,
  SIGN_IN_FAIL,
  SIGN_IN_SUCCESS,
  SIGN_OUT,
  UPDATE_USER_FAIL,
  UPDATE_USER_PROFILE,
  UPDATE_USER_SUCCESS
} from "./actionTypes";
import * as api from "@api";
import { createPromiseAction } from "@adobe/redux-saga-promise";

export const purgeAll = () => ({
  type: PURGE_ALL
});

///// PURGE_USER
export const purgeUser = () => ({
  type: PURGE_USER
});

///// SIGN_IN
export const signInPromise = createPromiseAction("SIGN_IN_PROMISE");

export const signInSuccess = user => {
  return {
    type: SIGN_IN_SUCCESS,
    profile: {
      ...user,
      signedIn: true,
      updated: true
    }
  };
};

export const signInFail = error => ({
  type: SIGN_IN_FAIL,
  error: error
});

///// SIGN_OUT

export const signOut = () => ({
  type: SIGN_OUT
});

///// RESTORE_USER

export const restoreUser = profileData => ({
  type: RESTORE_USER,
  profile: { ...profileData, signedIn: true }
});

/// UPDATE_USER
export const updateUserProfile = () => ({
  type: UPDATE_USER_PROFILE
});
export const updateUserPromise = createPromiseAction("UPDATE_USER_PROMISE");
export const updateUserSuccess = user => {
  return {
    type: UPDATE_USER_SUCCESS,
    profile: {
      ...user,
      signedIn: true
    }
  };
};

export const updateUserFail = error => ({
  type: UPDATE_USER_FAIL,
  payload: error
});
