import { Heading, Stack } from "@chakra-ui/react";
import React from "react";
import { TextEditorForQuizpang } from "../Input/TextEditorForQuizpang";

const QuizExplanation = ({ quizExplanationValue, onChangeQuizExplanation }) => {
  const handleTextChange = (newValue) => {
    onChangeQuizExplanation(newValue);
  };

  return (
    <Stack direction="column" spacing={4} w={"full"}>
      <Heading fontSize={"lg"}>5. 문제 해설 (선택)</Heading>
      <TextEditorForQuizpang
        style={{ height: "150px", overflow: "hidden" }}
        isFile={false}
        onChange={handleTextChange}
        value={quizExplanationValue}
      />
    </Stack>
  );
};

export default QuizExplanation;
