import { createSelector } from "reselect";

export const selectClassroom = state => {
  return state.classrooms[state.classrooms.length - 1];
};

export const selectActiveClassroom = createSelector(
  state => state.classrooms.classSession,
  classSession => {
    return classSession;
  }
);

export const selectIsClassSession = state => {
  return state.classrooms.isClassSession;
};

export const selectClassroomGroupId = createSelector(
  state => state.classrooms.groupId,
  groupId => {
    return groupId;
  }
);
