import React from "react";
import QuizTypeMultiple from "./QuizTypeMultiple";
import QuizTypeTrueFalse from "./QuizTypeTrueFalse";
import QuizTypeShortInput from "./QuizTypeShortInput";
import QuizTypeStylus from "./QuizTypeStylus";
import QuizTypeLongInput from "./QuizTypeLongInput";
const QuizTypeEditor = ({
  choices,
  setChoices,
  selectedQuizType,
  setSampleAnswerType,
  sampleAnswerType,
  setSampleAnswer,
  sampleAnswer,
  sampleAnswerImageFile,
  setSampleAnswerImageFile,
}) => {
  return {
    MULTIPLE: <QuizTypeMultiple choices={choices} setChoices={setChoices} />,
    SHORT: <QuizTypeShortInput choices={choices} setChoices={setChoices} />,
    OX: <QuizTypeTrueFalse choices={choices} setChoices={setChoices} />,
    STYLUS: (
      <QuizTypeStylus
        sampleAnswer={sampleAnswer}
        setSampleAnswer={setSampleAnswer}
        sampleAnswerType={sampleAnswerType}
        setSampleAnswerType={setSampleAnswerType}
        sampleAnswerImageFile={sampleAnswerImageFile}
        setSampleAnswerImageFile={setSampleAnswerImageFile}
      />
    ),
    LONG: (
      <QuizTypeLongInput
        sampleAnswer={sampleAnswer}
        setSampleAnswer={setSampleAnswer}
      />
    ),
  }[selectedQuizType];
};

export default QuizTypeEditor;
