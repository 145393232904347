import { PURGE_REPORT_EVENTS, SET_REPORT_EVENT_PROMISE } from "./actionTypes";

const initialState = {
  reports: []
};

const reportEvents = (state = initialState, action) => {
  switch (action.type) {
    case PURGE_REPORT_EVENTS:
      return {
        ...state,
        reports: []
      };

    case SET_REPORT_EVENT_PROMISE:
      return {
        ...state,
        reports: [...state.reports, ...action.reports]
      };

    default:
      break;
  }
  return state;
};

export default reportEvents;
