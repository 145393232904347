import { makeFreshAllIds } from "../helpers";
import {
  PURGE_REPORT,
  GET_REPORT_SUCCESS,
  GET_REPORT_FAIL,
  GET_REPORTS_LIST_BY_CLIENT_SUCCESS,
  GET_REPORTS_LIST_BY_CLIENT_FAIL,
  GET_REPORTS_LIST_BY_GROUP_SUCCESS,
  GET_REPORTS_LIST_BY_GROUP_FAIL,
} from "./actionTypes";

const initialState = {
  byId: {},
  allIds: [],
};

const reports = (state = initialState, action) => {
  switch (action.type) {
    case PURGE_REPORT:
      return initialState;

    // GET REPORT
    case GET_REPORT_SUCCESS:
      return {
        ...state,
        byId: { ...state.byId, [action.report.reportId]: action.report },
        allIds: makeFreshAllIds(state.allIds, [action.report.reportId]),
      };
    // GET REPORTS
    case GET_REPORTS_LIST_BY_CLIENT_SUCCESS:
    case GET_REPORTS_LIST_BY_GROUP_SUCCESS:
      return {
        ...state,
        byId: { ...state.byId, ...action.byId },
        allIds: makeFreshAllIds(state.allIds, action.allIds),
      };

    case GET_REPORT_FAIL:
    case GET_REPORTS_LIST_BY_CLIENT_FAIL:
    case GET_REPORTS_LIST_BY_GROUP_FAIL:
      // console.error("[Report reducer]", action.payload);
      return state;

    default:
      break;
  }
  return state;
};

export default reports;
