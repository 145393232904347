import { multipleTypes, MULTIPLE_TYPE } from "@app/Constants/surveyType";
import { Select } from "@chakra-ui/react";
import MultipleType from "./MultipleType";

const MultpleTypeSelect = ({
  value,
  handleMultipleTypeChange,
  choices,
  handleChoicesAddClick,
  handleChoicesDeleteClick,
  handlChoiceDescriptionChange,
  handleEmptyChoices,
  radioSetChoices,
}) => {
 
  const multipleTypeChagne = (event) => {
    const {value} = event.target
    handleMultipleTypeChange(event);
    handleEmptyChoices(); 
    if(value === "RADIO"){
      radioSetChoices()
    }
  };
  return (
    <>
      선다형 유형 선택
      <Select
        placeholder="선다형 유형을 선택하세요"
        width={219}
        name="multipleType"
        value={value}
        onChange={multipleTypeChagne}
      >
        {multipleTypes.map((multipleType) => (
          <option key={multipleType} value={multipleType}>
            {MULTIPLE_TYPE[multipleType]}
          </option>
        ))}
      </Select>
      <MultipleType
        value={value}
        choices={choices}
        handleChoicesAddClick={handleChoicesAddClick}
        handleChoicesDeleteClick={handleChoicesDeleteClick}
        handlChoiceDescriptionChange={handlChoiceDescriptionChange}
      />
    </>
  );
};

export default MultpleTypeSelect;
