import React from "react";
import { VStack, Button, Icon, Text } from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";

const QuizsetGroupCreationCard = ({ onAddGroup }) => {
  return (
    <Button
      w={"full"}
      h="800"
      overflow={"auto"}
      p={16}
      border="1px"
      borderColor="gray.200"
      borderRadius="lg"
      position="relative"
      onClick={onAddGroup}
    >
      <VStack spacing={3}>
        <Text fontSize="2xl">그룹 생성하기</Text>
        <Icon as={AddIcon} w={20} h={20} m={5} />
      </VStack>
    </Button>
  );
};

export default QuizsetGroupCreationCard;
