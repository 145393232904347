export const PURGE_REPORT = "PURGE_REPORT";

export const GET_REPORT = "GET_REPORT";
export const GET_REPORT_SUCCESS = "GET_REPORT_SUCCESS";
export const GET_REPORT_FAIL = "GET_REPORT_FAIL";

export const GET_REPORTS_LIST_BY_GROUP = "GET_REPORT_LIST_BY_GROUP";
export const GET_REPORTS_LIST_BY_GROUP_SUCCESS =
  "GET_REPORT_LIST_BY_GROUP_SUCCESS";
export const GET_REPORTS_LIST_BY_GROUP_FAIL = "GET_REPORT_LIST_BY_GROUP_FAIL";

export const GET_REPORTS_LIST_BY_CLIENT = "GET_REPORTS_LIST_BY_CLIENT";
export const GET_REPORTS_LIST_BY_CLIENT_SUCCESS =
  "GET_REPORTS_LIST_BY_CLIENT_SUCCESS";
export const GET_REPORTS_LIST_BY_CLIENT_FAIL =
  "GET_REPORTS_LIST_BY_CLIENT_FAIL";
