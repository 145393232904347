import { Box, FormControl, FormLabel, Input } from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { usePatchQuizset } from "@app/api/quiz/hooks/useQuizset";

const QuizsetEditTitle = ({ setHeader, quizsetData }) => {
  const [title, setTitle] = useState(quizsetData ? quizsetData.title : "");

  const { mutateAsync: patchQuizset } = usePatchQuizset();

  useEffect(() => {
    if (quizsetData && quizsetData.title) {
      setTitle(quizsetData.title);
    } else {
      setTitle("");
    }

    return () => {
      setTitle("");
      setHeader("");
    };
  }, [quizsetData, setHeader]);

  const handleChange = (e) => {
    const newTitle = e.target.value;
    setTitle(newTitle);
    setHeader(newTitle);
  };

  const handleBlur = async () => {
    if (!title.trim()) return;

    const quizzesArray = JSON.parse(quizsetData.quizzes);

    const updatedQuizset = {
      quizsetId: quizsetData.quizsetId,
      state: quizsetData.state,
      grade: quizsetData.grade,
      title: title,
      isAutoPassed: quizsetData.isAutoPassed,
      isShowRank: quizsetData.isShowRank,
      isRandomOrder: quizsetData.isRandomOrder,
      subject: quizsetData.subject,
      keyword: quizsetData.keyword,
      quizsetType: quizsetData.quizsetType,
      description: quizsetData.description,
      quizzes: JSON.stringify(
        quizzesArray.map((quiz) => ({ quizId: quiz.quizId }))
      ),
    };

    try {
      await patchQuizset(updatedQuizset);
    } catch (error) {
      console.error("Quizset title update failed:", error);
    }
  };

  return (
    <Box>
      <FormControl w={"full"}>
        <FormLabel>퀴즈 제목</FormLabel>
        <Input
          type="text"
          placeholder="퀴즈 제목을 입력하세요. (최대 30자)"
          value={title}
          onChange={handleChange}
          onBlur={handleBlur}
          maxLength={30}
        />
      </FormControl>
    </Box>
  );
};

export default QuizsetEditTitle;
