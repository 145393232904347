import { deleteBackend, getBackend, postBackend } from "../Backend";

export const getFilelinkByGroupId = async (groupId) => {
  return await getBackend(`filelink?groupId=${groupId}`);
};

export const createFilelink = async (filelinkData) => {
  return await postBackend(`filelink`, filelinkData);
};

export const deleteFilelink = async (filelinkId) => {
  return await deleteBackend(`filelink/${filelinkId}`);
};
