import QuizQusetionDelete from "@app/components/Quiz/Common/QuizQusetionDelete";
import { Input, InputGroup } from "@chakra-ui/react";
import React from "react";

const Chose = ({
  value,
  index,
  handlChoiceDescriptionChange,
  handleChoicesDeleteClick,
}) => {
  return (
    <>
      <InputGroup>
        <Input
          placeholder="선택형 보기입력"
          value={value}
          onChange={(event) => handlChoiceDescriptionChange(event, index)}
        />
      </InputGroup>
      <QuizQusetionDelete
        deleteHandler={handleChoicesDeleteClick}
        index={index}
      />
    </>
  );
};

export default Chose;
