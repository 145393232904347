import { makeFreshAllIds } from "../helpers";
import {
  PURGE_MEMBERSHIP,
  GET_MEMBERSHIP_SUCCESS,
  GET_MEMBERSHIP_FAIL,
  GET_MEMBERSHIPS_BY_CLIENT_SUCCESS,
  GET_MEMBERSHIPS_BY_CLIENT_FAIL,
  GET_MEMBERSHIPS_BY_GROUP_SUCCESS,
  GET_MEMBERSHIPS_BY_GROUP_FAIL,
  CREATE_MEMBERSHIP_SUCCESS,
  CREATE_MEMBERSHIP_FAIL,
  DELETE_MEMBERSHIP_SUCCESS,
  DELETE_MEMBERSHIP_FAIL,
} from "./actionTypes";

const initialState = {
  byId: {},
  allIds: [],
};

const membership = (state = initialState, action) => {
  switch (action.type) {
    case PURGE_MEMBERSHIP:
      console.log("[Membership Reducer]", "purge membership");
      return initialState;
    case GET_MEMBERSHIP_SUCCESS:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.filelink.filelinkId]: action.membership,
        },
        allIds: makeFreshAllIds(state.allIds, [
          `${membership.clientId},${membership.groupId}`,
        ]),
      };
    case GET_MEMBERSHIPS_BY_CLIENT_SUCCESS:
      return {
        ...state,
        byId: { ...state.byId, ...action.byId },
        allIds: makeFreshAllIds(state.allIds, action.allIds),
      };

    case GET_MEMBERSHIPS_BY_GROUP_SUCCESS:
      return {
        ...state,
        byId: { ...state.byId, ...action.byId },
        allIds: makeFreshAllIds(state.allIds, action.allIds),
      };

    case CREATE_MEMBERSHIP_SUCCESS:
      return {
        ...state,
        membership: { ...state.byId, ...action.membershipData.membership },
      };

    case DELETE_MEMBERSHIP_SUCCESS:
      const newAllIds = [
        ...state.allIds.filter((id) => id !== action.membershipData),
      ];
      const newById = { ...state.byId };

      if (action.membershipData in newById) {
        delete newById[action.membershipData];
      }
      return {
        ...state,
        allIds: newAllIds,
        byId: newById,
      };

    // todo: handle errors
    case DELETE_MEMBERSHIP_FAIL:
    case GET_MEMBERSHIP_FAIL:
    case GET_MEMBERSHIPS_BY_CLIENT_FAIL:
    case GET_MEMBERSHIPS_BY_GROUP_FAIL:
    case CREATE_MEMBERSHIP_FAIL:
      // console.error("[Membership Reducer]", action.payload);
      return state;
    default:
      break;
  }
  return state;
};

export default membership;
