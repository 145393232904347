export const PURGE_FILELINK = "PURGE_FILELINK";

export const GET_GROUP_FILELINK_SUCCESS = "GET_GROUP_FILELINK_SUCCESS";
export const GET_GROUP_FILELINK_FAIL = "GET_GROUP_FILELINK_FAIL";

export const CREATE_FILELINK_SUCCESS = "CREATE_FILELINK_SUCCESS";
export const CREATE_FILELINK_FAIL = "CREATE_FILELINK_FAIL";

export const DELETE_FILELINK_SUCCESS = "DELETE_FILELINK_SUCCESS";
export const DELETE_FILELINK_FAIL = "DELETE_FILELINK_FAIL";
