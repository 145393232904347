import {
  createSystemNotification,
  createSystemNotificationByGroup,
  getSystemNotification,
  postSystemNotificationError,
  updateSystemNotification,
} from "@api";
import {
  getReadyStudentListSuccess,
  getAnsweredStudentListSuccess,
  addStudentFocusStatusList,
  removeStudentFocusStatusList,
  getParticipationsByClassroomPromise,
  setSocketData,
} from "@app/store/actions";
import { selectActiveClassroom } from "@app/store/selectors";
import { useMutation, useQuery } from "@tanstack/react-query";
import moment from "moment";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

export const notificationKind = {
  // 수업 시작
  CLASS_START: "CLASS_START",
  // 학생 수업 참여
  CLASS_STUDENT_JOIN: "CLASS_STUDENT_JOIN",
  // 퀴즈 퍼미션 준비 요청 from 선생님
  // QUIZSET_PERMISSION_READY: 'QUIZSET_PERMISSION_READY',
  // 퀴즈 퍼미션 준비 완료
  QUIZSET_PERMISSION_STUDENT_READY: "QUIZSET_PERMISSION_STUDENT_READY",
  // 퀴즈 시작 준비
  QUIZSET_READY: "QUIZSET_READY",
  // 학생 현재 상태
  QUIZSET_STUDENT_STATUS: "QUIZSET_STUDENT_STATUS",
  // 퀴즈 시작
  QUIZSET_START: "QUIZSET_START",
  // 학생 퀴즈 작성 상태 체크 요청: 선생님이 학생에게
  QUIZ_STUDENT_STATUS_CHECK: "QUIZ_STUDENT_STATUS_CHECK",
  // 학생 퀴즈 작성 상태 체크 송신: 학생이 선생님에게
  QUIZ_STUDENT_STATUS_SEND: "QUIZ_STUDENT_STATUS_SEND",
  // 다음 퀴즈 시작
  QUIZ_NEXT_START: "QUIZ_NEXT_START",
  // 퀴즈 종료
  QUIZSET_COMPLETED: "QUIZSET_COMPLETED",
  // Eye Tracking Status: Turn red
  ADD_STUDENT_FOCUS_STATUS_LIST: "ADD_STUDENT_FOCUS_STATUS_LIST",
  // Eye Tracking Status: Reset
  REMOVE_STUDENT_FOCUS_STATUS_LIST: "REMOVE_STUDENT_FOCUS_STATUS_LIST",
  // Eye Tracking Enable
  ENABLE_STUDENT_FOCUS_STATUS_LIST: "ENABLE_STUDENT_FOCUS_STATUS_LIST",
  // Eye Tracking Disable
  DISABLE_STUDENT_FOCUS_STATUS_LIST: "DISABLE_STUDENT_FOCUS_STATUS_LIST",
  // 현재 퀴즈 진행 중 여부 확인
  QUIZSET_SESSION_ACTIVE_CHECK: "QUIZSET_SESSION_ACTIVE_CHECK",
  // 현재 퀴즈 진행 중 컨펌
  QUIZSET_SESSION_ACTIVE_CONFIRM: "QUIZSET_SESSION_ACTIVE_CONFIRM",
};
export const useSystemNotification = (groupId, enabled = false) => {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const activeClassroom = useSelector((state) => selectActiveClassroom(state));
  const { data: message } = useQuery({
    queryKey: ["system-notification"],
    queryFn: () =>
      getSystemNotification({
        limit: 30,
        offset: 0,
        state: "UNREAD",
        clientId: user.clientId,
      }),
    select: (res) => res.data.systemNotifications,
    refetchInterval: 3000,
    enabled: false, //enabled && !!user?.clientId,
  });

  const { mutateAsync: onSendMessage } = useMutation({
    mutationFn: (params) =>
      // params { toClientId, notificationKind, title, body, payload }
      createSystemNotification({
        state: "UNREAD",
        notificationType: "COMMAND",
        fromClientId: user?.clientId,
        ...params,
      }),
  });

  const { mutateAsync: onSendMessageByGroup } = useMutation({
    mutationFn: async (params) => {
      // console.log("센드 메세지그룹", params);
      //console.log("🚀 ~ SendMessage ~ ", params.notificationKind);
      // params { notificationKind, title, body, payload }
      dispatch(
        setSocketData({
          method: "POST",
          uri: "/classroom/sendImage",
          groupId: groupId,
          clientId: user.clientId,
          type: params.notificationKind,
          data: params.payload || "",
        })
      );
      return createSystemNotificationByGroup({
        state: "UNREAD",
        notificationType: "COMMAND",
        fromClientId: user?.clientId,
        toClientId: groupId,
        ...params,
      });
    },
  });

  const { mutateAsync: onReadMessage } = useMutation({
    mutationFn: ({ systemNotificationId }) =>
      updateSystemNotification({
        state: "READ",
        systemNotificationId,
      }),
    onError: (error, { systemNotificationId, notificationKind }) => {
      postSystemNotificationError({
        systemNotificationId,
        notificationKind,
        userEnv: "teacher",
        errorMessage: error?.response?.data ?? error?.message,
        errorStatus: error?.response?.status ?? error?.code,
      });
    },
  });

  useEffect(() => {
    // 메세지 읽음 처리
    if (!message || !enabled) {
      return;
    }
    message.forEach((message) => {
      if (
        message.toClientId === user.clientId &&
        moment(message.createdAt).isAfter(moment().subtract(1, "minute"))
      ) {
        switch (message.notificationKind) {
          case notificationKind.CLASS_STUDENT_JOIN:
            if (!activeClassroom) return;
            dispatch(
              getParticipationsByClassroomPromise(activeClassroom.classroomId)
            );
            break;
          case notificationKind.QUIZSET_PERMISSION_STUDENT_READY:
            break;
          case notificationKind.QUIZ_STUDENT_STATUS_SEND:
            dispatch(getAnsweredStudentListSuccess(message.fromClientId));
            break;
          case notificationKind.ADD_STUDENT_FOCUS_STATUS_LIST:
            dispatch(addStudentFocusStatusList(message.fromClientId));
            break;
          case notificationKind.REMOVE_STUDENT_FOCUS_STATUS_LIST:
            dispatch(removeStudentFocusStatusList(message.fromClientId));
            break;
          default:
            break;
        }
        console.log(
          `[System Notification] ${message.notificationKind}`,
          message.fromClientId
        );
        onReadMessage({
          systemNotificationId: message.systemNotificationId,
          notificationKind: message.notificationKind,
        });
      }
    });
  }, [
    message,
    enabled,
    dispatch,
    onReadMessage,
    user.clientId,
    activeClassroom,
  ]);

  return {
    message,
    onSendMessage,
    onSendMessageByGroup,
  };
};
