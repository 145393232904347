import {
  PURGE_DEVICE,
  REGISTER_DEVICE_SUCCESS,
  REGISTER_DEVICE_FAIL,
} from "./actionTypes";

const initialState = {
  device: {},
};

const device = (state = initialState, action) => {
  switch (action.type) {
    case PURGE_DEVICE:
      return initialState;

    case REGISTER_DEVICE_SUCCESS:
      return { ...state, device: action.device };

    case REGISTER_DEVICE_FAIL:
      // console.error("[Device Reducer] ", action.payload);
      return state;

    default:
      break;
  }
  return state;
};

export default device;
