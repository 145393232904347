import { Box, HStack, Heading, Stack, Text } from "@chakra-ui/react";
import React from "react";

const ModalClassManagementAddAppHeader = ({ title }) => {
  return (
    <Stack direction="column" spacing="1" justifyContent="flex-start">
      <Heading fontSize="md">・ {title ? title : "미디어"}</Heading>
      <Box
        bg="white"
        border="1px"
        borderColor="gray.200"
        borderRadius="lg"
        p="2"
      >
        <HStack w="full" justifyContent="space-between" spacing={4}>
          <Text fontWeight="bold" fontSize="sm" w="full" textAlign="center">
            이름
          </Text>
          <HStack spacing={2} w="full">
            <Text fontWeight="bold" fontSize="sm" w="full" textAlign="center">
              제어 가능한 OS
            </Text>
          </HStack>
        </HStack>
      </Box>
    </Stack>
  );
};

export default ModalClassManagementAddAppHeader;
