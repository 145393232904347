import { makeFreshAllIds } from "../helpers";
import {
  PURGE_FILELINK,
  GET_GROUP_FILELINK_SUCCESS,
  GET_GROUP_FILELINK_FAIL,
  CREATE_FILELINK_SUCCESS,
  CREATE_FILELINK_FAIL,
  DELETE_FILELINK_SUCCESS,
  DELETE_FILELINK_FAIL,
} from "./actionTypes";

const initialState = {
  byId: {},
  allIds: [],
};

const filelinks = (state = initialState, action) => {
  switch (action.type) {
    case PURGE_FILELINK:
      return initialState;

    case GET_GROUP_FILELINK_SUCCESS:
      return {
        ...state,
        byId: { ...state.byId, ...action.byId },
        allIds: makeFreshAllIds(state.allIds, action.allIds),
      };

    case CREATE_FILELINK_SUCCESS:
      return {
        ...state,
        byId: { ...state.byId, [action.filelink.filelinkId]: action.filelink },
        allIds: makeFreshAllIds(state.allIds, [action.filelink.filelinkId]),
      };

    case DELETE_FILELINK_SUCCESS:
      return {
        ...state,
        byId: { ...state.byId, [action.filelink.filelinkId]: action.filelink },
        allIds: state.allIds.filter(
          (filelinkId) => filelinkId !== action.filelink
        ),
      };

    case GET_GROUP_FILELINK_FAIL:
      // console.log('[Filelink Reducer] ', 'No Filelinks with this Group');
      return state;
    case CREATE_FILELINK_FAIL:
    case DELETE_FILELINK_FAIL:
      // console.error("[Filelink Reducer] ", action.payload);
      return state;
    default:
      break;
  }
  return state;
};

export default filelinks;
