import { useMediaQuery } from "@chakra-ui/react";
import { Stack, styled } from "@mui/material";
import React from "react";

const Body = ({ children, sx }) => {
  const [isLargerThan767] = useMediaQuery("(min-width: 768px)");
  return (
    <Container sx={sx} isLargerThan767={isLargerThan767}>
      {children}
    </Container>
  );
};

export default Body;

const Container = styled(Stack)`
  display: flex;
  padding: ${(props) => (props.isLargerThan767 ? "0 2rem" : "0 1rem")}};
`;
