import { useState } from "react";

export const useSurveyForm = (initSurveyData) => {
  const [surveyFormData, setSurveyFormData] = useState(initSurveyData);
  const [checked, setChecked] = useState(false);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  
  const handleSurveryFormChange = (event) => {
    const { type,name, value, files } = event.target;
    setSurveyFormData({ ...surveyFormData, [name]: value });
    
    const directInputTimeLimit = name === "timelimit" && value ==="직접 입력"
    
    if(type ==="file") {
      setSurveyFormData({...surveyFormData, linkType:"IMG"})
      setSurveyFormData({ ...surveyFormData, link: files[0]});
    }
    if(directInputTimeLimit) {
      setChecked(directInputTimeLimit)
      setSurveyFormData({ ...surveyFormData,[name]: 0 });
    }
  };
  
  const minutesHandler = (event) => {
    const {name, value}  = event.target
    console.log(value)
    const newMinutes = parseInt(value || 0, 10);
    if (!isNaN(newMinutes) && newMinutes >= 0) {
      setMinutes(newMinutes);
      setSurveyFormData({ ...surveyFormData,[name]: newMinutes * 60 + seconds });
  
    }
  };
  const secondsHandler = (event) => {
    const {name, value}  = event.target
    const newSeconds = parseInt(value || 0, 10);
    if (!isNaN(newSeconds) && newSeconds >= 0) {
      setSeconds(newSeconds);
      setSurveyFormData({ ...surveyFormData,[name]: minutes * 60 + newSeconds });
    }
  };


  const handleSurveyTitle = (value) => {
    setSurveyFormData({ ...surveyFormData, title: value });
  };
  const handleSurveyDescription = (value) => {
    setSurveyFormData({ ...surveyFormData, description: value });
  };
  return {
    checked,
    surveyFormData,
    handleSurveryFormChange,
    handleSurveyTitle,
    handleSurveyDescription,
    minutesHandler,
    secondsHandler,
  };
};

export const useMultipleType = () => {
  const [multipleType, setMultipleType] = useState({
    multipleType: "MULTIPLE",
  });
  const handleMultipleTypeChange = (event) => {
    const { name, value } = event.target;
    setMultipleType({ ...multipleType, [name]: value });
  };
  return { multipleType, handleMultipleTypeChange };
};

// export const useSampleAnswer = (initSampleAnswer) => {
//   const [sampleAnswerData, setSampleAnswerData] = useState(initSampleAnswer);
// };

export const useChoices = (initChoices) => {
  const [choices, setChoices] = useState(initChoices);
  const handleChoicesAddClick = () => {
    setChoices((prevChoices) => [
      ...prevChoices,
      {
        index: prevChoices.length + 1,
        description: "",
        point: 0,
        isAnswer: true,
      },
    ]);
  };
  const handleChoicesDeleteClick = (index) => {
    setChoices((prevChoices) => {
      const newChocies = prevChoices.filter(
        (_, choicesIndex) => choicesIndex !== index
      );
      return newChocies;
    });
  };
  const handlChoiceDescriptionChange = (event, choicesIndex) => {
    const { value } = event.target;
    setChoices((prevChoices) => {
      const newChocies = [...prevChoices];
      newChocies[choicesIndex] = {
        index: newChocies[choicesIndex].index,
        description: value,
        point: newChocies[choicesIndex].point,
        isAnswer: newChocies[choicesIndex].isAnswer,
      };
      return newChocies;
    });
  };

  const handleEmptyChoices = () => {
    setChoices(initChoices);
  };
  const radioSetChoices = () => {
    setChoices([
      { index: 1, description: "전혀 그렇지 않다", point: 0, isAnswer: true },
      { index: 2, description: "조금 그렇다", point: 0, isAnswer: true },
      { index: 3, description: "보통 그렇다", point: 0, isAnswer: true },
      { index: 4, description: "대단히 그렇다", point: 0, isAnswer: true },
    ]);
  };
  const tureFalseChoices = () => {
    setChoices([
      { index: 1, description: "O", point: 0, isAnswer: true },
      { index: 2, description: "X", point: 0, isAnswer: true },
    ]);
  };
  return {
    choices,
    handleChoicesAddClick,
    handleChoicesDeleteClick,
    handlChoiceDescriptionChange,
    handleEmptyChoices,
    radioSetChoices,
    tureFalseChoices,
  };
};

