import React from "react";
import HeadingText from "../SurveyEditor/HeadingText";
import { Stack, HStack, Select } from "@chakra-ui/react";

const SurveyCategory = ({surveyFormData, handleSurveryFormChange}) => {
  return (
    <Stack direction="column" spacing={4} w={"full"}>
      <HeadingText headingText="6. 설문지 카테고리(선택)" />
      <HStack spacing={4}>
        <Select
          placeholder="난이도 선택"
          value={surveyFormData.quizLevel}
          onChange={handleSurveryFormChange}
          name="quizLevel"
        >
          {["쉬움", "보통", "어려움"].map((level, index) => (
            <option key={index} value={index + 1}>
              {level}
            </option>
          ))}
        </Select>
        <Select
          placeholder="학년 선택"
          value={surveyFormData.grade}
          onChange={handleSurveryFormChange}
          name="grade"
        >
          <option value={0}>학년무관</option>
          {[1, 2, 3, 4, 5, 6].map((grade) => (
            <option key={grade} value={grade}>
              {grade}학년
            </option>
          ))}
        </Select>
      </HStack>
      <HStack spacing={4}>
        
      </HStack>
    </Stack>
  );
};

export default SurveyCategory;
