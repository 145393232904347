import React, { useEffect, useMemo, useState } from "react";
import CardLectureInfo from "@components/Card/CardLectureInfo";
import CardLectureInfoEmpty from "@components/Card/CardLectureInfo_Empty";
import { useDispatch, useSelector } from "react-redux";
import { selectAllTimetables } from "@store/selectors";
import styles from "./ClassList.module.scss";
import { selectAllMyGroups } from "@app/store/groups/selector";
import styled from "styled-components";
import { useTimetableByClientId } from "@app/pages/Home/hooks/useTimetable";
import {
  classifyTimetable,
  getDayToKorean,
} from "@app/utils/timetable/timetableUtils";
import { ModalEditTimetable } from "../Modal/Timetable/ModalEditTimetable";
import { ModalCreateTimetable } from "../Modal/Timetable/MocalCreateTimetable";
import { Button } from "@mui/material";
import {
  Box,
  Card,
  CardBody,
  CardHeader,
  Container,
  Heading,
  Stack,
  StackDivider,
  Text,
  VStack,
  useMediaQuery,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

const ClassList = () => {
  const { t } = useTranslation();

  const user = useSelector((state) => state.user);
  const allMyGroups = useSelector((state) => selectAllMyGroups(state));
  const allTimetables = useSelector((state) => selectAllTimetables(state));

  const [createModalShow, setCreateModalShow] = useState(false);
  const [editModalShow, setEditModalShow] = useState(false);
  const [formData, setFormData] = useState();

  const getTimetableData = useTimetableByClientId(user.clientId);

  const [isLargerThan810] = useMediaQuery("(min-width: 811px)");
  const [isLargerThan767] = useMediaQuery("(min-width: 768px)");

  const handleCreateModal = ({ timetables, weekDay, period = "" }) => {
    setFormData({
      timetables: [],
      timetableId: "",
      weekDay,
      period,
      subjectName: "",
      groupName: "",
      grade: "",
      groupId: "",
      startTime: "",
      endTime: "",
      isEdit: false,
    });
    setCreateModalShow(true);
  };

  if (
    (createModalShow && getTimetableData.isError) ||
    getTimetableData.isLoading
  ) {
    return (
      <>
        {createModalShow && (
          <ModalCreateTimetable
            show={createModalShow}
            onHide={() => setCreateModalShow(false)}
            timetableData={formData}
          />
        )}
      </>
    );
  }

  if (getTimetableData.isLoading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="text-2xl font-bold">시간표를 불러오고 있습니다...</div>
      </div>
    );
  }

  if (getTimetableData.isError) {
    return (
      <>
        <div className="flex flex-col items-center justify-center h-screen">
          <div className="text-2xl font-bold mb-4">
            생성된 시간표가 없는것 같아요. <br />
            아래의 시간표 생성 버튼을 눌러 시간표를 생성해보세요!
          </div>
          <div>
            <Button
              variant="contained"
              onClick={() => {
                handleCreateModal({ timetables: [], weekDay: 1, period: 1 });
              }}
            >
              시간표 생성
            </Button>
          </div>
        </div>
      </>
    );
  }

  const timetableData = getTimetableData.timetable.data;

  const classifiedTimetable = classifyTimetable(timetableData);

  const handleEditModal = ({
    timetables,
    weekDay,
    period,
    startTime,
    endTime,
    group,
    timetableId,
  }) => {
    setFormData({
      timetables: allTimetables.filter(
        (timetable) => timetable.groupId === group.groupId
      ),
      timetableId,
      weekDay: weekDay,
      period,
      groupType: group.groupType,
      subjectName: group.subjectName,
      groupName: group.groupName,
      startTime,
      endTime,
      grade: group.grade,
      groupId: group.groupId,
      isEdit: true,
    });
    setEditModalShow(true);
  };

  return (
    <Container
      maxW="full"
      h="100vh"
      pt={8}
      pb={8}
      pl={isLargerThan810 ? 8 : isLargerThan767 ? 0 : 8}
      pr={isLargerThan810 ? 8 : isLargerThan767 ? 0 : 8}
    >
      <Card
        w="full"
        p={4}
        borderRadius="lg"
        bg="white"
        color="black"
        mb={8}
        boxShadow="none"
        border="1px solid"
        borderColor="gray.200"
      >
        <CardHeader>
          <Heading size="md">안내 드립니다.</Heading>
        </CardHeader>
        <CardBody>
          <Stack divider={<StackDivider />} spacing="4">
            <Box>
              <Heading size="xs" textTransform="uppercase">
                포커스팡 겨울방학 업그레이드 기간 안내드립니다.
              </Heading>
              <Text pt="2" fontSize="sm">
                2023년 12월 31일 부터 2024년 3월 1일까지 포커스팡 서비스가 더욱
                업그레이드 되어 돌아올 예정입니다.
              </Text>
              <Text pt="2" fontSize="sm">
                또한 포커스팡은 부분유료화 정책을 도입하여, 기존의 무료로
                제공되던 서비스 중 일부 기능을 유료로 전환할 예정입니다.
              </Text>
              <Text pt="2" fontSize="sm">
                자세한 내용은 2024년 2월 셋째주에 공지될 예정이니, 이 점 참고
                부탁드립니다.
              </Text>
            </Box>
          </Stack>
        </CardBody>
      </Card>
      <Stack
        direction={{
          base: "column",
          md: "row",
        }}
        alignItems="flex-start"
        justifyContent={"space-between"}
      >
        {Object.entries(classifiedTimetable).map(
          ([weekDay, timeTables], index) => {
            var timeTables2 = [];
            var previousClass = 0;
            for (var i = 0; i < timeTables.length; i++) {
              var thisEntry = timeTables[i];
              var currentClass = thisEntry.period;
              while (previousClass + 1 < currentClass) {
                var timeTableObjectKey = weekDay + "-" + (previousClass + 1);
                var emptyEntry = {
                  timetableId: timeTableObjectKey,
                  groupId: null,
                  period: previousClass + 1,
                  startTime: "",
                  endTime: "",
                };
                timeTables2.push(emptyEntry);
                previousClass = previousClass + 1;
              }
              timeTables2.push(thisEntry);
              previousClass = currentClass;
            }
            var maxClass = 8;
            while (previousClass + 1 <= maxClass) {
              var timeTableObjectKey = weekDay + "-" + (previousClass + 1);
              var emptyEntry = {
                timetableId: timeTableObjectKey,
                groupId: null,
                period: previousClass + 1,
                startTime: "",
                endTime: "",
              };
              timeTables2.push(emptyEntry);
              previousClass = previousClass + 1;
            }
            return (
              <VStack w="full" h="full" spacing={4} borderRadius="lg">
                <Text fontWeight="bold">{getDayToKorean(weekDay, t)}</Text>
                {timeTables2.map((timeTable) => {
                  const targetGroup = allMyGroups.find(
                    (group) => group.groupId === timeTable.groupId
                  );
                  if (!targetGroup) {
                    if (timeTable.groupdId != null) return null;
                    var targetGroup_empty = {
                      grade: 0,
                      groupName: "empty_slot",
                      subjectName: "",
                    };
                    return (
                      <CardLectureInfoEmpty
                        key={timeTable.timetableId}
                        timetable={timeTable}
                        group={targetGroup_empty}
                        modal_func={handleCreateModal}
                        prop_tables={timeTables}
                        prop_weekday={index + 1}
                      />
                    );
                  }
                  return (
                    <CardLectureInfo
                      key={timeTable.timetableId}
                      timetable={timeTable}
                      group={targetGroup}
                      modal_func={handleEditModal}
                      prop_tables={timeTables}
                      prop_weekday={index + 1}
                    />
                  );
                })}
              </VStack>
            );
          }
        )}
        {editModalShow && (
          <ModalEditTimetable
            show={editModalShow}
            onHide={() => setEditModalShow(false)}
            timetableData={formData}
            timetableList={timetableData}
          />
        )}
        {createModalShow && (
          <ModalCreateTimetable
            show={createModalShow}
            onHide={() => setCreateModalShow(false)}
            timetableData={formData}
            timetableList={timetableData}
          />
        )}
      </Stack>
    </Container>
  );
};

export default ClassList;
