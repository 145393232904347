import { Stack, VStack } from "@chakra-ui/react";
import React from "react";

const QuizEditorWrapper = ({ children }) => {
  return (
    <VStack spacing={8} w={"full"}>
      {children}
    </VStack>
  );
};

export default QuizEditorWrapper;
