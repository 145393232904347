import { useQuizsetByQuizsetId } from "@app/api/quiz/hooks/useQuizset";
import React, { useState } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import SurveyForm from "./SurveyForm";
import { Box, Stack, VStack, Button } from "@chakra-ui/react";
import SurveyLabelInput from "./SurveyLabelInput";
import { useSurveyForm } from "@app/hooks/form/useSurveyForm";
import { SURVEY_FORM_DATA } from "@app/Constants/surveyType";
import SurveyDescription from "./SurveyEditor/SurveyDescription";

const SurveyView = () => {
  const [isEdit, setIsEdit] = useState(false);
  const { quizsetId } = useParams();
  const { data: quizsetData, refetch, isLoading } = useQuizsetByQuizsetId(
    quizsetId
  );
  const handleSurveyCreateClick = () => {
    setIsEdit(true);
  };
  console.log(quizsetData);
  const {
    checked,
    surveyFormData,
    handleSurveryFormChange,
    handleSurveyTitle,
    handleSurveyDescription,
    minutesHandler,
    secondsHandler,
  } = useSurveyForm(SURVEY_FORM_DATA);
  return (
    <Box w={"full"} h={"full"} overflow={"auto"}>
      <Stack direction="column" spacing={4} w={"full"}>
        <SurveyLabelInput
          name="header"
          value={surveyFormData.header}
          handleSurveryFormChange={handleSurveryFormChange}
        />
        <SurveyDescription
          name="surveyDescription"
          value={surveyFormData.surveyDescription}
          handleSurveryFormChange={handleSurveryFormChange}
        />
        <VStack>
          {!isEdit && (
            <Button onClick={handleSurveyCreateClick}>설문지 생성하기</Button>
          )}
        </VStack>
        {isEdit && (
          <SurveyForm
            checked={checked}
            header={surveyFormData.header}
            quizsetId={quizsetId}
            quizsetData={quizsetData}
            surveyFormData={surveyFormData}
            secondsHandler={secondsHandler}
            minutesHandler={minutesHandler}
            handleSurveyTitle={handleSurveyTitle}
            handleSurveryFormChange={handleSurveryFormChange}
            handleSurveyDescription={handleSurveyDescription}
          />
        )}
      </Stack>
    </Box>
  );
};

export default SurveyView;
