import React from 'react';
import styled from 'styled-components';

import newFP from 'assets/images/notice/newFP.png';
import focuspangNew from 'assets/images/notice/focuspang3.0Banner.png';
import kakao from 'assets/images/notice/kakao.png';
import eyetracking from 'assets/images/notice/eyetrackingBanner.png';

import { Carousel, Col, Collapse, Grid, Row, Slider } from 'antd';
import { Box } from '@mui/material';

const contentStyle = {
  margin: 0,
  height: '160px',
  color: '#fff',
  lineHeight: '160px',
  textAlign: 'center',
  background: '#364d79',
};

export const NotiPanel = () => {
  const onChange = (currentSlide) => {};

  let carouselStyle;
  let boxStyle;

  //if (window.innerWidth > 1200) {
  //  carouselStyle = {
  //    width: 'calc(100% - 280px)',
  //  };
  //} else {
  //  carouselStyle = {
  //    width: 'calc(100% - 100px)',
  //  };
  //}
  if (window.innerWidth > 1200) {
    boxStyle = {
      justifyContent: 'center',
      paddingTop: '1rem',
      position: 'relative',
      top: '0',
    };
  } else {
    boxStyle = {
      justifyContent: 'center',
      paddingTop: '1rem',
      position: 'relative',
      top: '0',
    };
  }

  return (
    <Box
      //오른쪽 끝 정렬
      sx={boxStyle}
    >
      <Carousel
        afterChange={onChange}
        arrows
        autoplay
        infinite={true}
        style={carouselStyle}
      >
        <Image src={eyetracking} alt='eyetracking' />
        <Image src={newFP} alt='newFP' />
        <div onClick={() => window.open('https://pf.kakao.com/_xjxgGDxj')}>
          <Image src={kakao} alt='kakao' />
        </div>
      </Carousel>
    </Box>
  );
};

const ImageContainerFullImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const Image = styled.img`
  cursor: pointer;
  /*height: 120px;*/
  height: 60px;
  // 중앙정렬 및 이미지 비율 유지
  object-fit: contain;
  object-position: center;
  width: 100%;
`;

//광고 수정
