import { Stack } from "@chakra-ui/react";
import React from "react";
import HeadingText from "./HeadingText";
import { TextEditorForQuizpang } from "@app/components/Input/TextEditorForQuizpang";
import { MediaInput } from "./MeddiaInput";

const TitleEditor = ({
  headingText,
  value,
  handleSurveyTitle,
  inputValue,
  handleSurveryFormChange,
}) => {
  return (
    <Stack spacing={4} w="full" overflow="hidden">
      <HeadingText headingText={headingText} />
      <TextEditorForQuizpang
        style={{ height: "300px", overflow: "hidden" }}
        isFile={false}
        value={value}
        onChange={handleSurveyTitle}
      />
      <MediaInput
        inputValue={inputValue}
        handleSurveryFormChange={handleSurveryFormChange}
      />
    </Stack>
  );
};

export default TitleEditor;
