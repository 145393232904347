import {
  notificationKind,
  useSystemNotification,
} from "@app/react-query/querys/useSystemNotification";
import {
  createClassroomPromise,
  deleteClassroomPromise,
  getActiveClassroomPromise,
  setClassroomState,
  setSocketData,
  purgeStudentFocusStatusList,
} from "@app/store/actions";
import {
  selectActiveClassroom,
  selectClassroomGroup,
  selectGroupById,
} from "@app/store/selectors";
import { serviceErrorSlackAlarm } from "@app/utils/alert/slackAlarm";
import { Button, HStack, Text, VStack } from "@chakra-ui/react";
import { Tooltip } from "@material-ui/core";
import { Typography } from "@mui/material";
import { QueryClient, useQueryClient } from "@tanstack/react-query";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import styled from "styled-components";

const Checkbox = styled.input.attrs({ type: "checkbox" })`
  width: 18px;
  height: 18px;
`;

const ClassroomStateChangeButton = () => {
  const { t } = useTranslation();

  const DEFAULT_DURATION = 45;
  const { groupId } = useParams();
  const user = useSelector((state) => state.user);
  const group = useSelector((state) => selectGroupById(state, groupId));
  const activeClassroom = useSelector((state) => selectActiveClassroom(state));
  const activeClassroomGroup = useSelector((state) =>
    selectClassroomGroup(state, activeClassroom)
  );
  const dispatch = useDispatch();
  const { onSendMessageByGroup } = useSystemNotification(groupId);
  const [isChecked, setIsChecked] = useState(false);

  const queryClient = useQueryClient();
  const schoolData = queryClient.getQueryData(["school", user?.schoolId]);

  const sendCreatedClassroomBySocket = (classroomId) => {
    dispatch(
      setSocketData({
        method: "POST",
        uri: "/classroom/sendImage",
        groupId: group?.groupId,
        clientId: user?.clientId,
        type: "CLASS_START",
        data: classroomId,
      })
    );
  };

  const sendCreatedClassroomByNoti = (classroomId) => {
    onSendMessageByGroup({
      notificationKind: notificationKind.CLASS_START,
      payload: JSON.stringify({
        classroomId: classroomId,
      }),
    });
  };

  const sendDeletedClassroomBySocket = () => {
    dispatch(
      setSocketData({
        method: "POST",
        uri: "/classroom/sendImage",
        groupId: group?.groupId,
        clientId: user?.clientId,
        type: "CLASS_END",
        data: "",
      })
    );
  };

  const clickClassStart = () => {
    if (!isChecked) {
      alert("포커스타임을 시작하기 전에 체크박스를 체크해주세요.");
      return;
    }

    const confirmed = window.confirm(
      // "실제 수업시간이 아닌 경우에, 포커스타임을 시작하게 되면 이미 사용 중인 선생님과 학생들을 방해하게 됩니다. 이 점 양해 바랍니다."
      t("timetable.focustime.start.alert.text")
    );

    if (confirmed) {
      if (activeClassroom && activeClassroom?.groupId !== group?.groupId) {
        const activeGroup = activeClassroomGroup;
        alert(
          `${activeGroup?.grade} - ${activeGroup?.groupName} ${activeGroup?.subjectName} 수업이 아직 진행중이에요. ${activeGroup.subjectName} 수업을 먼저 끝내고 다시 시도해주세요`
        );
        return;
      }

      if (!activeClassroom) {
        dispatch(
          createClassroomPromise({
            groupId: group.groupId,
            startAt: moment().toISOString(),
            finishAt: moment()
              .add(DEFAULT_DURATION, "minutes")
              .toISOString(),
          })
        )
          .then((createdClassroom) => {
            sendCreatedClassroomBySocket(createdClassroom.classroomId);
            dispatch(setClassroomState(true));
          })
          .then(() => {
            return dispatch(getActiveClassroomPromise(user.clientId));
          })
          .catch((error) => {
            serviceErrorSlackAlarm(
              error,
              schoolData?.data.name,
              user?.schoolId,
              user?.clientId,
              "createClassroomPromise",
              "ClassroomStateChangeButton.js",
              110
            );
          });
      }
    } else {
      setIsChecked(false);
    }
  };

  useEffect(() => {
    if (activeClassroom) {
      setIsChecked(true);
    } else {
      setIsChecked(false);
    }
  }, [activeClassroom]);

  if (activeClassroom) {
    return (
      <Tooltip title="포커스 타임을 종료를 하실 수 있는 버튼입니다. 클릭시 포커스타임이 종료됩니다.">
        <Button variant="outlined" size="large">
          {/* 수업 종료 */}
          {t("timetable.focustime.button.end")}
        </Button>
      </Tooltip>
    );
  } else {
    return (
      <>
        <VStack w="full" direction="column" alignItems="center">
          <HStack spacing={2}>
            <Text variant="body2" color="primary">
              {/* 시작하기 전에 체크해 주세요. */}
              {t("timetable.focustime.toggle.checkbeforestart")}
            </Text>
            <Checkbox
              checked={isChecked}
              onChange={(event) => setIsChecked(event.target.checked)}
            />
          </HStack>
          <VStack>
            <Button
              colorScheme="blue"
              isDisabled={!isChecked}
              onClick={clickClassStart}
            >
              {/* 포커스타임 시작 */}
              {t("timetable.focustime.button.start")}
            </Button>
          </VStack>
          <HStack>
            <Typography variant="body2" color="secondary">
              {/* <span style={{ color: "var(--third)" }}>화면공유</span>
              <span>와 </span>
              <span style={{ color: "var(--third)" }}>웹사이트 접근 제어</span>
              <span>를 할 수 있습니다.</span> */}
              {t("timetable.focustime.text.description")}
            </Typography>
          </HStack>
        </VStack>
      </>
    );
  }
};

export default ClassroomStateChangeButton;
