import axios from "axios";

const Backend = axios.create({
  baseURL: `${process.env.REACT_APP_BACKEND_ADDR}`,
});

Backend.interceptors.response.use(
  (response) => response,
  (error) => {
    console.error(error);
    return Promise.reject(error);
  }
);

const BackendMock = axios.create({
  baseURL: `https://f48cad7a-c604-4c5b-bd09-a04519a00291.mock.pstmn.io/v3/`,
});

BackendMock.interceptors.response.use(
  (response) => response,
  (error) => {
    console.error(error);
    return Promise.reject(error);
  }
);
// const BackendV3 = axios.create({
//   baseURL: `${process.env.REACT_APP_BACKEND_ADDR}/v3`,
// });

let header = {
  headers: {
    Authorization: "",
    "Access-Control-Max-Age": 600,
  },
};

export const resetToken = async () => {
  console.log("Resetting token");
  if ("Authorization" in header.headers) {
    delete header.headers.Authorization;
  }
  return header;
};

export const refreshToken = async () => {
  if (header.headers.Authorization === "") {
    const token = localStorage.getItem("token");
    if (token) {
      header.headers.Authorization = `Bearer ${token}`;
    }
  }
};

export const setToken = async (token) => {
  localStorage.setItem("token", token);
  header.headers.Authorization = `Bearer ${token}`;
  return header;
};

export const setRootToken = async () => {
  const root_user = {
    id: 1,
    token: process.env.REACT_APP_ROOT_AUTH_TOKEN,
  };
  console.log("Setting token to: ", root_user.token);
  header.headers.Authorization = `Bearer ${root_user.token}`;
  return header;
};

export const getBackend = async (endpoint, annonymous = false) => {
  await refreshToken();
  if (annonymous) {
    return await Backend.get(endpoint, {
      headers: { Authorization: "" },
    });
  }
  return await Backend.get(endpoint, {
    headers: { Authorization: header.headers.Authorization },
  });
};

export const getBackendMock = async (endpoint, annonymous = false) => {
  await refreshToken();
  if (annonymous) {
    return await BackendMock.get(endpoint, {
      headers: { Authorization: "" },
    });
  }
  return await BackendMock.get(endpoint, {
    headers: { Authorization: header.headers.Authorization },
  });
};

export const postBackend = async (
  endpoint,
  payload = {},
  annonymous = false
) => {
  await refreshToken();
  if (annonymous) {
    return await Backend.post(endpoint, payload, {
      headers: { Authorization: "" },
    });
  }
  return await Backend.post(endpoint, payload, {
    headers: { Authorization: header.headers.Authorization },
  });
};

export const postBackendMock = async (
  endpoint,
  payload = {},
  annonymous = false
) => {
  await refreshToken();
  if (annonymous) {
    return await BackendMock.post(endpoint, payload, {
      headers: { Authorization: "" },
    });
  }
  return await BackendMock.post(endpoint, payload, {
    headers: { Authorization: header.headers.Authorization },
  });
};

export const postBackendFormData = async (
  endpoint,
  payload = {},
  anonymous = false
) => {
  const formData = new FormData();
  Object.keys(payload).map((k) => {
    formData.append(k, payload[k]);
  });
  // if (anonymous) {
  //   return await Backend.post(endpoint, formData, {
  //     headers: {
  //       Authorization: header.headers.Authorization,
  //       'Content-Type': 'multipart/form-data',
  //     },
  //   });
  // }
  return await Backend.post(endpoint, formData, {
    headers: {
      ...header.headers,
      "Content-Type": "multipart/form-data",
    },
  });
};

export const patchBackend = async (
  endpoint,
  payload = {},
  annonymous = false
) => {
  await refreshToken();
  if (annonymous) {
    return await Backend.patch(endpoint, payload, {
      headers: { Authorization: "" },
    });
  }
  return await Backend.patch(endpoint, payload, {
    headers: { Authorization: header.headers.Authorization },
  });
};

export const patchBackendMock = async (
  endpoint,
  payload = {},
  annonymous = false
) => {
  await refreshToken();
  if (annonymous) {
    return await BackendMock.patch(endpoint, payload, {
      headers: { Authorization: "" },
    });
  }
  return await BackendMock.patch(endpoint, payload, {
    headers: { Authorization: header.headers.Authorization },
  });
};

export const deleteBackend = async (
  endpoint,
  payload = {},
  annonymous = false
) => {
  await refreshToken();
  if (annonymous) {
    return await Backend.delete(
      endpoint,
      {
        headers: { Authorization: "" },
      },
      payload
    );
  }
  return await Backend.delete(
    endpoint,
    {
      headers: { Authorization: header.headers.Authorization },
    },
    payload
  );
};

export const deleteBackendMock = async (
  endpoint,
  payload = {},
  annonymous = false
) => {
  await refreshToken();
  if (annonymous) {
    return await BackendMock.delete(
      endpoint,
      {
        headers: { Authorization: "" },
      },
      payload
    );
  }
  return await BackendMock.delete(
    endpoint,
    {
      headers: { Authorization: header.headers.Authorization },
    },
    payload
  );
};

// export const getBackendV3 = async (endpoint, annonymous = false) => {
//   if (annonymous) {
//     return await BackendV3.get(endpoint, { headers: { Authorization: '' } });
//   }
//   return await BackendV3.get(endpoint, header);
// };
// export const postBackendV3 = async (
//   endpoint,
//   payload = {},
//   annonymous = false
// ) => {
//   if (annonymous) {
//     return await BackendV3.post(endpoint, payload, {
//       headers: { Authorization: '' },
//     });
//   }
//   return await BackendV3.post(endpoint, payload, header);
// };
// export const patchBackendV3 = async (
//   endpoint,
//   payload = {},
//   annonymous = false
// ) => {
//   if (annonymous) {
//     return await BackendV3.patch(endpoint, payload, {
//       headers: { Authorization: '' },
//     });
//   }
//   return await BackendV3.patch(endpoint, payload, header);
// };
