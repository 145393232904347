import {
  createTimetable,
  deleteTimetable,
  getTimetableByClientId,
  getTimetablesByGroupId,
  updateTimetable,
} from "@app/api/timetable/timetableApi";
import { serviceErrorSlackAlarm } from "@app/utils/alert/slackAlarm";
import {
  QueryClient,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { useSelector } from "react-redux";

export const useTimetableByClientId = (clientId) => {
  const { data: timetable, isFetching, isLoading, isError, refetch } = useQuery(
    {
      queryKey: ["timetable"],
      queryFn: () => getTimetableByClientId(clientId),
      enabled: !!clientId,
    }
  );

  return {
    timetable,
    isFetching,
    isLoading,
    isError,
    refetch,
  };
};

export const useDeleteTimetable = (timetableId) => {
  const { clientId } = useSelector((state) => state.user);
  const { schoolId } = useSelector((state) => state.user);

  const queryClient = new QueryClient();

  const cacheData = useQueryClient();

  const schoolData = cacheData.getQueryData(["school", schoolId]);
  const schoolName = schoolData?.data.name;

  const { mutateAsync, isLoading, isError, isSuccess } = useMutation(
    deleteTimetable,
    {
      onSuccess: () => {
        queryClient.refetchQueries("timetable");
      },
    }
  );

  const deleteTimetableAsync = async () => {
    try {
      await mutateAsync(timetableId);
    } catch (error) {
      serviceErrorSlackAlarm(
        error,
        schoolName,
        schoolId,
        clientId,
        "deleteTimetableAsync",
        "useTimetable.js",
        "56"
      );
    }
  };

  return {
    deleteTimetableAsync,
    isLoading,
    isError,
    isSuccess,
  };
};

// patchTimetable
export const usePatchTimetable = (timetableData) => {
  const { clientId } = useSelector((state) => state.user);
  const { schoolId } = useSelector((state) => state.user);

  const queryClient = new QueryClient();
  const cacheData = useQueryClient();
  const schoolData = cacheData.getQueryData(["school", schoolId]);
  const schoolName = schoolData?.data.name;

  const { mutateAsync, isLoading, isError, isSuccess } = useMutation(
    updateTimetable,
    {
      onSuccess: () => {
        queryClient.refetchQueries("timetable");
      },
    }
  );

  const patchTimetableAsync = async (timetableData) => {
    try {
      await mutateAsync(timetableData);
    } catch (error) {
      serviceErrorSlackAlarm(
        error,
        schoolName,
        schoolId,
        clientId,
        "patchTimetableAsync",
        "useTimetable.js",
        "99"
      );
    }
  };

  return {
    patchTimetableAsync,
    isLoading,
    isError,
    isSuccess,
  };
};

// createTimeTable

export const useCreateTimetable = () => {
  const { clientId } = useSelector((state) => state.user);
  const { schoolId } = useSelector((state) => state.user);

  const queryClient = new QueryClient();
  const cacheData = useQueryClient();
  const schoolData = cacheData.getQueryData(["school", schoolId]);
  const schoolName = schoolData?.data.name;

  const { mutateAsync, isLoading, isError, isSuccess } = useMutation(
    createTimetable,
    {
      onSuccess: () => {
        queryClient.invalidateQueries("timetable");
      },
    }
  );

  const createTimetableAsync = async (timetableData) => {
    try {
      await mutateAsync(timetableData);
    } catch (error) {
      serviceErrorSlackAlarm(
        error,
        schoolName,
        schoolId,
        clientId,
        "createTimetableAsync",
        "useTimetable.js",
        "143"
      );
    }
  };

  return {
    createTimetableAsync,
    isLoading,
    isError,
    isSuccess,
  };
};

export const useTimetableByGroupId = (groupId) => {
  const { data: timetable } = useQuery({
    queryKey: ["timetable", groupId],
    queryFn: () => getTimetablesByGroupId(groupId),
    enabled: !!groupId,
    staleTime: 1000 * 60 * 60 * 18,
    cacheTime: 1000 * 60 * 60 * 24,

    select: (data) => {
      console.log("data!!", data?.data);
      return data?.data;
    },
  });

  return {
    timetable,
  };
};
