import { Tooltip, Typography } from "@mui/material";
import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import FullScreenInviteCodeModal from "../Modal/FullScreenInviteCode";
import { Fullscreen } from "@mui/icons-material";
import { Box, HStack, IconButton, Text, VStack } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

function InclassInviteCode() {
  const { t } = useTranslation();

  const invCode = useSelector((state) => state.groups.invCode);
  const [open, setOpen] = useState(false);

  const formattedCode = useMemo(() => {
    if (!invCode) {
      return "";
    }

    return invCode.match(/.{1,3}/g).join("-");
  }, [invCode]);

  const formattedCodeFirst = useMemo(() => {
    if (!invCode) {
      return "";
    }

    return invCode.slice(0, 3);
  }, [invCode]);

  const formattedCodeSecound = useMemo(() => {
    if (!invCode) {
      return "";
    }

    return invCode.slice(3, 6);
  }, [invCode]);

  const formattedCodeThird = useMemo(() => {
    if (!invCode) {
      return "";
    }
    return invCode.slice(6, 8);
  }, [invCode]);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  let chipStyle;
  let chipLabel;

  if (window.innerWidth > 1200) {
    chipStyle = {
      backgroundColor: "#E4E4E4",
      color: "#212121",
      borderRadius: "8px",
      height: "40px",
      fontSize: "11px",
      fontWeight: "700",
    };
  } else {
    chipStyle = {
      backgroundColor: "#E4E4E4",
      color: "#212121",
      fontSize: "10px",
      fontWeight: "700",

      //수업 참가 코드 반응형
      width: "80px",
      height: "52px",
    };
  }
  if (window.innerWidth > 1200) {
    chipLabel = "학생초대코드" + "\n" + formattedCode;
  } else {
    chipLabel = (
      <>
        <p>학생초대코드</p>
        <p>{formattedCode}</p>
      </>
    );
  }

  return (
    <>
      <Tooltip
        // title="학생들이 수업에 참여할 수 있도록하는 초대코드입니다. 클릭하여 전체화면으로 전환해보세요!"
        title={t("timetable.nav.link.invitationcode.description")}
        arrow
      >
        <Box
          onClick={handleClick}
          w="full"
          borderRadius="lg"
          border="1px"
          borderColor="gray.200"
          p={2}
        >
          <VStack
            spacing={1}
            alignItems="center"
            justifyContent="center"
            w="full"
          >
            <Text fontSize="sm" fontWeight="bold">
              {/* 학생 초대 코드 */}
              {t("timetable.nav.link.invitationcode")}
            </Text>
            <HStack spacing={1} alignItems="center" justifyContent="center">
              <Text fontSize="sm" fontWeight="bold">
                {formattedCode}
              </Text>
              <IconButton variant="ghost" icon={<Fullscreen />} />
            </HStack>
          </VStack>
        </Box>
      </Tooltip>
      <FullScreenInviteCodeModal open={open} onClose={handleClose}>
        {/* <ModalInviteCodeTextWrapper> */}
        <Typography
          variant="h1"
          sx={{
            fontSize: {
              xs: "88px",
              sm: "88px",
              md: "88px",
              lg: "240px",
              xl: "300px",
            },
            fontWeight: "bold",
            textAlign: "center",
            color: "var(--primary)",
          }}
        >
          {formattedCodeFirst +
            " " +
            formattedCodeSecound +
            " " +
            formattedCodeThird}
        </Typography>
        {/* </ModalInviteCodeTextWrapper> */}
      </FullScreenInviteCodeModal>
    </>
  );
}

export default React.memo(InclassInviteCode);
