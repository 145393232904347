import React, { useEffect, useState } from "react";
import TeacherScreen from "./TeacherScreen";
import useRTC from "@app/utils/webRTC/hooks/useRTC";
import StudentThumnail from "./StudentThumnail";
import { Grid, GridItem } from "@chakra-ui/react";
import ClassroomWrapper from "@app/components/Layout/Common/ClassroomWrapper";
import StudentThumnailWrapper from "./StudentThumnailWrapper";
import { useSelector } from "react-redux";
import { selectActiveClassroom } from "@app/store/selectors";
import { useTranslation } from "react-i18next";
import { useProgramconfigs } from "@app/hooks/queries/programconfigs/useProgramConfigs";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useGetPrograms } from "@app/hooks/queries/program/useProgram";

const NewClassroom = () => {
  const { groupId } = useParams();

  const activeClassroom = useSelector((state) => selectActiveClassroom(state));
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);

  useProgramconfigs(groupId);
  useGetPrograms();

  return (
    <ClassroomWrapper
      currentPageName="포커스 타임"
      // currentPageName={t("timetable.focustime.text.title")}
    >
      <Grid
        w="full"
        templateColumns={{
          md: "repeat(1, 1fr)",
          lg: "repeat(1, 1fr)",
          xl: "repeat(3, 1fr)",
        }}
        gap={4}
      >
        <GridItem
          colSpan={{
            md: 1,
            lg: 1,
            xl: 2,
          }}
          mb={4}
        >
          <TeacherScreen
            selectedStudent={selectedStudent}
            setSelectedStudent={setSelectedStudent}
            selectedImage={selectedImage}
            setSelectedImage={setSelectedImage}
          />
        </GridItem>
        <GridItem
          colSpan={{
            md: 1,
            lg: 1,
            xl: 1,
          }}
          mb={4}
        >
          {activeClassroom?.classroomId && (
            <StudentThumnailWrapper
              selectedStudent={selectedStudent}
              setSelectedStudent={setSelectedStudent}
              selectedImage={selectedImage}
              setSelectedImage={setSelectedImage}
            />
          )}
        </GridItem>
      </Grid>
    </ClassroomWrapper>
  );
};
export default NewClassroom;
