import {
  PURGE_PROGRAMCONFIG,
  GET_PROGRAMCONFIG_SUCCESS,
  GET_PROGRAMCONFIG_FAIL,
  GET_GROUP_PROGRAMCONFIGS_SUCCESS,
  GET_GROUP_PROGRAMCONFIGS_FAIL,
  CREATE_PROGRAMCONFIG_SUCCESS,
  CREATE_PROGRAMCONFIG_FAIL,
  UPDATE_PROGRAMCONFIG_SUCCESS,
  UPDATE_PROGRAMCONFIG_FAIL,
  DELETE_PROGRAMCONFIG_SUCCESS,
  DELETE_PROGRAMCONFIG_FAIL
} from "./actionTypes";
import { createPromiseAction } from "@adobe/redux-saga-promise";

export const purgeProgramconfig = () => ({
  type: PURGE_PROGRAMCONFIG
});

///// GET_PROGRAMCONFIG
export const getProgramconfigPromise = createPromiseAction(
  "GET_PROGRAMCONFIG_PROMISE"
);

export const getProgramconfigSuccess = programconfigData => ({
  type: GET_PROGRAMCONFIG_SUCCESS,
  programconfig: programconfigData
});

export const getProgramconfigFail = error => ({
  type: GET_PROGRAMCONFIG_FAIL,
  payload: error
});

///// GET_GROUP_PROGRAMCONFIGS
export const getGroupProgramconfigsPromise = createPromiseAction(
  "GET_GROUP_PROGRAMCONFIGS_PROMISE"
);

export const getGroupProgramconfigsSuccess = programconfigsData => {
  let byId = {};
  programconfigsData.forEach(programconfigData => {
    byId[programconfigData.programconfigId] = programconfigData;
  });

  const allIds = programconfigsData.map(
    programconfigData => programconfigData.programconfigId
  );
  return {
    type: GET_GROUP_PROGRAMCONFIGS_SUCCESS,
    byId: byId,
    allIds: allIds
  };
};

export const getGroupProgramconfigsFail = error => ({
  type: GET_GROUP_PROGRAMCONFIGS_FAIL,
  payload: error
});

//// CREATE_PROGRAMCONFIG
export const createProgramconfigPromise = createPromiseAction(
  "CREATE_PROGRAMCONFIG_PROMISE"
);

export const createProgramconfigSuccess = programconfigData => ({
  type: CREATE_PROGRAMCONFIG_SUCCESS,
  programconfig: programconfigData
});

export const createProgramconfigFail = error => ({
  type: CREATE_PROGRAMCONFIG_FAIL,
  payload: error
});

//// UPDATE_PROGRAMCONFIG
export const updateProgramconfigPromise = createPromiseAction(
  "UPDATE_PROGRAMCONFIG_PROMISE"
);

export const updateProgramconfigSuccess = programconfigData => ({
  type: UPDATE_PROGRAMCONFIG_SUCCESS,
  programconfig: programconfigData
});

export const updateProgramconfigFail = error => ({
  type: UPDATE_PROGRAMCONFIG_FAIL,
  payload: error
});

//// DELETE_PROGRAMCONFIG
export const deleteProgramconfigPromise = createPromiseAction(
  "DELETE_PROGRAMCONFIG_PROMISE"
);

export const deleteProgramconfigSuccess = programconfigData => ({
  type: DELETE_PROGRAMCONFIG_SUCCESS,
  programconfig: programconfigData
});

export const deleteProgramconfigFail = error => ({
  type: DELETE_PROGRAMCONFIG_FAIL,
  payload: error
});
