import React from "react";
import HeadingText from "./HeadingText";
import { TextEditorForQuizpang } from "@app/components/Input/TextEditorForQuizpang";
import { Stack } from "@chakra-ui/react";

const DescripitonEditor = ({ headingText, value, handleSurveyDescription }) => {
  return (
    <Stack spacing={4} w="full" overflow="hidden">
      <HeadingText headingText={headingText} />
      <TextEditorForQuizpang
        style={{ height: "300px", overflow: "hidden" }}
        isFile={false}
        value={value}
        onChange={handleSurveyDescription}
      />
    </Stack>
  );
};

export default DescripitonEditor;
