import React from "react";
import QuizTypeWrapper from "./Common/QuizTypeWrapper";
import { HStack, Heading, Input } from "@chakra-ui/react";
import { TextEditorForQuizpang } from "../Input/TextEditorForQuizpang";

const QuizTypeLongInput = ({ sampleAnswer, setSampleAnswer }) => {
  return (
    <QuizTypeWrapper>
      <Heading fontSize={"lg"}>3. 모범답안</Heading>
      <TextEditorForQuizpang
        onChange={setSampleAnswer}
        value={sampleAnswer}
        style={{ height: "150px" }}
        isFile={false}
      />
    </QuizTypeWrapper>
  );
};

export default QuizTypeLongInput;
