import { createStore, applyMiddleware, compose } from "redux";
import * as Sentry from "@sentry/react";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { promiseMiddleware } from "@adobe/redux-saga-promise";
import createSagaMiddleware from "redux-saga";
import rootReducer from "./reducers";
import rootSaga from "./sagas";

// import firebase from "firebase/compat/app";
// import { firestore } from "firebase/app";
// import { getFirestore } from "firebase/firestore";
// import firestore from "firebase/firestore";
// import "firebase/auth";
// import "firebase/firestore"; // <- needed if using firestore
// import "firebase/database";
// import "firebase/compat/auth";
// import "firebase/compat/firestore";
// import "firebase/compat/storage";
// import "firebase/compat/database";

// import {  combineReducers, compose } from "redux";
// import {
//   ReactReduxFirebaseProvider,
//   firebaseReducer,
// } from "react-redux-firebase";
// import { createFirestoreInstance, firestoreReducer } from "redux-firestore";

// const fbConfig = {
//   apiKey: "AIzaSyCZMiWjBJnwsBgrssoxAOiJ9HLNKInn0I8",
//   authDomain: "tripler-firebase.firebaseapp.com",
//   projectId: "tripler-firebase",
//   storageBucket: "tripler-firebase.appspot.com",
//   messagingSenderId: "699937112853",
//   appId: "1:699937112853:web:60b85033732ef881869f1c",
//   measurementId: "G-YET0FCZD4M",
// };

// react-redux-firebase config
// const rrfConfig = {
//   userProfile: "users",
//   useFirestoreForProfile: false, // Firestore for Profile instead of Realtime DB
// };

// Initialize firebase instance
// if (!firebase.apps.length) {
//   firebase.initializeApp(fbConfig);
//   firebase.firestore();
//   firebase.storage();
//   // firebase.app().firestore(); // <- needed if using firestore
// }

// persist
// use showAsyncStorageContentInDev() to show cached contents.
const persistConfig = {
  key: "root",
  storage: storage,
  blacklist: [
    "control",
    "managing",
    "groupActivity",
    "commentsLayer",
    "managed",
    "reportEvents",
    "reportLog",
  ],
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

// saga
const sagaMiddleware = createSagaMiddleware();

const middlewares = [promiseMiddleware, sagaMiddleware];

// debugger
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const sentryReduxEnhancer = Sentry.createReduxEnhancer({});

export const store = createStore(
  persistedReducer,
  // rootReducer,
  composeEnhancers(applyMiddleware(...middlewares), sentryReduxEnhancer)
  // applyMiddleware(...middlewares)
);

sagaMiddleware.run(rootSaga);

// export const rrfProps = {
//   firebase,
//   config: rrfConfig,
//   dispatch: store.dispatch,
//   createFirestoreInstance, // <- needed if using firestore
// };

export default store;
