import { useQuery } from "@tanstack/react-query";
import { getSchoolInformationBySchoolId } from "../schoolApi";

export const useSchoolBySchoolId = (schoolId) => {
  const { data: school } = useQuery({
    queryKey: ["school", schoolId],
    queryFn: () => getSchoolInformationBySchoolId(schoolId),
    enabled: !!schoolId,
    refetchOnWindowFocus: false,
    retry: false,
    //staletime 18시간
    staleTime: 1000 * 60 * 60 * 18,
    // 캐시타임 하루
    cacheTime: 1000 * 60 * 60 * 24,
  });

  return { school };
};
