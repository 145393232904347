import { Box, FormLabel } from "@chakra-ui/react";
import React from "react";
import TextInput from "./TextInput";

const SurveyLabelInput = ({ name, value, handleSurveryFormChange }) => {
  console.log(name, value)
  return (
    <Box>
      <FormLabel>설문지 제목</FormLabel>
      <TextInput
        placeholder="설문지 제목을 입력해주세요.(최대 30자)"
        name={name}
        onChange={handleSurveryFormChange}
        value={value}
      />
    </Box>
  );
};

export default SurveyLabelInput;
