export const PURGE_MANAGING = "PURGE_MANAGING";

export const GET_MANAGING_SUCCESS = "GET_MANAGING_SUCCESS";
export const GET_MANAGING_FAIL = "GET_MANAGING_FAIL";

export const GET_MANAGINGS_BY_GROUP_SUCCESS = "GET_MANAGINGS_BY_GROUP_SUCCESS";
export const GET_MANAGINGS_BY_GROUP_FAIL = "GET_MANAGINGS_BY_GROUP_FAIL";

export const GET_ACTIVE_MANAGING_SUCCESS = "GET_ACTIVE_MANAGING_SUCCESS";
export const GET_ACTIVE_MANAGING_FAIL = "GET_ACTIVE_MANAGING_FAIL";

export const GET_ACTIVE_MANAGING_BY_GROUP_SUCCESS =
  "GET_ACTIVE_MANAGING_BY_GROUP_SUCCESS";
export const GET_ACTIVE_MANAGING_BY_GROUP_FAIL =
  "GET_ACTIVE_MANAGING_BY_GROUP_FAIL";

export const SET_ACTIVE_MANAGING_EXIST = "SET_ACTIVE_MANAGING_EXIST";
export const SET_ACTIVE_MANAGING_NOT_EXIST = "SET_ACTIVE_MANAGING_NOT_EXIST";

export const CREATE_MANAGING_SUCCESS = "CREATE_MANAGING_SUCCESS";
export const CREATE_MANAGING_FAIL = "CREATE_MANAGING_FAIL";

export const UPDATE_MANAGING_LOADING = "UPDATE_MANAGING_LOADING";
export const UPDATE_MANAGING_SUCCESS = "UPDATE_MANAGING_SUCCESS";
export const UPDATE_MANAGING_FAIL = "UPDATE_MANAGING_FAIL";

export const DELETE_MANAGING_SUCCESS = "DELETE_MANAGING_SUCCESS";
export const DELETE_MANAGING_FAIL = "DELETE_MANAGING_FAIL";

export const SET_ACTIVE_MANAGING_SESSION = "SET_ACTIVE_MANAGING_SESSION";
