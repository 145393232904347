import { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { TextField } from "@mui/material";

const useStyles = makeStyles(theme => ({
  root: {
    "& .MuiFilledInput-root": {
      marginBottom: 20,
      borderBottom: "none"
    },
    "& .MuiFilledInput-root.Mui-focused": {
      backgroundColor: "#0BA1AE15"
    },
    "& .MuiFilledInput-root:before": {
      borderBottom: "none"
    },
    "& .MuiFilledInput-root:hover:not(.Mui-disabled):before": {
      borderBottom: "none"
    },
    "& .MuiFilledInput-root.Mui-error": {
      backgroundColor: "#F4433615"
    },
    "& .MuiFormHelperText-root.Mui-error": {
      position: "absolute",
      bottom: "0px"
    }
  }
}));

const MdInput = props => {
  const { name, label, variant, inputProps, type, defaultValue } = props;

  const [value, setValue] = useState(defaultValue);
  const [showWarning, setShowWarning] = useState(false);
  const [emailRegexWarning, setEmailRegexWarning] = useState(false);
  const [emailPrefixWarning, setEmailPrefixWarning] = useState(false);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const classes = useStyles();

  const handleBlur = event => {
    const newValue = event.target.value;

    if (type === "email") {
      const emailRegex = /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i;
      if (!emailRegex.test(newValue) && newValue.length > 0) {
        setEmailRegexWarning(true);
        if (!showWarning) {
          setShowWarning(true);
        }
      } else {
        setEmailRegexWarning(false);
        if (showWarning && !emailPrefixWarning) {
          setShowWarning(false);
        }
      }
    }
  };

  const handleChange = event => {
    const newValue = event.target.value;
    setValue(newValue);

    if (type === "email") {
      const emailPrefix = newValue.split("@")[0];
      if (emailPrefix.length > 30) {
        setEmailPrefixWarning(true);
        if (!showWarning) {
          setShowWarning(true);
        }
      } else {
        setEmailPrefixWarning(false);
        if (showWarning && !emailRegexWarning) {
          setShowWarning(false);
        }
      }
    } else {
      setShowWarning(false);
    }
  };

  return (
    <>
      <TextField
        {...props}
        name={name}
        label={label}
        type="text"
        fullWidth
        value={value}
        variant={variant ?? "filled"}
        InputProps={inputProps}
        className={classes.root}
        onBlur={handleBlur}
        onChange={handleChange}
        error={showWarning}
        helperText={
          showWarning
            ? emailRegexWarning
              ? "이메일 형식이 올바르지 않습니다."
              : emailPrefixWarning
              ? "이메일 앞자리는 30자 이내로 입력해주세요."
              : ""
            : ""
        }
      />
    </>
  );
};

export default MdInput;
