import {
  PURGE_SCHOOL,
  GET_SCHOOL_SUCCESS,
  GET_SCHOOL_FAIL,
  GET_SCHOOL_BY_INVITE_CODE_SUCCESS,
  GET_SCHOOL_BY_INVITE_CODE_FAIL
} from "./actionTypes";
import { createPromiseAction } from "@adobe/redux-saga-promise";

export const purgeSchool = () => ({
  type: PURGE_SCHOOL
});

///// GET_SCHOOL
export const getSchoolPromise = createPromiseAction("GET_SCHOOL_PROMISE");

export const getSchoolSuccess = schoolData => ({
  type: GET_SCHOOL_SUCCESS,
  school: schoolData
});

export const getSchoolFail = error => ({
  type: GET_SCHOOL_FAIL,
  payload: error
});

export const getSchoolByInviteCodeSuccess = schoolData => ({
  type: GET_SCHOOL_BY_INVITE_CODE_SUCCESS,
  school: schoolData
});

export const getSchoolByInviteCodeFail = error => ({
  type: GET_SCHOOL_BY_INVITE_CODE_FAIL,
  payload: error
});
