import {
  GET_ACCOUNT_TOKEN_FAIL,
  GET_ACCOUNT_TOKEN_SUCCESS,
  PURGE_ACCOUNT
} from "./actionTypes";
import * as api from "@api";
import { createPromiseAction } from "@adobe/redux-saga-promise";

export const purgeAccount = () => ({
  type: PURGE_ACCOUNT
});

export const getAccountTokenPromise = createPromiseAction(
  "GET_ACCOUNT_TOKEN_PROMISE"
);

export const getAccountTokenSuccess = accountTokenData => {
  return {
    type: GET_ACCOUNT_TOKEN_SUCCESS,
    accountToken: accountTokenData
  };
};

export const getAccountTokenFail = error => {
  return {
    type: GET_ACCOUNT_TOKEN_FAIL,
    payload: error
  };
};
