import React from "react";
import GroupManager from "@managers/GroupManager";
import ClassManager from "@managers/ClassManager";
import AuthManager from "@managers/AuthManager";
import ConnectionManager from "@managers/ConnectionManager";
// import ReportManager from './ReportManager';
import ClassroomManager from "./ClassroomManager";
import RefreshManager from "./RefreshManager";
import AlertManager from "./AlertManager";
import SiteManager from "./SiteManager";
import EventLogManager from "./EventLogManager";

const MasterManager = () => {
  return (
    <AuthManager>
      {/* <ReportManager /> */}
      <AlertManager />
      <RefreshManager />
      <SiteManager />
      <GroupManager />
      <ClassManager />
      <ClassroomManager />
      <ConnectionManager />
      {/* <EventLogManager /> */}
    </AuthManager>
  );
};

export default MasterManager;
