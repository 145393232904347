import {
  classGrouplist,
  classtimes,
  gradeList,
  weekdays,
} from "@app/Constants/groupTimetable";
import {
  socketKind,
  socketMethod,
  socketUrl,
} from "@app/Constants/socketConstants";
import {
  useCreateTimetable,
  useDeleteTimetable,
  usePatchTimetable,
  useTimetableByClientId,
} from "@app/pages/Home/hooks/useTimetable";
import {
  createGroupPromise,
  createMembershipPromise,
  deleteGroupPromise,
  setSocketData,
  updateGroupPromise,
} from "@app/store/actions";
import { selectAllMyGroups } from "@app/store/selectors";

import { getTimetableByGroupId } from "@app/utils/timetable/timetableUtils";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { useCreateTimetableValidate } from "@app/hooks/validate/useCreateTimetableValidate";
import { ButtonConstants } from "@app/Constants/ButtonConstants";
import { useTranslation } from "react-i18next";

export const ModalCreateTimetable = ({
  show,
  onHide,
  timetableData,
  timetableList,
}) => {
  const { t } = useTranslation();

  const allMyGroups = useSelector((state) => selectAllMyGroups(state));
  const initTimetableData = {
    week: "",
    period: "",
    startTime: "09:00",
    endTime: "09:45",
  };
  const initGradeData = {
    timetable: [{ ...initTimetableData }],
    groupType: "SUBJECT",
    groupName: "",
    grade: "",
  };
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const { clientId, schoolId } = useSelector((state) => state.user);
  const [timeCheck, setTimeCheck] = useState(false);
  const [timeCheckArray, setTimeCheckArray] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState("");

  const {
    isDisabled,
    isError,
    addInputRef,
    validate,
    setIsDisabled,
  } = useCreateTimetableValidate();
  const createTimeTable = useCreateTimetable();
  const getTimetableData = useTimetableByClientId(user.clientId);

  const findGroupId = async (grade, groupName, subjectName) => {
    const group = allMyGroups.find(
      (group) =>
        group.grade === grade &&
        group.groupName.toString() === groupName.toString() &&
        group.subjectName === subjectName
    );
    return group?.groupId;
  };

  const [creatingFields, setCreatingFields] = useState([
    {
      ...initGradeData,
      timetable: [
        {
          ...initTimetableData,
          week: timetableData.weekDay,
          period: timetableData.period,
        },
      ],
    },
  ]);

  useEffect(() => {
    creatingFields.map((field) => {
      field.timetable.map((time) => {
        const key = `${time.week}${time.period}`;
        if (time.endTime > time.startTime) {
          setTimeCheckArray((prev) => ({ ...prev, [key]: false }));
        }
        if (time.endTime <= time.startTime) {
          setTimeCheckArray((prev) => ({ ...prev, [key]: true }));
        }
      });
    });
  }, [creatingFields]);

  const [subjectName, setSubjectName] = useState(
    timetableData.subjectName || ""
  );

  // const timetablefilter = getTimetableByGroupId(
  //   timetableList,
  //   timetableData.groupId
  // );

  // const onAddGroup = () => {
  //   setCreatingFields([
  //     ...creatingFields,
  //     {
  //       ...initGradeData,
  //       timetable: [{ ...initTimetableData }],
  //     },
  //   ]);
  // };

  //   const onAddTimetable = (fieldIndex) => {
  //     let _createFields = [...creatingFields];
  //     _createFields[fieldIndex].timetable.push({ ...initTimetableData });
  //     setCreatingFields(_createFields);
  //   };

  const onAddTimetable = (fieldIndex) => {
    let _createFields = [...creatingFields];
    _createFields[fieldIndex].timetable.push({ ...initTimetableData });
    setCreatingFields(_createFields);
    setIsDisabled(true);
  };

  const onRemoveTimetable = (fieldIndex, timeIndex) => {
    let _createFields = [...creatingFields];
    _createFields[fieldIndex].timetable.splice(timeIndex, 1);
    if (!_createFields[fieldIndex].timetable.length) {
      _createFields.splice(fieldIndex, 1);
    }
    setCreatingFields(_createFields);
  };
  const onChangeGroup = (fieldIndex, e) => {
    const { name, value } = e.target;
    let _createFields = [...creatingFields];
    _createFields[fieldIndex][name] = value;
    const groupName = _createFields[0][name];
    console.log(groupName === "");
    setCreatingFields(_createFields);
  };
  const onChangeTimetable = (fieldIndex, timeIndex, e) => {
    const { name, value } = e.target;
    let _createFields = [...creatingFields];
    _createFields[fieldIndex].timetable[timeIndex][name] = value;
    setCreatingFields(_createFields);
  };

  const onCreate = async () => {
    setIsLoading(true);
    try {
      let allOperationsCompleted = false;
      for (let i = 0; i < creatingFields.length; i++) {
        const field = creatingFields[i];
        const groupData = {
          schoolId: schoolId,
          state: "CREATED",
          groupType: field.groupType,
          subjectName,
          groupName: field.groupName,
          grade: field.grade,
        };

        // 중복된 학년과 반 그리고 수업 명이 있는지 확인하는 로직
        const duplicateGroup = await findGroupId(
          field.grade,
          field.groupName,
          subjectName
        );

        // timetableDat에서 중복된 시간표가 있는지 입력한 grade, groupName, subjectName으로 필터링
        // const duplicateGroup = getTimetableListData.filter((timetable) =>
        //   console.log()
        // );

        // 중복된 학년과 반 그리고 수업 명이 없다면 새로운 그룹을 생성한 뒤 그룹 데이터를 가지고 멤버쉽을 생성한다 그 다음 시간표를 생성한다.
        if (!duplicateGroup) {
          const createdGroup = await dispatch(createGroupPromise(groupData));
          dispatch(
            createMembershipPromise({
              groupId: createdGroup.groupId,
              clientId: user.clientId,
              role: "TEACHER",
            })
          );

          await Promise.all(
            field.timetable.map(async (time) => {
              const timetableData = {
                groupId: createdGroup.groupId,
                weekday: time.week,
                period: time.period,
                startTime: time.startTime,
                endTime: time.endTime,
              };
              // await dispatch(createTimetablePromise(timetableData));
              await createTimeTable.createTimetableAsync(timetableData);
              return true;
            })
          );
        } else {
          await Promise.all(
            field.timetable.map(async (time) => {
              const timetableData = {
                groupId: duplicateGroup,
                weekday: time.week,
                period: time.period,
                startTime: time.startTime,
                endTime: time.endTime,
              };
              // await dispatch(createTimetablePromise(timetableData));
              await createTimeTable.createTimetableAsync(timetableData);
              return true;
            })
          );
        }
      }
      allOperationsCompleted = true;

      if (allOperationsCompleted) {
        getTimetableData.refetch();
        setIsSuccess(true);
        setFeedbackMessage("수업 생성이 완료되었습니다");
        setTimeout(() => {
          onHide();
          setIsSuccess(false);
        }, 1000);
      }
    } catch (error) {
      console.error("시간표 onCreate 에러: ", error);
      alert("수업 생성에 실패했습니다. 다시 시도해주세요.");
      window.location.reload();
    }
    setIsLoading(false);
  };

  return (
    <Dialog
      open={show}
      onClose={onHide}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="xl"
    >
      <DialogTitle>
        {/* 시간표 생성 */}
        {t("timetable.addtimetable.text.title")}
      </DialogTitle>
      <DialogContent dividers>
        {creatingFields.map((field, fieldIndex) => (
          <Box key={fieldIndex} sx={{ mt: 2 }}>
            <Grid container>
              <Grid xs={12} md={12}>
                <Typography variant="body1">
                  {/* 과목명 */}
                  {t("timetable.addtimetable.text.subjectname")}
                </Typography>
                <TextField
                  id="subjectName"
                  name="subjectName"
                  variant="outlined"
                  onChange={(e) => setSubjectName(e.target.value)}
                  value={subjectName}
                  onBlur={validate}
                  inputRef={(ref) => addInputRef("subjectName", ref)}
                  sx={{ width: "40%" }}
                  inputProps={{
                    maxLength: 25,
                  }}
                />
                {isError.subjectName && (
                  <p className="text-red-600">
                    {/* 과목을 작성해주세요. */}
                    {t("timetable.addtimetable.text.subjectname.error")}
                  </p>
                )}
              </Grid>
              <Grid xs={2} md={2}>
                <Typography variant="body1">
                  {/* 학년 */}
                  {t("timetable.addtimetable.text.grade")}
                </Typography>
                <FormControl
                  variant="outlined"
                  sx={{
                    width: "90%",
                  }}
                >
                  <Select
                    value={field.grade}
                    onChange={(e) => onChangeGroup(fieldIndex, e)}
                    name="grade"
                    onBlur={validate}
                    inputRef={(ref) => addInputRef("grade", ref)}
                  >
                    {gradeList.map((grade, idx) => (
                      <MenuItem key={idx} value={grade[0]}>
                        {t(grade[1])}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {isError.grade && (
                  <p className="text-red-600">
                    {/* 학년을 선택해주세요. */}
                    {t("timetable.addtimetable.text.grade.error")}
                  </p>
                )}
              </Grid>

              <Grid xs={2} md={2}>
                <Typography variant="body1">
                  {/* 반 */}
                  {t("timetable.addtimetable.text.class")}
                </Typography>
                <FormControl
                  variant="outlined"
                  sx={{
                    width: "90%",
                  }}
                >
                  <Select
                    value={field.groupName}
                    onChange={(e) => onChangeGroup(fieldIndex, e)}
                    name="groupName"
                    onBlur={validate}
                    inputRef={(ref) => addInputRef("groupName", ref)}
                  >
                    {classGrouplist.map((group, idx) => (
                      <MenuItem key={idx} value={group[0]}>
                        {t(group[1])}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {isError.groupName && (
                  <p className="text-red-600">
                    {/* 반을 선택해주세요. */}
                    {t("timetable.addtimetable.text.class.error")}
                  </p>
                )}
              </Grid>

              {field.timetable.map((time, timeIndex) => (
                <>
                  {timeIndex !== 0 && <Grid xs={4} md={4}></Grid>}
                  <Grid xs={2} md={2} mt={timeIndex !== 0 ? 2 : 0}>
                    <Typography variant="body1">
                      {/* 요일 */}
                      {t("timetable.addtimetable.text.week")}
                    </Typography>
                    <FormControl
                      variant="outlined"
                      sx={{
                        width: "90%",
                      }}
                    >
                      <Select
                        value={time.week}
                        onChange={(e) =>
                          onChangeTimetable(fieldIndex, timeIndex, e)
                        }
                        onBlur={validate}
                        inputRef={(ref) => addInputRef("week", ref)}
                        name="week"
                      >
                        {weekdays.map((weekday, idx) => (
                          <MenuItem key={idx} value={weekday[0]}>
                            {t(weekday[1])}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    {!weekdays[time.week - 1] && isError.week && (
                      <p className="text-red-600">
                        {/* 요일을 선택해주세요. */}
                        {t("timetable.addtimetable.text.week.error")}
                      </p>
                    )}
                  </Grid>
                  <Grid xs={2} md={2} mt={timeIndex !== 0 ? 2 : 0}>
                    <Typography variant="body1">
                      {/* 교시 */}
                      {t("timetable.addtimetable.text.period")}
                    </Typography>
                    <FormControl
                      variant="outlined"
                      sx={{
                        width: "80%",
                      }}
                    >
                      <Select
                        value={time.period}
                        onChange={(e) =>
                          onChangeTimetable(fieldIndex, timeIndex, e)
                        }
                        onBlur={validate}
                        inputRef={(ref) => addInputRef("period", ref)}
                        name="period"
                      >
                        {classtimes.map((classtime, idx) => (
                          <MenuItem key={idx} value={classtime[0]}>
                            {t(classtime[1])}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    {!classtimes[time.period - 1] && isError.period && (
                      <p className="text-red-600">
                        {/* 교시를 선택해주세요. */}
                        {t("timetable.addtimetable.text.period.error")}
                      </p>
                    )}
                  </Grid>
                  <Grid xs={2} md={2} mt={timeIndex !== 0 ? 2 : 0}>
                    <Typography variant="body1">
                      {/* 시작 시간 */}
                      {t("timetable.addtimetable.text.starttime")}
                    </Typography>
                    <TextField
                      id="startTime"
                      name="startTime"
                      type="time"
                      value={time.startTime}
                      onChange={(e) =>
                        onChangeTimetable(fieldIndex, timeIndex, e)
                      }
                      onBlur={validate}
                      inputRef={(ref) => addInputRef("startTime", ref)}
                      sx={{
                        width: "90%",
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    {isError.startTime && (
                      <p className="text-red-600">
                        {/* 시작 시간을 입력해주세요. */}
                        {t("timetable.addtimetable.text.starttime.error")}
                      </p>
                    )}
                  </Grid>
                  <Grid xs={2} md={2} mt={timeIndex !== 0 ? 2 : 0}>
                    <Typography variant="body1">
                      {/* 종료 시간 */}
                      {t("timetable.addtimetable.text.endtime")}
                    </Typography>
                    <Stack direction="row">
                      <TextField
                        id="endTime"
                        name="endTime"
                        type="time"
                        value={time.endTime}
                        onBlur={validate}
                        inputRef={(ref) => addInputRef("endTime", ref)}
                        onChange={(e) =>
                          onChangeTimetable(fieldIndex, timeIndex, e)
                        }
                        sx={{
                          width: "90%",
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                      {timeIndex !== 0 && (
                        <IconButton
                          onClick={() =>
                            onRemoveTimetable(fieldIndex, timeIndex)
                          }
                        >
                          <RemoveCircleOutlineIcon />
                        </IconButton>
                      )}
                    </Stack>
                  </Grid>
                </>
              ))}
              {isError.endTime && (
                <p className="text-red-600">
                  {/* 종료 시간을 입력해주세요. */}
                  {t("timetable.addtimetable.text.endtime.error")}
                </p>
              )}
            </Grid>
            <Divider sx={{ mt: 2, mb: 2 }} />
            <Button
              variant="contained"
              onClick={() => onAddTimetable(fieldIndex)}
              disabled={isDisabled}
            >
              {/* 수업 시간 추가하기 */}
              {t("timetable.addtimetable.button.addtime")}
            </Button>
          </Box>
        ))}
      </DialogContent>
      <DialogActions>
        <div style={{ flexGrow: 1 }} />
        {feedbackMessage && (
          <Typography
            color="green"
            style={{ marginLeft: "10px", marginRight: "10px" }}
          >
            {feedbackMessage}
          </Typography>
        )}
        <Button
          onClick={() => {
            onHide();
          }}
          variant="contained"
        >
          {/* 취소 */}
          {t("timetable.addtimetable.button.cancel")}
        </Button>
        <Button
          onClick={() => {
            onCreate();
          }}
          disabled={isDisabled || isLoading || isSuccess}
          variant="contained"
          style={{ minWidth: "max-content" }}
        >
          {isLoading ? (
            <CircularProgress size={24} />
          ) : isSuccess ? (
            <CheckIcon />
          ) : (
            // "추가"
            t("timetable.addtimetable.button.submit")
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
