import { Spinner, Text, VStack } from "@chakra-ui/react";
import React from "react";

const Loading = ({ text }) => {
  return (
    <VStack w="full" mt="16" spacing="2">
      <Spinner />
      <Text>{text}</Text>
    </VStack>
  );
};

export default Loading;
