import {
  PURGE_PROGRAM,
  GET_PROGRAM_SUCCESS,
  GET_PROGRAM_FAIL,
  GET_PROGRAMS_SUCCESS,
  GET_PROGRAMS_FAIL
} from "./actionTypes";
import { createPromiseAction } from "@adobe/redux-saga-promise";

export const purgeProgram = () => ({
  type: PURGE_PROGRAM
});

///// GET_PROGRAM
export const getProgramPromise = createPromiseAction("GET_PROGRAM_PROMISE");

export const getProgramSuccess = programData => ({
  type: GET_PROGRAM_SUCCESS,
  program: programData
});

export const getProgramFail = error => ({
  type: GET_PROGRAM_FAIL,
  payload: error
});

///// GET_PROGRAMS

export const getProgramsPromise = createPromiseAction("GET_PROGRAMS_PROMISE");

export const getProgramsSuccess = programsData => {
  let byId = {};
  programsData.forEach(programData => {
    byId[programData.programId] = programData;
  });

  const allIds = programsData.map(programData => programData.programId);
  return {
    type: GET_PROGRAMS_SUCCESS,
    byId: byId,
    allIds: allIds
  };
};

export const getProgramsFail = error => ({
  type: GET_PROGRAMS_FAIL,
  payload: error
});
