import { getBackend } from '@api/Backend';
import { deleteBackend, patchBackend, postBackend } from '../Backend';

export const getTimetableByClientId = async (clientId) => {
  return await getBackend(`timetable?clientId=${clientId}`);
};

export const getTimetablesByGroupId = async (groupId) => {
  return await getBackend(`timetable?groupId=${groupId}`);
};

export const getTimetableByTimetableId = async (timetableId) => {
  return await getBackend(`timetable?timetableId=${timetableId}`);
};

export const updateTimetable = async (timetableData) => {
  return await patchBackend('timetable', timetableData);
};

export const deleteTimetable = async (timetableId) => {
  return await deleteBackend(`timetable/${timetableId}`);
};

export const createTimetable = async (timetableData) => {
  return await postBackend('timetable', timetableData);
};
