import { purgeManaged } from "../actions";
import { makeFreshAllIds } from "../helpers";
import {
  PURGE_MANAGED,
  GET_MANAGED_SUCCESS,
  GET_MANAGED_FAIL,
  GET_MANAGEDS_BY_CLIENT_SUCCESS,
  GET_MANAGEDS_BY_CLIENT_FAIL,
  GET_MANAGEDS_BY_MANAGING_SUCCESS,
  GET_MANAGEDS_BY_MANAGING_FAIL,
  CREATE_MANAGED_SUCCESS,
  CREATE_MANAGED_FAIL,
  UPDATE_MANAGED_SUCCESS,
  UPDATE_MANAGED_FAIL,
  DELETE_MANAGED_SUCCESS,
  DELETE_MANAGED_FAIL,
  GET_MANAGEDS_BY_GROUP_SUCCESS,
  GET_MANAGEDS_BY_GROUP_FAIL,
} from "./actionTypes";

const initialState = {
  byId: {},
  allIds: [],
};

const managed = (state = initialState, action) => {
  switch (action.type) {
    case PURGE_MANAGED:
      return initialState;

    case GET_MANAGED_SUCCESS:
    case CREATE_MANAGED_SUCCESS:
    case UPDATE_MANAGED_SUCCESS:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.managed.clientId]: action.managed,
        },
        allIds: makeFreshAllIds(state.allIds, [action.managed.clientId]),
      };
    case DELETE_MANAGED_SUCCESS:
      return state;

    case GET_MANAGEDS_BY_CLIENT_SUCCESS:
    case GET_MANAGEDS_BY_MANAGING_SUCCESS:
    case GET_MANAGEDS_BY_GROUP_SUCCESS:
      return {
        ...state,
        byId: { ...state.byId, ...action.byId },
        allIds: makeFreshAllIds(state.allIds, action.allIds),
      };

    case GET_MANAGEDS_BY_GROUP_FAIL:
    case GET_MANAGED_FAIL:
    case GET_MANAGEDS_BY_CLIENT_FAIL:
    case GET_MANAGEDS_BY_MANAGING_FAIL:
      return initialState;
    case CREATE_MANAGED_FAIL:
    case UPDATE_MANAGED_FAIL:
    case DELETE_MANAGED_FAIL:
      // console.error("[Managed Reducer] ", action.payload);
      return state;

    default:
      break;
  }
  return state;
};

export default managed;
