import { Stack } from "@chakra-ui/react";
import React from "react";
import HeadingText from "./HeadingText";
import MultipleTypeSelect from "./SurveyType/Multiple/MultipleTypeSelect";
import TrueFalse from "./SurveyType/TrueFalse/TrueFalse";

const SurveyTypeEditor = ({
  surveyType,
  multipleType,
  handleMultipleTypeChange,
  choices,
  handleChoicesAddClick,
  handleChoicesDeleteClick,
  handlChoiceDescriptionChange,
  handleEmptyChoices,
  radioSetChoices,
}) => {
  const renderSurveyType = (surveyType) => {
    switch (surveyType) {
      case "MULTIPLE":
        return (
          <MultipleTypeSelect
            value={multipleType}
            handleMultipleTypeChange={handleMultipleTypeChange}
            choices={choices}
            handleChoicesAddClick={handleChoicesAddClick}
            handleChoicesDeleteClick={handleChoicesDeleteClick}
            handlChoiceDescriptionChange={handlChoiceDescriptionChange}
            handleEmptyChoices={handleEmptyChoices}
            radioSetChoices={radioSetChoices}
          />
        );
        case "OX":
          return <TrueFalse choices={choices}/>
      default:
        return null;
    }
  };

  return (
    <Stack spacing={4} w="full" overflow="hidden">
      <HeadingText headingText="3. 보기입력" />
      {renderSurveyType(surveyType)}
    </Stack>
  );
};

export default SurveyTypeEditor;
