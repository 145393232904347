import { call, put, takeLeading } from "redux-saga/effects";
import {
  getQuizListAllPromise,
  getQuizListAllSuccess,
  getQuizListAllFail,
  getQuizListByClientPromise,
  getQuizListByClientSuccess,
  getQuizListByClientFail,
  getQuizListBySchoolPromise,
  getQuizListBySchoolSuccess,
  getQuizListBySchoolFail,
  getQuizCategoryAllPromise,
  getQuizCategoryAllSuccess,
  getQuizCategoryAllFail,
  createQuizPromise,
  createQuizSuccess,
  createQuizFail,
  updateQuizPromise,
  updateQuizSuccess,
  updateQuizFail,
  deleteQuizPromise,
  deleteQuizSuccess,
  deleteQuizFail
} from "@store/actions";
import * as api from "@api";
import { implementPromiseAction } from "@adobe/redux-saga-promise";

function* getQuizListAllPromiseHandler(action) {
  yield call(implementPromiseAction, action, function*() {
    try {
      const response = yield call(api.getQuizListAll, action.payload);
      yield put(getQuizListAllSuccess(response.data.quizs));
      return yield response.data;
    } catch (error) {
      yield put(getQuizListAllFail(error));
    }
  });
}

function* getQuizListByClientPromiseHandler(action) {
  yield call(implementPromiseAction, action, function*() {
    try {
      const response = yield call(api.getQuizListByClient, action.payload);
      yield put(getQuizListByClientSuccess(response.data.quizs));
      return yield response.data;
    } catch (error) {
      yield put(getQuizListByClientFail(error));
    }
  });
}

function* getQuizListBySchoolPromiseHandler(action) {
  yield call(implementPromiseAction, action, function*() {
    try {
      const response = yield call(api.getQuizListBySchool, action.payload);
      yield put(getQuizListBySchoolSuccess(response.data.quizs));
      return yield response.data;
    } catch (error) {
      yield put(getQuizListBySchoolFail(error));
    }
  });
}

function* getQuizCategoryAllPromiseHandler(action) {
  yield call(implementPromiseAction, action, function*() {
    try {
      const response = yield call(api.getQuizCategory, action.payload);
      yield put(getQuizCategoryAllSuccess(response.data.quizCategories));
      return yield response.data;
    } catch (error) {
      yield put(getQuizCategoryAllFail(error));
    }
  });
}

function* createQuizPromiseHandler(action) {
  yield call(implementPromiseAction, action, function*() {
    try {
      const response = yield call(api.createQuiz, action.payload);
      yield put(createQuizSuccess(response.data));
      return yield response.data;
    } catch (error) {
      yield put(createQuizFail(error));
    }
  });
}

function* updateQuizPromiseHandler(action) {
  yield call(implementPromiseAction, action, function*() {
    try {
      const response = yield call(api.updateQuiz, action.payload);
      yield put(updateQuizSuccess(response.data));
      return yield response.data;
    } catch (error) {
      yield put(updateQuizFail(error));
    }
  });
}

function* deleteQuizPromiseHandler(action) {
  yield call(implementPromiseAction, action, function*() {
    try {
      const response = yield call(api.deleteQuiz, action.payload);
      yield put(deleteQuizSuccess(action.payload));
      return yield response.data;
    } catch (error) {
      yield put(deleteQuizFail(error));
    }
  });
}

function* quizSaga() {
  yield takeLeading(
    getQuizCategoryAllPromise,
    getQuizCategoryAllPromiseHandler
  );
  yield takeLeading(getQuizListAllPromise, getQuizListAllPromiseHandler);
  yield takeLeading(
    getQuizListByClientPromise,
    getQuizListByClientPromiseHandler
  );
  yield takeLeading(
    getQuizListBySchoolPromise,
    getQuizListBySchoolPromiseHandler
  );
  yield takeLeading(createQuizPromise, createQuizPromiseHandler);
  yield takeLeading(updateQuizPromise, updateQuizPromiseHandler);
  yield takeLeading(deleteQuizPromise, deleteQuizPromiseHandler);
}

export default quizSaga;
