import { RadioGroup, Stack } from "@chakra-ui/react";
import React from "react";
import RadioInput from "./RadioInput";

const RadioInputGroup = ({ choices }) => {

  return (
    <RadioGroup>
      <Stack>
        {choices.map((choice, index) => (
          <RadioInput key={choice.index} radioValue={index} radioText={choice.description} />
        ))}
      </Stack>
    </RadioGroup>
  );
};

export default RadioInputGroup;
