import { Box, Textarea, FormLabel } from "@chakra-ui/react";
import React from "react";

const SurveyDescription = ({ name, value, handleSurveryFormChange }) => {
  return (
    <Box>
      <FormLabel>설문지 설명</FormLabel>
      <Textarea
        name={name}
        value={value}
        onChange={handleSurveryFormChange}
        placeholder="설문지에 대해 간략하게 설명해주세요."
      >
      </Textarea>
    </Box>
  );
};

export default SurveyDescription;
