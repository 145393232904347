import { AddIcon } from "@chakra-ui/icons";
import {
  Button,
  HStack,
  VStack,
  Heading,
  Input,
  InputGroup,
  InputLeftAddon,
  Stack,
} from "@chakra-ui/react";
import React from "react";
import QuizScoreEditor from "./Common/QuizScoreEditor";
import QuizQusetionDelete from "./Common/QuizQusetionDelete";
import QuizTypeWrapper from "./Common/QuizTypeWrapper";
import { useCallback } from "react";
import { setReportLog } from "@app/store/actions";
import { ButtonConstants } from "@app/Constants/ButtonConstants";
import { useDispatch } from "react-redux";
const QuizTypeMultiple = ({ choices, setChoices }) => {
  const dispatch = useDispatch();
  const addChoice = () => {
    setChoices((prev) => {
      const newChoices = [...prev];
      newChoices.push({ description: "", point: 0 });
      return newChoices;
    });
  };
  const deleteChoice = (index) => {
    setChoices((prev) => {
      const newCho = prev.filter((x, i) => i !== index);
      return newCho;
    });
  };
  const setChoiceDescription = useCallback(
    (e, index) => {
      setChoices((prev) => {
        let newChoices = [...prev];
        newChoices[index] = {
          description: e.target.value,
          point: newChoices[index].point,
        };
        return newChoices;
      });
    },
    [setChoices]
  );
  const increaseScore = useCallback(
    (index) => {
      setChoices((prev) => {
        let newChoices = [...prev];
        newChoices[index] = {
          description: newChoices[index].description,
          point: ++newChoices[index].point,
        };
        return newChoices;
      });
    },
    [setChoices]
  );
  const decreaseScore = useCallback(
    (index) => {
      setChoices((prev) => {
        let newChoices = [...prev];
        newChoices[index] = {
          description: newChoices[index].description,
          point: --newChoices[index].point,
        };
        return newChoices;
      });
    },
    [setChoices]
  );

  return (
    <QuizTypeWrapper>
      <Heading fontSize={"lg"}>3. 보기입력</Heading>
      {choices.map((choice, index) => {
        return (
          <Stack direction={"column"} style={{ flex: "flexStart" }}>
            <HStack spacing={1}>
              <InputGroup>
                <InputLeftAddon>{index + 1}번</InputLeftAddon>
                <Input
                  placeholder={`${index + 1}번 보기 입력`}
                  value={choice.description}
                  onChange={(e) => setChoiceDescription(e, index)}
                />
              </InputGroup>
              <QuizQusetionDelete
                deleteHandler={deleteChoice}
                index={index}
                disabled={choices.length <= 1}
              />
            </HStack>
            <QuizScoreEditor
              score={choice.point}
              index={index}
              increase={increaseScore}
              decrease={decreaseScore}
            />
          </Stack>
        );
      })}
      <Button
        leftIcon={<AddIcon />}
        onClick={() => {
          addChoice();
          dispatch(
            setReportLog(ButtonConstants.MANAGING_QUIZPANG.ADD_CHOICE_MULTIPLE)
          );
        }}
      >
        보기 추가
      </Button>
    </QuizTypeWrapper>
  );
};

export default QuizTypeMultiple;
