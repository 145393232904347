export const PURGE_PARTICIPATION = "PURGE_PARTICIPATION";

export const GET_PARTICIPATION_SUCCESS = "GET_PARTICIPATION_SUCCESS";
export const GET_PARTICIPATION_FAIL = "GET_PARTICIPATION_FAIL";

export const GET_PARTICIPATIONS_BY_CLIENT_SUCCESS =
  "GET_PARTICIPATION_BY_CLIENT_SUCCESS";
export const GET_PARTICIPATIONS_BY_CLIENT_FAIL =
  "GET_PARTICIPATION_BY_CLIENT_FAIL";

export const GET_PARTICIPATIONS_BY_CLASSROOM_SUCCESS =
  "GET_PARTICIPATION_BY_CLASSROOM_SUCCESS";
export const GET_PARTICIPATIONS_BY_CLASSROOM_FAIL =
  "GET_PARTICIPATION_BY_CLASSROOM_FAIL";

export const CREATE_PARTICIPATION_SUCCESS = "CREATE_PARTICIPATION_SUCCESS";
export const CREATE_PARTICIPATION_FAIL = "CREATE_PARTICIPATION_FAIL";

export const UPDATE_PARTICIPATION_SUCCESS = "UPDATE_PARTICIPATION_SUCCESS";
export const UPDATE_PARTICIPATION_FAIL = "UPDATE_PARTICIPATION_FAIL";

export const DELETE_PARTICIPATION_SUCCESS = "DELETE_PARTICIPATION_SUCCESS";
export const DELETE_PARTICIPATION_FAIL = "DELETE_PARTICIPATION_FAIL";
