import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "react-quill/dist/quill.snow.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "@store";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { ConfigProvider } from "antd";
import { StyleProvider } from "@ant-design/cssinjs";
import "./i18n";

const rootNode = document.getElementById("root");

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

ReactDOM.createRoot(rootNode).render(
  <ConfigProvider
    theme={{
      token: {
        colorPrimary: "var(--primary)",
      },
    }}
  >
    <StyleProvider hashPriority="high">
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </Provider>
        <ReactQueryDevtools initialIsOpen={true} />
      </QueryClientProvider>
    </StyleProvider>
  </ConfigProvider>
);

reportWebVitals();
