import React from "react";
import { profileImgPath } from "@api";
import { purgeAll, setReportLog } from "@app/store/actions";
import {
  IconButton,
  Avatar,
  Box,
  CloseButton,
  Flex,
  HStack,
  VStack,
  Icon,
  useColorModeValue,
  Text,
  Drawer,
  DrawerContent,
  useDisclosure,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Img,
  Button,
} from "@chakra-ui/react";
import { useMemo } from "react";
import {
  FiHome,
  FiTrendingUp,
  FiCompass,
  FiMenu,
  FiBell,
  FiChevronDown,
} from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import focuspangLogo from "assets/images/logo/focuspang_ai.png";
import { ButtonConstants } from "@app/Constants/ButtonConstants";

import MenuBookIcon from "@mui/icons-material/MenuBook";
import ForumIcon from "@mui/icons-material/Forum";
import { useTranslation } from "react-i18next";
import MultiLanguageSelectMenu from "@app/components/Buttons/MultiLanguage/MultiLanguageSelectMenu";
import { Tooltip } from "@mui/material";

const SidebarContent = ({ onClose, ...rest }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const LinkItems = [
    // 처음으로
    { name: t("sidemenu.menu.home"), icon: FiHome, link: "/home" },
    // 수업 외 앱/웹 제어
    {
      name: t("sidemenu.menu.webapplimit"),
      icon: FiTrendingUp,
      link: "/managing",
    },
    // 퀴즈팡 관리
    {
      name: t("sidemenu.menu.quizpang"),
      icon: FiCompass,
      link: "/quiz-management",
    },
  ];

  return (
    <Box
      transition="3s ease"
      bg={useColorModeValue("white", "gray.900")}
      borderRight="1px"
      borderRightColor={useColorModeValue("gray.200", "gray.700")}
      w={{ base: "full", md: 60 }}
      pos="fixed"
      h="full"
      {...rest}
    >
      <Flex h="20" alignItems="center" mx="8" justifyContent="space-between">
        <Text fontSize="2xl" fontFamily="monospace" fontWeight="bold">
          <Img src={focuspangLogo} h={{ base: "20px", md: "30px" }} />
        </Text>
        <CloseButton display={{ base: "flex", md: "none" }} onClick={onClose} />
      </Flex>
      {LinkItems.map((link) => (
        <NavItem key={link.name} icon={link.icon} link={link.link}>
          {link.name}
        </NavItem>
      ))}
      <Box
        position="absolute"
        bottom="10"
        width="100%"
        display="flex"
        justifyContent="center"
      >
        <VStack spacing="2">
          <Button
            w="full"
            leftIcon={<ForumIcon />}
            onClick={() => {
              dispatch(
                setReportLog(ButtonConstants.CONFIG.LINK_TO_FOCUSPANG_ASK)
              );
            }}
          >
            <a href="https://focuspang.com/ask.html" target="_black">
              {/* 포커스팡 즉문즉답 */}
              {t("sidemenu.menu.qna")}
            </a>
          </Button>
          <Button
            w="full"
            leftIcon={<MenuBookIcon />}
            onClick={() => {
              dispatch(
                setReportLog(ButtonConstants.CONFIG.LINK_TO_FOCUSPANG_GUIDE)
              );
            }}
            overflow="hidden"
            whiteSpace="nowrap"
            textOverflow="ellipsis"
          >
            <a href="https://focuspang.com/guide_2.html" target="_black">
              {/* 포커스팡 매뉴얼 */}
              {t("sidemenu.menu.menual")}
            </a>
          </Button>
        </VStack>
      </Box>
    </Box>
  );
};

const NavItem = ({ icon, children, link, ...rest }) => {
  const history = useHistory();
  const location = useLocation();
  const isActive = location.pathname.slice(0, link.length) === link;
  const isCurrentPage = location.pathname === link;

  return (
    <Box
      as="a"
      onClick={() => {
        if (!isCurrentPage) {
          history.push(link);
        }
      }}
      style={{ textDecoration: "none" }}
      _focus={{ boxShadow: "none" }}
    >
      <Tooltip
        title={children}
        disableHoverListener={isCurrentPage}
        arrow={true}
      >
        <Flex
          align="center"
          p="4"
          mx="4"
          mt="2"
          borderRadius="lg"
          role="group"
          cursor="pointer"
          bg={isActive ? "gray.200" : "transparent"} // 활성화 상태에 따라 배경색 변경
          color={isActive ? "black" : "initial"} // 활성화 상태에 따라 텍스트 색상 변경
          _hover={{
            bg: "gray.400",
            color: "white",
          }}
          {...rest}
          overflow="hidden"
          whiteSpace="nowrap"
          textOverflow="ellipsis"
        >
          {icon && (
            <Icon
              mr="4"
              fontSize="16"
              _groupHover={{
                color: "white",
              }}
              as={icon}
              color={isActive ? "black" : "initial"}
            />
          )}
          {children}
        </Flex>
      </Tooltip>
    </Box>
  );
};

const MobileNav = ({ onOpen, ...rest }) => {
  const { t } = useTranslation();
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const history = useHistory();

  const onPurgeClick = () => {
    // 로그아웃 하시겠어요?
    if (window.confirm(t("sidemenu.menu.logout.alert.text"))) {
      dispatch(purgeAll());
      history.replace("/auth");
    }
  };
  const handleSettingClick = () => {
    history.push("/settings");
  };
  const myProfileImage = useMemo(() => {
    if (!user) {
      return undefined;
    }
    return profileImgPath(user.clientId);
  }, [user]);
  return (
    <Flex
      ml={{ base: 0, md: 60 }}
      px={{ base: 4, md: 4 }}
      height="20"
      alignItems="center"
      bg={useColorModeValue("white", "gray.900")}
      borderBottomWidth="1px"
      borderBottomColor={useColorModeValue("gray.200", "gray.700")}
      justifyContent={{ base: "space-between", md: "flex-end" }}
      {...rest}
    >
      <IconButton
        display={{ base: "flex", md: "none" }}
        onClick={onOpen}
        variant="outline"
        aria-label="open menu"
        icon={<FiMenu />}
      />

      <Text
        display={{ base: "flex", md: "none" }}
        fontSize="2xl"
        fontFamily="monospace"
        fontWeight="bold"
      >
        <Img src={focuspangLogo} h={{ base: "20px", md: "30px" }} />
      </Text>

      <HStack spacing={{ base: "2", md: "6" }}>
        <MultiLanguageSelectMenu />
        {/* <IconButton
          size="lg"
          variant="ghost"
          aria-label="open menu"
          icon={<FiBell />}
        /> */}
        <Flex alignItems={"center"}>
          <Menu>
            <MenuButton
              py={2}
              transition="all 0.3s"
              _focus={{ boxShadow: "none" }}
            >
              <HStack>
                <Avatar size={"sm"} src={myProfileImage} />
                <VStack
                  display={{ base: "none", md: "flex" }}
                  alignItems="flex-start"
                  spacing="1px"
                  ml="2"
                >
                  <Text fontSize="sm">{user?.userName}</Text>
                  <Text fontSize="xs" color="gray.600">
                    {user?.email}
                  </Text>
                </VStack>
                <Box display={{ base: "none", md: "flex" }}>
                  <FiChevronDown />
                </Box>
              </HStack>
            </MenuButton>
            <MenuList
              bg={useColorModeValue("white", "gray.900")}
              borderColor={useColorModeValue("gray.200", "gray.700")}
            >
              {/* 설정 */}
              <MenuItem onClick={handleSettingClick}>
                {t("sidemenu.setting.text.title")}
              </MenuItem>
              <MenuDivider />
              {/* 로그아웃 */}
              <MenuItem
                onClick={() => {
                  onPurgeClick();
                }}
              >
                {t("sidemenu.menu.logout")}
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </HStack>
    </Flex>
  );
};

const SidebarWithHeader = ({ children }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box>
      <SidebarContent
        onClose={() => onClose}
        display={{ base: "none", md: "block" }}
      />
      <Drawer
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent onClose={onClose} />
        </DrawerContent>
      </Drawer>
      {/* mobilenav */}
      <MobileNav onOpen={onOpen} />
      <Box ml={{ base: 0, md: 60 }} p="4">
        {children}
      </Box>
    </Box>
  );
};

export default SidebarWithHeader;
