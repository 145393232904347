import React, { useState } from "react";
import {
  Box,
  HStack,
  IconButton,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tooltip,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import RefreshIcon from "@mui/icons-material/Refresh";
import StudentThumnailList from "./StudentThumnailList";
import { useStudentList } from "../QuizPang/hooks/useStudentList";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useParticipationByClassroom } from "../QuizPang/hooks/useParticipation";
import { selectActiveClassroom } from "@app/store/selectors";
import { useSelector } from "react-redux";
import { BsArrowsFullscreen } from "react-icons/bs";
import Loading from "@app/components/Common/Loading";
import { useTranslation } from "react-i18next";

const StudentThumnailWrapper = ({
  selectedStudent,
  setSelectedStudent,
  selectedImage,
  setSelectedImage,
}) => {
  const { t } = useTranslation();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const { groupId } = useParams();
  const activeClassroom = useSelector((state) => selectActiveClassroom(state));
  const studentListdata = useStudentList(groupId);
  const {
    refetch: refetchParticipationByClassroom,
  } = useParticipationByClassroom(activeClassroom?.classroomId);

  if (studentListdata.isLoading) {
    return (
      <VStack w="full" h="100vh" justifyContent="center" alignItems="center">
        <Loading />
      </VStack>
    );
  }

  const studentList = studentListdata?.students;

  return (
    <Stack direction="column" w="full">
      <HStack>
        <Tooltip
          hasArrow
          // label="학생들의 화면을 새로고침하고 싶다면, 새로고침 버튼을 눌러주세요."
          label={t(
            "timetable.focustime.studentlist.allstudent.refresh.button.hover"
          )}
        >
          <IconButton
            icon={<RefreshIcon />}
            onClick={() => {
              refetchParticipationByClassroom();
              studentListdata.refetch();
            }}
          />
        </Tooltip>
        <Tooltip
          hasArrow
          // label="학생들의 화면을 더 크게 보고싶다면, 전체화면 버튼을 눌러주세요."
          label={t(
            "timetable.focustime.studentlist.allstudent.view.button.hover"
          )}
        >
          <IconButton icon={<BsArrowsFullscreen />} onClick={onOpen} />
        </Tooltip>
      </HStack>
      <Box h="100vh" overflowY="scroll">
        <StudentThumnailList
          colums={1}
          studentList={studentList}
          selectedStudent={selectedStudent}
          setSelectedStudent={setSelectedStudent}
          setSelectedImage={setSelectedImage}
          selectedImage={selectedImage}
          isOpen={isOpen}
          onOpen={onOpen}
          onClose={onClose}
        />
      </Box>
      {/* <Tabs variant="soft-rounded">
        <TabList>
          <Tab>1열 보기</Tab>
          <Tab>2열 보기</Tab>
          <Tab>3열 보기</Tab>
        </TabList>
        <TabPanels h="100vh" overflowY="scroll">
          <TabPanel>
            <StudentThumnailList
              colums={1}
              studentList={studentList}
              selectedStudent={selectedStudent}
              setSelectedStudent={setSelectedStudent}
              setSelectedImage={setSelectedImage}
              selectedImage={selectedImage}
              isOpen={isOpen}
              onOpen={onOpen}
              onClose={onClose}
            />
          </TabPanel>
          <TabPanel>
            <StudentThumnailList
              colums={2}
              studentList={studentList}
              selectedStudent={selectedStudent}
              setSelectedStudent={setSelectedStudent}
              setSelectedImage={setSelectedImage}
              selectedImage={selectedImage}
            />
          </TabPanel>
          <TabPanel>
            <StudentThumnailList
              colums={3}
              studentList={studentList}
              selectedStudent={selectedStudent}
              setSelectedStudent={setSelectedStudent}
              setSelectedImage={setSelectedImage}
              selectedImage={selectedImage}
            />
          </TabPanel>
        </TabPanels>
      </Tabs> */}
    </Stack>
  );
};

export default StudentThumnailWrapper;
