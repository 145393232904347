import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import styled from "styled-components";
import {
  Body2,
  Column,
  InputWhiteScroll,
  Row,
  VerticalSpace8,
} from "@app/pages/QuizPang/common";
import { Button } from "@mui/material";
import videoPlaceholder from "@images/quiz-pang/video-placeholder.png";
import getYoutubeId from "get-youtube-id";
import LiteYouTubeEmbed from "react-lite-youtube-embed";
import "react-lite-youtube-embed/dist/LiteYouTubeEmbed.css";

export const QuizVideoModal = ({
  open,
  onClose,
  setVideoLink,
  videoLink,
  setLinkType,
  removeImage,
}) => {
  const youtubeId = getYoutubeId(videoLink);
  return (
    <Modal
      open={open}
      onClose={() => {
        onClose();
        setVideoLink(null);
      }}
    >
      <Container>
        <Column style={{ width: 820 }}>
          <InputWhiteScroll
            placeholder="영상의 공유 URL을 입력해주세요."
            value={videoLink}
            onChange={(e) => {
              removeImage();
              setLinkType("YOUTUBE");
              setVideoLink(e.target.value);
            }}
            style={{ resize: "none" }}
            rows={1}
          />
          <VerticalSpace8 />
          {!!youtubeId ? (
            <LiteYouTubeEmbed id={youtubeId} title="video" />
          ) : (
            <VideoArea>
              <Column style={{ alignItems: "center" }}>
                <img
                  src={videoPlaceholder}
                  style={{ width: 60, height: 60 }}
                  alt="video_placeholder"
                />
                <Body2 style={{ color: "rgba(0, 0, 0, 0.37)" }}>
                  영상의 공유 URL을 적용시켜주세요.
                </Body2>
              </Column>
            </VideoArea>
          )}
          <Row style={{ justifyContent: "center", marginTop: 18 }}>
            <Button
              variant="contained"
              style={{ padding: "8px 64px" }}
              disabled={!youtubeId}
              onClick={() => {
                setVideoLink(videoLink);
                onClose();
              }}
            >
              적용하기
            </Button>
          </Row>
        </Column>
      </Container>
    </Modal>
  );
};

const Container = styled(Column)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.04), 0 36px 41px rgba(0, 0, 0, 0.04),
    0 4.50776px 5.13384px rgba(0, 0, 0, 0.03);
  border-radius: 8px;
  padding: 48px 64px;
  align-items: center;
  justify-content: center;
`;

const VideoArea = styled(Column)`
  width: 820px;
  height: 400px;
  border: 1px dashed #bdbdbd;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
`;
