import { makeFreshAllIds } from "../helpers";
import {
  PURGE_GROUP,
  GET_GROUP_SUCCESS,
  GET_GROUP_FAIL,
  GET_GROUPS_SUCCESS,
  GET_GROUPS_FAIL,
  CREATE_INVITE_CODE_SUCCESS,
  CREATE_INVITE_CODE_FAIL,
  CREATE_GROUP_SUCCESS,
  CREATE_GROUP_FAIL,
  UPDATE_GROUP_SUCCESS,
  UPDATE_GROUP_FAIL,
  DELETE_GROUP_SUCCESS,
  DELETE_GROUP_FAIL,
} from "./actionTypes";

const initialState = {
  byId: {},
  allIds: [],
  invCode: null,
  selectedGroupId: null,
};

const groups = (state = initialState, action) => {
  switch (action.type) {
    case PURGE_GROUP:
      return initialState;

    case GET_GROUP_SUCCESS:
    case CREATE_GROUP_SUCCESS:
    case UPDATE_GROUP_SUCCESS:
      return {
        ...state,
        byId: { ...state.byId, [action.group.groupId]: action.group },
        allIds: makeFreshAllIds(state.allIds, [action.group.groupId]),
      };
    case CREATE_INVITE_CODE_SUCCESS:
      return {
        ...state,
        invCode: action.inviteCode,
      };

    case GET_GROUPS_SUCCESS:
      return {
        ...state,
        byId: { ...state.byId, ...action.byId },
        allIds: makeFreshAllIds(state.allIds, action.allIds),
      };

    case DELETE_GROUP_SUCCESS:
      return {
        ...state,
        byId: { ...state.byId, [action.group.groupId]: action.group },
        allIds: state.allIds.filter(
          (groupId) => groupId !== action.group.groupId
        ),
      };

    case GET_GROUP_FAIL:
    case GET_GROUPS_FAIL:
    case CREATE_GROUP_FAIL:
    case UPDATE_GROUP_FAIL:
    case DELETE_GROUP_FAIL:
    case CREATE_INVITE_CODE_FAIL:
      // console.error("[Group Reducer] ", action.payload);
      return state;

    default:
      break;
  }
  return state;
};

export default groups;
