import quiz from "@app/store/quiz/reducer";
import {
  Avatar,
  Box,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Divider,
  HStack,
  Stack,
  Tag,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const SurveyCard = ({ quizset, groupId }) => {
  const history = useHistory();
  const [isHovered, setIsHovered] = useState(false);

  function handleClick() {
    history.push({
      pathname: `/survey/${groupId}/quizset-survey-ready/${quizset.quizsetId}`,
    });
    // if (type === "ABTEST") {
    //   if (location.pathname === "/quiz-management") {
    //     history.push({
    //       pathname: `/quiz-management/quiz-ab/${quizset.quizsetId}`,
    //     });
    //   } else {
    //     history.push({
    //       pathname: `/quiz-pang/${groupId}/quizset-ready/${quizset.quizsetId}`,
    //     });
    //   }
    // } else {
    //   if (location.pathname === "/quiz-management") {
    //     history.push({
    //       pathname: `/quiz-management/quiz/${quizset.quizsetId}`,
    //     });
    //   } else {
    //     history.push({
    //       pathname: `/quiz-pang/${groupId}/quizset-ready/${quizset.quizsetId}`,
    //     });
    //   }
    // }
  }

  // createAt을 받아서 날짜 형식으로 변환하는 함수
  const convertDate = (date) => {
    const convertedDate = new Date(date);
    const year = convertedDate.getFullYear();
    const month = convertedDate.getMonth() + 1;
    const day = convertedDate.getDate();
    const hours = convertedDate.getHours();
    const minutes = convertedDate.getMinutes();
    const convertedDateFormat = `${year}년 ${month}월 ${day}일 ${hours}시 ${minutes}분`;
    return convertedDateFormat;
  };

  // grade를 받아서 학년 형식으로 변환하는 함수 0이면 전체로 변환
  const convertGrade = (grade) => {
    if (grade === 0) {
      return "전체";
    } else {
      return `${grade}학년`;
    }
  };

  return (
    <Card
      h={"300"}
      cursor={"pointer"}
      onClick={handleClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      _hover={{ boxShadow: "lg" }}
      transition={"box-shadow 0.3s ease"}
      borderRadius={"lg"}
      boxShadow={"base"}
      overflowY="auto"
    >
      <CardHeader>
        <HStack spacing={2} w={"full"} h={"full"}>
          <Box minW={"45px"}>
            <Tag variant={"solid"} colorScheme="blue">
              설문
            </Tag>
          </Box>
          {quizset.title ? (
            <Text
              fontSize={"xl"}
              fontWeight={"bold"}
              isTruncated={!isHovered}
              overflowY={isHovered ? "auto" : "hidden"}
            >
              {quizset.title}
            </Text>
          ) : (
            <Text
              fontSize={"xl"}
              fontWeight={"bold"}
              color="gray.400"
              noOfLines={1}
            >
              (제목 없음)
            </Text>
          )}
        </HStack>
      </CardHeader>
      <CardBody>
        <VStack spacing={2} align={"flex-start"}>
          <Text>총 {quizset.quizCount} 개의 설문</Text>
        </VStack>
      </CardBody>
      <CardFooter>
        <Stack direction="column" w="full" spacing={4}>
          <Divider />
          <Text fontSize={"sm"} color={"gray.500"} noOfLines={1}>
            {convertDate(quizset.createdAt)} 생성
          </Text>
          <HStack>
            <Avatar size={"sm"} />
            <Text>{quizset.clientName}</Text>
          </HStack>
        </Stack>
      </CardFooter>
    </Card>
  );
};

export default SurveyCard;
