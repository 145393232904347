import React, {
  createContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setStudentImages,
  getMembershipsByGroupPromise,
  getActiveClassroomPromise,
  getParticipationsByClassroomPromise,
} from "@store/actions";
import {
  selectActiveClassroom,
  selectParticipationsByClassroom,
} from "@app/store/selectors";
import { useInterval } from "@api";
import {
  clearStudentImages,
  getManagedsByClientPromise,
  getAnsweredStudentListSuccess,
  setCommentsStudent,
  setReportData,
  setReportEventPromise,
  setStudentListUpdate,
  addStudentFocusStatusList,
  removeStudentFocusStatusList,
  setSocketData,
  addStudentGoodFocusStatusList,
  setQuizsetStudentStatus,
  setQuizResultUpdateRequest,
} from "@app/store/actions";
import { RECEIVE_SHARING_SCREEN } from "@app/Constants/eventHistoryType";
import { RECEIVE_SHARING_SCREEN_DATA } from "@app/Constants/eventHistoryData";
import { STUDENT, TEACHER } from "@app/Constants/eventHistoryRole";
import {
  notificationKind,
  useSystemNotification,
} from "@app/react-query/querys/useSystemNotification";
import { useParticipationByClassroom } from "@app/pages/QuizPang/hooks/useParticipation";
import { useStudentList } from "@app/pages/QuizPang/hooks/useStudentList";
import { useTimetableByClientId } from "@app/pages/Home/hooks/useTimetable";
import {
  socketKind,
  socketMethod,
  socketUrl,
} from "@app/Constants/socketConstants";
import {
  serviceErrorSlackAlarm,
  serviceSocketDisconnectSlackAlarm,
  serviceSocketReconnectErrorSlackAlarm,
  serviceSocketReconnectSlackAlarm,
} from "@app/utils/alert/slackAlarm";
import { useQueryClient } from "@tanstack/react-query";

const WebSocketContext = createContext(null);

export { WebSocketContext };

const ConnectionManager = React.memo(() => {
  const groupId = useSelector((state) => state.control?.selectedGroup);
  const user = useSelector((state) => state.user);
  const membership = useSelector((state) => state.membership.membership);
  const { schoolId } = useSelector((state) => state.user);

  const [images, setImages] = useState({});
  const [imageCounts, setImageCounts] = useState({});
  const [webSocket, setWebSocket] = useState();
  const [webSocketReady, setWebSocketReady] = useState(false);
  const [serverMessage, setServerMessage] = useState("");
  const activeClassroom = useSelector((state) => selectActiveClassroom(state));
  const screenData = useSelector((state) => state.control.screenData);
  const socketData = useSelector((state) => state.control.socketData);
  const [lastTimestamp, setLastTimestamp] = useState(new Date());
  const _participations = useSelector((state) =>
    selectParticipationsByClassroom(state, activeClassroom?.classroomId)
  );
  useSystemNotification("", true);
  const dispatch = useDispatch();
  const retries = useRef(0);

  const getTimetableData = useTimetableByClientId(user.clientId);

  const {
    refetch: refetchParticipationByClassroom,
  } = useParticipationByClassroom(activeClassroom?.classroomId);
  const { refetch: refetchStudentList } = useStudentList(groupId);

  const cacheData = useQueryClient();

  const schoolData = cacheData.getQueryData(["school", schoolId]);
  const schoolName = schoolData?.data.name;

  useEffect(() => {
    if (!user || !user.clientId) {
      return;
    }
    try {
      console.log("[Connection Manager] Socket Connect");
      setWebSocket(
        new WebSocket(`${process.env.REACT_APP_WS_DEV_ADDR}/${user.clientId}`)
      );
    } catch (error) {
      console.log("[ConnectionManager]: setWebSocket error", error);
      serviceSocketReconnectErrorSlackAlarm(
        error,
        user?.schoolId,
        user?.userName,
        user?.clientId
      );
    }
  }, [user.clientId, membership]);

  useEffect(() => {
    const maxRetries = 10; // 최대 재시도 횟수 설정
    const retryInterval = 5000;

    const setupWebSocket = () => {
      if (!user || !user.signedIn || !webSocket || !user.clientId) {
        return;
      }

      try {
        webSocket.onopen = () => {
          console.log("[Connection Manager] Socket Open");
          setWebSocketReady(true);
        };
      } catch (error) {
        console.log("[ConnectionManager]: webSocket.onopen error", error);
        serviceErrorSlackAlarm(
          error,
          schoolName,
          schoolId,
          user?.clientId,
          "webSocket.onopen",
          "ConnectionManager.js",
          126
        );
      }

      try {
        webSocket.onmessage = (event) => {
          setServerMessage(JSON.parse(event.data));
        };
      } catch (error) {
        console.log("[ConnectionManager]: webSocket.onmessage error", error);
        serviceErrorSlackAlarm(
          error,
          schoolName,
          schoolId,
          user?.clientId,
          "webSocket.onmessage",
          "ConnectionManager.js",
          144
        );
      }

      try {
        webSocket.onclose = (event) => {
          console.log("[Connection Manager] Socket Close Code", event.code);
          if (event.code === 4000) {
            return;
          }
          setWebSocketReady(false);
          if (retries.current < maxRetries) {
            setTimeout(() => {
              try {
                serviceSocketReconnectSlackAlarm(
                  user?.schoolId,
                  user?.userName,
                  user?.clientId
                );
                console.log(
                  "----- [Connection Manager] Socket Reconnect -----"
                );
                webSocket.close(); // 이전 WebSocket을 닫음
                setWebSocket(
                  new WebSocket(
                    `${process.env.REACT_APP_WS_DEV_ADDR}/${user.clientId}`
                  )
                );
              } catch (error) {
                serviceSocketReconnectErrorSlackAlarm(
                  error,
                  user?.schoolId,
                  user?.userName,
                  user?.clientId
                );
                console.log(
                  "[Connection Manager] Socket Reconnect Error",
                  error
                );
              } finally {
                console.log(
                  "----- [Connection Manager] Socket Reconnect -----"
                );
              }
              retries.current++;
            }, retryInterval);
          } else {
            console.log("[Connection Manager] Maximum retries reached");
            alert("네트워크가 불안정합니다.");
            retries.current = 0;
            window.location.reload();
          }
        };
      } catch (error) {
        console.log("[ConnectionManager]: webSocket.onclose error", error);
        serviceErrorSlackAlarm(
          error,
          schoolName,
          schoolId,
          user?.clientId,
          "webSocket.onclose",
          "ConnectionManager.js",
          161
        );
      }

      webSocket.onerror = (error) => {
        serviceSocketReconnectErrorSlackAlarm(
          error,
          user?.schoolId,
          user?.userName,
          user?.clientId
        );
        console.log("[Connection Manager] Socket Error", error, new Date());
        setWebSocketReady(false);
        webSocket?.close();
      };
    };

    setupWebSocket();

    return () => {
      console.log("[Connection Manager] Socket Disconnect");
      webSocket?.close(4000, "reset");
      setWebSocketReady(false);
    };
  }, [user.clientId, user.signedIn, webSocket]);

  useEffect(() => {
    if (webSocketReady && webSocket) {
      try {
        webSocket.send(JSON.stringify(socketData));
      } catch (e) {
        console.log("[Connection Manager] Socket send error", e);
      }
    }
  }, [webSocketReady, webSocket, socketData]);

  useEffect(() => {
    if (
      webSocketReady &&
      screenData &&
      Object.keys(screenData)?.length &&
      activeClassroom?.groupId
    ) {
      try {
        webSocket.send(JSON.stringify(screenData));
      } catch (e) {
        console.log("[Connection Manager] Socket send error", e);
      }
    }
  }, [screenData, webSocket, webSocketReady, activeClassroom?.groupId]);

  // pulse 소켓 서버와의 연결 상태 확인하는 로직
  useInterval(() => {
    if (!user || !user.clientId) {
      return;
    }
    const validTimetables = getTimetableData.timetable.data
      .flatMap((dataItem) => dataItem?.timetables || [])
      .filter((timetable) => timetable != null);

    if (!activeClassroom && validTimetables.length > 0) {
      const aliveData = {
        clientId: user.clientId,
        groupId: validTimetables[0].groupId,
        method: socketMethod.POST,
        uri: socketUrl.pulse,
      };
      try {
        webSocket?.send(JSON.stringify(aliveData));
      } catch (error) {
        serviceErrorSlackAlarm(
          error,
          schoolName,
          schoolId,
          user?.clientId,
          "webSocket.send",
          "ConnectionManager.js",
          214
        );
        setWebSocket(
          new WebSocket(`${process.env.REACT_APP_WS_DEV_ADDR}/${user.clientId}`)
        );
        console.log("[Connection Manager] Socket send error", error);
      }
      const diff = (new Date() - lastTimestamp) / 1000;
      const diffSecond = parseInt(diff);

      if (diffSecond >= 10 && !user.clientId) {
        setWebSocket(
          new WebSocket(`${process.env.REACT_APP_WS_DEV_ADDR}/${user.clientId}`)
        );
        setLastTimestamp(new Date());
        console.log("reconnet to socket");
      }
    }
    if (activeClassroom) {
      const aliveData = {
        clientId: user.clientId,
        groupId: activeClassroom?.groupId,
        method: socketMethod.POST,
        uri: socketUrl.pulse,
      };
      try {
        webSocket?.send(JSON.stringify(aliveData));
      } catch (e) {
        setWebSocket(
          new WebSocket(`${process.env.REACT_APP_WS_DEV_ADDR}/${user.clientId}`)
        );
        console.log("[Connection Manager] Socket send error", e);
      }
      const diff = (new Date() - lastTimestamp) / 1000;
      const diffSecond = parseInt(diff);

      if (diffSecond >= 10 && !user.clientId) {
        setWebSocket(
          new WebSocket(`${process.env.REACT_APP_WS_DEV_ADDR}/${user.clientId}`)
        );
        setLastTimestamp(new Date());
        console.log("reconnet to socket");
      }
    }
    if (!serverMessage?.now && !user.clientId) {
      return;
    }
  }, 5000);

  useEffect(() => {
    if (
      !activeClassroom &&
      Object.keys(images).length > 0 &&
      Object.keys(images).some((key) => images[key].length > 0)
    ) {
      setImageCounts({});
      dispatch(clearStudentImages());
      setImages({});
    }
    if (activeClassroom) {
      dispatch(setStudentImages(images));
    }
  }, [activeClassroom, images, dispatch]);

  useEffect(() => {
    if (!user || !user.clientId) {
      return;
    }
    // pulse로 부터 받은 메세지는 type이 존재하지 않고 now만 존재한다.
    if (!serverMessage?.type) {
      setLastTimestamp(new Date(serverMessage.now));
      return;
    }
    switch (serverMessage?.type) {
      case "REPORT":
        if (!activeClassroom) {
          return;
        }
        console.log("[ConnectionManager]", "REPORT", serverMessage);
        dispatch(setReportData(serverMessage));

        break;

      case "CLASS_END":
        dispatch(getActiveClassroomPromise(user.clientId));
        break;

      case "ADD_STUDENT_GOOD_FOCUS_STATUS_LIST":
        console.log(
          "[ConnectionManager]",
          "ADD_STUDENT_GOOD_FOCUS_STATUS_LIST"
        );
        dispatch(addStudentGoodFocusStatusList(serverMessage.clientId));
        break;
      case "ADD_STUDENT_FOCUS_STATUS_LIST":
        console.log("[ConnectionManager]", "ADD_STUDENT_FOCUS_STATUS_LIST");
        dispatch(removeStudentFocusStatusList(serverMessage.clientId));
        dispatch(addStudentFocusStatusList(serverMessage.clientId));
        break;
      case notificationKind.REMOVE_STUDENT_FOCUS_STATUS_LIST:
        console.log("[ConnectionManager]", "REMOVE_STUDENT_FOCUS_STATUS_LIST");
        dispatch(removeStudentFocusStatusList(serverMessage.clientId));
        break;

      case "IMAGE_STUDENT":
        if (!activeClassroom) {
          return;
        }
        if (activeClassroom) {
          if (serverMessage.groupId !== activeClassroom.groupId) {
            return;
          }
          setImages((prevState) => ({
            ...prevState,
            [serverMessage.clientId]: serverMessage.data,
          }));
          imageCounts[serverMessage.clientId] = 0;
          const reportEvents = [
            {
              groupId: activeClassroom?.groupId,
              sender: user.clientId,
              receiver: serverMessage?.clientId,
              senderRole: TEACHER,
              receiverRole: STUDENT,
              eventType: RECEIVE_SHARING_SCREEN,
              data: RECEIVE_SHARING_SCREEN_DATA,
              reportedAt: new Date(),
            },
          ];
          dispatch(setReportEventPromise(reportEvents));
        }

        break;
      case "JOIN_GROUP":
        console.log("[ConnectionManager]", "JOIN_GROUP");
        refetchStudentList();
        refetchParticipationByClassroom();
        dispatch(getMembershipsByGroupPromise(serverMessage.groupId));
        //TODO: getQueryClient 방식으로 변경
        dispatch(setStudentListUpdate(true));
        break;
      case "ATTEND_CLASS":
        refetchStudentList();
        refetchParticipationByClassroom();
        dispatch(getMembershipsByGroupPromise(serverMessage.groupId)).then(
          () => {
            dispatch(setStudentListUpdate(true));
          }
        );
        dispatch(getManagedsByClientPromise(serverMessage.groupId)).then(() => {
          dispatch(setStudentListUpdate(true));
        });
        dispatch(
          getParticipationsByClassroomPromise(activeClassroom?.classroomId)
        );
        break;
      case "COMMENTS_STUDENT":
        dispatch(
          setCommentsStudent({
            image: serverMessage.data,
            clientId: serverMessage.clientId,
          })
        );
        break;

      // 퀴즈팡
      case notificationKind.CLASS_STUDENT_JOIN:
        if (!activeClassroom) return;
        dispatch(
          getParticipationsByClassroomPromise(activeClassroom.classroomId)
        );
        break;

      case notificationKind.QUIZSET_PERMISSION_STUDENT_READY:
        break;

      case notificationKind.QUIZSET_STUDENT_STATUS:
        if (serverMessage.data === "QUIZ_SUBMIT") {
          dispatch(getAnsweredStudentListSuccess(serverMessage.clientId));
        }
        dispatch(
          setQuizsetStudentStatus(serverMessage.clientId, serverMessage.data)
        );
        break;

      case notificationKind.QUIZ_STUDENT_STATUS_SEND:
        dispatch(getAnsweredStudentListSuccess(serverMessage.clientId));
        break;

      case notificationKind.ADD_STUDENT_FOCUS_STATUS_LIST:
        dispatch(removeStudentFocusStatusList(serverMessage.clientId));
        dispatch(addStudentFocusStatusList(serverMessage.clientId));
        break;

      case notificationKind.REMOVE_STUDENT_FOCUS_STATUS_LIST:
        dispatch(removeStudentFocusStatusList(serverMessage.clientId));
        break;

      case notificationKind.QUIZSET_SESSION_ACTIVE_CHECK:
        if (/quiz-pang\/.*\/session/g.test(window.location.pathname)) {
          if (
            window.location.pathname.match(/quiz-pang\/(.*)\/session/)?.[1] ===
            serverMessage.groupId
          ) {
            dispatch(
              setSocketData({
                method: "POST",
                uri: "/classroom/sendImage",
                groupId: serverMessage.groupId,
                clientId: user.clientId,
                type: socketKind.quizpang.QUIZSET_SESSION_ACTIVE_CONFIRM,
                data: JSON.stringify({ targetClient: serverMessage.clientId }),
              })
            );
          }
          break;
        }
        break;
      case socketKind.quizpang.QUIZ_STUDENT_COMPLETED:
        dispatch(setQuizResultUpdateRequest(true));
        break;
      case "DEV_NOTIFICATION":
        console.log("Dev Notification");
        break;
      default:
        console.log("[Connection Manager] Default Message", serverMessage);
    }
  }, [dispatch, serverMessage, user.clientId]);

  return <div />;
});

export default ConnectionManager;
