import React from "react";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Container,
  Divider,
  HStack,
  Text,
  VStack,
} from "@chakra-ui/react";
import SidebarWithHeader from "./Sidebar";

const Wrapper = ({
  children,
  buttons,
  prevPageName,
  prevPageRoute,
  currentPageName,
}) => {
  return (
    <SidebarWithHeader>
      <Container maxW="container.xl" p="8">
        <VStack spacing={4} w={"full"}>
          <HStack
            spacing={2}
            w={"full"}
            justifyContent={
              buttons && buttons.length > 0 ? "space-between" : "flex-end"
            }
          >
            <HStack spacing={2}>
              {buttons && buttons.map((item) => item)}
            </HStack>
            <Breadcrumb>
              {prevPageName && (
                <BreadcrumbItem>
                  <BreadcrumbLink href={prevPageRoute}>
                    <Text fontSize={"2xl"}>{prevPageName}</Text>
                  </BreadcrumbLink>
                </BreadcrumbItem>
              )}
              <BreadcrumbItem isCurrentPage>
                <BreadcrumbLink>
                  <Text fontSize={"2xl"} fontWeight={"bold"}>
                    {currentPageName}
                  </Text>
                </BreadcrumbLink>
              </BreadcrumbItem>
            </Breadcrumb>
          </HStack>
          <Divider w={"full"} color="gray.200" />
          {children}
        </VStack>
      </Container>
    </SidebarWithHeader>
  );
};

export default Wrapper;
