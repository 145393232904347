import React from "react";
import { SentryRoute } from "@app/infra/sentry";

const AppRoute = ({ component: Component, isAuthProtected, ...rest }) => {
  return (
    <div>
      <SentryRoute {...rest} render={props => <Component {...props} />} />
    </div>
  );
};

export default AppRoute;
