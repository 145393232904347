import { useInfiniteQuery } from "@tanstack/react-query";
import { getSurveyList } from "../surveyApi";

export const useSurveyset = (clientId, type, orderType, orderBy) => {
  return useInfiniteQuery(
    ["surveysetList", clientId, type, orderType, orderBy],
    async ({ pageParam = 0 }) => {
      const offset = pageParam; // 페이지 번호에 따른 offset 계산
      const response = await getSurveyList({
        clientId,
        type,
        offset,
        limit: 10,
        orderType,
        orderBy,
      });
      return response;
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        const currentPage = lastPage.data.currentPage;
        const totalPages = lastPage.data.totalPages;

        if (currentPage <= totalPages - 1) {
          return currentPage + 1;
        } else {
          return undefined; // 마지막 페이지인 경우 undefined 반환
        }
      },
    }
  );
};
