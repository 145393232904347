import { createSelector } from "reselect";

export const selectGroup = (state, groupId) => {
  if (state.groups.allIds.length === 0) return null;

  return state.groups.byId[groupId];
};

export const selectAllGroups = state => {
  if (state.groups.allIds.length === 0) {
    return [];
  }
  return state.groups.allIds
    ?.filter(groupId => !state.groups.byId[groupId].hasOwnProperty("DELETED"))
    .map(groupId => state.groups.byId[groupId]);
};

export const selectAllGroupsCacheVer = createSelector(
  state => state.allIds,
  state => state.byId,
  (allIds, byId) => {
    if (allIds.length === 0) {
      return [];
    }
    return allIds
      ?.filter(groupId => !byId[groupId].hasOwnProperty("DELETED"))
      .map(groupId => byId[groupId]);
  }
);

export const selectAllMyGroups = state => {
  return selectAllGroups(state);
};

export const findGroupId = (state, groupId) => {
  return selectAllMyGroups(state).filter(group => group.groupId === groupId);
};

export const selectGroupById = createSelector(
  state => state.groups,
  (state, groupId) => groupId,
  (groups, groupId) => {
    return selectAllGroupsCacheVer(groups).find(
      group => group.groupId === groupId
    );
  }
);

// export const selectGroupById = (state ,groupId) => {
//   return selectAllGroups(state).find((group) => group.groupId === groupId)
// }

export const selectHRGroup = state => {
  return selectAllGroups(state).find(group => group.groupType === "HR");
};

export const selectSubjectGroups = state => {
  return selectAllGroups(state).filter(group => group.groupType === "SUBJECT");
};

export const selectManagingGroup = state => {
  // return selectAllGroups(state);
  return selectAllGroups(state).filter(group => group.groupType === "AFTER");
};

export const selectClassroomGroup = (state, classroom) => {
  return selectAllGroups(state).find(
    group => group.groupId === classroom?.groupId
  );
};
