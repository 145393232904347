import { useFilelinkByGroupId } from "@app/api/classroom/hooks/useFilelink";
import { SimpleGrid, Spinner, Text, VStack } from "@chakra-ui/react";
import React from "react";
import CardFileLink from "../Card/CardFileLink";
import { useSelector } from "react-redux";

const FileLinkList = ({ groupId }) => {
  const filelinkData = useFilelinkByGroupId(groupId);
  const user = useSelector((state) => state.user);

  if (filelinkData.isLoading) {
    return (
      <VStack spacing={2} mt={4}>
        <Spinner />
        <Text>파일 링크를 불러오는 중입니다.</Text>
      </VStack>
    );
  }

  if (filelinkData.isError) {
    return (
      <VStack spacing={2} mt={4}>
        <Text>공유된 파일링크가 없어요...</Text>
      </VStack>
    );
  }

  const filelinks = filelinkData.filelink.data.filelinks;

  return (
    <SimpleGrid
      columns={{
        base: 1,
        md: 1,
        lg: 2,
        xl: 3,
      }}
      spacing={2}
    >
      {filelinks?.map((filelink) => (
        <CardFileLink
          key={filelink.filelinkId}
          filelink={filelink}
          groupId={groupId}
          clientId={user.clientId}
        />
      ))}
    </SimpleGrid>
  );
};

export default FileLinkList;
