import { Select, Stack } from "@chakra-ui/react";
import React from "react";
import HeadingText from "./HeadingText";
import { SURVEY_TYPE, surveyTypes } from "@app/Constants/surveyType";

const SurveyTypeSelect = ({
  surveyType,
  handleSurveryFormChange,
  handleEmptyChoices,
  tureFalseChoices,
}) => {
  const handlSurveyTypeChange = (event) => {
    const { value } = event.target;
    handleSurveryFormChange(event);
    handleEmptyChoices();
    if (value === "OX") {
      tureFalseChoices();
    }
  };
  return (
    <Stack spacing={4} w="full" overflow="hidden">
      <HeadingText headingText="2. 설문지 유형" />
      <Select
        placeholder="설문지 유형을 선택하세요."
        value={surveyType}
        width={219}
        name="quizType"
        onChange={handlSurveyTypeChange}
      >
        {surveyTypes.map((surveyType) => (
          <option key={surveyType} value={surveyType}>
            {SURVEY_TYPE[surveyType]}
          </option>
        ))}
      </Select>
    </Stack>
  );
};

export default SurveyTypeSelect;
