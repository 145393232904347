export const PURGE_NOTIFICATION = "PURGE_NOTIFICATION";

export const CREATE_NOTIFICATION = "CREATE_NOTIFICATION";
export const CREATE_NOTIFICATION_SUCCESS = "CREATE_NOTIFICATION_SUCCESS";
export const CREATE_NOTIFICATION_FAIL = "CREATE_NOTIFICATION_FAIL";

export const GET_NOTIFICATION = "GET_NOTIFICATION";
export const GET_NOTIFICATION_SUCCESS = "GET_NOTIFICATION_SUCCESS";
export const GET_NOTIFICATION_FAIL = "GET_NOTIFICATION_FAIL";

export const FIND_NOTIFICATION = "FIND_NOTIFICATION";
export const FIND_NOTIFICATION_SUCCESS = "FIND_NOTIFICATION_SUCCESS";
export const FIND_NOTIFICATION_FAIL = "FIND_NOTIFICATION_FAIL";

export const GET_ALL_NOTIFICATION = "GET_ALL_NOTIFICATION";
export const GET_ALL_NOTIFICATION_SUCCESS = "GET_ALL_NOTIFICATION_SUCCESS";
export const GET_ALL_NOTIFICATION_FAIL = "GET_ALL_NOTIFICATION_FAIL";

// 0609 add
export const GET_CREATED_NOTIFICATION = "GET_CREATED_NOTIFICATION";
export const GET_CREATED_NOTIFICATION_SUCCESS =
  "GET_CREATED_NOTIFICATION_SUCCESS";
export const GET_CREATED_NOTIFICATION_FAIL = "GET_CREATED_NOTIFICATION_FAIL";

// 0609 add
export const PROCESS_NOTIFICATION = "PROCESS_NOTIFICATION";
export const PROCESS_NOTIFICATION_SUCCESS = "PROCESS_NOTIFICATION_SUCCESS";
export const PROCESS_NOTIFICATION_FAIL = "PROCESS_NOTIFICATION_FAIL";

export const READ_NOTIFICATION = "READ_NOTIFICATION";
export const READ_NOTIFICATION_SUCCESS = "READ_NOTIFICATION_SUCCESS";
export const READ_NOTIFICATION_FAIL = "READ_NOTIFICATION_FAIL";

export const DELETE_NOTIFICATION = "DELETE_NOTIFICATION";
export const DELETE_NOTIFICATION_SUCCESS = "DELETE_NOTIFICATION_SUCCESS";
export const DELETE_NOTIFICATION_FAIL = "DELETE_NOTIFICATION_FAIL";
