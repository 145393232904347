export const PURGE_CLASSROOM = "PURGE_CLASSROOM";

export const GET_CLASSROOM_SUCCESS = "GET_CLASSROOM_SUCCESS";
export const GET_CLASSROOM_FAIL = "GET_CLASSROOM_FAIL";

export const GET_ACTIVE_CLASSROOM_SUCCESS = "GET_ACTIVE_CLASSROOM_SUCCESS";
export const GET_ACTIVE_CLASSROOM_FAIL = "GET_ACTIVE_CLASSROOM_FAIL";

export const SET_ACTIVE_CLASSROOM_EXIST = "SET_ACTIVE_GROUP_EXIST";
export const SET_ACTIVE_CLASSROOM_NOT_EXIST = "SET_ACTIVE_GROUP_NOT_EXIST";

export const CREATE_CLASSROOM_SUCCESS = "CREATE_CLASSROOM_SUCCESS";
export const CREATE_CLASSROOM_FAIL = "CREATE_CLASSROOM_FAIL";

export const UPDATE_CLASSROOM_LOADING = "UPDATE_CLASSROOM_LOADING";
export const UPDATE_CLASSROOM_SUCCESS = "UPDATE_CLASSROOM_SUCCESS";
export const UPDATE_CLASSROOM_FAIL = "UPDATE_CLASSROOM_FAIL";

export const DELETE_CLASSROOM_SUCCESS = "DELETE_CLASSROOM_SUCCESS";
export const DELETE_CLASSROOM_FAIL = "DELETE_CLASSROOM_FAIL";

export const SET_SCREEN_SORT_OPTION = "SET_SCREEN_SORT_OPTION";
