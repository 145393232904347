import { useEffect } from "react";
import { createDeviceInfo } from "@app/api/device/deviceApi";
import { useSelector } from "react-redux";
import useUserAgent from "@app/utils/deviceInfo/useUserAgent";

const SiteManager = () => {
  const user = useSelector((state) => state.user);
  const userAgent = useUserAgent();

  // const getUserAgent = () => {
  //   const userAgent = window.navigator.userAgent;
  //   const platform =
  //     window.navigator?.userAgentData?.platform ?? window.navigator.platform;
  //   const macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"];
  //   const windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"];
  //   const iosPlatforms = ["iPhone", "iPad", "iPod"];
  //   // let os = null;

  //   if (macosPlatforms.indexOf(platform) !== -1) {
  //     // os = "Mac OS";
  //     os = "iOS";
  //   } else if (iosPlatforms.indexOf(platform) !== -1) {
  //     os = "iOS";
  //   } else if (windowsPlatforms.indexOf(platform) !== -1) {
  //     os = "Windows";
  //   } else if (/Android/.test(userAgent)) {
  //     os = "Android";
  //   } else if (!os && /Linux/.test(platform)) {
  //     os = "Linux";
  //   }

  //   return os;
  // };

  // const getUserBrowser = () => {
  //   let userAgent = window.navigator.userAgent;
  //   let browser = "";
  //   let browserVersion = "";
  //   if (userAgent.indexOf("Edg") !== -1) {
  //     // Microsoft Edge 브라우저를 사용 중입니다.
  //     browser = "Microsoft Edge";
  //     browserVersion = userAgent.match(/Edg\/([\d.]+)/)[1];
  //   } else if (userAgent.indexOf("Chrome") !== -1) {
  //     // Chrome 브라우저를 사용 중입니다.
  //     browser = "Google Chrome";
  //     browserVersion = userAgent.match(/Chrome\/([\d.]+)/)[1];
  //   } else if (userAgent.indexOf("Firefox") !== -1) {
  //     // Firefox 브라우저를 사용 중입니다.
  //     browser = "Mozilla Firefox";
  //     browserVersion = userAgent.match(/Firefox\/([\d.]+)/)[1];
  //   } else if (userAgent.indexOf("Safari") !== -1) {
  //     // Safari 브라우저를 사용 중입니다.
  //     browser = "Apple Safari";
  //     browserVersion = userAgent.match(/Version\/([\d.]+)/)[1];
  //   } else if (
  //     userAgent.indexOf("MSIE") !== -1 ||
  //     userAgent.indexOf("Trident") !== -1
  //   ) {
  //     // Internet Explorer를 사용 중입니다.
  //     browser = "Internet Explorer";
  //   } else {
  //     // 기타 브라우저를 사용 중입니다.
  //     browser = "알 수 없는 브라우저";
  //   }

  //   return [browser, browserVersion];
  // };

  useEffect(() => {
    if (!user.signedIn) {
      return;
    }

    const deviceProps = {
      schoolId: user.schoolId,
      clientId: user.clientId,
      deviceOs: userAgent.os,
      webBrowser: userAgent.browser,
      webVersion: userAgent.browserVersion,
    };

    createDeviceInfo(deviceProps).then((res) => console.log("res", res));
  }, [user]);

  return <div />;
};

export default SiteManager;
