import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  deleteProgramconfig,
  getGroupProgramconfigs,
  getProgramconfig,
  updateProgramconfig,
} from "@api";
import { createProgramconfig } from "@app/api/programconfig/programconfigApi";

export const useGetProgramConfigByProgramConfigId = (programconfigId) => {
  const queryClient = useQueryClient();
  const { data, isLoading, error } = useQuery(
    {
      queryKey: ["programconfig", programconfigId],
      queryFn: () => getProgramconfig(programconfigId),
      enabled: !!programconfigId,
      // select: data =>
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ["programconfig", programconfigId],
        });
      },
      onError: () => {
        queryClient.removeQueries({
          queryKey: ["programconfig", programconfigId],
        });
      },
    }
  );

  return { data, isLoading, error };
};
export const useGetProgramConfigsByGroupId = (groupId) => {
  const { data, isLoading, isSuccess, isError } = useQuery({
    queryKey: ["programconfig", groupId],
    queryFn: () => {
      return getGroupProgramconfigs(groupId);
    },
    enabled: !!groupId,
  });
  return { data, isLoading, isError };
};

export const useProgramconfigs = (groupId) => {
  const { data, isLoading, isError } = useQuery({
    queryKey: ["programconfig", groupId],
    queryFn: () => {
      return getGroupProgramconfigs(groupId);
    },
    enabled: !!groupId,
  });

  return { data, isLoading, isError };
};

export const useCreateProgramConfig = (groupId) => {
  const queryClient = useQueryClient();
  const { mutateAsync, isLoading, isError, isSuccess } = useMutation(
    createProgramconfig,
    {
      onSuccess: () => {
        queryClient.refetchQueries(["programconfig", groupId]);
      },
    }
  );
  const createProgramconfigAsync = async (programconfigData) => {
    mutateAsync(programconfigData);
  };
  return {
    createProgramconfigAsync,
    isLoading,
    isError,
    isSuccess,
  };
};

export const useUpdateProgramconfig = (groupId) => {
  const queryClient = useQueryClient();
  const { mutateAsync, isLoading, isError, isSuccess } = useMutation(
    updateProgramconfig,
    {
      onSuccess: () => {
        queryClient.refetchQueries(["programconfig", groupId]);
      },
    }
  );
  const updateProgramconfigAsync = async (programconfigData) => {
    mutateAsync(programconfigData);
  };
  return {
    updateProgramconfigAsync,
    isLoading,
    isError,
    isSuccess,
  };
};
export const useDeleteProgramconfigByProgramConfigId = (groupId) => {
  const queryClient = useQueryClient();
  const { mutateAsync, isLoading, isError, isSuccess } = useMutation(
    deleteProgramconfig,
    {
      onSuccess: () => {
        queryClient.refetchQueries(["programconfig", groupId]);
      },
    }
  );
  const deleteProgramconfigAsync = async (programconfigId) => {
    queryClient.invalidateQueries(["programconfig", programconfigId]);
    mutateAsync(programconfigId);
  };
  return {
    deleteProgramconfigAsync,
    isLoading,
    isError,
    isSuccess,
  };
};
