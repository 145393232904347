"use client";

import React, { ReactNode, useState } from "react";
import {
  IconButton,
  Box,
  CloseButton,
  Flex,
  Icon,
  useColorModeValue,
  Text,
  Drawer,
  DrawerContent,
  useDisclosure,
  Stack,
  VStack,
  Img,
} from "@chakra-ui/react";
import { FiHome, FiSettings, FiMenu } from "react-icons/fi";
import {
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import GradeClassStatus from "@app/components/Drawer/GradeClassStatus";
import { selectActiveClassroom, selectGroupById } from "@app/store/selectors";
import { useSelector } from "react-redux";
import InClassInformation from "@app/components/Drawer/InclassInformation";
import InclassInviteCode from "@app/components/Drawer/InclassInviteCode";
import focuspangLogo from "assets/images/logo/focuspang_ai.png";
import { GiNotebook } from "react-icons/gi";
import { LuBellDot } from "react-icons/lu";
import { FaRegChartBar } from "react-icons/fa";
import { BsPeople } from "react-icons/bs";
import { HiOutlineComputerDesktop } from "react-icons/hi2";
import { TbPencilQuestion } from "react-icons/tb";
import { RiSurveyLine } from "react-icons/ri";
import styled from "styled-components";
import { getTimetablesByGroupId } from "@app/api/timetable/timetableApi";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "@tanstack/react-query";

export default function SidebarClassroom({ children }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Box minH="100vh" bg={useColorModeValue("white", "gray.900")}>
      <SidebarContent
        onClose={() => onClose}
        display={{ base: "none", md: "block" }}
      />
      <Drawer
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent onClose={onClose} />
        </DrawerContent>
      </Drawer>
      {/* mobilenav */}
      <MobileNav display={{ base: "flex", md: "none" }} onOpen={onOpen} />
      <Box ml={{ base: 0, md: 60 }} p="4">
        {children}
      </Box>
    </Box>
  );
}

const SidebarContent = ({ onClose, ...rest }) => {
  const { t } = useTranslation();

  const { groupId } = useParams();
  const { state } = useLocation();
  const group = useSelector((state) => selectGroupById(state, groupId));
  const activeClassroom = useSelector((state) => selectActiveClassroom(state));
  const [data, setData] = useState();

  const cacheData = useQueryClient();
  const timetableData = cacheData?.getQueryData(["timetable", groupId]);

  useEffect(() => {
    if (!timetableData) {
      getTimetablesByGroupId(groupId).then((data) => {
        cacheData.setQueryData(["timetable", groupId], data.data);
      });
    }

    setData(timetableData?.timetables[0]);
  }, [timetableData, groupId]);

  const LinkItems = [
    {
      // name: '처음으로',
      name: t("sidemenu.menu.home"),
      icon: FiHome,
      link: "/home",
    },
    {
      // name: '수업 공지사항',
      name: t("timetable.nav.button.notification"),
      icon: LuBellDot,
      link: "/notice",
    },
    {
      // name: '대시보드',
      name: t("timetable.nav.button.dashboard"),
      icon: FaRegChartBar,
      link: "/dashboard",
    },
    {
      // name: '학생 목록',
      name: t("timetable.nav.button.studentlist"),
      icon: BsPeople,
      link: "/students",
    },
    {
      // name: '포커스 타임',
      name: t("timetable.nav.button.focustime"),
      icon: HiOutlineComputerDesktop,
      link: "/classroom",
    },
    {
      // name: '모둠 활동',
      name: t("timetable.nav.button.groupactivity"),
      icon: FiSettings,
      link: "/group-activity",
    },
    {
      // name: "과제관리",
      name: t("timetable.nav.button.assignmentmanagement"),
      icon: GiNotebook,
      link: "/assignment",
    },
    {
      // name: "퀴즈팡",
      name: t("timetable.nav.button.quizpang"),
      icon: TbPencilQuestion,
      link: "/quiz-pang",
    },
    // { name: "설문지", icon: RiSurveyLine, link: "/survey" },
  ];

  return (
    <Box
      bg={useColorModeValue("white", "gray.900")}
      borderRight="1px"
      borderRightColor={useColorModeValue("gray.200", "gray.700")}
      w={{ base: "full", md: 60 }}
      pos="fixed"
      display={"block"}
      h="full"
      {...rest}
    >
      <VStack align="flex-start" spacing="2" p="4">
        {/* <GradeClassStatus
          grade={group.grade}
          groupName={group.groupName}
          subjectName={group.subjectName}
        /> */}
        <Stack align={"flex-end"} w={"full"} spacing={0}>
          <CloseButton
            display={{ base: "flex", md: "none" }}
            onClick={onClose}
          />
        </Stack>
        <InClassInformation group={group} data={data} />
        <InclassInviteCode />
      </VStack>
      <HiddenScrollDiv>
        {activeClassroom?.classroomId
          ? LinkItems.slice(1).map((link) => (
              <NavItem key={link.name} icon={link.icon} link={link.link}>
                {link.name}
              </NavItem>
            ))
          : LinkItems.map((link) => (
              <NavItem key={link.name} icon={link.icon} link={link.link}>
                {link.name}
              </NavItem>
            ))}
      </HiddenScrollDiv>
    </Box>
  );
};

const NavItem = ({ icon, children, link, ...rest }) => {
  const location = useLocation();
  const isActive = location.pathname.slice(0, link.length) === link;

  const { state } = useLocation();
  const history = useHistory();
  const { groupId } = useParams();

  const isCurrentPage = location.pathname === link + `/${groupId}`;
  return (
    <Box
      as="a"
      onClick={() => {
        if (link === "/home") {
          history.push(`${link}`);
        } else {
          if (!isCurrentPage) {
            history.push({
              pathname: `${link}/${groupId}`,
              state,
            });
          }
        }
      }}
      style={{ textDecoration: "none" }}
      _focus={{ boxShadow: "none" }}
    >
      <Flex
        align="center"
        p="4"
        mx="4"
        mt="2"
        borderRadius="lg"
        role="group"
        cursor="pointer"
        bg={isActive ? "gray.200" : "transparent"} // 활성화 상태에 따라 배경색 변경
        color={isActive ? "black" : "initial"} // 활성화 상태에 따라 텍스트 색상 변경
        _hover={{
          bg: "gray.400",
          color: "white",
        }}
        {...rest}
      >
        {icon && (
          <Icon
            mr="4"
            fontSize="16"
            _groupHover={{
              color: "white",
            }}
            as={icon}
            color={isActive ? "black" : "initial"}
          />
        )}
        {children}
      </Flex>
    </Box>
  );
};
const MobileNav = ({ onOpen, ...rest }) => {
  return (
    <Flex
      ml={{ base: 0, md: 60 }}
      px={{ base: 4, md: 24 }}
      height="20"
      alignItems="center"
      bg={useColorModeValue("white", "gray.900")}
      borderBottomWidth="1px"
      borderBottomColor={useColorModeValue("gray.200", "gray.700")}
      justifyContent="flex-start"
      {...rest}
    >
      <IconButton
        variant="outline"
        onClick={onOpen}
        aria-label="open menu"
        icon={<FiMenu />}
      />

      <Text fontSize="2xl" ml="8" fontFamily="monospace" fontWeight="bold">
        <Img src={focuspangLogo} h={{ base: "20px", md: "30px" }} />
      </Text>
    </Flex>
  );
};
export const HiddenScrollDiv = styled.div`
  height: 65vh;
  overflow: auto;
  & {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }
`;
