import Sidebar from "@app/components/Layout/Sidebar";
import { ChevronLeftIcon } from "@chakra-ui/icons";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Divider,
  Grid,
  GridItem,
  HStack,
  Text,
  VStack,
  useMediaQuery,
} from "@chakra-ui/react";
import React from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import QuizSplitView from "./QuizSplitView";
import QuizSingleView from "./QuizSingleView";
import SidebarWithHeader from "./../../components/Layout/Common/Sidebar";
import SurveyForm from "@app/components/SurveryForm/SurveyForm";
import SurveyView from "@app/components/SurveryForm/SurveyView";

const QuizCreateHome = () => {
  const history = useHistory();
  const quizsetType = window.location.pathname.split("/")[2];
  const quizTypeSwitch = (quizsetType) => {

    switch (quizsetType) {
      case "quiz-ab": {
        return <QuizSplitView />;
      }
      case "quiz": {
        return <QuizSingleView quizsetType={"QUIZ"} />;
      }
      case "survey": {
        return <SurveyView/>;
      }
      default: {
        break;
      }
    }
  };
  const isSurvey = quizsetType === "survey";
  const [isLargerThan1200] = useMediaQuery("(min-width: 1201px)");
  return (
    <SidebarWithHeader>
      <GridItem area={"main"} p={16}>
        <VStack spacing={4} align={"flex-start"}>
          <HStack
            spacing={2}
            justifyContent={"space-between"}
            w={"full"}
            h={"full"}
          >
            <Button
              leftIcon={<ChevronLeftIcon />}
              onClick={() => {
                history.goBack();
              }}
            >
              <Text>뒤로가기</Text>
            </Button>
            <Breadcrumb>
              <BreadcrumbItem>
                <BreadcrumbLink href="/quiz-management">
                  <Text fontSize={"2xl"}>퀴즈팡 관리</Text>
                </BreadcrumbLink>
              </BreadcrumbItem>
              <BreadcrumbItem>
                <BreadcrumbLink isCurrentPage>
                  <Text fontSize={"2xl"} fontWeight={"bold"}>
                    {!isSurvey ? "퀴즈 만들기" : "설문지 만들기"}
                  </Text>
                </BreadcrumbLink>
              </BreadcrumbItem>
            </Breadcrumb>
          </HStack>
          <Divider w={"full"} color="gray.200" />
          {quizTypeSwitch(quizsetType)}
        </VStack>
      </GridItem>
    </SidebarWithHeader>
  );
};

export default QuizCreateHome;
