import { getProgram, getPrograms } from "@api";
import { useQuery } from "@tanstack/react-query";

export const useGetProgramByProgramId = (programId) => {
  const { data, isLoading, error } = useQuery({
    queryKey: ["program", programId],
    queryFn: () => getProgram(programId),
    enabled: !!programId,
  });
  return { data, isLoading, error };
};

export const useGetPrograms = () => {
  const { data, isLoading, isError, refetch } = useQuery({
    queryKey: ["programAll"],
    queryFn: () => getPrograms(),
    select: (data) => data.data.programs,
  });

  return { data, isLoading, isError, refetch };
};
