import {
  Box,
  Button,
  Divider,
  HStack,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import ModalClassManagementAddAppHeader from "../Layout/ModalClassManagementAddAppHeader";
import { useSelector } from "react-redux";
import {
  useCreateProgramConfig,
  useDeleteProgramconfigByProgramConfigId,
} from "@app/hooks/queries/programconfigs/useProgramConfigs";
import { useGetPrograms } from "@app/hooks/queries/program/useProgram";
import ModalClassManagementAddAppBody from "../Layout/ModalClassManagementAddAppBody";
import { useQueryClient } from "@tanstack/react-query";

const ModalClassManagementAddApp = ({
  groupId,
  onClose,
  isOpen,
  motionPreset,
  ...rest
}) => {
  const user = useSelector((state) => state.user);
  const cacheData = useQueryClient();
  const programData = cacheData.getQueryData(["programAll"]);

  const [scrollBehavior, setScrollBehavior] = React.useState("inside");
  const [checkedPrograms, setCheckedPrograms] = useState([]);

  const { createProgramconfigAsync } = useCreateProgramConfig(groupId);
  const { deleteProgramconfigAsync } = useDeleteProgramconfigByProgramConfigId(
    groupId
  );

  const [frequentlyPrograms, setFrequentlyPrograms] = useState([]);
  const [mediaPrograms, setMediaPrograms] = useState([]);
  const [basicsPrograms, setBasicsPrograms] = useState([]);

  useEffect(() => {
    if (!programData) {
      return;
    }

    const frequentlyPrograms = programData?.data.programs.filter(
      (program) => program.sorting === 0
    );
    const mediaPrograms = programData?.data.programs.filter(
      (program) => program.sorting === 1
    );
    const basicsPrograms = programData?.data.programs.filter(
      (program) => program.sorting === 2
    );

    setFrequentlyPrograms(frequentlyPrograms);
    setMediaPrograms(mediaPrograms);
    setBasicsPrograms(basicsPrograms);
  }, [programData]);

  const handleCreateProgramConfig = () => {
    const newProgramconfigs = checkedPrograms
      .filter((checkedProgram) => checkedProgram.listType === "new")
      .map((checkedProgram) => {
        return {
          programId: checkedProgram.programId,
          groupId: groupId,
          clientId: user.clientId,
          dayMax: 0,
          isAllowed: checkedProgram.isAllowed,
        };
      });

    const existingProgramconfigs = checkedPrograms
      .filter((checkedProgram) => checkedProgram.listType === "existing")
      .map((checkedProgram) => {
        return {
          programconfigId: checkedProgram.programconfigId,
        };
      });

    if (newProgramconfigs.length !== 0) {
      newProgramconfigs.forEach((newProgramconfig) => {
        createProgramconfigAsync(newProgramconfig);
      });
    }

    if (existingProgramconfigs.length !== 0) {
      existingProgramconfigs.forEach((existingProgramconfig) => {
        deleteProgramconfigAsync(existingProgramconfig.programconfigId);
      });
    }

    setCheckedPrograms([]);

    onClose();
  };

  return (
    <Modal
      isCentered
      onClose={onClose}
      isOpen={isOpen}
      motionPreset="slideInBottom"
    >
      <ModalOverlay backdropFilter="blur(10px)" />
      <ModalContent bg="#f5f5f5">
        <ModalHeader>제어할 앱과 웹사이트 선택</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack direction="column" spacing="4">
            <Stack direction="column" spacing="2">
              <ModalClassManagementAddAppHeader
                title={"자주 사용하는 앱과 웹사이트"}
              />
              <ModalClassManagementAddAppBody
                groupId={groupId}
                programs={frequentlyPrograms}
                setCheckedPrograms={setCheckedPrograms}
              />
              <Divider color="gray.200" />
            </Stack>
            <Stack direction="column" spacing="2">
              <ModalClassManagementAddAppHeader />
              <ModalClassManagementAddAppBody
                groupId={groupId}
                programs={mediaPrograms}
                setCheckedPrograms={setCheckedPrograms}
              />
            </Stack>
            <Divider color="gray.200" />
            <Stack direction="column" spacing="2">
              <ModalClassManagementAddAppHeader title={"기본 앱과 웹사이트"} />
              <ModalClassManagementAddAppBody
                groupId={groupId}
                programs={basicsPrograms}
                setCheckedPrograms={setCheckedPrograms}
              />
            </Stack>
          </Stack>
        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme="blue"
            onClick={() => {
              handleCreateProgramConfig();
            }}
          >
            저장
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalClassManagementAddApp;
