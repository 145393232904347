import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import styles from "./renewal.module.scss";
import MultiLanguageSelectMenu from "@app/components/Buttons/MultiLanguage/MultiLanguageSelectMenu";

const Renewal = () => {
  const history = useHistory();
  const user = useSelector((state) => state.user);
  const { t } = useTranslation();

  useEffect(() => {
    if (
      user.state === "REGISTERED_APPROVED" ||
      user.state === "REGISTERED_CERT" ||
      user.state === "REGISTERED_PENDING" ||
      user.state === "REGISTERED_GOOGLEFORMS"
    ) {
      console.log("RESULT OF USEEFFECT", user);
      // redirect to auth manager
      history.replace("/auth-manager");
      // window.location.href = "/auth-manager";
    }
  }, [user.state]);

  const onSubmit = (e) => {
    console.log("submit email");
    history.replace("/auth");
  };

  const Intro = {
    // title: "새롭게 달라진 포커스팡 ai 시작하기",
    title: "새롭게 달라진 포커스팡 ai 시작하기",
    // formTitle: "포커스팡 선생님 로그인",
    formTitle: "포커스팡 선생님 로그인",
  };

  return (
    <div className={styles["container"]}>
      <div className={styles["inner-box"]}>
        <div style={{ flex: 1 }}>
          <div className={styles["Span-greeting"]}>
            {/* 포커스팡 ai에 오신것을 환영합니다. */}
            {t("landing.text.welcome")}
          </div>
          <div className={styles["middle-text"]}>{Intro.desc}</div>
          <div
            style={{
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              marginTop: 50,
            }}
          >
            <button onClick={onSubmit} className={styles["start-button"]}>
              {/* 포커스팡 ai 시작 하기 */}
              {t("landing.button.enter")}
            </button>
          </div>
          <div
            style={{
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              marginTop: 50,
            }}
          >
            <MultiLanguageSelectMenu />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Renewal;
