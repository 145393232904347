import React from "react";
import { uploadNoticeFile } from "@api";
import { Tooltip } from "@material-ui/core";
import { useCallback, useEffect } from "react";
import { memo, useState } from "react";
import ReactQuill from "react-quill";
import { useSelector } from "react-redux";
import styled from "styled-components";
import Loading from "../Loading/Loading";

const modules = {
  toolbar: [
    //[{ 'font': [] }],
    [{ header: [1, 2, 3, false] }],
    ["bold", "italic", "underline", "strike"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link"],
    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
  ],
};

const formats = [
  //'font',
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "list",
  "bullet",
  "link",
  "color",
  "image",
  "background",
];

const TextEditorComponent = ({ value, onChange, isFile, style }) => {
  const user = useSelector((state) => state.user);
  const [file, setFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const fileUpload = useCallback(
    async (_file) => {
      setIsLoading(true);
      const fileLink = await uploadNoticeFile(_file, user.clientId);
      onChange(
        (prev) =>
          `${prev}<p>첨부파일 : <a href="${fileLink}">${fileLink}</a><p>`
      );
      setFile(null);
      setIsLoading(false);
    },
    [onChange, user.clientId]
  );

  useEffect(() => {
    file && fileUpload(file);
  }, [file, fileUpload]);

  const fileChange = useCallback((e) => {
    setFile(e.target.files[0]);
  }, []);

  return (
    <StyledContainer isFile={isFile}>
      {isFile !== false && (
        <Tooltip title="파일 첨부가 가능합니다." arrow>
          <FileUpload>
            파일첨부
            <input type="file" hidden onChange={fileChange} />
          </FileUpload>
        </Tooltip>
      )}
      <ReactQuill
        theme="snow"
        modules={modules}
        formats={formats}
        value={value || ""}
        onChange={(_content, _delta, _source, editor) =>
          onChange(editor.getHTML())
        }
        // 높이 설정
        style={{
          height: "auto",
          minHeight: "300px",
          overflow: "scroll",
          paddingBottom: "100px",
          ...style,
        }}
      />
      {isLoading && <StyledLoading />}
    </StyledContainer>
  );
};
const StyledContainer = styled.div`
  background-color: white;
  position: relative;
  padding: 4px 12px 12px;
  border: 1px solid #bbb;
  border-radius: 4px;

  .ql-container.ql-snow {
    border: 0;
    /* height: ${(p) => (p.isFile !== false ? "auto" : "auto")}px; */
  }
  .ql-toolbar.ql-snow {
    border: 0;
    border-bottom: 1px solid #bbb;
  }
  .ql-snow .ql-editor {
    h1,
    h2,
    h3 {
      margin-top: 0;
      margin-bottom: 8px;
      font-weight: 500;
      line-height: 1.2;
    }
    p {
      margin-top: 0;
      margin-bottom: 16px;
    }
    ol {
      list-style: decimal;
    }
    ul {
      list-style: disc;
    }
    ol,
    ul {
      margin-top: 0;
      padding-left: 32px;
      margin-bottom: 16px;
    }
    h1 {
      font-size: 39px;
    }
    h2 {
      font-size: 31px;
    }
    h3 {
      font-size: 27px;
    }
    *:last-child {
      margin-bottom: 0;
    }
  }
`;
const FileUpload = styled.label`
  position: absolute;
  top: 8px;
  right: 16px;
  font-size: 14px;
  color: var(--grey-lighten-1);

  width: 78px;
  height: 28px;
  text-align: center;

  background-color: var(--primary);
  padding: 4px;
  border-radius: 24px;
  cursor: pointer;

  &:hover {
    background-color: var(--third);
  }
`;
const StyledLoading = styled(Loading)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

export const TextEditorForQuizpang = memo(TextEditorComponent);

const ReadOnlyContainer = styled(StyledContainer)`
  padding: unset;
  border: unset;
  .ql-editor {
    /* padding: unset; */
    height: auto;
  }
  .ql-container.ql-snow {
    /* height: unset; */
  }
`;

export const ReadOnlyTextEditor = ({ text }) => {
  return (
    <ReadOnlyContainer>
      <ReactQuill
        theme="snow"
        modules={{ toolbar: false }}
        formats={formats}
        value={text}
        readOnly
      />
    </ReadOnlyContainer>
  );
};
