import {
  SET_COMMENTS_SEND_CLIENT,
  SET_COMMENTS_LAYER_PATH,
  SET_COMMENTS_STUDENT,
  SET_COMMENTS_SHARED_SCREEN,
  SET_COMMENTS_STROKE_COLOR,
  GET_COMMENTS_LAYER_FAIL,
  GET_COMMENTS_LAYER_SUCCESS,
  UPDATE_COMMENTS_LAYER_FAIL,
  CREATE_COMMENTS_LAYER_FAIL,
  CREATE_COMMENTS_LAYER_SUCCESS,
  UPDATE_COMMENTS_LAYER_SUCCESS,
} from "./actionTypes";
import randomColor from "randomcolor";

const color = randomColor({ luminosity: "light", count: 24 });
const strokeColor = color[Math.floor(Math.random() * color.length)];

const initialState = {
  paths: [],
  selectedClientIds: [],
  strokeColor,
  commentsLayer: {},
  studentComments: {},
  studentSharedClientId: "",
  studentSharedImage: "",
  commentsLayerState: false,
};

const commentsLayer = (state = initialState, action) => {
  switch (action.type) {
    case SET_COMMENTS_SEND_CLIENT:
      return {
        ...state,
        selectedClientIds: action.payload,
      };
    case SET_COMMENTS_LAYER_PATH:
      return {
        ...state,
        paths: action.payload,
      };
    case SET_COMMENTS_STUDENT:
      return {
        ...state,
        studentComments: {
          ...state.studentComments,
          [action.payload.clientId]: action.payload.image,
        },
      };
    case SET_COMMENTS_SHARED_SCREEN:
      return {
        ...state,
        studentSharedImage: action.payload.sharedImage,
        studentSharedClientId: action.payload.sharedClientId,
      };
    case SET_COMMENTS_STROKE_COLOR:
      return {
        ...state,
        strokeColor: action.payload,
      };
    case GET_COMMENTS_LAYER_SUCCESS:
      const commentsLayers = action.payload.commentsLayers;
      if (commentsLayers?.length) {
        return {
          ...state,
          commentsLayer: commentsLayers[commentsLayers.length - 1],
          studentComments: {},
          selectedClientIds: [],
        };
      }
      return {
        ...state,
        commentsLayer: {},
        studentComments: {},
        selectedClientIds: [],
        studentSharedImage: "",
        studentSharedClientId: "",
      };
    case CREATE_COMMENTS_LAYER_SUCCESS:
      return {
        ...state,
        commentsLayer: {
          ...action.payload,
          isActivated: true,
        },
        studentComments: {},
        selectedClientIds: [],
        commentsLayerState: true,
      };
    case UPDATE_COMMENTS_LAYER_SUCCESS:
      return {
        ...state,
        commentsLayer: action.payload,
        studentComments: {},
        selectedClientIds: [],
        commentsLayerState: !state.commentsLayerState,
      };
    case GET_COMMENTS_LAYER_FAIL:
    case CREATE_COMMENTS_LAYER_FAIL:
    case UPDATE_COMMENTS_LAYER_FAIL:
      // console.error("[Comments Layer Reducer] ", action.payload);
      return state;

    default:
      return state;
  }
};

export default commentsLayer;
