import React, { useState } from "react";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { makeStyles } from "@material-ui/core/styles";
import { TextField } from "@mui/material";

const useStyles = makeStyles(theme => ({
  root: {
    "& .MuiFilledInput-root": {
      marginBottom: 20,
      borderBottom: "none"
    },
    "& .MuiFilledInput-root.Mui-focused": {
      backgroundColor: "#0BA1AE15"
    },
    "& .MuiFilledInput-root:before": {
      borderBottom: "none"
    },
    "& .MuiFilledInput-root:hover:not(.Mui-disabled):before": {
      borderBottom: "none"
    }
  }
}));

const Password = props => {
  const { name, label } = props;

  const [passwordVisibility, setPasswordVisibillity] = useState(false);
  const classes = useStyles();

  return (
    <TextField
      name={name}
      label={label}
      {...props}
      fullWidth
      type={passwordVisibility ? "text" : "password"}
      variant="filled"
      className={classes.root}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => setPasswordVisibillity(prev => !prev)}
              edge="end"
              style={{ marginRight: 0 }}
            >
              {passwordVisibility ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        )
      }}
    />
  );
};

export default Password;
