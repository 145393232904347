import { Box, Stack, Typography, styled } from "@mui/material";
import React from "react";
import kakao from "assets/images/notice/kakao.png";
import newFP from "assets/images/notice/newFP.png";
import styledComponents from "styled-components";
import SimpleCarousel from "../Card/SimpleCarousel";
import { NotiPanel } from "./NotiPanel";
import { useMediaQuery } from "@chakra-ui/react";

const backgroundColorMap = {
  home: "#ffffff",
  notice: "#BFF6FB",
  dashboard: "#27AF82",
  students: "#F0BB51",
  "students-managed": "#F0BB51",
  classroom: "#ffffff",
  "group-activity": "#ffffff",
  assignment: "#ffffff",
  "quiz-pang": "#ffffff",
  managing: "#ffffff",
  settings: "#ffffff",
};

const colorMap = {
  home: "#000000",
};

const Header = ({
  title,
  buttons,
  leftDiscription,
  rightDiscription,
  banner,
}) => {
  const { pathname } = window.location;
  const [, domain] = pathname.split("/");

  const [isLargerThan767] = useMediaQuery("(min-width: 768px)");

  const onChange = (currentSlide) => {};
  return (
    <>
      {" "}
      <Container
        sx={{
          background: backgroundColorMap[domain],
          color: colorMap[domain],
          padding: isLargerThan767 ? "0 2rem" : "0 1rem",
        }} // 헤더 배경, 글씨 색 수정
      >
        <ContentsWrapper>
          <TitleRow>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                width: "100%",
                borderRadius: "8px",
              }}
            >
              <Stack direction="row" spacing={2}>
                <Typography variant="h5" fontWeight="bold" minHeight="38.5px">
                  {title}
                </Typography>
                {buttons && buttons.map((item) => item)}{" "}
              </Stack>
            </Box>
            <Box
              sx={{
                width: "40%",
                borderRadius: "8px",
              }}
            >
              {domain === "home" && <NotiPanel />}
            </Box>
          </TitleRow>
          <DiscriptionRow>
            {leftDiscription && <li>{leftDiscription}</li>}
            {rightDiscription && <li>{rightDiscription}</li>}
          </DiscriptionRow>
        </ContentsWrapper>
      </Container>
    </>
  );
};

export default Header;

const Container = styled(Stack)`
  display: flex;
`;

const ContentsWrapper = styled(Stack)`
  display: flex;
  flex-direction: column;
  height: fit-content;
  padding: 0;
  gap: 16px;
`;

const TitleRow = styled(Stack)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 18px;
  padding: 1rem 0;
  width: 100%;
  border-bottom: 1px solid #999999;
`;
const DiscriptionRow = styled("ul")`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 0rem 8px;
  justify-content: space-between;
  list-style: disc;
  list-style-position: inside;
  font-size: 12px;
`;

const Image = styledComponents.img`
    cursor: pointer;
  `;
