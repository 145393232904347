import { profileImgPath } from "@api";
import {
  Avatar,
  Box,
  Button,
  Divider,
  HStack,
  IconButton,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Stack,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import React from "react";
import { useSelector } from "react-redux";
import ClassroomTimer from "../Card/CardClassroomTimer";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import ReplyIcon from "@mui/icons-material/Reply";
import LockIcon from "@mui/icons-material/Lock";
import { RiFocus2Fill } from "react-icons/ri";

const ModalTeacherFullScreen = ({
  capturedImage,
  loading,
  setLoading,
  onClose,
  isOpen,
  activeClassroom,
  isSharing,
  isScreenShared,
  stopSharing,
  startSharing,
  selectedStudent,
  setSelectedStudent,
  selectedImage,
  setSelectedImage,
}) => {
  const user = useSelector((state) => state.user);

  return (
    <Modal size={"full"} onClose={onClose} isOpen={isOpen}>
      <ModalOverlay />
      <ModalContent>
        <ModalBody>
          {activeClassroom && isSharing && capturedImage && (
            <Image
              boxSize="full"
              src={capturedImage}
              alt="Captured Screen"
              style={{
                height: "100dvh",
                display: loading ? "none" : "block",
                objectFit: "contain",
              }}
              onLoad={() => setLoading(false)}
            />
          )}

          {activeClassroom && selectedStudent && selectedImage && (
            <Image
              boxSize="full"
              src={`data:image/webp;base64,${selectedImage}`}
              alt="Captured Screen"
              style={{
                display: loading ? "none" : "block",
                objectFit: "contain",
              }}
              onLoad={() => setLoading(false)}
            />
          )}
        </ModalBody>
        <ModalFooter>
          <Stack direction="column" spacing={2} w={"full"}>
            <Divider color="gray.300" />
            <Box p={2} w="full">
              <HStack spacing={4} justifyContent="space-between">
                <HStack>
                  <HStack>
                    <Avatar
                      size="sm"
                      name={user?.userName}
                      src={profileImgPath(user?.clientId)}
                    />
                    <Text fontSize="xs">{user?.userName}</Text>
                  </HStack>
                  <ClassroomTimer />
                </HStack>
                <HStack>
                  <Menu>
                    <Tooltip
                      hasArrow
                      label="실시간으로 공유 중인 선생님이 지정하신 화면을 중단할 수 있습니다."
                    >
                      <MenuButton
                        as={IconButton}
                        icon={
                          <ReplyIcon color={isSharing ? "white" : "black"} />
                        }
                        borderRadius="full"
                        backgroundColor={isSharing ? "#4684e7" : ""}
                        _hover={{ backgroundColor: "#4684e7" }}
                        onClick={() => {
                          if (isSharing) {
                            stopSharing();
                          }
                        }}
                      />
                    </Tooltip>
                    <MenuList>
                      <MenuItem>
                        <Text>선택한 학생 화면 공유</Text>
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          setSelectedStudent(null);
                          setSelectedImage(null);
                          startSharing();
                        }}
                      >
                        <Text>나의 화면 공유</Text>
                      </MenuItem>
                    </MenuList>
                  </Menu>
                  <Button onClick={onClose}>전체화면 종료</Button>
                </HStack>
              </HStack>
            </Box>
          </Stack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalTeacherFullScreen;
